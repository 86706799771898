// @flow
import React from 'react'
import type { Field } from 'lib/types'
import styles from './styles'

const renderInput = (field: Field)=> {
  const { autoFocus, input, mono, placeholder, type } = field
  const inputProps = {
    autoFocus,
    id: input.name,
    placeholder,
    type: type,
  }
  if (field.disabled) {
    inputProps.disabled = 'disabled'
  }
  let className = styles.inputContainer
  if (mono) {
    className = `${styles.inputContainer} ${styles.mono}`
  }

  return (
    <div className={className}>
      <input {...input} {...inputProps} className={styles.input} />
    </div>
  )
}

export default renderInput
