// @flow
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import normalizers from 'lib/field/normalizers'
import validators from 'lib/field/validators'
import { httpPut } from 'lib/http'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import type { Form } from 'lib/types'
import FormButtons from 'components/FormButtons'
import { renderInputField } from 'components/FormFields'
import styles from './styles'

type Props = {
  accountNumber: Object,
  ein_verifier: string,
  form: Form,
  onSuccess: Function,
}

const resetEIN = (data) => {
  const ein = data.ein ? data.ein.replace(/-/g, '') : ''
  const putData = { ein: ein }
  return httpPut(`/admin/accounts/${data.accountNumber}/ein`, putData)
}

const onSubmit = (data, dispatch, onSuccess) => {
  dispatch(setLoad())
  return resetEIN(data)
    .then(({ ein_verifier: ein_verifier }) => {
      onSuccess(data.accountNumber, ein_verifier)(dispatch)
      dispatch(setBanner('EIN successfully reset.'))
    })
    .catch(() => {
      dispatch(setBanner('EIN unsuccessfully reset.', 'danger'))
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
}

const Component = ({ accountNumber, ein_verifier, form, onSuccess }: Props) => {
  const handleSubmit = form.handleSubmit((data, dispatch) => {
    return onSubmit(
      {...data, accountNumber: accountNumber},
      dispatch,
      onSuccess)
  })

  let oldEIN
  if (ein_verifier) {
    oldEIN = (
      <div className={styles.formItem}>
        <label className={styles.label}>Old EIN</label>
        <div className={styles.mono}>XX-XXX{ein_verifier}</div>
      </div>
    )
  }

  return (
    <section>
      <h4 className='section'>Reset EIN</h4>
      <form onSubmit={handleSubmit}>
        <div className='section'>
          {oldEIN}
          <div className={styles.formItemMono}>
            <Field
              component={renderInputField}
              label='New EIN'
              name='ein'
              normalize={normalizers.ein}
              placeholder='00-0000000'
              type='text'
              validate={validators.ein}
              autoFocus
            />
          </div>
        </div>
        <FormButtons {...form} />
      </form>
    </section>
  )
}

const EINResetForm = reduxForm({
  form: 'einReset',
  propNamespace: 'form',
})(Component)

export default EINResetForm
