import { httpGet } from 'lib/http'
import { IconRecurring } from '../../../../../../components/Icons/IconRecurring'
import formatters from '../../../../../../lib/formatters'
import SectionHeader from '../../Header/SectionHeader'
import { requestTypeToValue } from '../constant'
import styles from './styles'

export default function ViewDrf(props) {
  const { form, calculation, accounts, setEdit, files } = props

  const requestor = accounts?.find((contact) => contact.id === form.requestorId)

  const fileLinkClicked = (key, link_type) => {
    const reqUrl = `/admin/files/${key}/${link_type}`

    httpGet(reqUrl).then(({ url }) => {
      link_type === 'preview' ? window.open(url, '_blank') : (window.location.href = url)
    })
  }

  return (
    <div className={styles.viewDrf}>
      <div className={styles.cardMarginBot}>
        <SectionHeader
          title={'Invoice Review View'}
          extra={
            <div className={styles.editButton} onClick={() => setEdit(true)}>
              Edit Details
            </div>
          }
          className={styles.invoiceReviewHeader}
        />
        <div className={`${styles.cardMarginBotWrapper} ${styles.serif}`}>
          <div className={styles.sectionWrapper}>
            <section className={styles.sectionNoMargin}>
              <div className={styles.subSection}>
                <p className={styles.subSectionLabel}>Request Documents</p>
                <div className={styles.subSectionValue}>
                  {files.map((file) => {
                    return (
                      <div className={styles.download}>
                        <a title="Download File" onClick={() => fileLinkClicked(file?.key, 'preview')}>
                          {file?.name}
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
            <div className={styles.invoiceRightView}>
              <section className={styles.sectionNoMargin}>
                <div className={`${styles.subSection}`}>
                  <p className={styles.subSectionLabel}>Date Received</p>
                  <p className={styles.subSectionValue}>{formatters.date(form.dateReceived)}</p>
                </div>
              </section>
              <section className={styles.sectionNoMargin}>
                <div className={styles.subSection}>
                  <p className={styles.subSectionLabel}>Who is making the request?</p>
                  <p className={styles.subSectionValue}>
                    {requestor?.name} - {requestor?.role}
                  </p>
                </div>
              </section>
            </div>
          </div>
          <div className={styles.sectionWrapper}>
            <section className={styles.sectionNoMargin}>
              <div className={styles.subSection}>
                <p className={styles.subSectionLabel} style={{ paddingBottom: '6px' }}>
                  Pay to
                </p>
                <p className={styles.subSectionValue}>
                  {/* <div className={styles.payTo}> */}
                  <div className={styles.xeroItem}>
                    <div className={styles.payToAddress} style={{ lineHeight: '1.6rem' }}>
                      <span className={styles.subText}>
                        {form.xeroPayTo.name} {form.xeroPayTo.street && <br />} {form.xeroPayTo.street} {form.xeroPayTo.street && <br />}
                      </span>
                      {form.xeroPayTo.city || form.xeroPayTo.state ? (
                        <span className={styles.subText}>
                          {form.xeroPayTo.city}, {form.xeroPayTo.state} {form.xeroPayTo.zip}
                        </span>
                      ) : null}
                    </div>
                    {/* </div> */}
                  </div>
                </p>
              </div>
            </section>
          </div>
          <div className={styles.sectionWrapper}>
            <section className={styles.sectionNoMargin}>
              <div className={`${styles.subSection}`}>
                <p className={styles.subSectionLabel}>Transaction Description - seen on client statements</p>
                <p className={styles.subSectionValue}>{form.description}</p>
              </div>
            </section>
          </div>
        </div>

        <div className={styles.cardMarginBotWrapperBottom}>
          <div className={`${styles.invoiceBottom} ${styles.header} ${styles.serif}`}>
            <p className={styles.desc}>Reference field of transaction</p>
            <p className={styles.req}>Request Type</p>
            <p className={styles.pay}>Payment Type</p>
            <p className={styles.amt}>Request Amount</p>
          </div>
          <div className={`${styles.invoiceBottom} ${styles.serif}`}>
            <p className={styles.desc}>{form.reference}</p>
            <p className={styles.req}>{form.requestType && requestTypeToValue[form.requestType]}</p>
            <p className={styles.pay}>
              {formatters.legiblePaymentType(form.paymentType)}
              <br />
              {['pay_by_phone', 'pay_online'].includes(form.paymentType) && (
                <span className={styles.formPaymentTypeSubtitle}>Call 888-123-4567 and use Credit Card ending in 4321</span>
              )}
            </p>
            <p className={styles.amt}>{formatters.money(form.amount)}</p>
          </div>
          {calculation.message && <div className={`${styles.amountInfo} ${calculation.styles}`}>{calculation.message}</div>}
          {form?.recurringDistribution?.requested && (
            <div className={styles.recurringInfo}>
              Requerring Payment Requested <IconRecurring />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
