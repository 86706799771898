// @flow
import React from 'react'
import { DynamicSelect } from 'components/DynamicSelect'
import DynamicTrustSelect from 'components/DynamicTrustSelect'
import type { Product } from 'lib/types'
import styles from './styles'

type Props = {
  canSubmit: boolean,
  dispatch: Function,
  onChange: Function,
  onProductLoad: Function,
  onSubmit: Function,
  updateAmount: Function,
  values: Object,
}

export type Values = {
  amount: string,
  categoryId: number,
  end_date: string,
  investmentId: number,
  journalId: number,
  product: Product,
  transaction_date: string,
  start_date: string,
}

const formatCurrency = (value) => (value ? (value * 1).toFixed(2) : '')

const createOptions = (product, array_attribute) => {
  let options = []

  if (product && product[array_attribute]) {
    options = product[array_attribute].map((value) => ({
      value: value.id,
      label: value.name,
    }))
  }

  return options
}

export const Template = (props: Props) => {
  const { canSubmit, updateAmount, onChange, onProductLoad, onSubmit, values } =
    props
  const amountNormalizer = () => updateAmount(formatCurrency)

  return (
    <div>
      <div className={styles.localHeader}>
        <div className={styles.localHeaderInner}>
          <h5>Allocate Interest</h5>
        </div>
      </div>
      <section className={styles.contentContainer}>
        <form onSubmit={onSubmit} className={styles.form}>
          <section className={styles.cardMarginBot}>
            <section className={styles.sectionNoMargin}>
              <div className={styles.rowHalf}>
                {
                  <DynamicTrustSelect
                    label="Trust"
                    placeholder="Select one"
                    onProductLoad={onProductLoad}
                  />
                }
              </div>

              <div className={styles.rowHalf}>
                {
                  <DynamicSelect
                    label="Investment"
                    name="investmentId"
                    onChange={onChange}
                    options={createOptions(values.product, 'investments')}
                  />
                }
              </div>
              <div className={styles.rowHalf}>
                {
                  <DynamicSelect
                    label="Category"
                    name="categoryId"
                    onChange={onChange}
                    options={createOptions(values.product, 'categories')}
                  />
                }
              </div>
              <div className={styles.formItemMono}>
                <label htmlFor="amount" className={styles.label}>
                  Total Allocation Amount
                </label>
                <input
                  className={styles.input}
                  name="amount"
                  onBlur={amountNormalizer}
                  onChange={onChange}
                  placeholder="0.00"
                  step={0.01}
                  type="number"
                  value={values.amount}
                />
              </div>
              <div className={styles.formItemMono}>
                <label htmlFor="transaction_date" className={styles.label}>
                  Transaction Date
                </label>
                <input
                  className={styles.input}
                  name="transaction_date"
                  onChange={onChange}
                  type="date"
                  value={values.transaction_date}
                />
              </div>
              <div className={styles.dateRange}>
                <h5 className={styles.sectionHeader}>Date Range</h5>
                <div className={styles.formItemMono}>
                  <label htmlFor="start_date" className={styles.label}>
                    Start
                  </label>
                  <input
                    className={styles.input}
                    name="start_date"
                    onChange={onChange}
                    type="date"
                    value={values.start_date}
                  />
                </div>
                <div className={styles.formItemMono}>
                  <label htmlFor="end_date" className={styles.label}>
                    End
                  </label>
                  <input
                    className={styles.input}
                    name="end_date"
                    onChange={onChange}
                    type="date"
                    value={values.end_date}
                  />
                </div>
              </div>
            </section>
          </section>
          <section className={styles.buttons}>
            <button
              className={styles.button}
              disabled={!canSubmit}
              type="submit"
            >
              Allocate
            </button>
          </section>
        </form>
      </section>
    </div>
  )
}
