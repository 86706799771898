// @flow
import React from 'react'

import styles from './styles'

type Props = {
  bodyText?: string,
  cancelButtonText?: string,
  confirmButtonText?: string,
  headerText?: string,
  onCancel: Function,
  onConfirm?: Function,
}

const Dialog = (props: Props) => {
  const { bodyText, cancelButtonText, confirmButtonText, headerText, onConfirm, disabled } = props

  const close = () => props.onCancel

  const renderCancelButton = () => (
    <button className={styles.cancel} onClick={close()}>
      {cancelButtonText}
    </button>
  )

  const renderConfirmButton = () => (
    <button className={styles.confirm} onClick={onConfirm} disabled={disabled}>
      {confirmButtonText}
    </button>
  )

  const renderHeader = () => <h5 className={styles.header}>{headerText}</h5>

  const renderBodyText = () => <div className={styles.bodyText}>{props.bodyText}</div>

  return (
    <div className={styles.dialogContainer}>
      <div className={styles.overlay} onClick={close()}></div>
      <section className={styles.dialog}>
        <div className={styles.section}>
          {headerText && renderHeader()}
          {bodyText && renderBodyText()}
        </div>
        <div className={styles.dialogButtons}>
          {cancelButtonText && renderCancelButton()}
          {onConfirm && renderConfirmButton()}
        </div>
      </section>
    </div>
  )
}

Dialog.defaultProps = {
  bodyText: null,
  cancelButtonText: null,
  confirmButtonText: 'OK',
  headerText: null,
  onConfirm: null,
}

export default Dialog
