// @flow
import React from 'react'
import styles from './styles'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getLoading } from 'lib/notification/selectors'

type Props = {
  loading: boolean,
  non_redux_loading: boolean
}

const Loader = (props: Props) => {
  if (props.loading || props.non_redux_loading) {
    return (
      <section className={styles.loaderWrapper}>
        <div className={styles.overlay}></div>
        <div className={styles.loader}>
          <div className={styles.bounce}>
            <div className={styles.bounce1} />
            <div className={styles.bounce2} />
            <div className={styles.bounce3} />
          </div>
        </div>
      </section>
    )
  }
  return null
}

export default connect(createStructuredSelector({
  loading: getLoading
}))(Loader)
