import React from 'react'
import { Notes } from 'components/Notes'
import {
  ContactRef,
  GPTAccountRef
} from 'components/References'

type Props = {
  hideContactRef: boolean,
  dispatch: Function,
  ticket: Object,
}

const contactRef = (hide, ticket) => {
  if (hide) {
    return null
  }

  return (
    <ContactRef
      collapse={true}
      contact={ticket.beneficiary}
      edit={true}
      title='Beneficiary'
    />
  )
}

const Reference = ({ hideContactRef, dispatch, ticket }: Props) => (
  <section>
    <GPTAccountRef
      beneficiary={{ name: ticket.beneficiary.name }}
      number={ticket.account_number}
      status={ticket.status}
    />
    {contactRef(hideContactRef, ticket)}
    <Notes
      accountNumber={ticket.account_number}
      dispatch={dispatch}
      notes={ticket.notes}
    />
  </section>
)

export default Reference
