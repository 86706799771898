// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__formItemQuarter___lTrnE {
  width: 25%;
  margin-bottom: 20px;
}

.styles__formItemQuarter___lTrnE input {
  margin-top: 10px;
  top: 2px;
}

.styles__input___WKoov {
}

.styles__pastDueDateInput___j6JuA {
  color: red;
}

.styles__inputLabelGroup___C_dAo {
  margin-top: 10px;
  margin-bottom: 20px;
}

.styles__inputLabelGroup___C_dAo input {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 0;
  top: 2px;
  position: relative;
}

.styles__inputLabelGroup___C_dAo textarea {
  height: 180px;
  line-height: 22px;
}

.styles__inputLabelGroup___C_dAo label {
  font-size: 14px;
}

.styles__label___QO6yH {
  text-align: left;
  margin-bottom: 10px;
  margin-right: 5px;
  font-size: 14px;
}

.styles__section___YgDlO {
}

.styles__dateBlock___s1jVo {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid borderColorLight;
  margin: 20px 0;
}

.styles__dateBlock___s1jVo .styles__label___QO6yH {
    text-align: right;
    white-space: nowrap;
  }

.styles__lastBlock___F99fT {
  height: 50px;
  width: 10px;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Closings/components/Edit/ClosingEventForm/styles.css"],"names":[],"mappings":"AAEA;EAEE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,QAAQ;AACV;;AAEA;AAEA;;AAEA;EAEE,UAAU;AACZ;;AAEA;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EAEE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;AAEA;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,yCAAyC;EACzC,cAAc;AAMhB;;AAJE;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;AAGF;EACE,YAAY;EACZ,WAAW;AACb","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.formItemQuarter {\n  composes: formItemQuarter from helpers;\n  width: 25%;\n  margin-bottom: 20px;\n}\n\n.formItemQuarter input {\n  margin-top: 10px;\n  top: 2px;\n}\n\n.input {\n  composes: input from helpers;\n}\n\n.pastDueDateInput {\n  composes: input from helpers;\n  color: red;\n}\n\n.inputLabelGroup {\n  composes: clearfix from helpers;\n  margin-top: 10px;\n  margin-bottom: 20px;\n}\n\n.inputLabelGroup input {\n  margin-top: 10px;\n  margin-right: 10px;\n  margin-left: 0;\n  top: 2px;\n  position: relative;\n}\n\n.inputLabelGroup textarea {\n  height: 180px;\n  line-height: 22px;\n}\n\n.inputLabelGroup label {\n  font-size: 14px;\n}\n\n.label {\n  composes: label from helpers;\n  text-align: left;\n  margin-bottom: 10px;\n  margin-right: 5px;\n  font-size: 14px;\n}\n\n.section {\n  composes: section from helpers;\n}\n\n.dateBlock {\n  display: flex;\n  width: 100%;\n  height: 60px;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n  border-bottom: 1px solid borderColorLight;\n  margin: 20px 0;\n\n  .label {\n    text-align: right;\n    white-space: nowrap;\n  }\n}\n\n.lastBlock {\n  height: 50px;\n  width: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"formItemQuarter": `styles__formItemQuarter___lTrnE ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItemQuarter"]}`,
	"input": `styles__input___WKoov ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"pastDueDateInput": `styles__pastDueDateInput___j6JuA ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"inputLabelGroup": `styles__inputLabelGroup___C_dAo ${___CSS_LOADER_ICSS_IMPORT_0___.locals["clearfix"]}`,
	"label": `styles__label___QO6yH ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`,
	"section": `styles__section___YgDlO ${___CSS_LOADER_ICSS_IMPORT_0___.locals["section"]}`,
	"dateBlock": `styles__dateBlock___s1jVo`,
	"lastBlock": `styles__lastBlock___F99fT`
};
export default ___CSS_LOADER_EXPORT___;
