// @flow
import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
  MenuHeader,
  MenuDivider,
  MenuRadioGroup,
} from '@szhsin/react-menu'
import XeroRefresh from './XeroRefresh'
import { HeaderMenuItem, HeaderMenuLogo, HeaderMenuSection } from './HeaderMenu'
import { HeaderNav, HeaderNavItem } from './HeaderNav'
import { HeaderUtility, HeaderUtilityItem } from './HeaderUtility'

import NotificationContainer from './Notification/NotificationContainer'

import logo from 'styles/img/logo.png'
import styles from './styles'
import { encodedData } from '../../lib/encrypt'

type Props = {
  isCoTrustee: boolean,
  isAuditor: boolean,
  isLoggedIn: boolean,
  menuActive: boolean,
  toggleMenu: Function,
}

// header class for background-color changes depending on environment
const containerClass = () => {
  if (__IS_SANDBOX__) return styles.containerRed
  if (__DEV__) return styles.containerGreen
  return styles.container
}

// Header Menu
const renderHeaderMenu = (props: Props) => {
  const { toggleMenu, menuActive, isCoTrustee, isAuditor } = props

  return (
    <>
      <div role="button" className={styles.dropTrigger} onClick={toggleMenu}></div>
      {
        <>
          {menuActive && (
            <div role="button" onClick={toggleMenu} className={styles.headerOverlay}></div>
          )}
          <div
            className={`${styles.headerMenu} ${
              menuActive ? styles.headerActive : styles.headerInActive
            }`}
          >
            <HeaderMenuLogo />
            {!isAuditor && (
              <HeaderMenuSection label="Account Management">
                <HeaderMenuItem
                  label="Create New Account"
                  to="/accounts/create"
                  id="test-hook-gpt-account-create"
                  onClick={toggleMenu}
                />

                <HeaderMenuItem
                  label="Potential Clients"
                  to="/potential_clients"
                  onClick={toggleMenu}
                />

                <HeaderMenuItem label="Closings" to="/closings" onClick={toggleMenu} />

                <HeaderMenuItem
                  label="Suspended Accounts"
                  to="/suspended-accts"
                  onClick={toggleMenu}
                />
              </HeaderMenuSection>
            )}
            {!isAuditor && (
              <HeaderMenuSection label="Funds">
                <HeaderMenuItem
                  label="Collect Annual fees"
                  to="/collect-annual-fees"
                  onClick={toggleMenu}
                />

                <HeaderMenuItem
                  label="Allocate Interest"
                  to="/allocate-interest"
                  onClick={toggleMenu}
                />
              </HeaderMenuSection>
            )}
            <HeaderMenuSection label="Reports">
              <HeaderMenuItem
                label="Account Statements"
                to="/account-statements"
                onClick={toggleMenu}
              />

              <HeaderMenuItem label="DCF Report" to="/dcf-report" onClick={toggleMenu} />

              <HeaderMenuItem label="Deposits Report" to="/deposit-report" onClick={toggleMenu} />

              <HeaderMenuItem label="Fee Report" to="/fee-report" onClick={toggleMenu} />

              <HeaderMenuItem
                label="Trustee Receipt Report"
                to="/trustee-receipt-report"
                onClick={toggleMenu}
              />
            </HeaderMenuSection>
            {isCoTrustee && (
              <HeaderMenuSection label="Co-Trustee Actions">
                <HeaderMenuItem
                  label="Approve New Accounts"
                  to="/cotrustee/approve-accounts"
                  onClick={toggleMenu}
                />

                <HeaderMenuItem label="Manage Users" to="/cotrustee/users" onClick={toggleMenu} />
                <HeaderMenuItem label="Manage Xero" to="/cotrustee/xero" onClick={toggleMenu} />
              </HeaderMenuSection>
            )}
          </div>
        </>
      }
    </>
  )
}

// Main Nav
const renderMainNav = (props: Props) => {
  if (!props.isLoggedIn) return null
  return (
    <HeaderNav>
      {renderHeaderMenu(props)}
      <HeaderNavItem label="Contacts" to="/contacts" />
      {!props.isAuditor && <HeaderNavItem label="Queue" to="/action-queue" />}
      <HeaderNavItem label="Master" to="/accounts/gpt-master" />
      <HeaderNavItem
        label="DRF"
        to={`/distributions/queue?tid=${encodedData('main')}&stid=${encodedData('0')}`}
      />
    </HeaderNav>
  )
}

// Utility Nav
const renderUtilityNav = (props: Props) => {
  const { isCoTrustee, isLoggedIn } = props

  if (!isLoggedIn) return null

  return (
    <HeaderUtility>
      <XeroRefresh />
      <NotificationContainer canResolve={isCoTrustee} />
      <HeaderUtilityItem label="Log Out" id="logout" to="/logout" />
    </HeaderUtility>
  )
}

// Assemble all Header Pieces
export const StatelessHeader = (props: Props) => {
  return (
    <header role="header" className={containerClass()}>
      <div className={styles.innerContainer}>
        {renderMainNav(props)}

        <NavLink className={styles.logo} to="/">
          <img src={logo} />
        </NavLink>

        {renderUtilityNav(props)}
      </div>
    </header>
  )
}
