export type SVGElement = {
  viewbox: string,
  paths?: string,
  points?: string,
}

type Holder = {
  [key: string]: SVGElement,
}

const Add: Holder = {
  viewbox: '0 0 20 20',
  paths:
  'M17.0671378,2.93286219 ' +
  'C20.9776207,6.84334511 ' +
  '20.9776207,13.1566549 ' +
  '17.0671378,17.0671378 ' +
  'C13.1566549,20.9776207 ' +
  '6.84334511,20.9776207 ' +
  '2.93286219,17.0671378 ' +
  'C-0.97762073,13.1566549 ' +
  '-0.97762073,6.84334511 ' +
  '2.93286219,2.93286219 ' +
  'C6.84334511,-0.97762073 ' +
  '13.1566549,-0.97762073 ' +
  '17.0671378,2.93286219 Z ' +
  'M10.9579898,16.674519 ' +
  'L11.067923,11.067923 ' +
  'L16.6588143,10.9736945 ' +
  'L16.6274048,8.80643895 ' +
  'L11.0993325,8.90066745 ' +
  'L11.2092658,3.35689046 ' +
  'L9.04201021,3.32548096 ' +
  'L8.93207695,8.93207695 ' +
  'L3.34118571,9.02630546 ' +
  'L3.37259521,11.1935611 ' +
  'L8.90066745,11.0993325 ' +
  'L8.7907342,16.6431095 ' +
  'L10.9579898,16.674519 Z'
}

const Back: Holder = {
  viewbox: '0 0 20 20',
  points:
    '14.938 18.154 13.215 19.938 '
    + '3.062 10.031 12.969 -.062 14.754 1.662 6.569 9.969'
}

const Check: Holder = {
  viewbox: '0 0 90 90',
  paths:
    'M45,0 ' +
    'C20.1,0 ' +
    '0,20.1 ' +
    '0,45 ' +
    'C0,69.9 ' +
    '20.1,90 ' +
    '45,90 ' +
    'C69.9,90 ' +
    '90,69.9 ' +
    '90,45 ' +
    'C90,20.1 ' +
    '69.9,0 ' +
    '45,0 Z ' +
    'M39.3,71.3 ' +
    'L17.3,49.6 ' +
    'L24.2,42.6 ' +
    'L38.5,56.7 ' +
    'L65.5,22.9 ' +
    'L73.1,29 ' +
    'L39.3,71.3 Z'
}

const Dropdown: Holder = {
  viewbox: '0 0 20 20',
  points: '18.215 5 10.031 13.369 1.723 5.185 0 6.969 10.092 16.877 20 6.723',
}

const Filter: Holder = {
  viewbox: '0 0 20 20',
  paths:
    'M20,3.9567053 ' +
    'C20,4.76114727 ' +
    '19.3479786,5.41341059 ' +
    '18.5432947,5.41341059 ' +
    'L1.4567053,5.41341059 ' +
    'C0.652263325,5.41341059 ' +
    '-2.84217094e-14,4.7613892 ' +
    '-2.84217094e-14,3.9567053 ' +
    'C-2.84217094e-14,3.15226332 ' +
    '0.652021387,2.5 ' +
    '1.4567053,2.5 ' +
    'L18.5435366,2.5 ' +
    'C19.3479786,2.5 ' +
    '20,3.15226332 ' +
    '20,3.9567053 Z ' +
    'M17.5806257,10.0784482 ' +
    'C17.5806257,10.8828902 ' +
    '16.9286043,11.5351535 ' +
    '16.1239204,11.5351535 ' +
    'L3.87607965,11.5351535 ' +
    'C3.07163767,11.5351535 ' +
    '2.41937435,10.8831321 ' +
    '2.41937435,10.0784482 ' +
    'C2.41937435,9.27400624 ' +
    '3.07139574,8.62174292 ' +
    '3.87607965,8.62174292 ' +
    'L16.1241623,8.62174292 ' +
    'C16.9286043,8.62174292 ' +
    '17.5806257,9.27400624 ' +
    '17.5806257,10.0784482 Z ' +
    'M13.9515641,16.1999492 ' +
    'C13.9515641,17.0043912 ' +
    '13.2995427,17.6566545 ' +
    '12.4948588,17.6566545 ' +
    'L7.50514117,17.6566545 ' +
    'C6.7006992,17.6566545 ' +
    '6.04843587,17.0046331 ' +
    '6.04843587,16.1999492 ' +
    'C6.04843587,15.3955072 ' +
    '6.70045726,14.7432439 ' +
    '7.50514117,14.7432439 ' +
    'L12.4951008,14.7432439 ' +
    'C13.2995427,14.7432439 ' +
    '13.9515641,15.3955072 ' +
    '13.9515641,16.1999492 Z'
}

const MoreHorizontal: Holder = {
  viewbox: '0 0 16 16',
  paths:
    'M4.5,8 ' +
    'C4.5,8.96649831 ' +
    '3.71649831,9.75 ' +
    '2.75,9.75 ' +
    'C1.78350169,9.75 ' +
    '1,8.96649831 ' +
    '1,8 ' +
    'C1,7.03350169 ' +
    '1.78350169,6.25 ' +
    '2.75,6.25 ' +
    'C3.71649831,6.25 ' +
    '4.5,7.03350169 ' +
    '4.5,8 Z ' +
    'M9.75,8 ' +
    'C9.75,8.96649831 ' +
    '8.96649831,9.75 ' +
    '8,9.75 ' +
    'C7.03350169,9.75 ' +
    '6.25,8.96649831 ' +
    '6.25,8 ' +
    'C6.25,7.03350169 ' +
    '7.03350169,6.25 ' +
    '8,6.25 ' +
    'C8.96649831,6.25 ' +
    '9.75,7.03350169 ' +
    '9.75,8 Z ' +
    'M15,8 ' +
    'C15,8.96649831 ' +
    '14.2164983,9.75 ' +
    '13.25,9.75 ' +
    'C12.2835017,9.75 ' +
    '11.5,8.96649831 ' +
    '11.5,8 ' +
    'C11.5,7.03350169 ' +
    '12.2835017,6.25 ' +
    '13.25,6.25 ' +
    'C14.2164983,6.25 ' +
    '15,7.03350169 ' +
    '15,8 Z'
}

const MoreVertical: Holder = {
  viewbox: '0 0 16 16',
  paths:
    'M8,11.5 ' +
    'C8.96649831,11.5 ' +
    '9.75,12.2835017 ' +
    '9.75,13.25 ' +
    'C9.75,14.2164983 ' +
    '8.96649831,15 ' +
    '8,15 ' +
    'C7.03350169,15 ' +
    '6.25,14.2164983 ' +
    '6.25,13.25 ' +
    'C6.25,12.2835017 ' +
    '7.03350169,11.5 ' +
    '8,11.5 Z ' +
    'M8,6.25 ' +
    'C8.96649831,6.25 ' +
    '9.75,7.03350169 ' +
    '9.75,8 ' +
    'C9.75,8.96649831 ' +
    '8.96649831,9.75 ' +
    '8,9.75 ' +
    'C7.03350169,9.75 ' +
    '6.25,8.96649831 ' +
    '6.25,8 ' +
    'C6.25,7.03350169 ' +
    '7.03350169,6.25 ' +
    '8,6.25 Z ' +
    'M8,1 ' +
    'C8.96649831,1 ' +
    '9.75,1.78350169 ' +
    '9.75,2.75 ' +
    'C9.75,3.71649831 ' +
    '8.96649831,4.5 ' +
    '8,4.5 ' +
    'C7.03350169,4.5 ' +
    '6.25,3.71649831 ' +
    '6.25,2.75 ' +
    'C6.25,1.78350169 ' +
    '7.03350169,1 ' +
    '8,1 Z'
}

const NavMenu: Holder = {
  viewbox: '0 0 40 40',
  paths:
    'M7.5,28 ' +
    'L32.5,28 ' +
    'C32.7761424,28 ' +
    '33,28.2238576 ' +
    '33,28.5 ' +
    'L33,30.5 ' +
    'C33,30.7761424 ' +
    '32.7761424,31 ' +
    '32.5,31 ' +
    'L7.5,31 ' +
    'C7.22385763,31 ' +
    '7,30.7761424 ' +
    '7,30.5 ' +
    'L7,28.5 ' +
    'C7,28.2238576 ' +
    '7.22385763,28 ' +
    '7.5,28 Z ' +
    'M7.5,10 ' +
    'L32.5,10 ' +
    'C32.7761424,10 ' +
    '33,10.2238576 ' +
    '33,10.5 ' +
    'L33,12.5 ' +
    'C33,12.7761424 ' +
    '32.7761424,13 ' +
    '32.5,13 ' +
    'L7.5,13 ' +
    'C7.22385763,13 ' +
    '7,12.7761424 ' +
    '7,12.5 ' +
    'L7,10.5 ' +
    'C7,10.2238576 ' +
    '7.22385763,10 ' +
    '7.5,10 Z ' +
    'M7.5,19 ' +
    'L32.5,19 ' +
    'C32.7761424,19 ' +
    '33,19.2238576 ' +
    '33,19.5 ' +
    'L33,21.5 ' +
    'C33,21.7761424 ' +
    '32.7761424,22 ' +
    '32.5,22 ' +
    'L7.5,22 ' +
    'C7.22385763,22 ' +
    '7,21.7761424 ' +
    '7,21.5 ' +
    'L7,19.5 ' +
    'C7,19.2238576 ' +
    '7.22385763,19 ' +
    '7.5,19 Z'
}

const NewNote: Holder = {
  viewbox: '0 0 22 22',
  paths:
    'M12.659625,0 ' +
    'L16.5,3.839 ' +
    'L16.5,9.6655625 ' +
    'C19.5930625,10.00725 ' +
    '21.998625,12.626625 ' +
    '22,15.8125 ' +
    'C21.9986594,18.9173875 ' +
    '19.711838,21.4843995 ' +
    '16.7306638,21.9294174 ' +
    'L16.5,21.9594375 ' +
    'L16.5,22 ' +
    'L0,22 ' +
    'L0,0 ' +
    'L12.659625,0 Z ' +
    'M11,1.373625 ' +
    'L1.375,1.373625 ' +
    'L1.375,20.625 ' +
    'L11.928125,20.625 ' +
    'C10.52425,19.492 ' +
    '9.625,17.7574375 ' +
    '9.625,15.8125 ' +
    'C9.625,12.7069422 ' +
    '11.9117879,10.1392599 ' +
    '14.8942357,9.69548326 ' +
    'L15.125,9.6655625 ' +
    'L15.125,5.4993125 ' +
    'L11,5.4993125 ' +
    'L11,1.373625 Z ' +
    'M15.8125,11.0790625 ' +
    'C13.1993125,11.08525 ' +
    '11.08525,13.1993125 ' +
    '11.0790625,15.8125 ' +
    'C11.08525,18.4243125 ' +
    '13.1993125,20.538375 ' +
    '15.8125,20.5445625 ' +
    'C18.4243125,20.538375 ' +
    '20.538375,18.4243125 ' +
    '20.5445625,15.8125 ' +
    'C20.538375,13.1993125 ' +
    '18.4243125,11.08525 ' +
    '15.8125,11.0790625 Z ' +
    'M16.545408,12.32 ' +
    'L16.545408,15.136 ' +
    'L19.36,15.136 ' +
    'L19.36,16.544 ' +
    'L16.545408,16.544 ' +
    'L16.545408,19.36 ' +
    'L15.136,19.36 ' +
    'L15.136,16.544 ' +
    'L12.32,16.544 ' +
    'L12.32,15.136 ' +
    'L15.136,15.136 ' +
    'L15.136,12.32 ' +
    'L16.545408,12.32 Z ' +
    'M12.373625,1.6589375 ' +
    'L12.373625,4.125 ' +
    'L14.840375,4.125 ' +
    'L12.373625,1.6589375 Z'
}

const Remove: Holder = {
  viewbox: '0 0 20 20',
  paths:
    'M11.2496002,10.0017647 ' +
    'L14.1550942,12.9072587 ' +
    'C14.4996745,13.251839 ' +
    '14.4996745,13.8105139 ' +
    '14.1550942,14.1550942 ' +
    'C13.8105139,14.4996745 ' +
    '13.251839,14.4996745 ' +
    '12.9072587,14.1550942 ' +
    'L10.0017647,11.2496002 ' +
    'L7.09627071,14.1550942 ' +
    'C6.75169041,14.4996745 ' +
    '6.19301547,14.4996745 ' +
    '5.84843518,14.1550942 ' +
    'C5.50385494,13.8105139 ' +
    '5.50385494,13.251839 ' +
    '5.84843518,12.9072587 ' +
    'L8.75392924,10.0017647 ' +
    'L5.84843518,7.09627071 ' +
    'C5.50385494,6.75169041 ' +
    '5.50385494,6.19301547 ' +
    '5.84843518,5.84843518 ' +
    'C6.19301547,5.50385494 ' +
    '6.75169041,5.50385494 ' +
    '7.09627071,5.84843518 ' +
    'L10.0017647,8.75392924 ' +
    'L12.9072587,5.84843518 ' +
    'C13.251839,5.50385494 ' +
    '13.8105139,5.50385494 ' +
    '14.1550942,5.84843518 ' +
    'C14.4996745,6.19301547 ' +
    '14.4996745,6.75169041 ' +
    '14.1550942,7.09627071 ' +
    'L11.2496002,10.0017647 Z'
}

const Warning: Holder = {
  viewbox: '0 0 90 90',
  paths:
    'M45,0 ' +
    'C20.1,0 ' +
    '0,20.1 ' +
    '0,45 ' +
    'C0,69.9 ' +
    '20.1,90 ' +
    '45,90 ' +
    'C69.9,90 ' +
    '90,69.9 ' +
    '90,45 ' +
    'C90,20.1 ' +
    '69.9,0 ' +
    '45,0 Z ' +
    'M38.7,15.2 ' +
    'L51.2,15.2 ' +
    'L51.2,54.8 ' +
    'L38.7,54.8 ' +
    'L38.7,15.2 Z ' +
    'M45,74.8 ' +
    'C40.7,74.8 ' +
    '37.3,71.4 ' +
    '37.3,67.1 ' +
    'C37.3,62.8 ' +
    '40.7,59.4 ' +
    '45,59.4 ' +
    'C49.3,59.4 ' +
    '52.7,62.8 ' +
    '52.7,67.1 ' +
    'C52.7,71.4 ' +
    '49.3,74.8 ' +
    '45,74.8 Z'
}

const icons: Holder = {
  Add,
  Back,
  Check,
  Dropdown,
  Filter,
  MoreHorizontal,
  MoreVertical,
  NavMenu,
  NewNote,
  Remove,
  Warning,
}

export default icons
