// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Contact } from 'components/Forms'
import { ContactRoleRef } from 'components/References'
import { Notes } from 'components/Notes'
import styles from './styles'

type Props = {
  contact: Object,
  dispatch: Function,
  errors: Object,
  form: Object,
  onPhoneChange: Function,
}

const mapAccount = (account) => {
  return {
    number: account.accountNumber,
    beneficiary: { id: account.beneficiaryId, name: account.beneficiaryName },
  }
}

const mapAccountNumber = (accountNumber) => {
  return { number: accountNumber }
}

const New = (props: Props) => {
  const { form, dispatch, contact, errors, onPhoneChange } = props
  const { handleSubmit } = form
  const representative_for_gpt = contact.representative_for_gpt.map(mapAccount)

  form.errors = errors

  return (
    <div className={styles.contentContainer}>
      <section className="twoThird">
        <h3 className="pageTitle">Edit Contact</h3>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Contact form={form} onPhoneChange={onPhoneChange} />
        </form>
      </section>
      <section className="oneThird">
        <ContactRoleRef
          able_for_gpt={contact.ableForGPT.map(mapAccount)}
          attorney_for_gpt={contact.attorneyForGPT.map(mapAccount)}
          beneficiary_of_gpt={contact.beneficiaryOfGPT.map(mapAccountNumber)}
          co_grantor_for_gpt={contact.coGrantorForGPT.map(mapAccount)}
          consult_for_gpt={contact.consultForGPT.map(mapAccount)}
          city={contact.city}
          dob={contact.dob}
          email={contact.email}
          grantor_for_gpt={contact.grantorForGPT.map(mapAccount)}
          id={contact.id}
          name={contact.name}
          phone_numbers={{
            day_phone: contact.dayPhone,
            cell_phone: contact.cellPhone,
            evening_phone: contact.eveningPhone,
            fax: contact.fax,
          }}
          photo_id={{
            content_type: contact.photoIdContentType,
            data: contact.photoIdData,
          }}
          referrer_for_gpt={contact.referrerForGPT.map(mapAccount)}
          representative_for_gpt={representative_for_gpt}
          ssn={contact.ssn}
          state={contact.state}
          street_1={contact.street1}
          street_2={contact.street2}
          zip={contact.zip}
        />
        <Notes
          contactId={contact.id}
          dispatch={dispatch}
          notes={contact.notes}
          type="contact"
        />
      </section>
    </div>
  )
}

export default connect()(New)
