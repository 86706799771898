// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { getBeneficiary } from 'lib/beneficiary/actions'
import { setModal } from 'lib/modal/actions'
import type { Contact } from 'lib/types'
import formatters from 'lib/formatters'
import {
  ContactRef,
  BeneficiaryAccountsRef,
  BeneficiaryDocsRef,
} from 'components/References'
import SSNResetForm from 'components/SSNResetForm'
import styles from './styles'

type Props = {
  beneficiary: Contact,
  dispatch: Function,
}

const Show = ({ beneficiary, dispatch }: Props) => {
  const openSSNResetModal = () => {
    const onSSNReset = () => {
      return () => dispatch(getBeneficiary(beneficiary.id, ['photo_id']))
    }
    const modalForm = (
      <SSNResetForm contact={beneficiary} onSuccess={onSSNReset} />
    )
    dispatch(setModal(modalForm))
  }

  const { name, updated_at } = beneficiary
  const updated = formatters.lastUpdate(updated_at)
  const balance = beneficiary.beneficiary_of_gpt.reduce(
    (acc, a) => (acc += a.balance),
    0,
  )
  return (
    <section className={styles.contentContainer}>
      <div className={styles.body}>
        <div className={styles.twoThird}>
          <div className={styles.titleBox}>
            <div className={styles.nameWrapper}>
              <div className={styles.row}>
                <h5>Beneficiary</h5>
              </div>
              <h1 className={styles.title}>{name}</h1>
            </div>
            <div className={styles.balanceWrapper}>
              <h5>Total Balance</h5>
              <h1 className={styles.title}>{formatters.money(balance || 0)}</h1>
            </div>
          </div>
          <ContactRef
            contact={beneficiary}
            title="Beneficiary"
            topSection={false}
            full={true}
          />
          <BeneficiaryAccountsRef beneficiary={beneficiary} />
        </div>
        <div className={styles.oneThird}>
          <div className={styles.actions}>
            <Link
              className={styles.buttonSecondary}
              to={`/contacts/${beneficiary.id}/edit`}
            >
              Edit Contact
            </Link>
            <div onClick={openSSNResetModal} className={styles.buttonSecondary}>
              Reset SSN
            </div>
            <div className={styles.updated}>
              <div className={styles.label}>Last updated:</div>
              <div className={styles.value}>{updated}</div>
            </div>
          </div>
          <BeneficiaryDocsRef {...beneficiary} />
        </div>
      </div>
    </section>
  )
}

export default Show
