// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

type Props = {
  children: Element,
}

const Protected = (props: Props) => (
  <section className="root">
    {props.children}
    <Outlet />
  </section>
)

const mapStateToProps = (state) => {
  return { router: state.router }
}

export default connect(mapStateToProps)(Protected)
