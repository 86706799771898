// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("styles/img/icon_down.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__label___V9Orx {
}

.styles__arrow___x8HIE {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: left;
  background-repeat: no-repeat;
  padding-right: 10px;
  display: block;
  height: 42px;
}

.styles__trustInput___DfzHN {
  -webkit-appearance: none;
  display: inline-block;
  width: 100%;
}

.styles__trustInput___DfzHN input {
  min-width: auto;
}

.styles__trustInput___DfzHN .Select-control {
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]};
  border-radius: 3px;
  cursor: pointer;
}

.styles__trustInput___DfzHN.Select.is-focused:not(.is-open) > .Select-control {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueLight"]};
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
  box-shadow: none;
  outline: none;
}

.styles__trustInput___DfzHN .Select-value {
  background-color: transparent;
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
}

.styles__trustInput___DfzHN.Select.has-value.Select--single
      > .Select-control
      .Select-value
      .Select-value-label {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
  line-height: 42px;
}

.styles__trustInputOption___aiRX6,
.styles__trustInput___DfzHN .Select-placeholder,
.styles__trustInputOption___aiRX6.is-focused {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
  line-height: 42px;
}

.styles__formItem___blz7B {
}
`, "",{"version":3,"sources":["webpack://./universal/components/TrustInput/styles.css"],"names":[],"mappings":"AAIA;AAEA;;AAEA;EACE,yDAAiD;EACjD,yBAAyB;EACzB,4BAA4B;EAC5B,mBAAmB;EACnB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,8DAAwB;EACxB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,8DAA6B;EAC7B,0DAAoB;EACpB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,0DAAwB;EACxB,mDAAa;AACf;;AAEA;;;;EAME,mDAAa;EACb,iBAAiB;AACnB;;AAEA;;;EAGE,mDAAa;EACb,iBAAiB;AACnB;;AAEA;AAEA","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: 'styles/colors.css';\n@value gtBlue, gtBlueDark, gtBlueLight, gtGray from helpers;\n\n.label {\n  composes: label from helpers;\n}\n\n.arrow {\n  background-image: url('styles/img/icon_down.png');\n  background-position: left;\n  background-repeat: no-repeat;\n  padding-right: 10px;\n  display: block;\n  height: 42px;\n}\n\n.trustInput {\n  -webkit-appearance: none;\n  display: inline-block;\n  width: 100%;\n}\n\n.trustInput input {\n  min-width: auto;\n}\n\n.trustInput :global(.Select-control) {\n  border: 1px solid gtGray;\n  border-radius: 3px;\n  cursor: pointer;\n}\n\n.trustInput:global(.Select.is-focused:not(.is-open) > .Select-control) {\n  background-color: gtBlueLight;\n  border-color: gtBlue;\n  box-shadow: none;\n  outline: none;\n}\n\n.trustInput :global(.Select-value) {\n  background-color: transparent;\n  border-color: gtBlueDark;\n  color: gtBlue;\n}\n\n.trustInput:global(\n    .Select.has-value.Select--single\n      > .Select-control\n      .Select-value\n      .Select-value-label\n  ) {\n  color: gtBlue;\n  line-height: 42px;\n}\n\n.trustInputOption,\n.trustInput :global(.Select-placeholder),\n.trustInputOption:global(.is-focused) {\n  color: gtBlue;\n  line-height: 42px;\n}\n\n.formItem {\n  composes: formItem from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `'styles/colors.css'`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"gtBlueDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]}`,
	"gtBlueLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueLight"]}`,
	"gtGray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]}`,
	"label": `styles__label___V9Orx ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`,
	"arrow": `styles__arrow___x8HIE`,
	"trustInput": `styles__trustInput___DfzHN`,
	"trustInputOption": `styles__trustInputOption___aiRX6`,
	"formItem": `styles__formItem___blz7B ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItem"]}`
};
export default ___CSS_LOADER_EXPORT___;
