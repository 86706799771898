// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Metric__metricWrapper___WHB8q {
  display: inline-block;
}

.Metric__metricLabel___Shaz8 {
  font-size: 13px;
  margin-bottom: 10px;
  color: textLight;
}

.Metric__metricValue___DH87i {
  font-size: 24px;
  font-weight: 600;
  color: textDark;
}

.Metric__metricValue___DH87i .Metric__dollar___xRIrT {
    font-size: 15px;
    font-weight: 400;
    margin-right: 3px;
    vertical-align: text-top;
    color: text;
  }
`, "",{"version":3,"sources":["webpack://./universal/components/Metric/Metric.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;AASjB;;AAPE;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,WAAW;EACb","sourcesContent":[".metricWrapper {\n  display: inline-block;\n}\n\n.metricLabel {\n  font-size: 13px;\n  margin-bottom: 10px;\n  color: textLight;\n}\n\n.metricValue {\n  font-size: 24px;\n  font-weight: 600;\n  color: textDark;\n\n  .dollar {\n    font-size: 15px;\n    font-weight: 400;\n    margin-right: 3px;\n    vertical-align: text-top;\n    color: text;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metricWrapper": `Metric__metricWrapper___WHB8q`,
	"metricLabel": `Metric__metricLabel___Shaz8`,
	"metricValue": `Metric__metricValue___DH87i`,
	"dollar": `Metric__dollar___xRIrT`
};
export default ___CSS_LOADER_EXPORT___;
