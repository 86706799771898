// @flow
import React from 'react'
import type { Field } from 'lib/types'
import styles from './styles'

const renderTextArea = (field: Field) => {
  const { autoFocus, input, meta, placeholder, required } = field
  const hasError = meta.touched && meta.error
  const inputStyles = hasError && required ? styles.requiredError : null
  return (
    <div className={inputStyles}>
      <textarea
        {...input}
        className={styles.textarea}
        placeholder={placeholder}
        autoFocus={autoFocus}
      >
        {input.value}
      </textarea>
      {hasError && <span className={styles.fieldError}>{meta.error}</span>}
    </div>
  )
}

export default renderTextArea
