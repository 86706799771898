// @flow
import React from 'react'
import type { Form } from 'lib/types'
import styles from './styles'

const FormButtons = (props: Form) => {
  const { onCancel, onDelete, cancelLabel, deleteLabel, disabled, errors, submitting, submitLabel } = props
  let errorMessage
  if (errors === 'unexpected') {
    errorMessage = (
      <div className={`${styles.errors} test-hook-FormButtons-error-message`}>
        <h5>Form Error</h5>
        <h4>Something unexpected went wrong.</h4>
      </div>
    )
  } else {
    for (let key in errors) {
      if (!errors.hasOwnProperty(key)) continue
      let obj = errors[key]
      for (let prop in obj) {
        if (!obj.hasOwnProperty(prop)) continue
        errorMessage = (
          <div className={`${styles.errors} test-hook-FormButtons-error-message`}>
            <h5>Form Error</h5>
            <h4>Please check the form for error messages.</h4>
          </div>
        )
        break
      }
    }
  }

  return (
    <div>
      {errorMessage}
      <div className={styles.buttons}>
        <div className={styles.buttonsLeft}>{renderDelete({ submitting, onDelete, deleteLabel })}</div>
        <div className={styles.buttonsRight}>
          {renderCancel({ submitting, onCancel, cancelLabel })}
          {renderSubmit({ submitting, disabled, submitLabel })}
        </div>
      </div>
    </div>
  )
}

const renderDelete = ({ submitting, onDelete, deleteLabel }) =>
  onDelete && (
    <button onClick={onDelete} disabled={submitting} type="button" className={styles.deleteBtn}>
      {deleteLabel}
    </button>
  )

const renderSubmit = ({ disabled, submitLabel, submitting }: Form) => (
  <button type="submit" disabled={submitting || disabled} className={styles.button}>
    {submitLabel}
  </button>
)

const renderCancel = ({ submitting, onCancel, cancelLabel }) =>
  onCancel && (
    <button onClick={onCancel} type="button" disabled={submitting} className={styles.cancelBtn}>
      {cancelLabel}
    </button>
  )

FormButtons.defaultProps = {
  cancelLabel: 'Cancel',
  deleteLabel: 'Delete',
  submitLabel: 'Submit',
  onCancel: null,
}

export default FormButtons
