// @flow
import Main from './components/Main'
import Edit from './components/Edit'

export default (store) => ({
  path: 'potential_clients',
  children: [
    {
      element: <Main />,
      index: true,
    },
    {
      path: 'edit/:id',
      element: <Edit />,
    },
  ],
})
