// @flow
import React from 'react'
import moment from 'moment'
import FormButtons from 'components/FormButtons'
import { fetchAccountHTTP } from 'lib/account/actions'
import { httpPost } from 'lib/http'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import type { Form } from 'lib/types'
import styles from './styles'

type Props = {
  accountNumber: number,
  dispatch: Function,
  form: Form,
}

type State = {
  date: Date,
  isNotDeceased: boolean
}

class ClosingTrackForm extends React.Component<Props, State> {
  props: Props

  state = { date: '', isNotDeceased: false }

  _wrapSubmit = () => (event) => {
    event.preventDefault()
    this._onSubmit()
  }

  _onSubmit = () => {
    const { accountNumber, dispatch } = this.props
    const dod = this.state.date

    dispatch(setLoad())

    return httpPost(
      `/admin/closings/${accountNumber}`, { date_of_death: dod }
    ).then(() => {
      dispatch(fetchAccountHTTP(accountNumber))
      dispatch(setBanner('Closing track started'))
    })
    .catch(() => {
      dispatch(setBanner('Closing track start error', 'danger'))
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
  }

  _handleDateChange = ({ target }) => {
    this.setState({ date: target.value, isNotDeceased: false })
  }

  _handleNotDeceased = ({ target }) => {
    this.setState({ date: '', isNotDeceased: target.checked })
  }

  _canSubmit = () => {
    const dodMoment = moment(this.state.date)
    return this.state.isNotDeceased || dodMoment.isValid()
  }

  render() {
    return (
      <section>
        <h4 className={styles.section}>Begin Closing Track</h4>
        <form onSubmit={this._wrapSubmit()}>
          <div className={styles.section}>
            <label className={styles.label} htmlFor="date">
              Date of death
            </label>
            <div className={styles.formItemQuarter}>
              <input
                className={styles.input}
                id="date"
                name="date"
                onChange={this._handleDateChange}
                type="date"
                value={this.state.date}
              />
            </div>
            <div className={styles.inputLabelGroup}>
              <input
                id="not_deceased"
                name="not_deceased"
                type="checkbox"
                checked={this.state.isNotDeceased}
                onChange={this._handleNotDeceased}
              />
              <label htmlFor="not_deceased">
                Beneficiary is not deceased OR date of death is not yet known
              </label>
            </div>
          </div>
          <FormButtons
            submitLabel={'Submit'}
            disabled={!this._canSubmit()}
          />
        </form>
      </section>
    )
  }
}

export default ClosingTrackForm
