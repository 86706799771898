import React from 'react'

export default function IconBox(props) {
  return (
    <div
      style={{ width: 28, height: 28, display: 'inline-block', padding: 4 }}
      {...props}
    />
  )
}
