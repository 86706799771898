// @flow
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { logIn, requestSession, requestToken } from 'lib/user/actions'
import cleaners from 'lib/field/cleaners'
import { httpPost } from 'lib/http'
import Component from './component'
import validate from './validate'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { withRouter } from 'lib/hooks/withRouter'
type Props = {
  dispatch: Function,
  location: Object,
  searchParams: Object,
}

const ComponentForm = reduxForm({
  form: 'userLogin',
  propNamespace: 'form',
  validate,
})(Component)
class Container extends React.Component {
  props: Props
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      verify: false,
    }
  }
  setLocalStorage(dispatch, email, sid, isCoTrustee, roles) {
    localStorage.setItem('email', email)
    localStorage.setItem('isCoTrustee', isCoTrustee)
    localStorage.setItem('sid', sid)
    localStorage.setItem('roles', roles)
    dispatch(logIn()).then(() => {
      console.log('Logged in...')
    })
  }

  componentDidMount() {
    const { dispatch, location } = this.props
    let token = location.query.get('token')
    let as = location.query.get('as')
    let sessionRequest

    if (token) {
      sessionRequest = requestSession(token)
    }
    if (['development', 'sandbox'].includes(process.env.NODE_ENV)) {
      if (as) {
        sessionRequest = () => {
          return new Promise((resolve, reject) => {
            httpPost('/sessions/backdoor', { email: as })
              .then((data) => {
                resolve(data)
              })
              .catch((err) => reject(err))
          })
        }
      }
    }

    if (!sessionRequest) {
      dispatch(setBanner('Session expired - please log in again', 'danger'))
      this.setState({ verify: false })
      return
    }

    if (token || as) {
      this.setState({ verify: true })
      dispatch(setLoad())
      dispatch(sessionRequest)
        .then(({ email, isCoTrustee, sid, roles }) => {
          this.setLocalStorage(dispatch, email, sid, isCoTrustee, roles)
          location.navigate('/contacts')
        })
        .catch(() => {
          dispatch(
            setBanner(
              'The supplied link is no longer valid. Please log in to request ' + 'another.',
              'danger',
            ),
          )
          this.setState({ verify: false })
        })
        .finally(() => {
          dispatch(setUnload())
        })
    }
  }

  _submitForm = (data, dispatch) => {
    dispatch(setLoad())
    return dispatch(requestToken(cleaners.email(data.email)))
      .then(() => {
        dispatch(setBanner('Check your email for a link.'))
        this.setState({ submitted: true })
      })
      .catch(() => {
        dispatch(setBanner('Failed to log in.', 'danger'))
      })
      .then(() => {
        dispatch(setUnload())
      })
  }

  render() {
    const { submitted, verify } = this.state
    let title = 'Welcome to Guardian Trust'
    let helpText = 'Log in to continue.'
    if (verify) {
      title = 'Please wait while we verify your login'
    }

    if (submitted) {
      {
        /*eslint-disable max-len*/
      }
      title = 'We’ve sent a link to your email address that you can use to log in'
      helpText =
        'Didn’t receive an email? Check your spam folder or try entering your email address again. Be sure to use only the most recent login link.'
      {
        /*eslint-enable max-len*/
      }
    }

    return <ComponentForm onSubmit={this._submitForm} title={title} help={helpText} />
  }
}

export default withRouter(connect()(Container))
