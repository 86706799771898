// @flow
import React from 'react'
import styles from './styles'

type Props = {
  rejection_notes: string,
}

const ApprovalRef = (props: Props) => {
  if (!props.rejection_notes) return null

  return (
    <section>
      <h5 className={styles.rejectionTitleWrapper}>Rejection Notes</h5>
      <div className={styles.rejectionBody}>{props.rejection_notes}</div>
    </section>
  )
}

export default ApprovalRef
