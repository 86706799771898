// @flow
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { isAuditor } from 'lib/user/selectors'
import { Field } from 'redux-form'
import normalizers from 'lib/field/normalizers'
import states from 'lib/states'
import type { Form } from 'lib/types'
import FormButtons from 'components/FormButtons'
import {
  renderS3FileUpload,
  renderInputField,
  renderSelectField,
} from 'components/FormFields'
import styles from './styles'

type Props = {
  form: Form,
  isAuditor: boolean,
  onPhoneChange?: Function,
}

const Contact = (props: Props) => {
  const { form, isAuditor } = props
  const submitLabel = (form.form === 'editContact')
    ? 'Save Changes'
    : 'Create Contact'

  const phoneErrors = form.errors && form.errors.phoneNumbers

  const phoneSectionTitle = phoneErrors
    ? (
      <div className={styles.errorSection}>
        <h5 className={styles.phoneSectionError}>
          Phone Numbers — At least one phone number is required.
        </h5>
      </div>
    )
    : <h5>Phone Numbers</h5>

  let ssnField
  if (form.form !== 'editContact') {
    ssnField = (
      <div className={styles.formItemMono}>
        <Field
          component={renderInputField}
          label='Social Security Number'
          name='ssn'
          normalize={normalizers.ssn}
          placeholder='000-00-0000'
          type='text'
        />
      </div>
    )
  }

  return (
    <div>
      <section className={styles.cardMarginBot}>
        <div className={styles.sectionTitle}>
          <h5>Basic Information</h5>
        </div>
        <div className={styles.sectionNoMargin}>
          <div className={styles.formItemHalf}>
            <Field
              component={renderInputField}
              name='name'
              placeholder='Name'
              type='text'
              autoFocus
            />
          </div>
          <div className={styles.formItemMono}>
            <Field
              component={renderInputField}
              label='Date of Birth'
              name='dob'
              type='date'
            />
          </div>
          <div className={styles.formItemHalf}>
            <Field
              component={renderInputField}
              label='Email'
              name='email'
              placeholder='test@example.com'
              type='text'
            />
          </div>
          <div className={styles.formItemHalf}>
            <Field
              component={renderInputField}
              label='Company Name'
              name='company_name'
              type='text'
            />
          </div>
          {ssnField}
        </div>
      </section>
      <section className={styles.cardMarginBot}>
        <div className={styles.sectionTitle}>
          {phoneSectionTitle}
        </div>
        <div className={styles.sectionNoMargin}>
          <div className={styles.formItemMono}>
            <Field
              component={renderInputField}
              label='Day Phone'
              name='dayPhone'
              normalize={normalizers.phone}
              onChange={props.onPhoneChange}
              placeholder='(000) 000-0000'
              type='text'
              error={phoneErrors}
            />
          </div>
          <div className={styles.formItemMono}>
            <Field
              component={renderInputField}
              label='Evening Phone'
              name='eveningPhone'
              normalize={normalizers.phone}
              onChange={props.onPhoneChange}
              placeholder='(000) 000-0000'
              type='text'
            />
          </div>
          <div className={styles.formItemMono}>
            <Field
              component={renderInputField}
              label='Cell Phone'
              name='cellPhone'
              normalize={normalizers.phone}
              onChange={props.onPhoneChange}
              placeholder='(000) 000-0000'
              type='text'
            />
          </div>
          <div className={styles.formItemMono}>
            <Field
              component={renderInputField}
              label='Fax'
              name='fax'
              normalize={normalizers.phone}
              onChange={props.onPhoneChange}
              placeholder='(000) 000-0000'
              type='text'
            />
          </div>
        </div>
      </section>
      <section className={styles.cardMarginBot}>
        <div className={styles.sectionTitle}>
          <h5>Address</h5>
        </div>
        <div className={styles.sectionNoMargin}>
          <div className={styles.formItemHalf}>
            <Field
              component={renderInputField}
              name='street1'
              placeholder='Street 1'
              label='Street 1'
            />
          </div>
          <div className={styles.formItemHalf}>
            <Field
              component={renderInputField}
              name='street2'
              label='Street 2'
              placeholder='Street 2'
            />
          </div>
          <div className={styles.formItemHalf}>
            <Field
              component={renderInputField}
              name='city'
              placeholder='City'
              label='City'
            />
          </div>
          <div className={styles.formItemHalf}>
            <Field
              component={renderSelectField}
              options={states}
              name='state'
              label='State'
            />
          </div>
          <div className={styles.formItemQuarter}>
            <Field
              component={renderInputField}
              name='zip'
              placeholder='Zip'
              label='Zip'
            />
          </div>
        </div>
      </section>
      <section className={styles.cardMarginBot}>
        <div className={styles.sectionTitle}>
          <h5>Photo ID Upload</h5>
        </div>
        <div className={styles.sectionNoMargin}>
          <div className={styles.formItem}>
            <Field
              name='photoId'
              normalize={normalizers.file}
              component={renderS3FileUpload}
              label='Photo ID'
            />
          </div>
        </div>
      </section>
      <FormButtons {...form} submitLabel={submitLabel} disabled={isAuditor} />
    </div>
  )
}

export default connect(
  createStructuredSelector({
    isAuditor
  }),
)(Contact)
