// @flow
import { KEY } from './constants'

export const SET_POTENTIAL_CLIENTS = `${KEY}/SET_POTENTIAL_CLIENTS`
export const SET_POTENTIAL_CLIENT = `${KEY}/SET_POTENTIAL_CLIENT`
export const UPDATE_TRUST_ATTR = `${KEY}/UPDATE_TRUST_ATTR`
export const SET_EIN = `${KEY}/SET_EIN`

export const CREATE_DISTRIBUTION = `${KEY}/CREATE_DISTRIBUTION`
export const REMOVE_DISTRIBUTION = `${KEY}/REMOVE_DISTRIBUTION`
export const UPDATE_DISTRIBUTION = `${KEY}/UPDATE_DISTRIBUTION`

export const CREATE_INCOME = `${KEY}/CREATE_INCOME`
export const REMOVE_INCOME = `${KEY}/REMOVE_INCOME`
export const UPDATE_INCOME = `${KEY}/UPDATE_INCOME`

export const CREATE_ASSET = `${KEY}/CREATE_ASSET`
export const REMOVE_ASSET = `${KEY}/REMOVE_ASSET`
export const UPDATE_ASSET = `${KEY}/UPDATE_ASSET`

export const CREATE_CONTACT_ROLE = `${KEY}/CREATE_CONTACT_ROLE`
export const REMOVE_CONTACT_ROLE = `${KEY}/REMOVE_CONTACT_ROLE`
export const UPDATE_CONTACT_ROLE_RELATIONSHIP =
  `${KEY}/UPDATE_CONTACT_ROLE_RELATIONSHIP`
export const TOGGLE_CONTACT_ROLE_RECIEVES_STATEMENTS =
  `${KEY}/TOGGLE_CONTACT_ROLE_RECIEVES_STATEMENTS`

export const SET_DOCUMENT_FOLDERS = `${KEY}/SET_DOCUMENT_FOLDERS`
export const REMOVE_DELETED_DOCUMENT = `${KEY}/REMOVE_DELETED_DOCUMENT`
export const SET_UPLOAD_STATUS = `${KEY}/SET_UPLOAD_STATUS`

export const APPROVE_STEP = `${KEY}/APPROVE_STEP`
export const DENY_STEP = `${KEY}/DENY_STEP`

export const SET_denyNote = `${KEY}/SET_denyNote`
