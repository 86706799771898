// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__errorWrapper___AtlzT {
}

.styles__formItem___NMnyb {
}

.styles__label___SN4lY {
}
`, "",{"version":3,"sources":["webpack://./universal/components/DocInput/styles.css"],"names":[],"mappings":"AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.errorWrapper {\n  composes: errorWrapper from helpers;\n}\n\n.formItem {\n  composes: formItem from helpers;\n}\n\n.label {\n  composes: label from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"errorWrapper": `styles__errorWrapper___AtlzT ${___CSS_LOADER_ICSS_IMPORT_0___.locals["errorWrapper"]}`,
	"formItem": `styles__formItem___NMnyb ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItem"]}`,
	"label": `styles__label___SN4lY ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`
};
export default ___CSS_LOADER_EXPORT___;
