// @flow
import React from 'react'
import styles from './styles.css'

type HeaderNavProps = {
  children?: React.ReactNode,
}

const HeaderNav = (props: HeaderNavProps) => {
  const { children } = props

  return (
    <nav role="navigation" className={styles.headerNav}>
      {children}
    </nav>
  )
}

export default HeaderNav
