// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeeReport__feeReport___wCUSY {
  display: flex;
  flex-direction: column;
}

.FeeReport__export___Ofm5S {
  margin-top: 20px;
}

.FeeReport__export___Ofm5S button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    box-shadow: 0 1px 0 rgba(55, 110, 161, 0.2);
    color: #376ea1;
    -webkit-text-decoration: none;
    text-decoration: none;
    transition: all 0.2s;
  }

.FeeReport__body___Uul_u {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.FeeReport__body___Uul_u > * {
    margin-bottom: 20px;
  }

.FeeReport__form___EoAcy {
  width: 25%;
  margin: 0 auto;
}

.FeeReport__rowWrapper___bS3Xw {
  display: inline-block;
}

.FeeReport__rowWrapper___bS3Xw .FeeReport__row___rOSJ8 {
    display: flex;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

.FeeReport__col___nyOw8 {
  display: flex;
  flex-direction: column;
}

.FeeReport__sign___JSCIr {
  align-self: flex-end;
  padding: 0 10px 5px;
}

.FeeReport__metric___MBVoo {
  padding-bottom: 10px;
}

.FeeReport__metric___MBVoo:last-of-type {
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/FeeReport/FeeReport.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAWlB;;AAVE;IACE,6BAA6B;IAC7B,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,2CAA2C;IAC3C,cAAc;IACd,6BAAqB;IAArB,qBAAqB;IACrB,oBAAoB;EACtB;;AAGF;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,iBAAiB;EACjB,WAAW;EACX,cAAc;AAKhB;;AAHE;IACE,mBAAmB;EACrB;;AAGF;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,qBAAqB;AAOvB;;AALE;IACE,aAAa;IACb,mBAAmB;IACnB,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;EACpB;;AAGF;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".feeReport {\n  display: flex;\n  flex-direction: column;\n}\n\n.export {\n  margin-top: 20px;\n  button {\n    background-color: transparent;\n    border: 0;\n    box-shadow: none;\n    cursor: pointer;\n    box-shadow: 0 1px 0 rgba(55, 110, 161, 0.2);\n    color: #376ea1;\n    text-decoration: none;\n    transition: all 0.2s;\n  }\n}\n\n.body {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  max-width: 1440px;\n  width: 100%;\n  margin: 0 auto;\n\n  > * {\n    margin-bottom: 20px;\n  }\n}\n\n.form {\n  width: 25%;\n  margin: 0 auto;\n}\n\n.rowWrapper {\n  display: inline-block;\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    width: fit-content;\n  }\n}\n\n.col {\n  display: flex;\n  flex-direction: column;\n}\n\n.sign {\n  align-self: flex-end;\n  padding: 0 10px 5px;\n}\n\n.metric {\n  padding-bottom: 10px;\n}\n\n.metric:last-of-type {\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feeReport": `FeeReport__feeReport___wCUSY`,
	"export": `FeeReport__export___Ofm5S`,
	"body": `FeeReport__body___Uul_u`,
	"form": `FeeReport__form___EoAcy`,
	"rowWrapper": `FeeReport__rowWrapper___bS3Xw`,
	"row": `FeeReport__row___rOSJ8`,
	"col": `FeeReport__col___nyOw8`,
	"sign": `FeeReport__sign___JSCIr`,
	"metric": `FeeReport__metric___MBVoo`
};
export default ___CSS_LOADER_EXPORT___;
