// @flow
import Main from './Main'
import Deposit from './Deposit'
import Edit from './Edit'
import Statement from './Statement'
import TrusteeReceipt from './TrusteeReceipt'
import SubAccountsRoutes from './Subaccounts'

export default ((store: Object) => ({
  path: ':number',
  children: [
    {
      path: '',
      element: <Main />,
      index: true,
    },
    {
      path: 'deposit',
      element: <Deposit />,
    },
    {
      path: 'edit',
      element: <Edit />,
    },
    {
      path: 'statement',
      element: <Statement />,
    },
    {
      path: 'trustee-receipt',
      element: <TrusteeReceipt />,
    },
    { ...SubAccountsRoutes },
  ],
}))()
