// @flow
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'lib/hooks/withRouter'
import Error from 'components/Error'
import { httpGet, httpPost } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { rateLimitMessage } from 'lib/rateLimit'
import { Template } from './Template'

type Props = {
  dispatch: Function,
  location: Object,
}

type State = {
  isSubmitting: boolean,
  journalId: string,
  rateLimitProblem: ?string,
  scheduled: Array<Object>,
}

class AnnualFees extends React.Component<Props, State> {
  props: Props

  constructor(props: Props) {
    super(props)
    this.state = {
      isSubmitting: false,
      journalId: '',
      rateLimitProblem: null,
      scheduled: [],
    }
  }

  apiPath = () => {
    const date = new Date()
    const year = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1
    const journalId = this.state.journalId

    return `/admin/annual-fees/${year}/${month}?journal_id=${journalId}`
  }

  fetchFees = () => {
    const { dispatch } = this.props

    dispatch(setLoad())
    httpGet(this.apiPath())
      .then(({ scheduled }) => {
        const rateLimitProblem = null
        this.setState({ rateLimitProblem, scheduled })
      })
      .catch((error) => {
        const { code, unit } = error
        if (code === 598) {
          this.setState({ rateLimitProblem: unit })
        } else {
          throw error
        }
      })
      .then(() => dispatch(setUnload()))
  }

  componentDidUpdate(prevProps, { journalId: prevJournalId }) {
    const { journalId } = this.state
    if (journalId != prevJournalId) {
      if (journalId === '') {
        this.setState({
          isSubmitting: false,
          journalId: '',
          rateLimitProblem: null,
          scheduled: [],
        })
      } else {
        this.fetchFees()
      }
    }
  }

  handleChange = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { name, value } = event.currentTarget
      ? event.currentTarget
      : {
          name: event.name,
          value: event?.value ? event?.value?.value : '',
        }
    this.setState({ [name]: value })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({ isSubmitting: true }, this.postFees)
  }

  postFees = () => {
    const { dispatch } = this.props

    dispatch(setLoad())
    httpPost(this.apiPath())
      .then(() => {
        this.props.location.navigate('/contacts')
        dispatch(setBanner('Fees successfully collected.'))
      })
      .catch(({ code, unit }) => {
        if (code === 598) {
          dispatch(setBanner(rateLimitMessage(unit), 'danger'))
        } else {
          dispatch(setBanner('No fees collected.', 'danger'))
        }
      })
      .then(() => {
        this.setState({ isSubmitting: false })
        dispatch(setUnload())
      })
  }

  render() {
    const { journalId, rateLimitProblem, scheduled } = this.state
    if (rateLimitProblem) {
      return (
        <Error
          message={rateLimitMessage(rateLimitProblem)}
          title="Rate Limit Exceeded"
        />
      )
    }

    const negativeAccounts = () =>
      scheduled.filter((s) => s.postFeeCheckingBalance < 0)

    return (
      <Template
        isSubmitting={this.state.isSubmitting}
        journalId={journalId}
        negativeAccounts={negativeAccounts()}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        scheduled={scheduled}
      />
    )
  }
}

export default withRouter(connect()(AnnualFees))
