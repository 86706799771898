// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecentSearches__item___NKa22 {
  height: 69px;
  width: 401px;
  background-color: #FFF;
  box-shadow: 0 0.5px 0 0 rgba(195, 195, 195, 0.45);
  display: flex;
  align-items: center;
  padding: 0 18px;
  cursor: pointer;
  margin-bottom: 8px;
}

.RecentSearches__itemLabel___lcyMm {
  padding: 0 18px;
  flex: 1 1;
}

.RecentSearches__itemLabel___lcyMm span {
    display: block;
  }

.RecentSearches__container___zGG9w {
  margin-top: 20px;
}

.RecentSearches__title___Evq6B {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1px;
  margin-bottom: 25px;
  display: block;
}

.RecentSearches__small___aYtWs {
  font-size: 12px;
  font-weight: 500;
  color: #9B9B9B;
}

.RecentSearches__linkIcon___mNzuC {
  color: #376EA1;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Contacts/components/Search/RecentSearches/RecentSearches.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,iDAAiD;EACjD,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,SAAS;AAKX;;AAHE;IACE,cAAc;EAChB;;AAGF;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,qBAAqB;EACrB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".item {\n  height: 69px;\n  width: 401px;\n  background-color: #FFF;\n  box-shadow: 0 0.5px 0 0 rgba(195, 195, 195, 0.45);\n  display: flex;\n  align-items: center;\n  padding: 0 18px;\n  cursor: pointer;\n  margin-bottom: 8px;\n}\n\n.itemLabel {\n  padding: 0 18px;\n  flex: 1 1;\n\n  span {\n    display: block;\n  }\n}\n\n.container {\n  margin-top: 20px;\n}\n\n.title {\n  font-size: 16px;\n  font-weight: bold;\n  letter-spacing: 0.1px;\n  margin-bottom: 25px;\n  display: block;\n}\n\n.small {\n  font-size: 12px;\n  font-weight: 500;\n  color: #9B9B9B;\n}\n\n.linkIcon {\n  color: #376EA1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `RecentSearches__item___NKa22`,
	"itemLabel": `RecentSearches__itemLabel___lcyMm`,
	"container": `RecentSearches__container___zGG9w`,
	"title": `RecentSearches__title___Evq6B`,
	"small": `RecentSearches__small___aYtWs`,
	"linkIcon": `RecentSearches__linkIcon___mNzuC`
};
export default ___CSS_LOADER_EXPORT___;
