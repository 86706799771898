// @flow
import Main from './components/Main'
import Edit from './components/Edit'

export default (store: Object) => ({
  path: 'users',
  children: [
    {
      index: true,
      element: <Main />, //index route
    },
    {
      path: 'edit/:id',
      element: <Edit />,
    },
  ],
})
