// @flow
import Main from './Main'
import Statement from './Statement'

export default ((store: Object) => ({
  path: 'subaccounts/:subnumber',
  children: [
    {
      element: <Main />,
      index: true,
    },
    {
      element: <Statement />,
      path: 'statement',
    },
  ],
}))()
