// @flow
import React from 'react'
import formatters from 'lib/formatters'
import Row from './Row'
import IconSwitch from 'components/IconSwitch/IconSwitch'
import Ref from 'components/References/Ref'
import { FilePreview } from 'components/FilePreview'

type Props = {
  hasSSI: boolean,
  hasSSIFood: boolean,
  hasSSIShelter: boolean,
  ssiAmount: number,
  ssiApprovalContentType: string,
  ssiApprovalFile: number,
}

const SSIRef = (props: Props) => {
  const {
    hasSSI,
    hasSSIFood,
    hasSSIShelter,
    ssiAmount,
    ssiApprovalFile,
  } = props

  let ssiDoc = 'No documentation'

  const showLink = (label, file) => <FilePreview file={file} label={label} />

  if (ssiApprovalFile) {
    ssiDoc = showLink('Attorney Document', ssiApprovalFile)
  }

  return (
    <Ref
      title="SSI"
      preview={
        <Row
          singleLine
          label="Supplemental Security Income"
          val={<IconSwitch flag={hasSSI} />}
        />
      }
      details={
        hasSSI && (
          <div>
            <Row
              singleLine
              label="Amount"
              val={formatters.money(ssiAmount || 0)}
              mono={true}
            />
            <Row
              singleLine
              label="Food"
              val={<IconSwitch flag={hasSSIFood} />}
            />
            <Row
              singleLine
              label="Shelter"
              val={<IconSwitch flag={hasSSIShelter} />}
            />
            <Row singleLine label="Attorney Approval" val={ssiDoc} />
          </div>
        )
      }
    />
  )
}

export default SSIRef
