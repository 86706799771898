// @flow
import React, { useState, useRef, useEffect } from 'react'
import { IconSearch } from 'components/Icons/IconSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutocompleteResults } from './AutocompleteResults'

import styles from './Autocomplete.css'

type Props = {
  searchQuery: String,
  items: { accounts: [], contacts: [] },
  onChange: Function,
  onSelect: Function,
  onSubmit: Function,
  onClear: Function,
}

export const Autocomplete = ({
  searchQuery,
  onSubmit,
  onChange,
  onSelect,
  onClear,
  items,
}: Props) => {
  const containarElementRef = useRef()
  const inputRef = useRef()
  const [isActive, setIsActive] = useState(false)
  const [focusedValue, setFocusedValue] = useState({ list: null, pos: null })
  const [mouseOnClear, setMouseOnClear] = useState(false)
  const hasResults = items.accounts.length > 0 || items.contacts.length > 0

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!isActive) {
        return
      }
      if (containarElementRef.current && !containarElementRef.current.contains(e.target)) {
        setIsActive(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [containarElementRef, isActive])

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      if (focusedValue.pos === null) {
        onSubmit(searchQuery)
      } else {
        onSelect(items[focusedValue.list][focusedValue.pos], focusedValue)
      }

      setIsActive(false)
      e.target.blur()

      setFocusedValue({ list: null, pos: null })

      return
    }

    if (mouseOnClear) {
      setMouseOnClear(false)
    }

    if (!hasResults) {
      return
    }

    if (e.key === 'ArrowUp') {
      focusPrev()
    } else if (e.key === 'ArrowDown') {
      focusNext()
    }
  }

  function focusNext() {
    if (focusedValue.list == null) {
      const nextList = items['accounts'].length ? 'accounts' : 'contacts'
      setFocusedValue(() => ({ list: nextList, pos: 0 }))
      return
    }

    if (focusedValue.pos == null) {
      setFocusedValue((v) => ({ ...v, pos: 0 }))
      return
    }

    const nextList = focusedValue.list === 'accounts' ? 'contacts' : 'accounts'
    let nextValue = focusedValue.pos + 1

    if (nextValue > items[focusedValue.list].length - 1) {
      if (items[nextList].length > 0) {
        setFocusedValue({ list: nextList, pos: 0 })
      } else {
        setFocusedValue((v) => ({ ...v, pos: 0 }))
      }
    } else {
      setFocusedValue((v) => ({ ...v, pos: nextValue }))
    }
  }

  function focusPrev() {
    const nextList = focusedValue.list === 'accounts' ? 'contacts' : 'accounts'

    if (focusedValue.pos == null) {
      setFocusedValue(() => ({
        list: nextList,
        pos: items[nextList].length - 1,
      }))

      return
    }

    let nextValue = focusedValue.pos - 1

    if (nextValue < 0) {
      if (items[nextList].length > 0) {
        setFocusedValue({
          list: nextList,
          pos: items[nextList].length - 1,
        })
      } else {
        setFocusedValue((v) => ({
          ...v,
          pos: items[focusedValue.list].length - 1,
        }))
      }
    } else {
      setFocusedValue((v) => ({ ...v, pos: nextValue }))
    }
  }

  function onInputChange(e) {
    const value = e.target.value
    onChange(value)
  }

  function onInputClick(item, selection) {
    onSelect(item, selection)
  }

  function onSubmitClick(e) {
    onSubmit(searchQuery, e)
    setIsActive(false)
  }

  function onClearClick() {
    setFocusedValue(() => ({ list: null, pos: null }))
    onClear && onClear()
  }

  return (
    <div className={styles.autocompleteContainer} onKeyDown={onKeyDown} ref={containarElementRef}>
      <div className={styles.autoCompleteInputContainer}>
        <div
          className={styles.autoCompleteInput}
          onClick={() => inputRef.current && inputRef.current.focus()}
        >
          <IconSearch />
          <input
            placeholder="Search by name, phone number or account number"
            value={searchQuery}
            ref={inputRef}
            autoFocus={true}
            onFocus={() => setIsActive(true)}
            onChange={onInputChange}
          />
          {searchQuery && (
            <div
              className={styles.autoCompleteItems_clearIcon}
              role="button"
              onMouseEnter={() => setMouseOnClear(true)}
              onMouseLeave={() => setMouseOnClear(false)}
              onClick={onClearClick}
            >
              <FontAwesomeIcon size="lg" icon={['fas', 'times-circle']} />
            </div>
          )}
        </div>

        <button onClick={onSubmitClick} className={styles.autoCompleteInputBtn}>
          Search
        </button>
      </div>
      {hasResults && isActive && !mouseOnClear && (
        <div className={styles.autocomplete_resultContainer}>
          <AutocompleteResults
            query={searchQuery}
            searchResult={items}
            focusedValue={focusedValue}
            onItemClick={(item, selection) => onInputClick(item, selection)}
          />
        </div>
      )}
    </div>
  )
}
