// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__lockUnlock___OkEPM {
  padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Distributions/components/permissions/styles.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB","sourcesContent":[".lockUnlock {\n  padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lockUnlock": `styles__lockUnlock___OkEPM`
};
export default ___CSS_LOADER_EXPORT___;
