// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__fullWidth___nkLI_ {
  width: 100%;
}

.styles__contentContainer___slD3A {
  padding-bottom: 20px;
  padding-top: 20px;
}

.styles__updatedTime___w4yxf {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["textLight"]};
  font-size: 12px;
}

.styles__valueAlert___myFAg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]};
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/ActionQueue/styles.css"],"names":[],"mappings":"AAKA;EACE,WAAW;AACb;;AAEA;EAEE,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,mDAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,mDAAY;EAEZ,iBAAiB;AACnB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value textLight from colors;\n@value gtRed from colors;\n\n.fullWidth {\n  width: 100%;\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n  padding-bottom: 20px;\n  padding-top: 20px;\n}\n\n.updatedTime {\n  color: textLight;\n  font-size: 12px;\n}\n\n.valueAlert {\n  color: gtRed;\n  composes: value from helpers;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"textLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["textLight"]}`,
	"gtRed": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]}`,
	"fullWidth": `styles__fullWidth___nkLI_`,
	"contentContainer": `styles__contentContainer___slD3A ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"updatedTime": `styles__updatedTime___w4yxf`,
	"valueAlert": `styles__valueAlert___myFAg ${___CSS_LOADER_ICSS_IMPORT_1___.locals["value"]}`
};
export default ___CSS_LOADER_EXPORT___;
