// @flow
import React from 'react'
import { connect } from 'react-redux'
import formatters from 'lib/formatters'

import styles from './styles'

type Props = {
  xeroLastFetchAt: string,
}

export class XeroRefresh extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({}), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  style() {
    const diff = formatters.dateTimeDiff(this.props.xeroLastFetchAt, 'minutes')

    if (diff <= 30) return ''
    if (diff <= 60) return styles.yellow
    return styles.red
  }

  render() {
    const { xeroLastFetchAt } = this.props
    const text = `Xero last updated ${formatters.lastUpdate(xeroLastFetchAt)}`

    return xeroLastFetchAt ? (
      <span>
        <div className={styles.xeroRefresh + ' ' + this.style()}>{text}</div>
      </span>
    ) : null
  }
}

const mapStateToProps = ({
  lib_account: { xero_last_fetch_at: xeroLastFetchAt },
}) => {
  return { xeroLastFetchAt }
}

export default connect(mapStateToProps)(XeroRefresh)
