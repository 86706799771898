// @flow
import cleaners from 'lib/field/cleaners'

const scrub = (value) => {
  return value === '' ? null : value
}

const contact = (data: Object) => {
  const cleaned = {
    cellPhone: cleaners.numbers(scrub(data.cellPhone)),
    city: scrub(data.city),
    dayPhone: cleaners.numbers(scrub(data.dayPhone)),
    dob: scrub(data.dob),
    email: scrub(data.email),
    eveningPhone: cleaners.numbers(scrub(data.eveningPhone)),
    fax: cleaners.numbers(scrub(data.fax)),
    name: scrub(data.name),
    ssn: cleaners.numbers(scrub(data.ssn)),
    state: scrub(data.state),
    street1: scrub(data.street1),
    street2: scrub(data.street2),
    zip: scrub(data.zip),
    company_name: scrub(data.company_name)
  }
  const file = cleaners.file(data.photoId)
  if (file && !data.photoId.initial) {
    cleaned.photoIdData = file.data
    cleaned.photoIdContentType = file.content_type
  }
  return cleaned
}

const contactList = (contacts: Array<Object>) =>
  contacts.map((contact) => contact.id)

const editBeneficiary = (data: Object) => {
  const { phone, ssn, photo_id, ...rest } = data
  const files = {}
  const photo = cleaners.file(photo_id)
  if (photo && !photo_id.initial) {
    files.photo_id = photo
  }

  return {
    ...files,
    ...rest,
    phone: cleaners.numbers(phone),
    ssn: cleaners.numbers(ssn),
  }
}

const lineItem = (data: Object) => {
  const cleaned = {
    amount: cleaners.money(scrub(data.amount)),
    effective_date: scrub(data.date),
    memo: scrub(data.memo),
  }

  return cleaned
}

const newAccount = (data: Object) => {
  const { primary_grantor_doc, ...rest } = data
  const files = {}
  const primDoc = cleaners.file(primary_grantor_doc)
  if (primDoc && !primary_grantor_doc.initial) {
    files.primary_grantor_doc = primDoc
  }

  const account = { ...rest, ...files }
  if (data.grantor) {
    account.grantor = grantor(data.grantor)
  }

  return account
}

const grantor = (data: Object) => {
  const { phone, ssn, photo_id } = data
  const photo = cleaners.file(photo_id)
  if (photo && !photo_id.initial) {
    data.photo_id = photo
  }

  return {
    ...data,
    phone: cleaners.numbers(phone),
    ssn: cleaners.numbers(ssn),
  }
}

export default {
  contact,
  contactList,
  editBeneficiary,
  lineItem,
  newAccount,
}
