// @flow
import * as t from './actionTypes'
import { httpGet } from 'lib/http'

export const getBeneficiary = (id: number) => {
  return (dispatch: Function) => {
    return httpGet(`/admin/beneficiaries/${id}`)
      .then((data) => {
        dispatch({ type: t.SET, beneficiary: data.beneficiary })
      })
  }
}
