// @flow
import * as t from './actionTypes'

export const setBanner = (message: string, messageType: string = 'success') => {
  return (dispatch: Function) => {
    const onTimeout = () => {
      dispatch({ type: t.SET_BANNER, message: null })
    }
    const timeoutMilliseconds = 6 * 1000
    setTimeout(onTimeout, timeoutMilliseconds)

    return dispatch({ type: t.SET_BANNER, message, messageType })
  }
}

export const unsetBanner = () => {
  return (dispatch: Function) => {
    return dispatch({ type: t.SET_BANNER, message: null })
  }
}

export const setLoad = () => {
  return (dispatch: Function) => {
    return dispatch({ type: t.SET_LOADER, loading: true })
  }
}

export const setUnload = () => {
  return (dispatch: Function) => {
    return dispatch({ type: t.SET_LOADER, loading: false })
  }
}
