// @flow
import * as t from './actionTypes'

export const setModal = (contents) => {
  return (dispatch: Function) => {
    return dispatch({ type: t.SET_MODAL, contents })
  }
}

export const unsetModal = () => {
  return (dispatch: Function) => {
    return dispatch({ type: t.SET_MODAL, contents: null })
  }
}
