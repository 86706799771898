// @flow
import formatters from 'lib/formatters'
import React from 'react'
import styles from './Header.css'
import { Link } from 'react-router-dom'
import { SUBACCOUNT_TYPES } from 'lib/constants'

type Props = {
  balance: number,
  beneficiary_name: string,
  number: string,
  subaccount_type: string,
  subnumber: string,
}

const Header = (props: Props) => {
  const {
    balance = 0.0,
    beneficiary_name = '',
    number,
    subaccount_type = '',
    subnumber
  } = props

  const subaccount_title = SUBACCOUNT_TYPES[subaccount_type]
  const link_title = `${beneficiary_name} - ${subaccount_title}`

  return (
    <div className={styles.titleBox}>
      <div className={styles.nameWrapper}>
        <span className={styles.titleName}>
          <h5 className={styles.accountNumber}>{subnumber}</h5>
        </span>
        <h1>
          <Link
            to={`/accounts/${number}`}
            className={styles.beneName}
          >
            {link_title}
          </Link>
        </h1>
      </div>
      <div className={styles.balanceWrapper}>
        <h5 className={styles.boxLabel}>Balance</h5>
        <h1>{formatters.money(balance || 0)}</h1>
      </div>
    </div>
  )
}

export default Header
