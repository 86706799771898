// @flow
import React from 'react'
import Collapse from 'react-collapse'
import formatters from 'lib/formatters'
import { httpPost, httpPut, httpDelete } from 'lib/http'
import { setModal, unsetModal } from 'lib/modal/actions'
import { Dialog } from 'components/Dialog'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { LeaveNoteForm } from './LeaveNoteForm'
import styles from './styles'
import type { Note } from 'lib/types'

type Props = {
  accountNumber: string,
  subnumber: string,
  contactId: number,
  distributionId: Number,
  dispatch: Function,
  notes: [Note],
  type: string,
}

export class Notes extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      currentNotePriority: null,
      deleteNote: null,
      notes: props.notes,
      toggleNotePriority: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.notes != prevProps.notes) {
      this.setState({ notes: this.props.notes })
    }
  }

  _baseUrl = () => {
    const { accountNumber, contactId, distributionId, subnumber, type } =
      this.props

    if (type == 'subaccount') {
      return (
        `/admin/accounts/${accountNumber}/` + `subaccounts/${subnumber}/notes/`
      )
    } else if (type == 'contact') {
      return `/admin/contacts/${contactId}/notes/`
    } else if (type == 'closing') {
      return `/admin/closings/${accountNumber}/notes/`
    } else if (type == 'distribution') {
      return `/admin/distributions/${distributionId}/notes/`
    } else {
      return `/admin/accounts/${accountNumber}/notes/`
    }
  }

  _handleSubmit = ({ note }, dispatch) => {
    const data = { note: note }
    dispatch(setLoad())
    return httpPost(this._baseUrl(), data)
      .then(({ items }) => {
        this.setState({ notes: items })
        dispatch(setBanner('Note successfully left.'))
      })
      .catch(() => {
        dispatch(setBanner('Note unsuccessfully left.', 'danger'))
      })
      .then(() => {
        dispatch(unsetModal())
        dispatch(setUnload())
      })
  }

  _handleRemove = (noteId) => {
    const { dispatch } = this.props
    const url = `/admin/notes/${noteId}/`
    dispatch(setLoad())
    return httpDelete(url)
      .then(() => {
        const { notes } = this.state
        const newNotes = notes.filter((note) => note.id !== noteId)
        this.setState({ notes: newNotes })
        dispatch(setBanner('Note successfully removed'))
      })
      .catch(() => {
        dispatch(setBanner('Error removing note', 'danger'))
      })
      .then(() => {
        this.setState({ deleteNote: null })
        dispatch(setUnload())
      })
  }

  _handleTogglePriority = (noteId) => {
    const { dispatch } = this.props
    const url = `/admin/notes/${noteId}/priority`

    const { notes } = this.state
    const note = notes.find((item) => item.id == noteId)
    const newPriority = note.priority == 'high' ? 'standard' : 'high'
    const putData = { priority: newPriority }

    dispatch(setLoad())
    return httpPut(url, putData)
      .then((data) => {
        if (data.status == 'updated') {
          const newNotes = notes.map((note) =>
            note.id == noteId ? { ...note, priority: newPriority } : note,
          )
          this.setState({ notes: newNotes })
          dispatch(setBanner('Note prority changed'))
        } else {
          dispatch(setBanner(data.message, 'danger'))
        }
      })
      .catch(() => {
        dispatch(setBanner('Error changing note priority', 'danger'))
      })
      .then(() => {
        this.setState({ toggleNotePriority: null })
        dispatch(setUnload())
      })
  }

  _leaveNote = () => {
    const { dispatch } = this.props
    dispatch(
      setModal(
        <LeaveNoteForm
          handleSubmit={this._handleSubmit}
          onCancel={() => dispatch(unsetModal())}
        />,
      ),
    )
  }

  _priorityClassName = (note) => {
    return note.priority == 'high' ? `${styles.importantNoteWrapper}` : ''
  }

  _sortNotes = ({ notes }) => {
    const importantNotes = notes
      .filter((x) => x.priority == 'high')
      .sort((x, y) => y.id - x.id)

    const standardNotes = notes
      .filter((x) => x.priority != 'high')
      .sort((x, y) => y.id - x.id)

    return importantNotes.concat(standardNotes)
  }

  _renderNoteRows = () => {
    const notes = this._sortNotes(this.state)
    return notes.map((note, idx) => {
      const timestamp = formatters.timestamp(note.leftAt)
      return (
        <li
          key={idx}
          className={`${styles.noteWrapper} ` + this._priorityClassName(note)}
        >
          <div className={styles.flexRow}>
            <div className={styles.flexSB}>
              <div className={styles.flexRow}>
                <div className={styles.important}>
                  <a
                    title="Important"
                    onClick={() =>
                      this.setState({
                        toggleNotePriority: note.id,
                        currentNotePriority: note.priority,
                      })
                    }
                  />
                </div>
                <div>
                  <div className={styles.note}>{note.note}</div>
                  <div className={styles.user}>{`${note.user.split(
                    '@',
                    1,
                  )}, ${timestamp}`}</div>
                </div>
              </div>
              <div className={styles.remove}>
                <a
                  title="Remove Note"
                  onClick={() => this.setState({ deleteNote: note.id })}
                />
              </div>
            </div>
          </div>
        </li>
      )
    })
  }

  _renderPriorityHeaderText = () => {
    const { currentNotePriority } = this.state
    return currentNotePriority == 'high'
      ? 'Remove high priority from this note?'
      : 'Make this note high priority?'
  }

  render() {
    const { deleteNote, toggleNotePriority } = this.state
    return (
      <section data-active={true} className={styles.collapseRef}>
        <div className={styles.titleSection}>
          <div className={styles.title}>
            <h5>Notes</h5>
          </div>
          <span
            className={styles.leaveNoteIcon}
            onClick={this._leaveNote}
            title="Leave Note"
          />
        </div>
        <Collapse isOpened={true}>
          <ul className={styles.notes}>{this._renderNoteRows()}</ul>
        </Collapse>
        {deleteNote && (
          <Dialog
            cancelButtonText="Cancel"
            confirmButtonText="Yes, Delete"
            headerText="Are you sure you want to delete this note?"
            onCancel={() => this.setState({ deleteNote: null })}
            onConfirm={() => this._handleRemove(deleteNote)}
          />
        )}
        {toggleNotePriority && (
          <Dialog
            cancelButtonText="Cancel"
            confirmButtonText="Yes"
            headerText={this._renderPriorityHeaderText()}
            onCancel={() => this.setState({ toggleNotePriority: null })}
            onConfirm={() => this._handleTogglePriority(toggleNotePriority)}
          />
        )}
      </section>
    )
  }
}
