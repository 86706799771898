import React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import theme from 'ui/theme'

type ButtonAddProps = {
  label?: string,
  onClick?: () => void,
  className?: any,
}

const Layout = styled.button`
  background-color: transparent;
  display: flex;
  flex-grow: 0;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: auto;
  height: 1.25rem;
  margin: 18px 0 14px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
`

const Label = styled.span`
  position: relative;
  top: -1px;
  margin-left: 0.75rem;
  font-family: ${theme.font.family};
  font-weight: ${theme.font.weight.demi};
  font-size: 0.875rem;
  line-height: 100%;
  color: ${theme.color.text.default};
`

const ButtonAdd = (props: ButtonAddProps) => {
  const { label = 'Add', onClick, className } = props

  return (
    <Layout type="button" className={className} onClick={onClick}>
      <span>
        <Icon icon="Add" width={20} height={20} fill={theme.color.green} />
      </span>

      <Label>{label}</Label>
    </Layout>
  )
}

export default ButtonAdd
