// @flow
import * as t from './actionTypes'
import type { State } from './model'

const initialState: State = {
  email: '',
  isCoTrustee: false,
  isAuditor: false,
  isLoggedIn: false,
  user: {
    first_name: '',
    last_name: '',
    email: '',
  },
  users: [],
}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case t.LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        isCoTrustee: action.isCoTrustee,
        isAuditor: action.isAuditor,
      }
    case t.LOGOUT:
      return { ...state, isLoggedIn: false }
    case t.SET_EMAIL:
      return { ...state, email: action.email }
    case t.SET_USER:
      return { ...state, user: action.user }
    case t.SET_USERS:
      return { ...state, users: action.users }
    case t.UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, [action.attr]: action.val },
      }
    case t.TOGGLE_ROLE:
      return {
        ...state,
        user: { ...state.user, roles: toggleRole(state, action.role) },
      }
    default:
      return state
  }
}

const toggleRole = (state, role) => {
  let roles = [...state.user.roles]
  let idx = -1

  for (let i = 0; i < roles.length; i++) {
    if (roles[i].type == role) {
      idx = i
    }
  }

  if (idx !== -1) {
    roles.splice(idx, 1)
  } else {
    roles.push({ type: role, user_id: state.user.id })
  }

  return roles
}
