// @flow
import React from 'react'
import Row from 'components/References/Row'
import formatters from 'lib/formatters'
import styles from './styles'

type Props = {
  amount: number,
  balance: number,
  checkNumber: string,
  feeRate: number,
  feeSchedule: string,
  hasCustomFeeSchedule: boolean,
  enrollmentFee: number,
  isRefund: boolean,
  isTransfer: boolean,
}

const EnrollmentFee = (fee) =>
  <Row
    label='Enrollment Fee'
    val={formatters.money(fee)}
    key='enrollment' full={true}
  />

export const FeeCalculationsRef = (props: Props) => {
  const {
    amount,
    balance,
    feeRate,
    feeSchedule,
    enrollmentFee,
    isRefund,
    isTransfer
  } = props
  const hasEnrollmentFee = enrollmentFee > 0
  const fee = Math.round(
    (hasEnrollmentFee ? amount - enrollmentFee : amount) * feeRate / 100
  )
  return (
    <div className={styles.cardMarginBot}>
      <h5 className={styles.sectionTitle}>Fee Calculation</h5>
      <div className={styles.sectionNoMargin}>
        <Row
          label='Balance'
          val={formatters.money(balance || 0)}
          key='balance'
          full={true}
        />
        <Row
          label='Deposit'
          val={formatters.money(amount)}
          key='deposit'
          full={true}
        />
        {hasEnrollmentFee && EnrollmentFee(enrollmentFee)}
        <Row
          label='Fee Schedule'
          val={feeSchedule}
          key='custom'
          full={true}
        />
        <Row
          label='Deposit Fee Rate'
          val={`${feeRate || 0}%`}
          key='rate'
          full={true}
        />
        <Row
          label='Deposit Fee'
          val={formatters.money(fee || 0)}
          key='fee'
          full={true}
        />
        <Row
          label='Is this a refund?'
          val={isRefund ? 'Yes' : 'No'}
          key='refund'
          full={true}
        />
        <Row
          label='Is this a transfer?'
          val={isTransfer ? 'Yes' : 'No'}
          key='transfer'
          full={true}
        />
      </div>
    </div>
  )
}
