// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__inputLabelGroup___YgA0p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.styles__inputLabelGroup___YgA0p input[type='checkbox'] {
    width: 16px;
    height: 16px;
  }
.styles__red___FArDU input[type='checkbox'] {
    accent-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]};
  }
.styles__green___QQZq7 input[type='checkbox'] {
    accent-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]};
  }

.styles__inputLabelGroup___YgA0p input {
  margin: 0 10px 0px 4px;
  top: 2px;
  position: relative;
}

.styles__inputLabelGroup___YgA0p label {
  font-size: 13px;
}

.styles__label___n_PUM {
}
`, "",{"version":3,"sources":["webpack://./universal/components/CheckBoxSelect/styles.css"],"names":[],"mappings":"AAIA;EAEE,gBAAgB;EAChB,mBAAmB;AAMrB;AAJE;IACE,WAAW;IACX,YAAY;EACd;AAGA;IACE,0DAAmB;EACrB;AAGA;IACE,0DAAqB;EACvB;;AAGF;EACE,sBAAsB;EACtB,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;AAEA","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtRed, gtGreen from colors;\n\n.inputLabelGroup {\n  composes: clearfix from helpers;\n  margin-top: 10px;\n  margin-bottom: 10px;\n\n  input[type='checkbox'] {\n    width: 16px;\n    height: 16px;\n  }\n}\n.red {\n  input[type='checkbox'] {\n    accent-color: gtRed;\n  }\n}\n.green {\n  input[type='checkbox'] {\n    accent-color: gtGreen;\n  }\n}\n\n.inputLabelGroup input {\n  margin: 0 10px 0px 4px;\n  top: 2px;\n  position: relative;\n}\n\n.inputLabelGroup label {\n  font-size: 13px;\n}\n\n.label {\n  composes: label from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtRed": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]}`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"inputLabelGroup": `styles__inputLabelGroup___YgA0p ${___CSS_LOADER_ICSS_IMPORT_1___.locals["clearfix"]}`,
	"red": `styles__red___FArDU`,
	"green": `styles__green___QQZq7`,
	"label": `styles__label___n_PUM ${___CSS_LOADER_ICSS_IMPORT_1___.locals["label"]}`
};
export default ___CSS_LOADER_EXPORT___;
