// @flow
import React from 'react'
import Dropzone from 'react-dropzone'
import { makeBlobURL } from 'lib/file'
import type { Field } from 'lib/types'
import FieldWrapper from './FieldWrapper'
import styles from './styles'

export default class renderFileUpload extends React.Component {
  props: Field
  state: {
    file: Object,
    prevInput: Object,
  }

  static defaultProps = {
    fileType: '',
    multiple: false,
  }

  constructor(props: Field) {
    super(props)
    this.state = {
      file: {},
      prevInput: {},
    }
  }

  componentDidMount() {
    this._makeBlob(this.props.input)
  }

  componentDidUpdate(prevProps) {
    const { input } = this.props
    if (prevProps.input !== input) {
      this._makeBlob(input)
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { input } = props
    if (input !== state.prevInput) {
      if (input.value.data) {
        const file = state.file
        file.preview = makeBlobURL(input.value)
        return { file, prevInput: input }
      }
    }

    return null
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.file.preview)
  }

  _onDrop = (files: Array<Object>) => {
    const file = files.pop()
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      () => {
        URL.revokeObjectURL(this.state.file.preview)
        this.props.input.onChange({
          content_type: file.type,
          result: reader.result,
        })
      },
      false,
    )
    reader.readAsDataURL(file)
    this.setState({ file })
  }

  _makeBlob = (input: Object) => {
    if (input.value.data) {
      const file = this.state.file
      file.preview = makeBlobURL(input.value)
      this.setState({ file, prevInput: input })
    }
  }

  _showFile = (url: string) => {
    if (url) {
      return (
        <div className={styles.previewMargin}>
          <a
            href={url}
            target="_blank"
            className={styles.docLink}
            rel="noreferrer"
          >
            {this.props.label}
          </a>
        </div>
      )
    }
    return false
  }

  render() {
    const { accept, input, multiple } = this.props
    const url = this.state.file.preview
    const dzProps = {
      accept,
      multiple,
      onDrop: this._onDrop,
    }

    return (
      <FieldWrapper {...this.props}>
        <div className={styles.inputContainer}>
          <input {...input} className={styles.input} type="hidden" />
          {this._showFile(url)}
          <Dropzone
            {...dzProps}
            className={styles.dropzoneArea}
            activeClassName={styles.drag}
          >
            {({ getRootProps, getInputProps }) => (
              <div className={styles.dropzoneArea} {...getRootProps()}>
                <div className={styles.upIcon} />
                <div className={styles.uploadText}>
                  Drop here or click to browse
                </div>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </div>
      </FieldWrapper>
    )
  }
}
