// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { FileInput } from 'components/FileInput'
import formatters from 'lib/formatters'
import { NumericFormat } from 'react-number-format'
import FormButtons from 'components/FormButtons'
import { ContactFormRef, ContactBasicFormRef } from 'components/References'
import { Table, Row as TableRow, Cell } from 'components/Table'
import { EIN_PRODUCTS } from 'lib/constants'
import styles from './styles'

type Props = {
  accountNumber: string,
  errors: Object,
  isSubmitting: boolean,
  onBlur: Function,
  onChange: Function,
  onSubmit: Function,
  openAbleSelectionModal: Function,
  openAttorneySelectionModal: Function,
  openBeneficiarySSNResetModal: Function,
  openCoGrantorSelectionModal: Function,
  openCoGrantorSSNResetModal: Function,
  openConsultSelectionModal: Function,
  openEINResetModal: Function,
  openGrantorSelectionModal: Function,
  openGrantorSSNResetModal: Function,
  openReferrerSelectionModal: Function,
  openRepresentativeSelectionModal: Function,
  openRepresentativeSSNResetModal: Function,
  primaryGrantorDocLabel?: string,
  removeAble: Function,
  removeAttorney: Function,
  removeConsult: Function,
  removeReferrer: Function,
  shouldCoGrantorRender: boolean,
  shouldGrantorDocsRender: boolean,
  shouldGrantorRender: boolean,
  shouldOtherGrantorDocsRender: boolean,
  shouldRepresentativeRender: boolean,
  toggleStatementRecipient: Function,
  values: Object,
}

type DataProps = {
  data: Object,
  index: number,
}

const removableContactRenderer = (removeRole: Function, keyPrefix: string, errors: Object) => {
  const RemovableContact = (contact: Object) => {
    const contactRemoveButton = (
      <div className={styles.removeButton} onClick={() => removeRole(contact.id)} />
    )

    return (
      <ContactBasicFormRef
        contact={contact}
        errors={errors}
        key={keyPrefix + contact.id}
        remove={contactRemoveButton}
      />
    )
  }
  return RemovableContact
}

const renderCoGrantor = (props: Props) => {
  const { errors, openCoGrantorSelectionModal, openCoGrantorSSNResetModal, values } = props

  const coGrantor = values.co_grantor

  const renderCoGrantorButtons = () => renderContactButtons(coGrantor, openCoGrantorSSNResetModal)

  const coGrantorStyles = errors.co_grantor_id
    ? `${styles.sectionSmall} ${styles.errorWrapper}`
    : styles.sectionSmall

  const errorMsg = errors.co_grantor_id && renderErrorLabel(' · ' + errors.co_grantor_id)

  return (
    <div className={coGrantorStyles}>
      <h3 className={errors.co_grantor_id && styles.errorText}>Co-Grantor {errorMsg}</h3>
      <div className={styles.contactButtons}>
        <a className={styles.buttonLocal} onClick={openCoGrantorSelectionModal}>
          {coGrantor ? 'Change Co-Grantor' : 'Select Co-Grantor'}
        </a>
        {coGrantor && renderCoGrantorButtons()}
      </div>
      <ContactFormRef contact={coGrantor} errors={errors.co_grantor} />
    </div>
  )
}

const renderContactButtons = (contact: Object, openSSNResetModal: Function) => (
  <span>
    <Link to={`/contacts/${contact && contact.id}/edit`} className={styles.editContact}>
      Edit Contact
    </Link>
    <a className={styles.buttonSecondary} onClick={openSSNResetModal}>
      Reset SSN
    </a>
  </span>
)

const renderEINNavLink = (product_name) => {
  if (product_name && EIN_PRODUCTS.includes(product_name)) {
    return (
      <a href="#ein" className={styles.menuLink}>
        EIN
      </a>
    )
  } else {
    return <div></div>
  }
}

const renderEINSection = (product_name, ein_verifier, openEINResetModal) => {
  const hasEIN = ein_verifier && ein_verifier.length > 0
  const einLabel = hasEIN ? 'EIN on file' : 'No EIN'
  const einButton = hasEIN ? 'Change EIN' : 'Set EIN'

  if (product_name && EIN_PRODUCTS.includes(product_name)) {
    return (
      <section className={styles.section}>
        <div className={styles.formItemMono}>
          <div id="ein" className={styles.anchorLink} />
          <h2 className={styles.sectionTitle}>EIN</h2>
          <p>{einLabel}</p>
          <a className={styles.buttonLocal} onClick={openEINResetModal}>
            {einButton}
          </a>
        </div>
      </section>
    )
  } else {
    return <div></div>
  }
}

const renderErrorLabel = (error: string) => (
  <label className={styles.label}>
    <strong>{error}</strong>
  </label>
)

const renderGrantor = (props: Props) => {
  const { errors, openGrantorSelectionModal, openGrantorSSNResetModal, values } = props
  const { grantor } = values
  const grantorStyles = errors.grantor_id
    ? `${styles.sectionSmall} ${styles.errorWrapper}`
    : styles.sectionSmall
  const errorMsg = errors.grantor_id && renderErrorLabel(' · ' + errors.grantor_id)

  return (
    <div className={grantorStyles}>
      <h3 className={errors.grantor_id && styles.errorText}>Grantor {errorMsg}</h3>
      <div className={styles.contactButtons}>
        <a className={styles.buttonLocal} onClick={openGrantorSelectionModal}>
          {grantor ? 'Change Grantor' : 'Select Grantor'}
        </a>
        {grantor && renderContactButtons(grantor, openGrantorSSNResetModal)}
      </div>
      <ContactFormRef contact={grantor} errors={errors.grantor} />
    </div>
  )
}

const renderGrantorDocs = (props: Props) => {
  const { errors, onChange, primaryGrantorDocLabel, shouldOtherGrantorDocsRender, values } = props

  const error = errors.primary_grantor_doc && ` · ${errors.primary_grantor_doc}`

  return (
    <div className={styles.section}>
      <h3>Required Documents</h3>
      <div className={styles.formItem}>
        <div className={errors.primary_grantor_doc && styles.errorWrapper}>
          <label className={styles.label} htmlFor="primary_grantor_doc">
            {primaryGrantorDocLabel}
            <strong>{error}</strong>
          </label>
          <FileInput
            name="primary_grantor_doc"
            onChange={onChange}
            value={values.primary_grantor_doc || ''}
          />
        </div>
      </div>
      {shouldOtherGrantorDocsRender && renderOtherGrantorDocs(props)}
    </div>
  )
}

const renderPreviousRole = (props: DataProps) => {
  const { data, index } = props
  let { contact, role, inactive_date } = data
  let { id, name } = contact
  const nameLink = <Link to={`/contacts/${id}`}>{name}</Link>

  return (
    <TableRow key={index}>
      <Cell value={nameLink} />
      <Cell value={role} />
      <Cell value={formatters.date(inactive_date)} />
    </TableRow>
  )
}

const renderRepresentative = (props: Props) => {
  const { errors, openRepresentativeSelectionModal, openRepresentativeSSNResetModal, values } =
    props
  const { representative } = values
  const representativeSyles = errors.representative_id
    ? `${styles.sectionSmall} ${styles.errorWrapper}`
    : styles.sectionSmall
  const errorMsg = errors.representative_id && renderErrorLabel(' · ' + errors.representative_id)

  return (
    <div className={representativeSyles}>
      <h3 className={errors.representative_id && styles.errorText}>Representative {errorMsg}</h3>
      <div className={styles.contactButtons}>
        <a className={styles.buttonLocal} onClick={openRepresentativeSelectionModal}>
          {representative ? 'Change Representative' : 'Select Representative'}
        </a>
        {representative && renderContactButtons(representative, openRepresentativeSSNResetModal)}
      </div>
      <ContactFormRef contact={representative} errors={errors.representative} />
    </div>
  )
}

const renderOtherGrantorDocs = ({ errors, onChange, values }: Props) => {
  const error = errors.secondary_grantor_doc && ` · ${errors.secondary_grantor_doc}`

  return (
    <div className={styles.formItem}>
      <div className={errors.secondary_grantor_doc && styles.errorWrapper}>
        <label className={styles.label} htmlFor="secondary_grantor_doc">
          Letters of Guardianship<strong>{error}</strong>
        </label>
        <FileInput
          name="secondary_grantor_doc"
          onChange={onChange}
          value={values.secondary_grantor_doc || ''}
        />
      </div>
    </div>
  )
}

const renderReferrer = (referrer, removeReferrer) => {
  const contactRemoveButton = <div className={styles.removeButton} onClick={removeReferrer} />

  return (
    <ContactBasicFormRef
      contact={referrer}
      key={`referrer${referrer.id}`}
      remove={contactRemoveButton}
    />
  )
}

const renderSSI = ({ onBlur, onChange, values }: Props) => (
  <div className={styles.section}>
    <div className={styles.formItemMono}>
      <label className={styles.label} htmlFor="ssi_amount">
        Amount
      </label>
      <input
        className={styles.input}
        id="ssi_amount"
        name="ssi_amount"
        onBlur={onBlur}
        onChange={onChange}
        placeholder="0.00"
        step={0.01}
        type="number"
        value={values.ssi_amount || ''}
      />
    </div>
    <div className={styles.inputLabelGroup}>
      <input
        id="ssi_food?"
        name="ssi_food?"
        type="checkbox"
        checked={values['ssi_food?']}
        onChange={onChange}
      />
      <label htmlFor="ssi_food?">Food purchases approved</label>
    </div>
    <div className={styles.inputLabelGroup}>
      <input
        id="ssi_shelter?"
        name="ssi_shelter?"
        type="checkbox"
        checked={values['ssi_shelter?']}
        onChange={onChange}
      />
      <label htmlFor="ssi_shelter?">Shelter purchases approved</label>
    </div>
    <div className={styles.formItem}>
      <label className={styles.label} htmlFor="ssi_approval">
        Attorney Approval
      </label>
      <FileInput name="ssi_approval" onChange={onChange} value={values.ssi_approval || ''} />
    </div>
  </div>
)

const renderStatementRecipient = (contact, toggleStatementRecipient) => {
  return (
    <div className={styles.inputLabelGroup} key={contact.id}>
      <input
        id={contact.id}
        name={contact.id}
        type="checkbox"
        checked={contact.checked}
        disabled={contact.readonly}
        onChange={toggleStatementRecipient}
      />
      <label htmlFor={contact.id}>{contact.name}</label>
    </div>
  )
}

export const Template = (props: Props) => {
  const {
    accountNumber,
    errors,
    isSubmitting,
    onChange,
    onSubmit,
    openAbleSelectionModal,
    openAttorneySelectionModal,
    openBeneficiarySSNResetModal,
    openConsultSelectionModal,
    openEINResetModal,
    openReferrerSelectionModal,
    removeAble,
    removeAttorney,
    removeConsult,
    removeReferrer,
    shouldCoGrantorRender,
    shouldGrantorDocsRender,
    shouldGrantorRender,
    shouldRepresentativeRender,
    toggleStatementRecipient,
    values,
  } = props
  const {
    ables,
    'budget?': hasBudget,
    consults,
    feeSchedule,
    attorneys,
    beneficiary,
    ein_verifier,
    grantor_relationship: grantorRelationship,
    joinder_agreement: joinderAgreement,
    'lien?': hasLien,
    'non_cash_assets?': hasNonCashAssets,
    'p_card?': hasPCard,
    'participating_org?': hasParticipatingOrg,
    'prepaid_burial?': hasPrepaidBurial,
    previous_roles,
    product_name,
    'public_guardian?': hasPublicGuardian,
    'receives_monthly_statements?': receivesMonthlyStatements,
    referrer,
    'restricted?': isRestricted,
    'special_tax_situation?': hasSpecialTaxSituation,
    'ssi?': hasSSI,
    statement_recipient_list,
    'teams?': hasTeams,
  } = values

  const grantorOfFundedBy = formatters.grantorOrFundedBy({ account_number: accountNumber })

  const previousRolesHeaders = ['Name', 'Previous Role', 'Removed On']

  let previousRolesRows = []
  if (previous_roles && previous_roles.length > 0) {
    previousRolesRows = previous_roles.map((item, idx) =>
      renderPreviousRole({ data: item, index: idx }),
    )
  }

  const joinderError = errors.joinder_agreement
  const beneficiaryButtons = renderContactButtons(beneficiary, openBeneficiarySSNResetModal)
  const renderAble = removableContactRenderer(removeAble, 'able', errors.ables)
  const renderAttorney = removableContactRenderer(removeAttorney, 'attorney', errors.attorneys)
  const renderConsult = removableContactRenderer(removeConsult, 'consult', errors.consults)

  let trustNameInput
  if (product_name.substring(0, 3) != 'GPT') {
    trustNameInput = (
      <div className={styles.formItem}>
        <label className={styles.label} htmlFor="trust_name">
          Trust Name
        </label>
        <input
          className={styles.input}
          id="trust_name"
          name="trust_name"
          type="string"
          onChange={onChange}
          value={values.trust_name || ''}
        />
      </div>
    )
  }

  return (
    <section className={styles.contentContainer}>
      <div className={styles.row}>
        <section className={styles.formWrapper}>
          <h1 className={styles.section}>Edit GPT Account</h1>
          <form id="editform" onSubmit={onSubmit}>
            <section className={styles.section}>
              <div id="beneficiary" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Beneficiary</h2>
              <div className={styles.contactButtons}>{beneficiaryButtons}</div>
              <ContactFormRef contact={beneficiary} />
            </section>
            <section>
              <div className={styles.section}>
                <div id="grantor" className={styles.anchorLink} />
                <h2 className={styles.sectionTitle}>{grantorOfFundedBy} Information</h2>
                <div className={styles.formItemHalf}>
                  <label className={styles.label} htmlFor="grantor_relationship">
                    Relationship to Beneficiary
                  </label>
                  <div>
                    <select
                      className={styles.select}
                      name="grantor_relationship"
                      onChange={onChange}
                      value={grantorRelationship || ''}
                    >
                      <option value="coGuardian">Co-Guardian</option>
                      <option value="proGuardian">Professional guardian</option>
                      <option value="famGuardian">Family guardian</option>
                      <option value="poa">Power of attorney</option>
                      <option value="self">Self</option>
                      <option value="selfRep">Self with representative</option>
                      <option value="parent">Parent</option>
                      <option value="grandparent">Grandparent</option>
                      <option value="court">Court</option>
                    </select>
                  </div>
                </div>
              </div>
              {shouldGrantorRender && renderGrantor(props)}
              {shouldCoGrantorRender && renderCoGrantor(props)}
              {shouldGrantorDocsRender && renderGrantorDocs(props)}
            </section>
            <section>{shouldRepresentativeRender && renderRepresentative(props)}</section>
            <section className={styles.section}>
              <div id="attorneys" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Attorneys</h2>
              <a className={styles.buttonLocal} onClick={openAttorneySelectionModal}>
                Add Attorney
              </a>
              {attorneys.map(renderAttorney)}
            </section>
            <section className={styles.section}>
              <div id="referrer" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Referrer</h2>
              <a className={styles.buttonLocal} onClick={openReferrerSelectionModal}>
                {referrer ? 'Change Referrer' : 'Select Referrer'}
              </a>
              {referrer && renderReferrer(referrer, removeReferrer)}
            </section>
            <section>
              <div className={hasSSI ? styles.sectionSmall : styles.section}>
                <div id="ssi" className={styles.anchorLink} />
                <h2 className={styles.sectionTitle}>Supplemental Security Income (SSI)</h2>
                <div className={styles.inputLabelGroup}>
                  <input
                    id="ssi?"
                    name="ssi?"
                    type="checkbox"
                    checked={hasSSI}
                    onChange={onChange}
                  />
                  <label htmlFor="ssi?">Beneficiary on SSI</label>
                </div>
              </div>
              {hasSSI && renderSSI(props)}
            </section>
            <section className={styles.section}>
              <div id="joinder" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Joinder Agreement</h2>
              <div className={joinderError && styles.errorWrapper}>
                {joinderError && renderErrorLabel(joinderError)}
                <FileInput
                  name="joinder_agreement"
                  onChange={onChange}
                  value={joinderAgreement || ''}
                />
              </div>
            </section>
            <section className={styles.section}>
              <div id="ables" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Ables</h2>
              <a className={styles.buttonLocal} onClick={openAbleSelectionModal}>
                Add Able
              </a>
              {ables.map(renderAble)}
            </section>
            <section className={styles.section}>
              <div id="consults" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Consults</h2>
              <a className={styles.buttonLocal} onClick={openConsultSelectionModal}>
                Add Consult
              </a>
              {consults.map(renderConsult)}
            </section>
            <section className={styles.section}>
              <div id="extras" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Extras</h2>
              {trustNameInput}
              <div className={styles.formItem}>
                <label className={styles.label} htmlFor="fee_schedule">
                  Fee schedule (see supervisor for assistance in changing schedule)
                </label>
                <input
                  className={styles.input}
                  id="fee_schedule"
                  name="fee_schedule"
                  type="string"
                  readOnly={true}
                  value={values.fee_schedule || 'Standard'}
                />
              </div>
              <div className={styles.formItemCurrency}>
                <label className={styles.label} htmlFor="spending_plan">
                  Spending Plan (monthly)
                </label>
                <NumericFormat
                  id="spending_plan"
                  thousandSeparator={true}
                  className={styles.currencyInput}
                  prefix={'$'}
                  value={values.spending_plan || ''}
                  name="spending_plan"
                  onChange={onChange}
                />
              </div>
              <div className={styles.formItemCurrency}>
                <label className={styles.label} htmlFor="floor">
                  Floor
                </label>
                <NumericFormat
                  id="floor"
                  thousandSeparator={true}
                  className={styles.currencyInput}
                  prefix={'$'}
                  value={values.floor || ''}
                  name="floor"
                  onChange={onChange}
                />
              </div>
              <div className={styles.inputLabelGroup}>
                <label htmlFor="prepaid_burial?">
                  <input
                    id="prepaid_burial?"
                    name="prepaid_burial?"
                    type="checkbox"
                    checked={hasPrepaidBurial}
                    onChange={onChange}
                  />
                  Prepaid burial
                </label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="restricted?"
                  name="restricted?"
                  type="checkbox"
                  checked={isRestricted}
                  onChange={onChange}
                />
                <label htmlFor="restricted?">Restricted</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="non_cash_assets?"
                  name="non_cash_assets?"
                  type="checkbox"
                  checked={hasNonCashAssets}
                  onChange={onChange}
                />
                <label htmlFor="non_cash_assets?">Non-cash assets</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="receives_monthly_statements?"
                  name="receives_monthly_statements?"
                  type="checkbox"
                  checked={receivesMonthlyStatements}
                  onChange={onChange}
                />
                <label htmlFor="receives_monthly_statements?">Receives monthly statements</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="budget?"
                  name="budget?"
                  type="checkbox"
                  checked={hasBudget}
                  onChange={onChange}
                />
                <label htmlFor="budget?">Budget</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="public_guardian?"
                  name="public_guardian?"
                  type="checkbox"
                  checked={hasPublicGuardian}
                  onChange={onChange}
                />
                <label htmlFor="public_guardian?">Public Guardian</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="special_tax_situation?"
                  name="special_tax_situation?"
                  type="checkbox"
                  checked={hasSpecialTaxSituation}
                  onChange={onChange}
                />
                <label htmlFor="special_tax_situation?">Special Tax Situation</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="participating_org?"
                  name="participating_org?"
                  type="checkbox"
                  checked={hasParticipatingOrg}
                  onChange={onChange}
                />
                <label htmlFor="participating_org?">Participating Organization</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="lien?"
                  name="lien?"
                  type="checkbox"
                  checked={hasLien}
                  onChange={onChange}
                />
                <label htmlFor="lien?">Lien</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="teams?"
                  name="teams?"
                  type="checkbox"
                  checked={hasTeams}
                  onChange={onChange}
                />
                <label htmlFor="teams?">TEAMS</label>
              </div>
              <div className={styles.inputLabelGroup}>
                <input
                  id="p_card?"
                  name="p_card?"
                  type="checkbox"
                  checked={hasPCard}
                  onChange={onChange}
                />
                <label htmlFor="p_card?">P-Card</label>
              </div>
            </section>
            <section className={styles.section}>
              <div id="openCloseDates" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Open/Close Dates</h2>
              <label className={styles.label} htmlFor="open_date">
                Open Date
              </label>
              <div className={styles.formItemMono}>
                <input
                  className={styles.input}
                  id="open_date"
                  name="open_date"
                  type="date"
                  disabled={true}
                  readOnly={true}
                  value={values.open_date || ''}
                />
              </div>
              <label className={styles.label} htmlFor="close_date">
                Close Date
              </label>
              <div className={styles.formItemMono}>
                <input
                  className={styles.input}
                  id="close_date"
                  name="close_date"
                  type="date"
                  disabled={true}
                  readOnly={true}
                  value={values.close_date || ''}
                />
              </div>
            </section>
            <section className={styles.section}>
              <div id="statementRecipients" className={styles.anchorLink} />
              <h2 className={styles.sectionTitle}>Account Statement Recipients</h2>
              <p>
                <i>
                  Note: trustee receipts and payment remittances go to either the grantor, the
                  representative, or the beneficiary depending on the grantor relationship type.
                </i>
              </p>
              {statement_recipient_list.map((s) =>
                renderStatementRecipient(s, toggleStatementRecipient),
              )}
            </section>
            <section className={styles.section}>
              <div id="previousRoles" className={styles.anchorLink} />
              <Table headers={previousRolesHeaders} tableSize="small" title="Previous Roles">
                {previousRolesRows}
              </Table>
            </section>
            {renderEINSection(product_name, ein_verifier, openEINResetModal)}
            <FormButtons errors={errors} submitLabel="Save Changes" submitting={isSubmitting} />
          </form>
        </section>
        <section className={styles.menuWrapper}>
          <section className={styles.navigationMenuWrapper}>
            <h5 className="sectionTitle">Navigation</h5>
            <Link className={styles.backLink} to={`/accounts/${accountNumber}`}>
              Return to Account Page
            </Link>
            <a href="#beneficiary" className={styles.menuLink}>
              Beneficiary
            </a>
            <a href="#grantor" className={styles.menuLink}>
              {grantorOfFundedBy} Information
            </a>
            <a href="#attorneys" className={styles.menuLink}>
              Attorneys and Referrer
            </a>
            <a href="#ssi" className={styles.menuLink}>
              Supplemental Security Income
            </a>
            <a href="#joinder" className={styles.menuLink}>
              Joinder Agreement
            </a>
            <a href="#ables" className={styles.menuLink}>
              Ables and Consults
            </a>
            <a href="#extras" className={styles.menuLink}>
              Extras
            </a>
            <a href="#openCloseDates" className={styles.menuLink}>
              Open/Close Dates
            </a>
            <a href="#statementRecipients" className={styles.menuLink}>
              Account Statement Recipients
            </a>
            <a href="#previousRoles" className={styles.menuLink}>
              Previous Roles
            </a>
            {renderEINNavLink(product_name)}
            <div className={styles.navigationSave}>
              <button form="editform" className={styles.saveButton} type="submit">
                Save Changes
              </button>
            </div>
          </section>
        </section>
      </div>
    </section>
  )
}
