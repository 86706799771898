// @flow
import { isCoTrustee } from 'lib/user/selectors'
import TicketsRoutes from './components/Tickets'
import UserRoutes from './components/User'
import Xero from './components/Xero'

const checkAuth = (store, replace, cb) => {
  const state = store.getState()
  return isCoTrustee(state)
}

export default (store: Object) => {
  if (!checkAuth(store))
    return {
      path: 'cotrustee',
      children: [
        { ...TicketsRoutes(store) },
        { ...UserRoutes(store) },
        {
          path: 'xero',
          element: <Xero />,
        },
      ],
    }
}
