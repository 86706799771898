import * as t from './actionTypes'
import { State } from './model'
import _ from 'lodash'

const initialState: State = {
  denyNote: '',
  potential_clients: [],
  potential_client: null,
  org_potential_client: null,
  upload_status: null,
}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case t.SET_denyNote:
      return {...state, denyNote: action.denyNote}
    case t.SET_POTENTIAL_CLIENTS:
      return {...state, potential_clients: action.potential_clients}
    case t.SET_POTENTIAL_CLIENT:
      return {
        ...state,
        potential_client: action.potential_client,
        org_potential_client: _.cloneDeep(action.potential_client)
      }
    case t.SET_EIN:
      return {
        ...state,
        potential_client:
          updateEIN(state.potential_client, action.val)
      }
    case t.UPDATE_TRUST_ATTR:
      return {
        ...state,
        potential_client: updateAttr(
            state.potential_client, action.attr, action.val
          ),
      }
    case t.CREATE_DISTRIBUTION:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            distributions: [
                ...state.potential_client.distributions,
                distribution(state, action)
            ]
          }
        }
    case t.REMOVE_DISTRIBUTION:
      return {
        ...state,
        potential_client: {
          ...state.potential_client,
          distributions:
            state.potential_client.distributions.filter(d => d.id != action.id)
        }
      }
    case t.UPDATE_DISTRIBUTION:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            distributions:
              updateAssociationAttribute(state, 'distributions', action)
          },
      }
    case t.CREATE_INCOME:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            income: [
                ...state.potential_client.income,
                income(state, action)
            ]
          }
        }
    case t.REMOVE_INCOME:
      return {
        ...state,
        potential_client: {
          ...state.potential_client,
          income:
            state.potential_client.income.filter(i => i.id != action.id)
        }
      }
    case t.UPDATE_INCOME:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            income: updateAssociationAttribute(state, 'income', action)
          },
      }
    case t.CREATE_ASSET:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            assets: [
                ...state.potential_client.assets,
                asset(state, action)
            ]
          }
        }
    case t.REMOVE_ASSET:
      return {
        ...state,
        potential_client: {
          ...state.potential_client,
          assets:
            state.potential_client.assets.filter(a => a.id != action.id)
        }
      }
    case t.UPDATE_ASSET:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            assets: updateAssetAttribute(state.potential_client.assets, action)
          },
      }
    case t.CREATE_CONTACT_ROLE:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            contact_roles: [
                ...state.potential_client.contact_roles,
                contactRole(state, action)
            ]
          }
        }
    case t.REMOVE_CONTACT_ROLE:
      return {
        ...state,
        potential_client: {
          ...state.potential_client,
          contact_roles:
            state.potential_client.contact_roles.filter(c => c.id != action.id)
        }
      }
    case t.TOGGLE_CONTACT_ROLE_RECIEVES_STATEMENTS:
      return {
        ...state,
        potential_client: {
          ...state.potential_client,
          contact_roles: toggleReceivesStatements(state, action)
        }
      }
    case t.UPDATE_CONTACT_ROLE_RELATIONSHIP:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            contact_roles: updateContactRoleRelationship(
              state.potential_client.contact_roles,
              action
            )
          },
      }
    case t.REMOVE_DELETED_DOCUMENT:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            folders: removeFile(state.potential_client.folders, action)
          },
      }
    case t.SET_DOCUMENT_FOLDERS:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            folders: action.folders
          }
      }
    case t.SET_UPLOAD_STATUS:
      return {
        ...state,
        potential_client:
          {
            ...state.potential_client,
            upload_status: action.status
          }
      }
    default:
      return state
  }
}

const toggleReceivesStatements = (state, action) => {
  let roles = [...state.potential_client.contact_roles]

  for (let i = 0; i < roles.length; i++) {
    if (roles[i].id) {
      if (roles[i].id == action.role.id) {
        roles[i].receives_account_statements =!
          roles[i].receives_account_statements
      }
    } else if (roles[i].contact.name == action.role.contact.name
        && roles[i].contact.email == action.role.contact.email) {
      roles[i].receives_account_statements =!
        roles[i].receives_account_statements
    }
  }

  return roles
}

const updateAssetAttribute = (assets, action) => {
  const subAssets = assets.filter(a => a.subtype == action.subtype)
  const otherAssets = assets.filter(a => a.subtype != action.subtype)

  const newSubAssets = subAssets.map(
    (obj, idx) =>
      (
        idx == action.idx ?
        updateAttr(obj, action.attr, action.val) :
        obj
      )
  )

  return newSubAssets.concat(otherAssets)
}

const updateAssociationAttribute = (state, association, action) => {
  const objects = state.potential_client[association]
  const newObjects = objects.map(
    (obj, idx) =>
      (idx == action.idx ? updateAttr(obj, action.attr, action.val) : obj)
  )

  return newObjects
}

const distribution = (state, action) => {
  switch (action.type) {
    case t.CREATE_DISTRIBUTION:
      return {
        potential_client_id: state.potential_client.id,
        is_required: true,
        description: ''
      }
    default:
      return state
  }
}

const income = (state, action) => {
  switch (action.type) {
    case t.CREATE_INCOME:
      return {
        potential_client_id: state.potential_client.id,
        type: 'income',
        description: '',
        amount: 0
      }
    default:
      return state
    }
}

const contactRole = (state, action) => {
  switch (action.type) {
    case t.CREATE_CONTACT_ROLE:
      return {
        potential_client_id: state.potential_client.id,
        type: action.role_type,
        contact: action.contact,
      }
    default:
      return state
    }
}

const updateContactRoleRelationship = (contact_roles, action) => {
  const newContactRoles = contact_roles.map((cr) =>
    (
      cr.id == action.role_id ||
        (!cr.id && cr.contact.id == action.contact_id) ?
      updateAttr(cr, 'type', action.relationship_type) :
      cr
    )
  )

  return newContactRoles
}

const asset = (state, action) => {
  switch (action.type) {
    case t.CREATE_ASSET:
      return {
        potential_client_id: state.potential_client.id,
        type: 'asset',
        subtype: action.subtype,
        description: '',
        amount: 0
      }
    default:
      return state
    }
}

const updateAttr = (object, attr, val = null) => {
  if (typeof(object[attr]) ==='boolean') {
    object[attr] = !object[attr]
  } else {
    object[attr] = val
  }

  return object
}

const updateEIN = (client, ein) => {
  if (ein && ein.length == 9) {
    client.ein = ein
    client.ein_verifier = ein.substring(ein.length - 4, ein.length)
  }

  return client
}

const removeFile = (folders, action) => {
  let newFolders = []

  for (let i=0; i<folders.length; i++) {
    let curFolder = folders[i]

    if (curFolder.name != action.folder) {
      newFolders.push(curFolder)
    } else {
      const filteredFiles = curFolder.files.filter(f => f.id != action.file_id)
      curFolder.files = filteredFiles
      newFolders.push(curFolder)
    }
  }

  return newFolders
}
