// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("styles/img/uploadAlt.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__dropzoneArea___MEuUs {
}

.styles__drag___xzaiC {
  border: 2px dashed ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]};
  background: #E2F1EE;
}

.styles__upIcon___YYRkV {
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  height: 80px;
  width: 50px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat left center;
  background-size: 32px;
}

.styles__uploadText___zQwG0 {
  pointer-events: none;
  display: inline-block;
  height: 80px;
}

.styles__previewWrapper___AKUPm {
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./universal/components/S3FileInput/styles.css"],"names":[],"mappings":"AAIA;AAEA;;AAEA;EACE,+DAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,oBAAoB;EACpB,YAAY;EACZ,WAAW;EACX,yEAAiE;EACjE,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: 'styles/colors.css';\n@value gtGreen from colors;\n\n.dropzoneArea {\n  composes: dropzoneArea from helpers;\n}\n\n.drag {\n  border: 2px dashed gtGreen;\n  background: #E2F1EE;\n}\n\n.upIcon {\n  display: inline-block;\n  vertical-align: middle;\n  pointer-events: none;\n  height: 80px;\n  width: 50px;\n  background: url(\"styles/img/uploadAlt.png\") no-repeat left center;\n  background-size: 32px;\n}\n\n.uploadText {\n  pointer-events: none;\n  display: inline-block;\n  height: 80px;\n}\n\n.previewWrapper {\n  margin: 10px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `'styles/colors.css'`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"dropzoneArea": `styles__dropzoneArea___MEuUs ${___CSS_LOADER_ICSS_IMPORT_1___.locals["dropzoneArea"]}`,
	"drag": `styles__drag___xzaiC`,
	"upIcon": `styles__upIcon___YYRkV`,
	"uploadText": `styles__uploadText___zQwG0`,
	"previewWrapper": `styles__previewWrapper___AKUPm`
};
export default ___CSS_LOADER_EXPORT___;
