// @flow
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Error from 'components/Error'
import { getAccount } from 'lib/account/selectors'
import { isAuditor } from 'lib/user/selectors'
import {
  clearAccount,
  createDocument,
  deleteDocument,
  fetchAccountHTTP,
} from 'lib/account/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import type { Account } from 'lib/types'
import { Template } from './Template'
import { httpPut } from 'lib/http'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  account: Account,
  dispatch: Function,
  isAuditor: boolean,
  location: Object,
}

class Number extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      toggleStatusNeedsApproval: false,
      toggleStatusDescription: '',
    }
  }

  loadAccount(number) {
    const { dispatch } = this.props
    dispatch(setLoad())
    dispatch(fetchAccountHTTP(number))
      .then(() => this.setState({ error: false }))
      .catch((error) => {
        if (error.code === 404) {
          this.setState({ error: true })
        } else {
          throw error
        }
      })
      .then(() => dispatch(setUnload()))
  }

  toggleAccountSuspension = () => {
    const number = this.props.account.number

    let description = `suspend account ${number}`

    if (this.props.account.status == 'suspended') {
      description = `unsuspend account ${number}`
    }

    this.setState({
      toggleStatusNeedsApproval: true,
      toggleStatusDescription: description,
    })
  }

  toggleAccountSuspensionApproved = (isApproved) => {
    this.setState({
      toggleStatusNeedsApproval: false,
      toggleStatusDescription: '',
    })

    const { dispatch } = this.props
    const number = this.props.account.number

    if (isApproved) {
      httpPut(`/admin/suspensions/${number}`)
        .then(() => {
          window.location.reload(false)
        })
        .catch((error) => {
          dispatch(setBanner(error.message, 'danger'))
        })
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearAccount())
  }

  componentDidMount() {
    this.loadAccount(this.props.location.params.number)
  }

  componentDidUpdate(prevProps) {
    const { number } = this.props.location.params
    if (prevProps.location.params.number !== number) {
      this.loadAccount(number)
    }
  }

  onDeleteClick = (folder, id) => {
    this.props.dispatch(deleteDocument(folder, id))
  }

  buildUploadParams = async (folder, fileName, file) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })

    const data = await toBase64(file)
    const params = {
      content_type: file.type,
      name: fileName,
      folder: folder,
      data: data.split(',')[1],
    }

    return params
  }

  onUploadClick = (id, folder, fileName, file) => {
    this.buildUploadParams(folder, fileName, file).then((params) => {
      this.props.dispatch(createDocument(id, params))
    })
  }

  render() {
    const { account, dispatch, isAuditor } = this.props
    if (this.state.error) {
      return (
        <Error
          title="404: Account Not Found"
          message="The account you are looking for does not exist"
          icon="account"
        />
      )
    }

    if (this.state.error === false) {
      return (
        <Template
          account={account}
          dispatch={dispatch}
          isAuditor={isAuditor}
          onDeleteClick={this.onDeleteClick}
          onUploadClick={this.onUploadClick}
          toggleAccountSuspension={this.toggleAccountSuspension}
          toggleAccountSuspensionApproved={this.toggleAccountSuspensionApproved}
          toggleStatusNeedsApproval={this.state.toggleStatusNeedsApproval}
          toggleStatusDescription={this.state.toggleStatusDescription}
        />
      )
    }
    return null
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      account: getAccount,
      isAuditor,
    }),
  )(Number),
)
