// @flow
import React from 'react'
import { ContactBasicFormRef } from 'components/References'
import FormButtons from 'components/FormButtons'
import Ticket from 'components/Ticket'
import Reference from './Reference'
import styles from './styles'

type Props = {
  dispatch: Function,
  errors: Object,
  isSubmitting: boolean,
  onAddContact: Function,
  onRemoveContact: Function,
  onNotesEdit: Function,
  onSubmit: Function,
  ticket: Object,
  values: Object,
}

const renderRemaindermen = (remaindermens, errors, onRemoveContact) =>
  remaindermens.map((remaindermen, idx) => {
    const removeBtn = (
      <div
        onClick={onRemoveContact(remaindermen.id)}
        className={styles.removeButton}
      />
    )

    return (
      <ContactBasicFormRef
        contact={remaindermen}
        key={idx}
        remove={removeBtn}
        errors={errors.remaindermens}
      />
    )
  })

export const RemaindermenStation = (props: Props) => {
  const {
    errors,
    isSubmitting,
    onAddContact,
    onRemoveContact,
    onNotesEdit,
    onSubmit,
    ticket,
    values: { remaindermen, remaindermen_notes }
  } = props
  const header = {
    subheader: 'Account Creation',
    title: 'Remaindermen',
  }
  const reference = ticket && <Reference {...props} />

  return (
    <Ticket
      header={header}
      history={ticket.history}
      reference={reference}
    >
      <p className={styles.infoText}>
        Please describe remaindermen info and
        optionally add relevant contacts
      </p>
      <form className={styles.form} onSubmit={onSubmit}>
        <section className={styles.cardMarginBot}>
          <section className={styles.sectionNoMargin}>
            <h3>Remaindermen Notes</h3>
            <textarea
              className={styles.textareaLarge}
              id="notes"
              name="notes"
              onChange={onNotesEdit}
              value={remaindermen_notes || ''}
            />
          </section>
          <section className={styles.sectionNoMargin}>
            <h3>Remaindermen Contacts</h3>
            <button
              id='test-hook-select-remaindermen'
              onClick={onAddContact}
              className={styles.buttonLocalSecondary}
              type='button'
            >
              Select Contact
            </button>
            <div>
              {renderRemaindermen(remaindermen, errors, onRemoveContact)}
            </div>
          </section>
        </section>
        <FormButtons submitLabel='Save & Continue' submitting={isSubmitting} />
      </form>
    </Ticket>
  )
}
