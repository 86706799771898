// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { LineItems } from './LineItems'
import { RecipientAddress } from './RecipientAddress'
import formatters from 'lib/formatters'
import logo from 'styles/img/logo-2020-cropped.png'
import styles from './styles'

type Props = {
  address: Object,
  creditTotal: number,
  debitTotal: number,
  endDate: string,
  endingBalance: number,
  lines: Array<Object>,
  beneficiaryName: string,
  number: string,
  startDate: string,
  startingBalance: number,
  subnumber: string,
  addedHeadersMap: Map,
  vaNumber: string,
  summary: Array<Object>
}

const buildLineItemData = (lineItems) => {
  return lineItems.map((item) => ({
    ...item,
    balance: formatters.money(item.balance),
    credit: item.amount > 0 ? formatters.money(item.amount) : ' ',
    date: item.date,
    debit: item.amount < 0 ? formatters.money(item.amount * -1) : ' ',
  }))
}

const titleLink = (props: Props) => {
  let number = props.number
  let url = `/accounts/${number}`

  if (props.vaNumber) {
    number = props.vaNumber
  }

  if (props.subnumber) {
    url = `/accounts/${number}/subaccounts/${props.subnumber}`
    number = props.subnumber
  }

  return (
    <Link to={url}>
      <h5>{number}</h5>
    </Link>
  )
}

const Transactions = (props: Props) => {
  const {
    lines,
    startDate,
    startingBalance,
  } = props
  const allTransactions = buildLineItemData(lines)

  if (startingBalance > 0) {
    allTransactions.unshift({
      balance: formatters.money(startingBalance),
      credit: ' ',
      date: formatters.date(startDate),
      debit: ' ',
      description: 'Starting Balance',
    })
  }

  return <LineItems lineItems={allTransactions} />
}

const Footer = (props: Props) => {
  const {
    creditTotal,
    debitTotal,
    endingBalance,
    number,
    startDate,
    endDate
  } = props

  const dateDesc = formatters.date(startDate)
                    + ' to '
                    + formatters.date(endDate)

  return (
      <tr className={styles.boldRow}>
        <td className={styles.dateCell}>{number}</td>
        <td className={styles.descriptionCell}>{dateDesc}</td>
        <td className={styles.debitCell}>{formatters.money(debitTotal)}</td>
        <td className={styles.creditCell}>{formatters.money(creditTotal)}</td>
        <td className={styles.balanceCell}>
          {formatters.money(endingBalance)}
        </td>
      </tr>
  )
}

const periodTable = (props: Props) => (
  <table className={styles.period}>
    <thead>
      <tr>
        <th className={styles.label}>Period</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className={styles.left}>
          {formatters.date(props.startDate)}
          <span> to </span>
          {formatters.date(props.endDate)}
        </td>
      </tr>
    </tbody>
  </table>
)

const reportHeaderTable =
  ({address, ...props}: Props) => (
      <table className={styles.table}>
        <thead>
          <tr className={styles.left}>
            <th className={styles.reportHeader} colSpan={6}>
              <RecipientAddress address={address}
                key={`address:${(address ? address.id : 0)}:${props.number}`}
              />
              <div className={styles.contactInfo}>
                <img src={logo}
                  className={(address ? styles.invisible : styles.logo)}
                  alt="Guardian Trust"
                />
                <div className={(address ? styles.invisible : styles.visible)}>
                  901 Chestnut Street, Suite C<br />
                  Clearwater, FL 33756<br />
                  (800) 669-2499<br />
                  www.guardiantrusts.org
                </div>
              </div>
              <div>
                {titleLink(props)}
                <h1>{props.beneficiaryName}</h1>
              </div>
              {periodTable(props)}
            </th>
          </tr>
        </thead>
      </table>
)

const buildSummaryRows = (summary) => {
  const row = (number, balance, type, idx) => (
      <tr key={idx}
        className={type == 'Total Balance'? styles.boldRow: ''}>
        <td className={styles.dateCell}>{number}</td>
        <td className={styles.descriptionCell}>
          {formatters.legibleSubaccountType(type)}
        </td>
        <td className={styles.balanceCell}>{formatters.money(balance)}</td>
      </tr>
  )

  return summary.map(({number, balance, type}, idx) =>
    row(number, balance, type, idx))
}

const accountsSummaryTable = ({summary}: Props) => (
  <table className={styles.table}>
    <thead>
      <tr className={styles.left}>
        <th className={styles.secondaryHeader} colSpan={6}>
          <div>
            <h2 className={styles.tableTitle}>
              Account Balances Summary
            </h2>
          </div>
        </th>
      </tr>
      <tr>
        <th className={styles.dateHeader}>Number</th>
        <th className={styles.descriptionHeader}>Type</th>
        <th className={styles.balanceHeader}>Balance</th>
      </tr>
    </thead>
    <tbody>
      {buildSummaryRows(summary)}
    </tbody>
  </table>
)

const statementTitle = ({number, subaccount_type, ...props}) => {
  let statementType = 'Account Statement'

  switch (subaccount_type) {
    case 'individual_investment':
      statementType = 'Individual Investment Account Statement'
      break
    case 'asset':
      statementType = 'Asset Account Statement'
      break
    default:
      if (hasSubaccounts(props)) {
        statementType = 'Main Account Statement'
      }
  }

  return statementType + ' for ' + number
}

const transactionsTable =
  ({addedHeadersMap = new Map(), ...props}: Props) => (
  <table className={styles.table}>
    <thead>
      <tr>
        <th colSpan={6} className={styles.spacer} />
      </tr>
      <tr className={styles.left}>
        <th className={styles.reportHeader} colSpan={6}>
          <div>
            {hasSubaccounts(props) && titleLink(props)}
            <h2 className={styles.tableTitle}>
              {statementTitle(props)}
            </h2>
          </div>
          <table>
            <thead>
              <tr>
                {
                  [...addedHeadersMap.keys()].map((key) =>
                    <th className={styles.label} key={key}>
                      {key}
                    </th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              <tr>
                {
                  [...addedHeadersMap.values()].map((val) =>
                    <td className={styles.left} key={val}>
                      {val}
                    </td>
                  )
                }
              </tr>
            </tbody>
          </table>
        </th>
      </tr>
      <tr>
        <th className={styles.dateHeader} width="13%">Date</th>
        <th className={styles.descriptionHeader} width="50%">
          Description
        </th>
        <th className={styles.debitHeader} width="11%">Debit</th>
        <th className={styles.creditHeader} width="11%">Credit</th>
        <th className={styles.balanceHeader} width="15%">Balance</th>
      </tr>
    </thead>
    <Transactions {...props} />
    <tfoot className={styles.nonRepeatingFooter}>
      <tr className={styles.spacerRow}>
        <th className={styles.dateHeaderTfoot}>Account</th>
        <th className={styles.descriptionHeaderTfoot}>
          Period
        </th>
        <th className={styles.debitHeaderTfoot}>Total Debit</th>
        <th className={styles.creditHeaderTfoot}>Total Credit</th>
        <th className={styles.balanceHeaderTfoot}>Ending Balance</th>
      </tr>
      <Footer {...props} />
    </tfoot>
  </table>
)

const hasSubaccounts = ({summary}) => {
  // Note: pooled accounts w/ no subaccounts
  // will have two summary rows, one for their
  // "Main" account balance and one "Total Balance"
  return summary && summary.length > 2
}

export const AccountStatement = (props: Props) => {
  return (
    <div className={styles.contentContainer}>
      {reportHeaderTable(props)}
      {(hasSubaccounts(props) ? accountsSummaryTable(props) : <div></div>)}
      {transactionsTable(props)}
      <div className={styles.printBreak}/>
    </div>
  )
}
