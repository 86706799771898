// @flow
import formatters from 'lib/formatters'
import React from 'react'
import Row from './Row'
import styles from './styles'

type Props = {
  closeDate: string,
  openDate: string,
  status: string,
  updatedAt: string,
  toggleAccountSuspension: Function,
}

const GPTDatesRef = (props: Props) => {
  const {
    closeDate, openDate, status, updatedAt, toggleAccountSuspension
  } = props

  let dateTitle
  let dateStyle
  let dateInformation
  let showEdit = false

  switch(status) {
    case 'closed':
      dateTitle = 'Closed'
      dateStyle = styles.dateStyleClosed
      dateInformation = styles.dateInfoClosed
      break
    case 'closing':
      dateTitle = 'Closing'
      dateStyle = styles.dateStyleClosing
      dateInformation = styles.dateInfoClosing
      break
    case 'rejected':
      dateTitle = 'Rejected'
      dateStyle = styles.dateStyleClosed
      dateInformation = styles.dateInfoClosed
      break
    case 'suspended':
      dateTitle = 'Suspended'
      dateStyle = styles.dateStyleSuspended
      dateInformation = styles.dateInfoClosing
      showEdit = true
      break
    case 'pending':
      dateTitle = 'Pending'
      dateStyle = styles.dateStylePending
      dateInformation = styles.dateInfoPending
      break
    case 'active':
    case 'approved':
      dateTitle = 'Active'
      dateStyle = styles.dateStyleActive
      dateInformation = styles.dateInfoOpen
      showEdit = true
      break
  }

  let closed, opened, updated
  if (updatedAt) {
    updated = (
      <div className={styles.lastUpdated}>
        <Row label='Last Update:' val={formatters.lastUpdate(updatedAt)} />
      </div>
    )
  }

    closed = (
      <Row label='Close Date' val={formatters.date(closeDate)} />
    )

    opened = (
      <Row label='Open Date' val={formatters.date(openDate)} />
    )

  return (
    <div className={dateStyle}>
      <div className={styles.flexRow}>
        <div className={styles.accountInfo}>
          <h5>Account Information</h5>
          {updated}
        </div>
        <div className={styles.dateTitle}>
          {dateTitle}
          {showEdit &&
            <a
              title="Change status"
              className={styles.refEditSmall}
              onClick={() => toggleAccountSuspension()}
            />
          }
        </div>
      </div>
      <div className={dateInformation}>
        {opened}
        {closed}
      </div>
    </div>
  )
}

export default GPTDatesRef
