// @flow
import { connect } from 'react-redux'
import React from 'react'
import styles from './styles'
import { Table, Row, Cell } from 'components/Table'
import { fetchStatus, submitCode } from 'lib/xero/actions'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  location: Object,
}

type State = {
  client_id: string,
  code: String,
  organizations: Array,
  redirect_uri: string,
  status: string,
  submit_code_count: number,
}

class Main extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      client_id: '',
      organizations: {},
      redirect_uri: '',
      status: '',
      submit_code_count: 0,
    }
  }

  props: Props

  componentDidMount() {
    this.props
      .dispatch(fetchStatus())
      .then(({ status }) => {
        this.setState({
          client_id: status.client_id,
          organizations: status.organizations,
          redirect_uri: status.redirect_uri,
          status: '',
        })
      })
      .catch(() => {
        this.setState({ status: 'Error fetching status' })
      })
  }

  render() {
    const params = new URLSearchParams(this.props.location.query)
    const code = params.get('code')

    if (this.state.submit_code_count == 0 && code) {
      if (process.env.NODE_ENV === 'development') {
        alert(
          `Submit this code to Xero via: iex -S mix  Accounting.submit_auth_code("cotrustee@localhost", "${code}")`,
        )
      } else {
        this.setState({
          status: 'Loading...',
          submit_code_count: this.state.submit_code_count + 1,
        })

        this.props
          .dispatch(submitCode(code))
          .then(() => {
            // Note: adding an explicit link for refresh because on dev,
            // the url has been redirected to a ngrok.io address which
            // doesn't work well compared to localhost.
            let url = '/cotrustee/xero'

            if (process.env.NODE_ENV === 'development') {
              url = 'http://localhost:3000' + url
            }

            const msg = (
              <div>
                <b>Success!</b>
                <br />
                <i>
                  Note - Conductor API configuration takes several seconds following successful Xero
                  authentication.
                  <br />
                  Please refresh <a href={url}>here</a> momentarily.
                </i>
              </div>
            )
            this.setState({ status: msg })
          })
          .catch(() => {
            this.setState({ status: 'Error.' })
          })
      }
    }

    const orgTableRow = (org, configured) => (
      <Row key={`configured${org}`}>
        <Cell value={org} />
        <Cell value={configured ? 'Y' : 'N'} />
      </Row>
    )

    const orgTableRows = (orgs) => {
      let rows = []

      Object.keys(orgs).forEach((org) => {
        rows.push(orgTableRow(org, orgs[org]))
      })

      return rows
    }

    const query_params = {
      response_type: 'code',
      client_id: this.state.client_id,
      redirect_uri: this.state.redirect_uri,
      scope: [
        'offline_access',
        'accounting.transactions',
        'accounting.settings',
        'accounting.contacts.read',
        'accounting.journals.read',
      ].join(' '),
    }

    const query_string = new URLSearchParams(query_params).toString()
    const url = `https://login.xero.com/identity/connect/authorize?${query_string}`

    return (
      <section className={styles.contentContainer}>
        <div className={styles.row}>
          <section className={styles.formWrapper}>
            <div className={styles.headerSection}>
              <h1 className={styles.headerTitle}>Conductor Xero Management</h1>
            </div>
            <div className={styles.statusTable}>
              <Table
                title="Organization Status"
                headers={['Organization', 'Configured']}
                tableSize="large"
                tableType="read"
              >
                {orgTableRows(this.state.organizations)}
              </Table>
            </div>
            <a className={styles.link} href={url} target="_blank" rel="noreferrer">
              Xero OAuth Portal
            </a>
            <p>{this.state.status}</p>
          </section>
        </div>
      </section>
    )
  }
}

export default withRouter(connect()(Main))
