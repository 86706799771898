// @flow
import React from 'react'
import { connect } from 'react-redux'
import { logOut } from 'lib/user/actions'

type Props = {
  dispatch: Function,
}

class Container extends React.Component {
  props: Props
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(logOut())
  }
  render() {
    return null
  }
}

export default connect()(Container)
