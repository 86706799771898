// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__errorWrapper___IhUgo {
  border-left: 3px solid #A13739;
  padding-left: 10px;
}

.styles__errorWrapper___IhUgo label {
    color: #A13739;
  }

.styles__cardMarginBot___BROtD {
}

.styles__checkbox___D0T3S {
  float: left;
  margin-left: 0;
}

.styles__sectionNoMargin___LAhlt {
}

.styles__form___ob_UZ {
}

.styles__formItem___PxxOq {
}

.styles__formItemHalf___kpVZ9 {
}

.styles__formItemMono___iLwKm {
}

.styles__input___VUO3H {
}

.styles__inputLabelGroup___oGDH6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.styles__inputLabelGroup___oGDH6 input {
  margin: 0 10px 20px 4px;
  top: 2px;
  position: relative;
}

.styles__inputLabelGroup___oGDH6 label {
  font-size: 14px;
}

.styles__label___t621n {
}

.styles__select___YPbRq {
}

.styles__warningLabelGroup___DGyv6 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.styles__warningLabelGroup___DGyv6 input {
  margin: 0 10px 20px 4px;
  top: 2px;
  position: relative;
}

.styles__warningLabel___e0NrE {
  color: red;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./universal/components/DepositFunds/styles.css"],"names":[],"mappings":"AAIA;EACE,8BAA8B;EAC9B,kBAAkB;AAKpB;;AAHE;IACE,cAAc;EAChB;;AAGF;AAEA;;AAEA;EACE,WAAW;EACX,cAAc;AAChB;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtBlueDark, gtGrayLight from colors;\n\n.errorWrapper {\n  border-left: 3px solid #A13739;\n  padding-left: 10px;\n\n  label {\n    color: #A13739;\n  }\n}\n\n.cardMarginBot {\n  composes: cardMarginBot from helpers;\n}\n\n.checkbox {\n  float: left;\n  margin-left: 0;\n}\n\n.sectionNoMargin {\n  composes: sectionNoMargin from helpers;\n}\n\n.form {\n  composes: form from helpers;\n}\n\n.formItem {\n  composes: formItem from helpers;\n}\n\n.formItemHalf {\n  composes: formItemHalf from helpers;\n}\n\n.formItemMono {\n  composes: formItemMono from helpers;\n}\n\n.input {\n  composes: input from helpers;\n}\n\n.inputLabelGroup {\n  composes: clearfix from helpers;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.inputLabelGroup input {\n  margin: 0 10px 20px 4px;\n  top: 2px;\n  position: relative;\n}\n\n.inputLabelGroup label {\n  font-size: 14px;\n}\n\n.label {\n  composes: label from helpers;\n}\n\n.select {\n  composes: select from helpers;\n}\n\n.warningLabelGroup {\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n.warningLabelGroup input {\n  margin: 0 10px 20px 4px;\n  top: 2px;\n  position: relative;\n}\n\n.warningLabel {\n  color: red;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtBlueDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]}`,
	"gtGrayLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGrayLight"]}`,
	"errorWrapper": `styles__errorWrapper___IhUgo`,
	"cardMarginBot": `styles__cardMarginBot___BROtD ${___CSS_LOADER_ICSS_IMPORT_1___.locals["cardMarginBot"]}`,
	"checkbox": `styles__checkbox___D0T3S`,
	"sectionNoMargin": `styles__sectionNoMargin___LAhlt ${___CSS_LOADER_ICSS_IMPORT_1___.locals["sectionNoMargin"]}`,
	"form": `styles__form___ob_UZ ${___CSS_LOADER_ICSS_IMPORT_1___.locals["form"]}`,
	"formItem": `styles__formItem___PxxOq ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItem"]}`,
	"formItemHalf": `styles__formItemHalf___kpVZ9 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItemHalf"]}`,
	"formItemMono": `styles__formItemMono___iLwKm ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItemMono"]}`,
	"input": `styles__input___VUO3H ${___CSS_LOADER_ICSS_IMPORT_1___.locals["input"]}`,
	"inputLabelGroup": `styles__inputLabelGroup___oGDH6 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["clearfix"]}`,
	"label": `styles__label___t621n ${___CSS_LOADER_ICSS_IMPORT_1___.locals["label"]}`,
	"select": `styles__select___YPbRq ${___CSS_LOADER_ICSS_IMPORT_1___.locals["select"]}`,
	"warningLabelGroup": `styles__warningLabelGroup___DGyv6`,
	"warningLabel": `styles__warningLabel___e0NrE`
};
export default ___CSS_LOADER_EXPORT___;
