import { recurring } from './configuration'

export const pendingRequestOptions = [
  { id: 'need_additional_funds', label: 'Additional funds needed' },
  { id: 'need_invoice', label: 'We need the Invoice / Bill / Receipt' },
  {
    id: 'missing_credit_card_receipts',
    label: 'We are missing the credit card receipt(s)',
  },
  {
    id: 'need_proof_of_payment',
    label: 'We need proof of payment (i.e. bank statement, canceled check, etc)',
  },
  {
    id: 'need_signed_distribution_request_form',
    label: 'We need a signed Distibution Request Form',
  },
  {
    id: 'need_additional_information',
    label: 'Additional information needed',
  },
  {
    id: 'unauthorized_person_submitted',
    label: 'An unauthorized person submitted the request',
  },
  {
    id: 'not_active',
    label: 'Account not active',
  },
  { id: 'other_reason', label: 'Other reason not listed' },
]

export const pendingRequestRadioOptions = [
  {
    id: 'clarification_by_senior_admin',
    label: 'Need clarification from Sr. Admin',
  },
  {
    id: 'clarification_by_signer',
    label: 'Need clarification or additional input from Signer',
  },
  { id: 'other_reason', label: 'Other reason not listed' },
]

export const deniedRequestRadioOptions = [
  {
    id: 'duplicate_bill',
    label: 'Duplicate bill entered',
  },
  {
    id: 'goods_from_individual',
    label: 'Unable to purchase goods from an individual',
  },
  {
    id: 'gifts_from_trust',
    label: 'Cannot pay for gifts from the trust',
  },
  {
    id: 'paid_from_beneficiary_account',
    label: "Cannot reimburse for expenses paid from Beneficiary's account",
  },
  {
    id: 'individual_caregiver_not_team',
    label: 'Cannot pay for private individual caregiver not employed by TEAM',
  },
  { id: 'gift_cards', label: 'Cannot pay for gift cards' },
  {
    id: 'life_insurance_premiums',
    label: 'Cannot pay for life insurance premiums',
  },
  {
    id: 'parents_provide_for_minors',
    label: 'Parents are legally required to provide necessities for minors',
  },
  {
    id: 'outside_approved_budget',
    label: 'Cannot pay for any items outside of your approved budget',
  },
  {
    id: 'non_beneficiary_travel_expenses',
    label: 'Cannot pay travel expenses for anyone other than the benficiary',
  },
  {
    id: 'prior_expenses',
    label: 'Cannot pay for expenses more than 6 months prior to establishment of trust',
  },
  {
    id: 'social_security_over_payments',
    label: 'Social Security does not allow for over payments to be paid from a pooled trust',
  },
  {
    id: 'not_best_use_of_funds',
    label: 'This purchase is not the best use of your funds',
  },
  {
    id: 'services_not_yet_rendered',
    label: 'Unable to pay for services that have not yet been rendered.',
  },
  {
    id: 'payment_type_changed',
    label: 'Payment type changed',
  },
  { id: 'other_reason', label: 'Other reason not listed' },
]

export const actionsNeededOptions = [
  {
    id: 'add-info-treasury',
    label: 'Added information to iTreasury',
  },
  {
    id: 'uploaded-screenshot-treasury',
    label: 'Uploaded screenshot of iTreasury transaction',
  },
]

export const ActionsEnum = {
  WRITE: 'Write',
  APPROVE: 'Approve',
  ADMIN_REVIEW: 'Admin Review',
  SIGNER_REVIEW: 'Signer Review',
  SAVE_REQUEST: 'Save Request',
  SAVE_UPDATES: 'Save Updates',
  READY_TO_REVIEW: 'Ready to Review',
  READY_TO_PROCESS: 'Ready to Process',
  DENIED_DRF: 'Denied',
  CLOSE_REQUEST: 'Close Request',
  ADMIN_WRAP_UP: 'Admin Wrap-Up',
  PAID: 'Paid',
  APPROVE_NOT_PAID: 'APPROVED (NOT YET PAID)',
  PENDING: 'PENDING',
  SR_ADMIN_REVIEW: 'SR ADMIN REVIEW',
  SIGNER_REVIEW: 'SIGNER REVIEW',
  APPROVED: 'APPROVED',
  EXPIRED: 'Expired',
  AGED_PENDING: 'AGED PENDING',
  DENIED_CLOSED_DRF: 'CLOSED',
}

// TODO: to move the variable to recurring
export const recurringStatus = recurring

export const StatusName = {
  written: 'Pending',
  admin_reviewed: 'Pending',
  signer_reviewed: 'Pending',
  admin_wrapped_up: 'Pending',
  approved_not_paid: 'Approved',
  paid_today: 'Paid',
  pending_client: 'Pending',
  pending_internal: 'Pending',
  aged_pending: 'Pending',
  pending: 'Pending',
  paid: 'Paid',
  denied: 'Denied',
  denied_closed: 'Denied Closed',
  recurring_written: 'Pending',
  recurring_reviewed: 'Pending',
  recurring_approved: 'Pending',
  scheduled: 'Pending',
  expired: 'Expired',
  recurring_expired: 'Recurring Expired',
}

export const MONTHS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
]

export const DRF_TYPE = {
  STANDARD: 'standard',
  TEMPLATE: 'template',
  SCHEDULED: 'scheduled',
}

export const requestTypeToValue = {
  automobile: 'Automobile',
  entertainment: 'Entertainment',
  food: 'Food',
  housing: 'Housing',
  insurance: 'Insurance',
  misc: 'Misc',
  services: 'Services',
  utilities: 'Utilities',
  credit_card: 'Credit Card',
  reimburse: 'Reimbursement',
}
