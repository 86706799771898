import formatters from '../../lib/formatters'
import styles from './styles.css'

const DateInput = ({ name, placeholder, value, onChange, required, disabled, min, max, disableKeyboard }) => {
  return (
    <div className={`${styles.inputBox} ${disabled && styles.inputBoxDisabled}`}>
      {value && <span className={styles.prefix}>{formatters.getWeekday(value)}:</span>}
      <input
        name={name}
        type="date"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        min={min}
        max={max}
        onKeyDown={(e) => {
          if (disableKeyboard) {
            e.preventDefault()
          }
        }}
      />
    </div>
  )
}

export default DateInput
