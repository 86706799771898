// @flow
import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './styles.css'

type HeaderMenuItemProps = {
  label: string,
  to?: string,
  onClick?: () => void,
  id?: string,
}

const HeaderMenuItem = (props: HeaderMenuItemProps) => {
  const { label, to, onClick, id } = props

  return (
    <NavLink
      to={to}
      id={id}
      className={({ isActive, isPending }) => {
        return isActive
          ? `${styles.headerMenuItem} ${styles.headerMenuItemActive}`
          : styles.headerMenuItem
      }}
      onClick={onClick}
    >
      {label}
    </NavLink>
  )
}

export default HeaderMenuItem
