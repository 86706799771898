// @flow
import React from 'react'
import Row from './Row'
import Ref from 'components/References/Ref'
import formatters from 'lib/formatters'
import styles from './styles'

type Props = {
  dateOfDeath: Date,
  openDateOfDeathModal: Function
}

const DateOfDeathRef = (props: Props) => {
  const {
    dateOfDeath,
    openDateOfDeathModal
  } = props

  const showEditLink = () => <a
    className={styles.dodLink}
    onClick={() => openDateOfDeathModal()}>
        Edit
    </a>

  const dodDisplay = dateOfDeath && dateOfDeath != '1900-01-01' ?
                      formatters.date(dateOfDeath) :
                      'Not Set'

  return (
    <Ref
      title="Date of Death"
      preview={
        <div>
          <Row
            singleLine
            label={dodDisplay}
            val={showEditLink()}
            mono={true}
          />
        </div>
      }
    />
  )
}

export default DateOfDeathRef
