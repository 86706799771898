export const verificationDocLabels = (relationship) => {
  let retVal
  switch (relationship) {
    case 'coGuardian':
    case 'proGuardian':
    case 'famGuardian':
      retVal = { primary: 'Court Order', secondary: 'Letters of Guardianship' }
      break
    case 'court':
      retVal = { primary: 'Court Order' }
      break
    case 'parent':
    case 'grandparent':
      retVal = { primary: 'Birth Certificate' }
      break
    case 'poa':
      retVal = { primary: 'Power of Attorney' }
      break
  }

  return retVal
}

export default verificationDocLabels
