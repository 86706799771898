// @flow
import React from 'react'
import Dropzone from 'react-dropzone'
import type { File } from 'lib/types'
import { FilePreview } from 'components/FilePreview'
import styles from './styles'

type Props = {
  name: string,
  onChange: Function,
  value: File,
}

const dropHandler = (onChange, name) => {
  return (acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.onload = () => {
      const value = {
        content_type: file.type,
        data: reader.result.split(',')[1],
      }
      onChange({ target: { name: name, value: value } })
    }
    reader.readAsDataURL(file)
  }
}

const renderPreview = (value: File) => (
  <div className={styles.previewWrapper}>
    <FilePreview file={value} />
  </div>
)

export const FileInput = (props: Props) => {
  const { name, onChange, value } = props
  return (
    <div>
      {value && renderPreview(value)}
      <Dropzone
        className={styles.dropzoneArea}
        disablePreview={true}
        activeClassName={styles.drag}
        multiple={false}
        name={name}
        onDrop={dropHandler(onChange, name)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={styles.dropzoneArea} {...getRootProps()}>
            <div className={styles.upIcon} />
            <div className={styles.uploadText}>
              Drop here or click to browse
            </div>
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
    </div>
  )
}
