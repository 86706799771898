import React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import { IconTypes } from '../Icon/IconTypes'
import theme from 'ui/theme'

type ButtonIconProps = {
  shape?: 'cricle' | 'rectangle',
  size?: 'default' | 'small',
  icon?: IconTypes,
  iconColor?: any,
  onClick?: () => void,
  className?: any,
}

const SIZE_DEFAULT = theme.button.icon.default
const SIZE_SMALL = theme.button.icon.small

const Layout = styled.button`
  appearance: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${(props) =>
    props.size === 'default'
      ? SIZE_DEFAULT / theme.rem
      : SIZE_SMALL / theme.rem}rem;
  height: ${(props) =>
    props.size === 'default'
      ? SIZE_DEFAULT / theme.rem
      : SIZE_SMALL / theme.rem}rem;
  margin: 0;
  padding: 0;
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '.1875rem')};
  border: none;
  outline: none;
  background-color: #edeced;
`

const ButtonIcon = (props: ButtonIconProps) => {
  const {
    shape = 'circle',
    size = 'default',
    icon = 'Back',
    iconColor = theme.color.blue,
    onClick,
    className,
  } = props

  return (
    <Layout
      type="button"
      shape={shape}
      size={size}
      className={className}
      onClick={onClick}
    >
      <Icon
        icon={icon}
        width={size === 'default' ? 14 : 10}
        height={size === 'default' ? 14 : 10}
        fill={iconColor}
      />
    </Layout>
  )
}

export default ButtonIcon
