import styles from './styles'
import Stepper from '@mui/joy/Stepper'
import Step from '@mui/joy/Step'
import CheckIcon from '@mui/icons-material/Check'
import moment from 'moment'

const CustomStepper = ({ steps, activeStep, width }) => {
  let marginLeft = null
  if (steps?.length > 4) {
    width = 'auto'
    marginLeft = steps?.length > 10 ? '0px' : '-30px'
  } else if (steps?.length <= 2) {
    marginLeft = '-40px'
  }
  return (
    <div className={styles.customStepper}>
      <Stepper
        sx={{
          width: width ?? 400,
          marginLeft: marginLeft,
          '--StepIndicator-size': '12px',
          '--Step-connectorInset': '0px',
        }}
      >
        {steps.map((step, index) => {
          const isActiveStep = index === activeStep
          return (
            <Step
              orientation="vertical"
              key={step.key}
              indicator={
                <div className={styles.indicator}>
                  {step.date && <div className={styles.headerTop}>{moment(step.date).format('MM/DD/YY')}</div>}
                  {isActiveStep ? (
                    <div className={styles.ActiveDot}></div>
                  ) : index <= activeStep ? (
                    <div className={styles.ActiveCurrentDot}>
                      <CheckIcon
                        sx={{
                          fontSize: 8,
                          color: 'white',
                        }}
                      />
                    </div>
                  ) : (
                    <div className={styles.DefaultDot}></div>
                  )}
                </div>
              }
              sx={{
                minWidth: '50px',
                '&::after': {
                  height: 2,
                  ...(activeStep > index && {
                    bgcolor: 'primary.solidBg',
                    height: 4,
                  }),
                },
              }}
            >
              <div className={`${styles.item} ${isActiveStep ? styles.isActiveStep : ''}`}>{step.label}</div>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}

export default CustomStepper
