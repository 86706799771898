import styles from './colors-theme.css'

export const colorTheme = {
  cyan: {
    bgDark: styles.cyanBgDark,
    bgMid: styles.cyanBgMid,
    bgLight: styles.cyanBgLight,
    textDark: styles.cyanTextDark,
    textLight: styles.cyanText,
    borderLight: styles.cyanBorderLight,
    borderMid: styles.cyanBorderMid,
  },
  amber: {
    bgDark: styles.amberBgDark,
    bgMid: styles.amberBgMid,
    bgLight: styles.amberBgLight,
    textDark: styles.amberTextDark,
    textLight: styles.amberText,
    borderLight: styles.amberBorderLight,
    borderMid: styles.amberBorderMid,
  },
  orange: {
    bgDark: styles.orangeBgDark,
    bgLight: styles.orangeBgLight,
    textDark: styles.orangeTextDark,
    textLight: styles.orangeText,
    borderLight: styles.orangeBorderLight,
  },
  purple: {
    bgDark: styles.purpleBgDark,
    bgMid: styles.purpleBgMid,
    bgLight: styles.purpleBgLight,
    textDark: styles.purpleTextDark,
    textLight: styles.purpleText,
    borderLight: styles.purpleBorderLight,
    borderMid: styles.purpleBorderMid,
  },
  lime: {
    bgDark: styles.limeBgDark,
    bgMid: styles.limeBgMid,
    bgLight: styles.limeBgLight,
    textDark: styles.limeTextDark,
    textLight: styles.lime,
    borderLight: styles.limeBorderLight,
    borderMid: styles.limeBorderMid,
  },
}
