// @flow
import { ContactListRef, ContactRef, RemaindermenRef } from 'components/References'
import type { Contact } from 'lib/types'
import React from 'react'

type Props = {
  ables: [Contact],
  attorneys: [Contact],
  consults: [Contact],
  dispatch: Function,
  referrer: Contact,
  remaindermen: [Contact],
  remaindermen_notes: string,
}

const People = (props: Props) => {
  const {
    ables, attorneys, dispatch, consults, referrer, remaindermen, remaindermen_notes
  } = props
  return (
    <div>
      <ContactListRef contacts={ables} title="Ables" />
      <ContactListRef contacts={consults} title="Consults" />
      <ContactListRef contacts={attorneys} title="Attorneys" />
      <ContactRef
        collapse={true}
        contact={referrer}
        dispatch={dispatch}
        edit={true}
        title="Referrer"
      />
      <RemaindermenRef
        contacts={remaindermen}
        notes={remaindermen_notes}
        title='Remaindermen Contacts'
      />
    </div>
  )
}

export default People
