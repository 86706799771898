// @flow
import React from 'react'
import Collapse from 'react-collapse'
import formatters from 'lib/formatters'
import styles from './styles'

type Props = {
  warnings: [string],
}

export const Warnings = (props: Props) => {
  const { warnings } = props

  const renderWarningRows = () => {
    return warnings.map((warning, idx) => {
      return (
        <li
          key={idx}
          className={`${styles.noteWrapper} ${styles.importantNoteWrapper}`}
        >
          <div className={styles.flexRow}>
            <div className={styles.flexSB}>
              <div className={styles.flexRow}>
                <div className={styles.important}>
                  <a title="Important"/>
                </div>
                <div>
                  <div className={styles.note}>
                    {formatters.legibleWarning(warning)}
                  </div>
                  <div className={styles.user}></div>
                </div>
              </div>
            </div>
          </div>
        </li>
      )
    })
  }

  return (
    <section data-active={true} className={styles.collapseRef}>
      <div className={styles.titleSection}>
        <div className={styles.title}>
          <h5>Warnings</h5>
        </div>
      </div>
      <Collapse isOpened={true}>
        <ul className={styles.notes}>{renderWarningRows()}</ul>
      </Collapse>
    </section>
  )
}
