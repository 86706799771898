// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container */
.styles__headerNav___ALrpS {
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
}
.styles__headerNav___ALrpS nav {
    padding: 0;
    width: 280px;
  }
.styles__headerNav___ALrpS nav ul {
      width: 100%;
      margin-top: 0;
    }

/* Nav Item */
.styles__headerNavItem___HyerK {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  margin-left: 30px;
  padding-top: 3px;
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.018em;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  transition: color 150ms linear;
}
.styles__headerNavItem___HyerK:hover {
    border-bottom-color: rgba(255, 255, 255, 1);
    box-shadow: none;
    color: rgba(255, 255, 255, 1);
  }

.styles__headerNavItemActive___WKEsm {
  border-bottom-color: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
}
`, "",{"version":3,"sources":["webpack://./universal/components/Header/HeaderNav/styles.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;AAUd;AATE;IACE,UAAU;IACV,YAAY;EAMd;AAJE;MACE,WAAW;MACX,aAAa;IACf;;AAIJ,aAAa;AACb;EACE,aAAa;EACb,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,+CAA+C;EAC/C,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,6BAAqB;EAArB,qBAAqB;EACrB,+BAA+B;EAC/B,8BAA8B;AAOhC;AALE;IACE,2CAA2C;IAC3C,gBAAgB;IAChB,6BAA6B;EAC/B;;AAGF;EACE,2CAA2C;EAC3C,6BAA6B;AAC/B","sourcesContent":["/* Container */\n.headerNav {\n  display: flex;\n  align-items: center;\n  width: auto;\n  height: 100%;\n  nav {\n    padding: 0;\n    width: 280px;\n\n    ul {\n      width: 100%;\n      margin-top: 0;\n    }\n  }\n}\n\n/* Nav Item */\n.headerNavItem {\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: center;\n  align-items: center;\n  width: auto;\n  height: 100%;\n  margin-left: 30px;\n  padding-top: 3px;\n  border-bottom: 3px solid rgba(255, 255, 255, 0);\n  box-shadow: none;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 100%;\n  letter-spacing: 0.018em;\n  text-decoration: none;\n  color: rgba(255, 255, 255, 0.7);\n  transition: color 150ms linear;\n\n  &:hover {\n    border-bottom-color: rgba(255, 255, 255, 1);\n    box-shadow: none;\n    color: rgba(255, 255, 255, 1);\n  }\n}\n\n.headerNavItemActive {\n  border-bottom-color: rgba(255, 255, 255, 1);\n  color: rgba(255, 255, 255, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerNav": `styles__headerNav___ALrpS`,
	"headerNavItem": `styles__headerNavItem___HyerK`,
	"headerNavItemActive": `styles__headerNavItemActive___WKEsm`
};
export default ___CSS_LOADER_EXPORT___;
