// @flow
import React from 'react'
import styles from './styles'
import Ref from 'components/References/Ref'
import Metric from 'components/Metric/Metric'
import Overlay from 'components/Overlay/Overlay'

type Props = {
  title: string,
  preview?: {}[],
  details?: {}[],
  loading?: boolean,
}

const MetricsRef = (props: Props) => {
  const { title, details, preview, loading } = props
  return (
    <Ref
      sectionStyle={{ padding: '20px 20px 10px 20px' }}
      title={title}
      preview={
        <Overlay loading={loading}>
          {preview.map(({ title, value }) => (
            <Metric
              className={styles.metric}
              key={title}
              title={title}
              value={value}
            />
          ))}
        </Overlay>
      }
      details={
        details &&
        details.map(({ title, value }) => (
          <Metric
            className={styles.metric}
            key={title}
            title={title}
            value={value}
          />
        ))
      }
    />
  )
}

export default MetricsRef
