// @flow
import React from 'react'
import { connect } from 'react-redux'
import { httpGet } from 'lib/http'
import { Table, Row, Cell } from 'components/Table'
import { DEFAULT_FOLDERS } from 'lib/constants'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Dropzone from 'react-dropzone'
import { Dialog } from 'components/Dialog'
import moment from 'moment'
import formatters from 'lib/formatters'
import styles from './styles'

type Props = {
  children: [Object],
  content_type: String,
  dispatch: Function,
  files: [Object],
  folder: String,
  folders: [Object],
  headers: [String],
  key: String,
  link_type: String,
  name: String,
  onDeleteClick: Function,
  onDeleteLinkClicked: Function,
  onFileDrop: Function,
  onFileLinkClicked: Function,
  onHeaderSortChange: Function,
  onLevelChange: Function,
  onUploadClick: Function,
  parentEntityId: Number,
  rows: [Row],
  versions: [Object],
}

const treeLevels = {
  FOLDERS: 'folders',
  FILES: 'files',
  VERSIONS: 'versions',
}

const viewableContentTypes = [
  'application/pdf',
  'text/plain',
  'image/png',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'audio/mpeg',
  'video/mpeg',
]

const TableComponent = ({ rows, headers }: Props) => (
  <Table headers={headers} tableSize="infinite" tableType="read" noResults="">
    {rows}
  </Table>
)

const FoldersTable = ({ folders = [], onLevelChange = null }: Props) => {
  const folderCell = (folder) => (
    <div>
      <div className={styles.folderIcon}></div>
      <div className={styles.childLink}>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            onLevelChange(folder)
          }}
        >
          {folder.name}
        </a>
      </div>
    </div>
  )

  const folderRow = (folder, idx) => {
    return (
      <Row key={idx} className={styles.tableRow}>
        <Cell value={folderCell(folder)} />
        <Cell value={folder.files.length.toString()} />
      </Row>
    )
  }

  const folderRows = folders.map((f, idx) => folderRow(f, idx))

  return <TableComponent rows={folderRows} headers={['Folder', 'Files']} />
}

const FilesTable = ({
  files = [],
  onDeleteLinkClicked = null,
  onFileLinkClicked = null,
  onLevelChange = null,
  onHeaderSortChange = null,
}: Props) => {
  const versionLink = (file) => (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault()
        onLevelChange(file)
      }}
    >
      {file.versions.length.toString()}
    </a>
  )

  const headerLink = (title) => (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault()
        onHeaderSortChange(title)
      }}
    >
      {title}
    </a>
  )

  const deleteLink = (id) => (
    <div className={styles.remove}>
      <a title="Delete File" onClick={() => onDeleteLinkClicked(id)} />
    </div>
  )

  const downloadLink = ({ key, name }, version_id) => (
    <div className={styles.download}>
      <a
        title="Download File"
        onClick={() => onFileLinkClicked(key, version_id, 'download')}
      >
        {name}
      </a>
    </div>
  )

  const previewLink = ({ key, content_type }, version_id) => {
    if (viewableContentTypes.includes(content_type)) {
      return (
        <div className={styles.preview}>
          <a
            title="Preview File"
            onClick={(e) => {
              e.preventDefault()
              onFileLinkClicked(key, version_id, 'preview')
            }}
          />
        </div>
      )
    } else {
      return ' '
    }
  }

  const fileRow = (file, idx) => {
    const latest = file.versions[file.versions.length - 1]

    return (
      <Row key={idx + 1} className={styles.tableRow}>
        <Cell value={downloadLink(file, latest.version_id)} />
        <Cell value={previewLink(file, latest.version_id)} />
        <Cell value={formatters.fileTimestamp(latest.last_modified)} />
        <Cell value={versionLink(file)} />
        <Cell value={deleteLink(file.id)} />
      </Row>
    )
  }

  const fileRows = files.map((f, idx) => fileRow(f, idx))
  const fileLink = headerLink('File')
  const dateLink = headerLink('Date')

  return (
    <TableComponent
      rows={fileRows}
      headers={[
        { value: fileLink, type: 'link' },
        '',
        { value: dateLink, type: 'link' },
        'Versions',
        '',
      ]}
    />
  )
}

const VersionsTable = ({ versions = [] }: Props) => {
  const versionLink = (version) => (
    <a href={version.url}>{formatters.fileTimestamp(version.last_modified)}</a>
  )

  const versionRow = (version, idx) => {
    return (
      <Row key={idx + 1} className={styles.tableRow}>
        <Cell value={versionLink(version)} />
      </Row>
    )
  }

  const versionRows = versions.map((v, idx) => versionRow(v, idx))

  return <TableComponent rows={versionRows} headers={['Version']} />
}

class FileFolders extends React.Component {
  props: Props

  static MergeFoldersWithDefaults(folders, defaultFolders = DEFAULT_FOLDERS) {
    let tmp = []

    for (let i = 0; i < defaultFolders.length; i++) {
      const name = defaultFolders[i].name
      const existing = folders.find((f) => f.name == name)

      if (existing) {
        tmp.push(existing)
      } else {
        tmp.push(defaultFolders[i])
      }
    }

    return tmp
  }

  constructor(props) {
    super(props)
    this.state = {
      deleteFileId: null,
      treeLevel: treeLevels.FOLDERS,
      selectedFolderFiles: [],
      selectedFolderName: null,
      selectedFileVersions: [],
      selectedFileName: null,
      uploadFile: null,
      uploadFileName: '',
      breadcrumbs: this.buildBreadcrumbs(
        treeLevels.FOLDERS,
        'All Folders',
        () => this.selectAllFolders(),
      ),
    }
  }

  componentDidUpdate(prevProps) {
    const refreshCurrentFolder = () => {
      const folder = this.props.folders.find(
        (f) => f.name == this.state.selectedFolderName,
      )

      if (folder) {
        this.selectFolder(folder)
      }
    }

    if (prevProps.folders != this.props.folders) {
      switch (this.state.treeLevel) {
        case treeLevels.FOLDERS:
          break
        case treeLevels.FILES:
          refreshCurrentFolder()
          break
        case treeLevels.VERSIONS:
          // todo: implement versions refresh
          break
      }
    }
  }

  buildBreadcrumbs = (treeLevel, linkText, onClick = null) => {
    const newBreadcrumbLink = (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          {
            onClick()
          }
        }}
      >
        {linkText}
      </a>
    )

    let breadcrumbs = this.state ? this.state.breadcrumbs : []

    switch (treeLevel) {
      case treeLevels.FOLDERS:
        breadcrumbs = [newBreadcrumbLink]
        break
      case treeLevels.FILES:
        breadcrumbs = [breadcrumbs[0], newBreadcrumbLink]
        break
      case treeLevels.VERSIONS:
        breadcrumbs = [breadcrumbs[0], breadcrumbs[1], newBreadcrumbLink]
        break
      default:
        break
    }

    return breadcrumbs
  }

  selectAllFolders = () => {
    this.setState({
      treeLevel: treeLevels.FOLDERS,
      breadcrumbs: this.buildBreadcrumbs(
        treeLevels.FOLDERS,
        'All Folders',
        () => this.selectAllFolders(),
      ),
    })
  }

  selectFolder = (folder) => {
    this.setState({
      treeLevel: treeLevels.FILES,
      selectedFolderFiles: folder.files,
      selectedFolderName: folder.name,
      breadcrumbs: this.buildBreadcrumbs(treeLevels.FILES, folder.name, () =>
        this.selectFolder(folder),
      ),
    })
  }

  selectFile = (file) => {
    this.setState({
      treeLevel: treeLevels.VERSIONS,
      selectedFileVersions: this.sortVersionsByDate(file.versions),
      selectedFileName: file.name,
      breadcrumbs: this.buildBreadcrumbs(
        treeLevels.VERSIONS,
        file.name + ' versions',
      ),
    })
  }

  sortFilesByColumn = (columnTitle) => {
    let files = this.state.selectedFolderFiles

    const mostRecentMoment = (versions) =>
      moment(versions[versions.length - 1].last_modified)

    if (columnTitle === 'File') {
      files.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        } else if (a.name === b.name) {
          return 0
        } else {
          return 1
        }
      })
    } else if (columnTitle === 'Date') {
      files.sort((a, b) => {
        const aVersion = mostRecentMoment(a.versions)
        const bVersion = mostRecentMoment(b.versions)

        if (aVersion.isBefore(bVersion)) {
          return 1
        } else if (aVersion.isSame(bVersion)) {
          return 0
        } else {
          return -1
        }
      })
    }

    this.setState({ selectedFolderFiles: files })
  }

  sortVersionsByDate = (versions) => {
    versions.sort((a, b) => {
      const aVersion = moment(a.last_modified)
      const bVersion = moment(b.last_modified)

      if (aVersion.isBefore(bVersion)) {
        return 1
      } else if (aVersion.isSame(bVersion)) {
        return 0
      } else {
        return -1
      }
    })

    return versions
  }

  table = () => {
    switch (this.state.treeLevel) {
      case treeLevels.FOLDERS:
        return (
          <FoldersTable
            folders={this.props.folders}
            onLevelChange={this.selectFolder}
          />
        )
      case treeLevels.FILES:
        return (
          <FilesTable
            files={this.state.selectedFolderFiles}
            onDeleteLinkClicked={this.deleteLinkClicked}
            onFileLinkClicked={this.fileLinkClicked}
            onLevelChange={this.selectFile}
            onHeaderSortChange={this.sortFilesByColumn}
          />
        )
      case treeLevels.VERSIONS:
        return <VersionsTable versions={this.state.selectedFileVersions} />
      default:
    }
  }

  dropHandler = () => {
    return (acceptedFiles) => {
      const acceptedFile = acceptedFiles[0]
      this.setState({
        uploadFile: acceptedFile,
        uploadFileName: acceptedFile.name,
      })
    }
  }

  customizeUploadFileName = (event) => {
    this.setState({ uploadFileName: event.target.value })
  }

  fileLinkClicked = (key, version_id, link_type) => {
    const reqUrl = `/admin/files/${key}/versions/${version_id}/${link_type}`

    httpGet(reqUrl).then(({ url }) => {
      link_type === 'preview'
        ? window.open(url, '_blank')
        : (window.location.href = url)
    })
  }

  uploadClicked = () => {
    if (!this.state.uploadFile) {
      return
    }

    this.props.onUploadClick(
      this.props.parentEntityId,
      this.state.selectedFolderName || 'Other',
      this.state.uploadFileName,
      this.state.uploadFile,
    )

    this.setState({ uploadFileName: '' })
  }

  deleteLinkClicked = (id) => {
    this.setState({ deleteFileId: id })
  }

  signalDelete = () => {
    this.props.onDeleteClick(
      this.state.selectedFolderName,
      this.state.deleteFileId,
    )

    this.setState({ deleteFileId: null })
  }

  render() {
    const table = this.table()

    return (
      <div className={styles.fileFolders}>
        <Breadcrumbs crumbs={this.state.breadcrumbs} />
        {table}
        <div className={styles.uploadControls}>
          <Dropzone
            onDrop={this.dropHandler()}
            className={styles.dropzoneArea}
            activeClassName={styles.drag}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div className={styles.dropzoneArea} {...getRootProps()}>
                <div className={styles.upIcon} />
                <div className={styles.uploadText}>
                  Drop here or click to browse
                </div>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
          <div
            className={
              this.state.uploadFile ? styles.lowerUploadControls : styles.hidden
            }
          >
            <input
              id="fileNameInput"
              className={styles.fileNameInput}
              type="text"
              placeholder="Name"
              value={this.state.uploadFileName}
              onChange={this.customizeUploadFileName}
            />
            <button
              type="button"
              className={styles.fileUploadButton}
              onClick={this.uploadClicked}
            >
              Upload
            </button>
          </div>
        </div>
        {this.state.deleteFileId && (
          <Dialog
            cancelButtonText="Cancel"
            confirmButtonText="Yes, Delete"
            headerText="Are you sure you want to delete this file?"
            onCancel={() => this.setState({ deleteFileId: null })}
            onConfirm={() => this.signalDelete()}
          />
        )}
      </div>
    )
  }
}

export default connect()(FileFolders)
