import * as t from './actionTypes'
import { State } from './model'

const initialState: State = {
  tickets: [],
}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case t.INDEX:
      return { ...state, tickets: action.tickets }
    default:
      return state
  }
}
