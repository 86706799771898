import cleaners from './cleaners'

const email = (val) => {
  let error = 'Required'
  if (val) {
    error = null
    if (!val.match(/.+@.+/)) {
      error = 'Invalid Email Format'
    }
  }
  return error
}

const file = (val) => {
  let error = 'Required'
  if (val) {
    error = null
    if (!val.data || !val.content_type) {
      error = 'Required'
    }
  }
  return error
}

const phone = (val) => {
  let error = 'Required'
  if (val) {
    error = null
    const numbers = cleaners.numbers(val)
    if (!numbers.match(/^\d{7}$/) && !numbers.match(/^\d{10}$/)) {
      error = 'Invalid phone number'
    }
  }

  return error
}

const requiredField = (val) => (val ? null : 'Required')

const requiredWithTrim = (val) => (val && val.trim() ? null : 'Required')

const ssn = (val) => {
  let error = 'Required'
  if (val) {
    error = null
    if (!cleaners.numbers(val).match(/^\d{9}$/)) {
      error = 'Must be exactly 9 numbers'
    }
  }

  return error
}

const optionalSsn = (val) => {
  let error = null
  if (val && !cleaners.numbers(val).match(/^\d{9}$/)) {
    error = 'Must be exactly 9 numbers'
  }

  return error
}

export default {
  email,
  file,
  optionalSsn,
  phone,
  requiredField,
  requiredWithTrim,
  ssn,
}
