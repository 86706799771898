// @flow
import React from 'react'
import { AccountStatement } from 'components/AccountStatement'
import formatters from 'lib/formatters'

type Props = {
  statements: Array<Object>,
}

const statementKey = (statement) => {
  return statement.address
    ? `${statement.address.id}:${statement.number}`
    : statement.number
}

const renderStatements = (statements) =>
  statements?.map((statement) => (
    <AccountStatement
      {...statement}
      key={statementKey(statement)}
      addedHeadersMap={
        new Map([
          ['DOB', formatters.date(statement.beneficiaryDOB)],
          ['SSN', formatters.ssn(statement.beneficiarySSN)],
        ])
      }
    />
  ))

export const Report = ({ statements }: Props) => (
  <div>{renderStatements(statements)}</div>
)
