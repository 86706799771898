// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("styles/img/icon_documentAlt.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__docLink___RU6c2 {
  cursor: pointer;
}

.styles__docLink___RU6c2::before {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat left center;
  background-size: 18px;
  bottom: 0;
  content: '';
  display: inline-block;
  height: 24px;
  left: -32px;
  margin: auto;
  position: absolute;
  top: 0;
  width: 22px;
}
`, "",{"version":3,"sources":["webpack://./universal/components/S3FilePreview/styles.css"],"names":[],"mappings":"AAEA;EAEE,eAAe;AACjB;;AAEA;EACE,yEAAwE;EACxE,qBAAqB;EACrB,SAAS;EACT,WAAW;EACX,qBAAqB;EACrB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,WAAW;AACb","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.docLink {\n  composes: docLink from helpers;\n  cursor: pointer;\n}\n\n.docLink::before {\n  background: url(\"styles/img/icon_documentAlt.png\") no-repeat left center;\n  background-size: 18px;\n  bottom: 0;\n  content: '';\n  display: inline-block;\n  height: 24px;\n  left: -32px;\n  margin: auto;\n  position: absolute;\n  top: 0;\n  width: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"docLink": `styles__docLink___RU6c2 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["docLink"]}`
};
export default ___CSS_LOADER_EXPORT___;
