import * as t from './actionTypes'
import { State } from './model'

const initialState: State = {}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case t.SET:
      return action.beneficiary
    default:
      return state
  }
}
