export const httpGet = (route) => {
  const url = httpUrl(route)
  const sid = localStorage.getItem('sid')
  let headers = {}
  if (sid) {
    headers['Authorization'] = `Bearer ${sid}`
  }
  return fetchJSON(url, { headers, method: 'GET' })
}

export const httpRaw = (route) => {
  const url = httpUrl(route)
  const sid = localStorage.getItem('sid')
  let headers = {}
  if (sid) {
    headers['Authorization'] = `Bearer ${sid}`
  }
  return fetch(url, { method: 'GET', headers })
}

export const httpPost = (route, data) => {
  const url = httpUrl(route)
  const sid = localStorage.getItem('sid')
  const headers = { 'Content-Type': 'application/json' }
  if (sid) {
    headers['Authorization'] = `Bearer ${sid}`
  }
  const request = { headers, method: 'POST', body: JSON.stringify(data) }
  return fetchJSON(url, request)
}

export const httpPut = (route, data) => {
  const url = httpUrl(route)
  const sid = localStorage.getItem('sid')
  const headers = { 'Content-Type': 'application/json' }
  if (sid) {
    headers['Authorization'] = `Bearer ${sid}`
  }
  const request = { headers, method: 'PUT', body: JSON.stringify(data) }
  return fetchJSON(url, request)
}

export const httpDelete = (route) => {
  const url = httpUrl(route)
  const sid = localStorage.getItem('sid')
  const headers = { 'Content-Type': 'application/json' }
  if (sid) {
    headers['Authorization'] = `Bearer ${sid}`
  }
  return fetchJSON(url, { headers, method: 'DELETE' })
}

export const httpUrl = (route) => __PHOENIX_API_URL__ + route

export const wsUrl = (route = '') =>
  __PHOENIX_API_URL__.replace(/(http|https)/, 'ws').replace('/api', '') + '/socket' + route

const fetchJSON = (url, request) => {
  return fetch(url, request)
    .then((response) => response.json())
    .then(({ data, error }) => {
      if (error) {
        if (error?.code == 401 && window.location.pathname != '/login') {
          window.location.replace('/login')
        }
        throw error
      } else {
        return data
      }
    })
}
