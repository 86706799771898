// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Table, Row, Cell } from 'components/Table'
import { DynamicSelect } from 'components/DynamicSelect'
import formatters from 'lib/formatters'
import FormButtons from 'components/FormButtons'
import { httpGet, httpPost } from 'lib/http'
import { Investment, Product } from 'lib/types'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import styles from './styles'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  account_id: number,
  dispatch: Function,
  history: Array<Object>,
  next_change_date: string,
  product_id: number,
  location: Object,
}

type State = {
  investments: Array<Investment>,
  selectedInvestment: number,
  confirmingChange: boolean,
}

class InvestmentsRef extends React.Component<Props, State> {
  props: Props

  fetchingProduct = false
  isSubmitting = false

  constructor(props) {
    super(props)

    this.state = {
      investments: [],
      selectedInvestment: 0,
      confirmingChange: false,
    }
  }

  buildParams = () => {
    return {
      account_id: this.props.account_id,
      investment_id: this.state.selectedInvestment.value,
    }
  }

  createOptions = (investments) =>
    investments.map((value) => ({ value: value.id, label: value.name }))

  fetchProduct = (product_id) => {
    httpGet(`/admin/products?id=${product_id}`)
      .then((data: Product) => {
        this.fetchingProduct = false
        this.setState({ investments: data.product.investments })
      })
      .catch((error) => {
        throw error
      })
  }

  investmentChangeElement = () => {
    const isCotrustee = localStorage.getItem('isCoTrustee') === 'true'
    const changeDateFormatted = formatters.date(this.props.next_change_date)
    const submitLabel = this.state.confirmingChange
      ? 'Confirm Investment Change'
      : 'Change Investment'
    let element

    if (isCotrustee) {
      if (!this.fetchingProduct && this.state.investments.length === 0) {
        this.fetchingProduct = true
        this.fetchProduct(this.props.product_id)
      }

      element = (
        <section className={styles.cardMarginBot}>
          <h5 className={styles.titleSection}>Change Investment</h5>
          <div className={styles.investmentInfo}>
            <h6>Change will be effective {changeDateFormatted}</h6>
            <div className={styles.formItemHalf}>
              {
                <DynamicSelect
                  placeholder="Select Investment"
                  onChange={({ value: value }) =>
                    this.onInvestmentSelection(value)
                  }
                  options={this.createOptions(this.state.investments)}
                  value={this.state.selectedInvestment}
                />
              }
            </div>
            <form onSubmit={this.wrapSubmit(this.onSubmit)}>
              <FormButtons
                disabled={
                  !this.isSubmitting && !this.state.selectedInvestment > 0
                }
                submitLabel={submitLabel}
              />
            </form>
          </div>
        </section>
      )
    } else {
      element = <div></div>
    }

    return element
  }

  onInvestmentSelection = (value) =>
    this.setState({ selectedInvestment: value })

  onSubmit = () => {
    if (this.state.confirmingChange) {
      this.isSubmitting = true
      this.setState({ confirmingChange: false })
      this.postChange()
    } else {
      this.setState({ confirmingChange: true })
    }

    event.preventDefault()
  }

  postChange = () => {
    const { dispatch } = this.props

    dispatch(setLoad())
    return httpPost('/co-trustee/investments/account', this.buildParams())
      .then(() => {
        this.props.location.navigate('/contacts')
        dispatch(setBanner('Investment successfully changed'))
      })
      .catch(() => {
        dispatch(setBanner('Error changing investment', 'danger'))
      })
      .then(() => {
        this.isSubmitting = false
        dispatch(setUnload())
      })
  }

  renderInvestment = (investment, index) => (
    <Row key={`${index}`}>
      <Cell value={investment.name} />
      <Cell value={formatters.date(investment.start_date)} />
      <Cell value={formatters.date(investment.end_date)} />
    </Row>
  )

  wrapSubmit = (onSubmit) => (event) => {
    onSubmit()
    event.preventDefault()
  }

  render() {
    const { history } = this.props

    const headers = [
      { value: 'Name' },
      { value: 'Start Date' },
      { value: 'End Date' },
    ]

    return (
      <div>
        <Table headers={headers} tableSize="small" title="Investments History">
          {history.map(this.renderInvestment)}
        </Table>
        <div>{this.investmentChangeElement()}</div>
      </div>
    )
  }
}

export default withRouter(connect()(InvestmentsRef))
