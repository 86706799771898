// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__bodyLeft___cndAt {
}

.styles__bodyRight___VboIZ {
}

.styles__contentContainer___c1iBl {
  display: flex;
  flex-flow: column wrap;
}

@media print {

.styles__contentContainer___c1iBl {
    display: block;
    margin: 0;
    padding: 0;
}
  }

.styles__section___SqHSW {
  margin-bottom: 20px;
  position: relative;
}

.styles__section___SqHSW h2 {
    margin-bottom: 20px;
  }

.styles__swapBtn___L9ASe {
  display: block;
  margin: 0 auto 20px;
}

.styles__selectionFilter___mSG9F {
  align-items: center;
  color: darkgray;
  display: flex;
  font-style: italic;
  font-weight: lighter;
  font-weight: italic;
  height: 40px;
  position: absolute;
  right: 80px;
  top: 10px;
  z-index: 9;
}

.styles__selectionFilter___mSG9F span {
    margin-right: 10px;
  }

.styles__alreadySelected___tPInX {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]};
  font-weight: normal;
}

small {
  color: darkgray;
  font-style: italic;
  font-weight: lighter;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Contacts/components/Merge/styles.css"],"names":[],"mappings":"AAIA;AAEA;;AAEA;AAEA;;AAEA;EAEE,aAAa;EACb,sBAAsB;AAOxB;;AALE;;AALF;IAMI,cAAc;IACd,SAAS;IACT,UAAU;AAEd;EADE;;AAGF;EAEE,mBAAmB;EACnB,kBAAkB;AAKpB;;AAHE;IACE,mBAAmB;EACrB;;AAGF;EAEE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;AAKZ;;AAHE;IACE,kBAAkB;EACpB;;AAGF;EACE,mDAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtBlue, gtBlueDark, gtGreen from colors;\n\n.bodyLeft {\n  composes: bodyLeft from helpers;\n}\n\n.bodyRight {\n  composes: bodyRight from helpers;\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n  display: flex;\n  flex-flow: column wrap;\n\n  @media print {\n    display: block;\n    margin: 0;\n    padding: 0;\n  }\n}\n\n.section {\n  composes: boxWrap from helpers;\n  margin-bottom: 20px;\n  position: relative;\n\n  h2 {\n    margin-bottom: 20px;\n  }\n}\n\n.swapBtn {\n  composes: button from helpers;\n  display: block;\n  margin: 0 auto 20px;\n}\n\n.selectionFilter {\n  align-items: center;\n  color: darkgray;\n  display: flex;\n  font-style: italic;\n  font-weight: lighter;\n  font-weight: italic;\n  height: 40px;\n  position: absolute;\n  right: 80px;\n  top: 10px;\n  z-index: 9;\n\n  span {\n    margin-right: 10px;\n  }\n}\n\n.alreadySelected {\n  color: gtGreen;\n  font-weight: normal;\n}\n\nsmall {\n  color: darkgray;\n  font-style: italic;\n  font-weight: lighter;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"gtBlueDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]}`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"bodyLeft": `styles__bodyLeft___cndAt ${___CSS_LOADER_ICSS_IMPORT_1___.locals["bodyLeft"]}`,
	"bodyRight": `styles__bodyRight___VboIZ ${___CSS_LOADER_ICSS_IMPORT_1___.locals["bodyRight"]}`,
	"contentContainer": `styles__contentContainer___c1iBl ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"section": `styles__section___SqHSW ${___CSS_LOADER_ICSS_IMPORT_1___.locals["boxWrap"]}`,
	"swapBtn": `styles__swapBtn___L9ASe ${___CSS_LOADER_ICSS_IMPORT_1___.locals["button"]}`,
	"selectionFilter": `styles__selectionFilter___mSG9F`,
	"alreadySelected": `styles__alreadySelected___tPInX`
};
export default ___CSS_LOADER_EXPORT___;
