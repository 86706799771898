// @flow
import React from 'react'
import { Notes } from 'components/Notes'
import { GPTAccountRef } from 'components/References'
import { Warnings } from 'components/Warnings'
import Ticket from 'components/Ticket'
import { FeeCalculationsRef } from './FeeCalculationsRef'
import { FundCheckLogRef } from './FundCheckLogRef'
import { WarningsAcknowledgementRef } from './WarningsAcknowledgementRef'
import { S3FilePreview } from 'components/S3FilePreview'
import { Dialog } from 'components/Dialog'
import styles from './styles'

type Props = {
  canSubmit: boolean,
  disabled: boolean,
  dispatch: Function,
  errors: Object,
  isDeleting: boolean,
  isSubmitting: boolean,
  onApproval: Function,
  onBeginDelete: Function,
  onCancelDelete: Function,
  onChange: Function,
  onDelete?: Function,
  onSubmit: Function,
  ticket: Object,
  values: Object,
}

const Reference = ({ dispatch, ticket }: Props) => (
  <section>
    <GPTAccountRef
      beneficiary={{ name: ticket.beneficiary_name }}
      number={ticket.account_number}
      status={ticket.account_status}
    />
    <Warnings warnings={ticket.warnings} />
    <Notes
      accountNumber={ticket.account_number}
      dispatch={dispatch}
      notes={ticket.notes}
    />
  </section>
)

const DepositVerificationRef = ({
  ticket: { verification_image_key },
}: Props) => {
  const title = 'Scanned Check / Deposit Verification'

  return (
    <div className={styles.cardMarginBot}>
      <h5 className={styles.sectionTitle}>{title}</h5>
      <div className={styles.sectionNoMargin}>
        <S3FilePreview file_key={verification_image_key} />
      </div>
    </div>
  )
}

export const ApprovalStation = (props: Props) => {
  const {
    canSubmit,
    disabled,
    isDeleting,
    isSubmitting,
    onApproval,
    onBeginDelete,
    onCancelDelete,
    onDelete,
    onSubmit,
    ticket,
    values
  } = props
  const { acknowledgeDuplicateCheck, acknowledgeEarlyDate } = values
  const header = { subheader: 'Deposit', title: 'Approval' }
  const reference = ticket && <Reference {...props} />
  const rejectionError = props.errors.rejection_notes
  const errorWrapper = rejectionError && styles.errorWrapper

  return (
    <Ticket header={header} history={ticket.history} reference={reference}>
      <FeeCalculationsRef
        amount={ticket.amount}
        balance={ticket.balance}
        feeRate={Number((ticket.fee_rate * 100).toFixed(15))}
        feeSchedule={ticket.fee_schedule}
        enrollmentFee={ticket['enrollment_fee']}
        isRefund={ticket.is_refund}
        isTransfer={ticket.is_transfer}
      />
      <FundCheckLogRef {...props.ticket} />
      {<DepositVerificationRef {...props} />}
      <form onSubmit={onSubmit}>
        <section className={styles.cardMarginBot}>
          <section className={styles.sectionNoMargin}>
            <div className={errorWrapper}>
              <label className={styles.label} htmlFor="rejection_notes">
                Reasons for Rejection {rejectionError && `- ${rejectionError}`}
              </label>
              <textarea
                className={styles.textarea}
                id="rejection_notes"
                name="rejection_notes"
                onChange={props.onChange}
              />
            </div>
          </section>
        </section>
        <WarningsAcknowledgementRef
          disabled={disabled}
          warnings={ticket.warnings}
          onChange={props.onChange}
          acknowledgeEarlyDate={acknowledgeEarlyDate}
          acknowledgeDuplicateCheck={acknowledgeDuplicateCheck}
        />
        <section className={styles.buttons}>
          <div className={styles.buttonsLeft}>
            {onDelete && (
              <button
                className={styles.buttonDanger}
                disabled={isSubmitting}
                onClick={onBeginDelete}
              >
                Delete
              </button>
            )}
          </div>
          <div className={styles.buttonsRight}>
            <button
              className={styles.buttonDanger}
              disabled={disabled}
              id="reject"
              onClick={(event) => onApproval(event, false)}
            >
              Reject
            </button>
            <button
              className={styles.button}
              disabled={!canSubmit}
              id="approve"
              onClick={(event) => onApproval(event, true)}
            >
              Make Deposit
            </button>
          </div>
        </section>
      </form>
      {isDeleting && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Yes, Delete"
          headerText="Are you sure you want to delete this deposit?"
          onCancel={onCancelDelete}
          onConfirm={onDelete}
        />
      )}
    </Ticket>
  )
}
