import * as React from 'react'
import AccordionGroup from '@mui/joy/AccordionGroup'
import Accordion, { accordionClasses } from '@mui/joy/Accordion'
import AccordionDetails from '@mui/joy/AccordionDetails'
import AccordionSummary from '@mui/joy/AccordionSummary'

const accordionGroupStyle = {
  [`& .${accordionClasses.root}`]: {
    marginTop: '0.5rem',
    transition: '0.2s ease',
    '& [aria-expanded="true"]': {
      boxShadow: 'none',
    },
    '& button': {
      color: '#666666',
      fontFamily: 'MaisonNeue, sans-serif',
    },
    '& button:not([aria-expanded="true"])': {
      transition: '0.2s ease',
      paddingBottom: '0.625rem',
    },
    '& button:hover': {
      background: 'transparent',
      color: '#666666',
    },
  },
  '& [aria-expanded="true"]': {
    boxShadow: (theme) => `inset 0 -1px 0 ${theme.vars.palette.divider}`,
  },
}

const accordionSummaryStyle = {
  paddingBottom: '20px',
  fontWeight: 700,
  textTransform: 'uppercase',
  marginTop: '12px',
  color: '#666666',
}

export default function AccordionStylingExpansion({ items }) {
  return (
    <AccordionGroup sx={accordionGroupStyle} variant="plain">
      {items.map((item, index) => (
        <Accordion defaultExpanded key={`${item.title ?? index}-${index ** index}`}>
          <AccordionSummary style={accordionSummaryStyle}>{item.title}</AccordionSummary>
          <AccordionDetails>{item.details}</AccordionDetails>
        </Accordion>
      ))}
    </AccordionGroup>
  )
}
