// @flow
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './AutocompleteResults.css'
import formatters from 'lib/formatters'

type FocusedValue = {
  list: 'accounts' | 'contacts',
  pos: Number,
}

type Props = {
  query: String,
  focusedValue: FocusedValue,
  searchResult: { accounts: Array, contacts: Array },
  onItemClick: Function,
}

function highlighLabel(label, search) {
  if (label && label.toLowerCase().includes(search.toLowerCase())) {
    return label.replace(
      search,
      `<span class="${styles.matchedText}">${search}</span>`,
    )
  }

  return label
}

export const AutocompleteResults = ({
  query,
  searchResult,
  focusedValue,
  onItemClick,
}: Props) => {

  return (
    <div className={styles.autoCompleteItems}>
      {searchResult.accounts.length > 0 && (
        <React.Fragment>
          <span className={styles.autoCompleteItems_sectionTitle}>
            Accounts
          </span>
          <ul>
            {searchResult.accounts.map((account, idx) => (
              <li
                key={account.number}
                role="button"
                className={`${
                  focusedValue.list === 'accounts' && idx === focusedValue.pos
                    ? styles.active
                    : ''
                }`}
                onClick={() =>
                  onItemClick(account, { list: 'accounts', pos: idx })
                }
              >
                <FontAwesomeIcon
                  size="lg"
                  className={styles.autoCompleteItems_icon}
                  icon={['fas', 'book']}
                />
                <div className={styles.autoCompleteItems_label}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlighLabel(account.number, query),
                    }}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlighLabel(account.name, query),
                    }}
                    className={styles.small}
                  />
                </div>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}

      {searchResult.contacts.length > 0 && (
        <React.Fragment>
          <span className={styles.autoCompleteItems_sectionTitle}>
            Contacts
          </span>
          <ul>
            {searchResult.contacts.map((contact, idx) => (
              <li
                className={
                  focusedValue.list === 'contacts' && idx === focusedValue.pos
                    ? styles.active
                    : ''
                }
                key={idx}
                onClick={() =>
                  onItemClick(contact, { list: 'contacts', pos: idx })
                }
              >
                {/*eslint-disable max-len*/}
                <FontAwesomeIcon
                  size="lg"
                  className={`${styles.autoCompleteItems_icon} ${styles.user_icon}`}
                  icon={['fas', 'user']}
                />
                {/*eslint-enable max-len*/}
                <div className={styles.autoCompleteItems_label}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlighLabel(contact.name, query),
                    }}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlighLabel(
                        formatters.phone(contact.phone_number),
                        query,
                      ),
                    }}
                    className={styles.small}
                  />
                </div>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </div>
  )
}
