import React from 'react'
import { connect } from 'react-redux'
import { getPotentialClients } from 'lib/potential_client/selectors'
import {
  createPotentialClient,
  fetchPotentialClients,
} from 'lib/potential_client/actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Table, Row, Cell } from 'components/Table'
import { Link } from 'react-router-dom'
import formatters from 'lib/formatters'
import { setModal, unsetModal } from 'lib/modal/actions'
import { ContactSelectModal } from 'components/ContactSelectModal'
import styles from './styles'

type Props = {
  children: Array,
  dispatch: Function,
  onClick: Function,
  potential_clients: [Object],
}

const renderClientEditLink = (id) => {
  return <Link to={`/potential_clients/edit/${id}`}>View/Edit</Link>
}

const ClientRow = (client) => (
  <Row key={client.id}>
    <Cell value={client.id} />
    <Cell value={client.beneficiary_name} />
    <Cell value={formatters.legiblePotentialClientStatus(client.status)} />
    <Cell value={renderClientEditLink(client.id)} />
  </Row>
)

const CreatedClientRows = ({ potential_clients = [] }: Props) =>
  potential_clients.map(ClientRow)
const mapStateToCreatedClientProps = (state) => {
  const clients = getPotentialClients(state).filter(
    (c) => c.status == 'created',
  )

  return {
    potential_clients: clients,
  }
}
const CreatedClientRowsContainer = connect(mapStateToCreatedClientProps)(
  CreatedClientRows,
)

const ArchivedClientRows = ({ potential_clients = [] }: Props) =>
  potential_clients.map(ClientRow)
const mapStateToArchivedClientProps = (state) => {
  const clients = getPotentialClients(state).filter(
    (c) => c.status == 'archived',
  )

  return {
    potential_clients: clients,
  }
}
const ArchivedClientRowsContainer = connect(mapStateToArchivedClientProps)(
  ArchivedClientRows,
)

const ActiveClientRows = ({ potential_clients = [] }: Props) =>
  potential_clients.map(ClientRow)
const mapStateToActiveClientProps = (state) => {
  const clients = getPotentialClients(state).filter(
    (c) => !['created', 'archived'].includes(c.status),
  )

  return {
    potential_clients: clients,
  }
}
const ActiveClientRowsContainer = connect(mapStateToActiveClientProps)(
  ActiveClientRows,
)

const PotentialClientsTable = (props: Props) => (
  <div className={styles.table}>
    <Table
      headers={['Client #', 'Name', 'Status', ' ']}
      tableSize="large"
      tableType="read"
      noResults=""
    >
      {props.children}
    </Table>
  </div>
)

const NewClientComponent = ({ onClick }: Props) => (
  <button
    className={styles.actionButton}
    type="submit"
    onClick={() => onClick()}
  >
    New Potential Client
  </button>
)
const mapDispatchToNewClientProps = (dispatch: Props) => {
  const createOnClickHandler = () => {
    const handler = (contact: Object) => () => {
      const potentialClient = {
        beneficiary_id: contact.id,
      }

      dispatch(unsetModal())
      dispatch(createPotentialClient(potentialClient))
    }

    return dispatch(
      setModal(
        <ContactSelectModal dispatch={dispatch} selectionHandler={handler} />,
      ),
    )
  }

  return {
    onClick: () => {
      createOnClickHandler()
    },
  }
}
const NewClientContainer = connect(
  null,
  mapDispatchToNewClientProps,
)(NewClientComponent)

const Main = () => (
  <section className={styles.contentContainer}>
    <div className={styles.row}>
      <section className={styles.formWrapper}>
        <div className={styles.headerSection}>
          <h1 className={styles.headerTitle}>Potential Clients</h1>
        </div>
        <Tabs defaultIndex={0}>
          <TabList className={[styles.tabList, 'react-tabs']}>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Active
            </Tab>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Created
            </Tab>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Archived
            </Tab>
          </TabList>
          <TabPanel>
            <PotentialClientsTable>
              <ActiveClientRowsContainer />
            </PotentialClientsTable>
          </TabPanel>
          <TabPanel>
            <PotentialClientsTable>
              <CreatedClientRowsContainer />
            </PotentialClientsTable>
          </TabPanel>
          <TabPanel>
            <PotentialClientsTable>
              <ArchivedClientRowsContainer />
            </PotentialClientsTable>
          </TabPanel>
        </Tabs>
      </section>
      <section className={styles.menuWrapper}>
        <div className={styles.navigationMenuWrapper}>
          <NewClientContainer />
        </div>
      </section>
    </div>
  </section>
)

class MainContainer extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  props: Props

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchPotentialClients())
  }

  render() {
    return <Main />
  }
}

export default connect()(MainContainer)
