// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__container___pqdVo {
  /* margin-bottom: 20px; */
  display: inline-block;
}

.styles__description___hNHDE {
  font-size: 16px;
  /* margin-bottom: 5px;
  margin-right: 10px; */
}

.styles__link___gJtPw {
  box-shadow: none;
  text-shadow: none;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
}

.styles__link___gJtPw:hover {
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./universal/components/XeroLink/styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf;uBACqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  /* margin-bottom: 20px; */\n  display: inline-block;\n}\n\n.description {\n  font-size: 16px;\n  /* margin-bottom: 5px;\n  margin-right: 10px; */\n}\n\n.link {\n  box-shadow: none;\n  text-shadow: none;\n  height: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 6px;\n  margin-bottom: 8px;\n}\n\n.link:hover {\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles__container___pqdVo`,
	"description": `styles__description___hNHDE`,
	"link": `styles__link___gJtPw`
};
export default ___CSS_LOADER_EXPORT___;
