import React from 'react'
import type { Contact } from 'lib/types'
import { Link } from 'react-router-dom'
import { Table, Row, Cell } from 'components/Table'
import ticketFlag from 'styles/img/ticket.png'
import formatters from 'lib/formatters'
import styles from './styles'

const ContactRoleRef = (contact: Contact) => {
  let dataRows = []
  const { grantor_for_gpt, co_grantor_for_gpt } = contact
  const previousRoles = contact.previous_roles || []
  const roles = contact.roles || []

  contact.able_for_gpt.map((account, idx) => {
    const {
      number,
      beneficiary: { name, id },
    } = account
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = <Link to={`/beneficiaries/show/${id}`}>{name}</Link>
    dataRows.push(
      <Row key={`able${idx}`}>
        <Cell value="Able" />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={<span />} />
      </Row>,
    )
  })

  contact.attorney_for_gpt.map((account, idx) => {
    const {
      number,
      beneficiary: { name, id },
    } = account
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = <Link to={`/beneficiaries/show/${id}`}>{name}</Link>
    dataRows.push(
      <Row key={`a${idx}`}>
        <Cell value="Attorney" />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={<span />} />
      </Row>,
    )
  })

  contact.beneficiary_of_gpt.map(({ number }, idx) => {
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = (
      <Link to={`/beneficiaries/show/${contact.id}`}>{contact.name}</Link>
    )
    const { has_open_ticket: hasOpenTicket } = contact
    const openTickets = hasOpenTicket ? (
      <Link to={`/accounts/${number}`}>
        <div className={`${styles.ticketFlagSmall}`}>
          <img src={ticketFlag} />
        </div>
      </Link>
    ) : (
      <span />
    )

    dataRows.push(
      <Row highlight={hasOpenTicket} key={`b${idx}${number}`}>
        <Cell value="Beneficiary" />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={openTickets} />
      </Row>,
    )
  })

  contact.consult_for_gpt.map((account, idx) => {
    const {
      number,
      beneficiary: { name, id },
    } = account
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = <Link to={`/beneficiaries/show/${id}`}>{name}</Link>
    dataRows.push(
      <Row key={`c${idx}`}>
        <Cell value="Consult" />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={<span />} />
      </Row>,
    )
  })

  grantor_for_gpt.concat(co_grantor_for_gpt).map((account, idx) => {
    const {
      number,
      beneficiary: { name, id },
    } = account
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = <Link to={`/beneficiaries/show/${id}`}>{name}</Link>
    dataRows.push(
      <Row key={`g${idx}`}>
        <Cell value="Grantor" />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={<span />} />
      </Row>,
    )
  })

  contact.referrer_for_gpt.map((account, idx) => {
    const {
      number,
      beneficiary: { name, id },
    } = account
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = <Link to={`/beneficiaries/show/${id}`}>{name}</Link>
    dataRows.push(
      <Row key={`referrer${idx}`}>
        <Cell value="Referrer" />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={<span />} />
      </Row>,
    )
  })

  contact.representative_for_gpt.map((account, idx) => {
    const {
      number,
      beneficiary: { name, id },
    } = account
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = <Link to={`/beneficiaries/show/${id}`}>{name}</Link>
    dataRows.push(
      <Row key={`representative${idx}`}>
        <Cell value="Representative" />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={<span />} />
      </Row>,
    )
  })

  roles.map((account, idx) => {
    const {
      number,
      beneficiary: { name, id },
      role,
    } = account
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = <Link to={`/beneficiaries/show/${id}`}>{name}</Link>
    dataRows.push(
      <Row key={`role${idx}`}>
        <Cell value={formatters.unsnake(role)} />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={<span />} />
      </Row>,
    )
  })

  previousRoles.map((account, idx) => {
    const {
      number,
      beneficiary: { name, id },
      role,
    } = account
    const acctLink = <Link to={`/accounts/${number}`}>{number}</Link>
    const nameLink = <Link to={`/beneficiaries/show/${id}`}>{name}</Link>
    dataRows.push(
      <Row key={`previous${idx}`}>
        <Cell value={`PREVIOUS ${role}`} />
        <Cell value={acctLink} account="gpt" />
        <Cell value={nameLink} />
        <Cell value={<span />} />
      </Row>,
    )
  })

  let noResults = dataRows.length ? null : 'No roles held.'

  return (
    <Table
      headers={['Role', 'Account', 'Beneficiary', '']}
      tableSize="small"
      tableType="read"
      title="Contact Roles"
      noResults={noResults}
    >
      {dataRows}
    </Table>
  )
}

export default ContactRoleRef
