// @flow
import { ContactRef, GrantorRef } from 'components/References'
import type { Contact } from 'lib/types'
import { BalanceRef } from 'components/References'
import type { Subaccount } from 'lib/types'
import React from 'react'
import styles from './Main.css'

type Props = {
  beneficiary: Contact,
  co_grantor: Contact,
  grantor: Contact,
  grantor_relationship: string,
  number: string,
  representative: Contact,
  subaccounts: [Subaccount],
}

const Main = (props: Props) => {
  const {
    beneficiary,
    co_grantor,
    grantor,
    grantor_relationship,
    number,
    representative,
    subaccounts,
  } = props
  return (
    <section className={styles.rowTop}>
      <div className={styles.oneHalf}>
        <ContactRef contact={beneficiary} title="Beneficiary" />
      </div>
      {representative && (
        <div className={styles.oneHalf}>
          <ContactRef contact={representative} title="Representative" />
        </div>
      )}
      <div className={styles.oneHalf}>
        <GrantorRef
          grantor={grantor}
          relationship={grantor_relationship}
          account_number={number}
        />
      </div>
      {co_grantor && (
        <div className={styles.oneHalf}>
          <GrantorRef
            grantor={co_grantor}
            relationship={grantor_relationship}
            title="Co-Grantor"
          />
        </div>
      )}
      <div className={styles.full}>
        <BalanceRef
          collapse={false}
          accountNumber={number}
          subaccounts={subaccounts}
        />
      </div>
    </section>
  )
}

export default Main
