// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__checkbox____zIIc {
  margin-right: 8px;
}

.styles__localHeader___y_Wle {
}

@media print {

.styles__localHeader___y_Wle {
    display: none;
}
  }

.styles__localHeaderInner___sPXIW {
}

@media print {

.styles__localHeaderInner___sPXIW {
    display: none;
}
  }

.styles__contentContainer___f6WpM {
  display: flex;
  flex-direction: column;
}

.styles__section___Bm8P8 {
}

.styles__header___KW6mo {
  background: white;
  border-radius: 3px;
  margin: 0 0 20px;
  padding: 20px;
  width: 100%;
}

.styles__halfWidth___FsKcb {
  margin: 0 auto;
  width: 25%;
}

.styles__form___uDbVN {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.styles__formItem___Lfm2O {
  margin-top: 20px;
}

.styles__label___Y9RoA {
}

.styles__input___M5oD1 {
}

.styles__warning___whfkM {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]};
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowDark"]};
  font-size: 90%;
}

.styles__danger___VZEU2 {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRedLight"]};
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRedDark"]};
  font-size: 90%;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Account/components/Number/Statement/styles.css"],"names":[],"mappings":"AAIA;EACE,iBAAiB;AACnB;;AAEA;AAMA;;AAHE;;AAHF;IAII,aAAa;AAEjB;EADE;;AAGF;AAMA;;AAHE;;AAHF;IAII,aAAa;AAEjB;EADE;;AAGF;EAEE,aAAa;EACb,sBAAsB;AACxB;;AAEA;AAEA;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,aAA2B;EAA3B,wBAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EACE,8DAA+B;EAC/B,8DAA0B;EAC1B,mDAAmB;EAEnB,cAAc;AAChB;;AAEA;EACE,8DAA4B;EAC5B,8DAAuB;EACvB,mDAAgB;EAEhB,cAAc;AAChB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: 'styles/colors.css';\n@value gtRed, gtRedDark, gtRedLight, gtYellow, gtYellowDark, gtYellowLight from colors;\n\n.checkbox {\n  margin-right: 8px;\n}\n\n.localHeader {\n  composes: localHeader from helpers;\n\n  @media print {\n    display: none;\n  }\n}\n\n.localHeaderInner {\n  composes: localHeaderInner from helpers;\n\n  @media print {\n    display: none;\n  }\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n  display: flex;\n  flex-direction: column;\n}\n\n.section {\n  composes: section from helpers;\n}\n\n.header {\n  background: white;\n  border-radius: 3px;\n  margin: 0 0 20px;\n  padding: 20px;\n  width: 100%;\n}\n\n.halfWidth {\n  margin: 0 auto;\n  width: 25%;\n}\n\n.form {\n  lost-flex-container: column;\n  width: 100%;\n}\n\n.formItem {\n  margin-top: 20px;\n}\n\n.label {\n  composes: label from helpers;\n}\n\n.input {\n  composes: input from helpers;\n}\n\n.warning {\n  background-color: gtYellowLight;\n  border: 1px solid gtYellow;\n  color: gtYellowDark;\n  composes: section from helpers;\n  font-size: 90%;\n}\n\n.danger {\n  background-color: gtRedLight;\n  border: 1px solid gtRed;\n  color: gtRedDark;\n  composes: section from helpers;\n  font-size: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `'styles/colors.css'`,
	"gtRed": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]}`,
	"gtRedDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRedDark"]}`,
	"gtRedLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRedLight"]}`,
	"gtYellow": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]}`,
	"gtYellowDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowDark"]}`,
	"gtYellowLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]}`,
	"checkbox": `styles__checkbox____zIIc`,
	"localHeader": `styles__localHeader___y_Wle ${___CSS_LOADER_ICSS_IMPORT_1___.locals["localHeader"]}`,
	"localHeaderInner": `styles__localHeaderInner___sPXIW ${___CSS_LOADER_ICSS_IMPORT_1___.locals["localHeaderInner"]}`,
	"contentContainer": `styles__contentContainer___f6WpM ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"section": `styles__section___Bm8P8 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`,
	"header": `styles__header___KW6mo`,
	"halfWidth": `styles__halfWidth___FsKcb`,
	"form": `styles__form___uDbVN`,
	"formItem": `styles__formItem___Lfm2O`,
	"label": `styles__label___Y9RoA ${___CSS_LOADER_ICSS_IMPORT_1___.locals["label"]}`,
	"input": `styles__input___M5oD1 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["input"]}`,
	"warning": `styles__warning___whfkM ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`,
	"danger": `styles__danger___VZEU2 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`
};
export default ___CSS_LOADER_EXPORT___;
