// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TrusteeReceiptReport__body___BY_g_ {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.TrusteeReceiptReport__body___BY_g_ > * {
    margin-bottom: 20px;
  }

.TrusteeReceiptReport__excludeEmailed___KtXWA {
  margin-top: 20px;
}

.TrusteeReceiptReport__excludeEmailed___KtXWA label {
    margin-left: 10px;
  }

.TrusteeReceiptReport__printPage___hB8kC:last-child {
  page-break-before: always;
}

.TrusteeReceiptReport__printPage___hB8kC {
  display: flex;
  justify-content: space-around;
  page-break-after: always;
}

@media print {
  .TrusteeReceiptReport__printPage___hB8kC * {
    border: none;
    box-shadow: none;
  }

  * {
    transition: none !important;
  }

  .TrusteeReceiptReport__noPrint___LRskN,
  .TrusteeReceiptReport__noPrint___LRskN * {
    display: none;
  }
}

.TrusteeReceiptReport__form___f_PPa {
  width: 50%;
  margin: 0 auto;
}

.TrusteeReceiptReport__formItemMono___H_Rb0 {
}

.TrusteeReceiptReport__label___QldpA {
}

.TrusteeReceiptReport__input___ou0Gs {
}

.TrusteeReceiptReport__rowWrapper___w_TzI {
  display: inline-block;
}

.TrusteeReceiptReport__rowWrapper___w_TzI .TrusteeReceiptReport__row___S1XrA {
    display: flex;
    flex-direction: row;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

.TrusteeReceiptReport__col___d837l {
  display: flex;
  flex-direction: column;
}

.TrusteeReceiptReport__sign___ZMscG {
  align-self: flex-end;
  padding: 0 10px 5px;
}

.TrusteeReceiptReport__metric___RuZJw {
  padding-bottom: 10px;
}

.TrusteeReceiptReport__metric___RuZJw:last-of-type {
  padding-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/TrusteeReceiptReport/TrusteeReceiptReport.css"],"names":[],"mappings":"AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,cAAc;AAKhB;;AAHE;IACE,mBAAmB;EACrB;;AAGF;EACE,gBAAgB;AAKlB;;AAHE;IACE,iBAAiB;EACnB;;AAGF;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,wBAAwB;AAC1B;;AAEA;EACE;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IAEE,2BAA2B;EAC7B;;EAEA;;IAEE,aAAa;EACf;AACF;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EACE,qBAAqB;AAOvB;;AALE;IACE,aAAa;IACb,mBAAmB;IACnB,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;EACpB;;AAGF;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.body {\n  max-width: 1440px;\n  width: 100%;\n  margin: 0 auto;\n\n  > * {\n    margin-bottom: 20px;\n  }\n}\n\n.excludeEmailed {\n  margin-top: 20px;\n\n  label {\n    margin-left: 10px;\n  }\n}\n\n.printPage:last-child {\n  page-break-before: always;\n}\n\n.printPage {\n  display: flex;\n  justify-content: space-around;\n  page-break-after: always;\n}\n\n@media print {\n  .printPage * {\n    border: none;\n    box-shadow: none;\n  }\n\n  * {\n    -webkit-transition: none !important;\n    transition: none !important;\n  }\n\n  .noPrint,\n  .noPrint * {\n    display: none;\n  }\n}\n\n.form {\n  width: 50%;\n  margin: 0 auto;\n}\n\n.formItemMono {\n  composes: formItemMono from helpers;\n}\n\n.label {\n  composes: label from helpers;\n}\n\n.input {\n  composes: input from helpers;\n}\n\n.rowWrapper {\n  display: inline-block;\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    width: fit-content;\n  }\n}\n\n.col {\n  display: flex;\n  flex-direction: column;\n}\n\n.sign {\n  align-self: flex-end;\n  padding: 0 10px 5px;\n}\n\n.metric {\n  padding-bottom: 10px;\n}\n\n.metric:last-of-type {\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"body": `TrusteeReceiptReport__body___BY_g_`,
	"excludeEmailed": `TrusteeReceiptReport__excludeEmailed___KtXWA`,
	"printPage": `TrusteeReceiptReport__printPage___hB8kC`,
	"noPrint": `TrusteeReceiptReport__noPrint___LRskN`,
	"form": `TrusteeReceiptReport__form___f_PPa`,
	"formItemMono": `TrusteeReceiptReport__formItemMono___H_Rb0 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItemMono"]}`,
	"label": `TrusteeReceiptReport__label___QldpA ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`,
	"input": `TrusteeReceiptReport__input___ou0Gs ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"rowWrapper": `TrusteeReceiptReport__rowWrapper___w_TzI`,
	"row": `TrusteeReceiptReport__row___S1XrA`,
	"col": `TrusteeReceiptReport__col___d837l`,
	"sign": `TrusteeReceiptReport__sign___ZMscG`,
	"metric": `TrusteeReceiptReport__metric___RuZJw`
};
export default ___CSS_LOADER_EXPORT___;
