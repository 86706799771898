import rollbar from './rollbar.umd.nojson.min'

export default rollbar.init({
  accessToken: __ROLLBAR_TOKEN__,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: __PROD__ ? 'production' : 'development',
  }
})
