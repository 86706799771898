// @flow
import React from 'react'
import { StatelessHeader } from './component'

type Props = {
  isCoTrustee: boolean,
  isAuditor: boolean,
  isLoggedIn: boolean,
}

class Header extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      menuActive: false,
    }
  }

  toggleMenu = () => {
    this.setState({ menuActive: !this.state.menuActive })
  }
  render() {
    return (
      <StatelessHeader
        isCoTrustee={this.props.isCoTrustee}
        isAuditor={this.props.isAuditor}
        isLoggedIn={this.props.isLoggedIn}
        menuActive={this.state.menuActive}
        toggleMenu={this.toggleMenu}
      />
    )
  }
}

export default Header
