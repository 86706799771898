// @flow
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'lib/hooks/withRouter'
import Header from 'components/Header'
import { Banner, Loader } from 'components/Notifications'
import { Modal } from 'components/Modal'
import { isLoggedIn, isCoTrustee, isAuditor } from 'lib/user/selectors'
import styles from './styles'
import 'styles/vendor/normalize'
import 'styles/core'
import 'styles/fonts/icomoon'
import 'styles/fonts/ico-feather'
import 'styles/type'
import 'styles/helpers'

type Props = {
  children: Element,
  isLoggedIn: boolean,
  isCoTrustee: boolean,
  isAuditor: boolean,
  location: Object,
}

const CoreLayout = (props) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (props.location.location.pathname === '/') {
      navigate('/contacts')
    }
  }, [])
  return (
    <div className={styles.mainContainer}>
      <Header {...props} />
      <Banner />
      <div>{props.children}</div>
      <Modal />
      <Loader />
      <Outlet />
    </div>
  )
}

export default withRouter(
  connect(
    createStructuredSelector({
      isLoggedIn,
      isCoTrustee,
      isAuditor,
    }),
  )(CoreLayout),
)
