// @flow
import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles'

type Props = {
  callback: Function,
  contact: Object,
  fieldName: string,
  label: string,
  testHook: string,
};

const ContactPicker = (props: Props) => {
  const {
    callback,
    contact,
    fieldName,
    label,
    testHook
  } = props

  let editButton
  let selectText = `Select ${label}`

  if (contact) {
    selectText = `Change ${label}`
    editButton = (
      <Link
        to={`/contacts/${contact.id}/edit`}
        className={styles.buttonSecondary}
      >Edit Contact</Link>
    )
  }

  return (
    <section className={styles.renderButtons}>
      <div className={styles.formItem}>
        <div>
          <label className={styles.label}>{label}</label>
        </div>
        <input
          className={styles.buttonLocal}
          id={testHook}
          name={fieldName}
          onClick={callback}
          type='button'
          value={selectText}
        />
        {editButton}
      </div>
    </section>
  )
}

export default ContactPicker
