// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import formatters from 'lib/formatters'
import type { Contact } from 'lib/types'
import Row from './Row'
import styles from './styles'


type Props = {
  contact: Contact,
};

class ContactModalRef extends React.Component {
  constructor(props) {
    super(props)
  }

  props: Props

  _addInfo() {
    const { contact } = this.props
    let accounts = []
    let roles = []

    contact.able_for_gpt.map((account, idx) => {
      const { number } = account
      accounts.push(
        <span key={`able${idx}`}>{number} </span>
      )
      if (roles.indexOf('Able ') < 0) {
        roles.push(
          'Able '
        )
      }

    })
    contact.beneficiary_of_gpt.map((account, idx) => {
      const { number } = account
      accounts.push(
        <span key={`bene${idx}`}>{number} </span>
      )
      if (roles.indexOf('Beneficiary ') < 0) {
        roles.push(
          'Beneficiary '
        )
      }
    })
    contact.attorney_for_gpt.map((account, idx) => {
      const { number } = account
      accounts.push(
        <span key={`att${idx}`}>{number} </span>
      )
      if (roles.indexOf('Attorney ') < 0) {
        roles.push(
          'Attorney '
        )
      }
    })
    contact.consult_for_gpt.map((account, idx) => {
      const { number } = account
      accounts.push(
        <span key={`cons${idx}`}>{number} </span>
      )
      if (roles.indexOf('Consult ') < 0) {
        roles.push(
          'Consult '
        )
      }
    })
    const grantors = contact.grantor_for_gpt.concat(contact.co_grantor_for_gpt)
    grantors.map((account, idx) => {
      const { number } = account
      accounts.push(
        <span key={`gran${idx}`}>{number} </span>
      )
      if (roles.indexOf('Grantor ') < 0) {
        roles.push(
          'Grantor '
        )
      }
    })
    contact.referrer_for_gpt.map((account, idx) => {
      const { number } = account
      accounts.push(
        <span key={`ref${idx}`}>{number} </span>
      )
      if (roles.indexOf('Referrer ') < 0) {
        roles.push(
          'Referrer '
        )
      }
    })

    if (accounts.length === 0) {
      accounts = null
    }
    if (roles.length === 0) {
      roles = null
    }

    return (
      <div className={styles.modal}>
        <div className={styles.userInfo}>
          <Row
            label='Contact ID'
            val={contact.id}
            mono={true}
          />
          <Row
            label='DOB'
            val={formatters.date(contact.dob)}
            mono={true}
          />
        </div>
        <div className={styles.accountsInfo}>
          <Row
            label='Accounts'
            val={accounts}
          />
          <Row
            label='Roles'
            val={roles}
          />
        </div>
        <div className={styles.addressInfo}>
          <Row
            label='Email'
            val={contact.email}
          />
          <Row
            label="Address"
            val={formatters.address(contact)}
          />
        </div>
      </div>
    )
  }

  render() {
    const { contact } = this.props
    if (!contact) return null
    const editLink = (
      <Link
        to={`/contacts/${contact.id}/edit`}
        className={styles.refEdit}
      ></Link>
    )
    const renderTitle = (
      <div className={styles.titleSection}>
        <div className={styles.title}>
          <div className={styles.name}>{contact.name}</div>
        </div>
        {editLink}
      </div>
    )
    return (
      <section className={styles.collapseRef} data-active={true}>
        {renderTitle}
        {this._addInfo()}
      </section>
    )
  }
}

export default ContactModalRef
