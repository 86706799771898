// @flow
import React from 'react'
import { connect } from 'react-redux'
import type { Form } from 'lib/types'
import { httpGet } from 'lib/http'
import { Template } from './Template'
import { setModal } from 'lib/modal/actions'
import LineItemForm from 'components/Forms/LineItem'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  form: Form,
  location: Object,
}

class Subaccount extends React.Component {
  props: Props

  constructor(props) {
    super(props)

    this.state = {
      attachments: [],
      balance: null,
      beneficiary_name: '',
      lineItems: [],
      notes: [],
      subaccount_type: null,
      open_date: null,
    }
  }

  componentDidMount() {
    this.fetchDetails()
  }

  openNewLineItemModal = () => {
    const { number, subnumber } = this.props.location.params
    this.props.dispatch(
      setModal(
        <LineItemForm
          number={number}
          subnumber={subnumber}
          afterCreate={this.loadDetails}
        />,
      ),
    )
  }

  fetchDetails = () => {
    const { number, subnumber } = this.props.location.params

    const url = `/admin/accounts/${number}/subaccounts/${subnumber}`
    httpGet(url).then((data) => {
      this.loadDetails(data)
    })
  }

  loadDetails = ({ subaccount }) => {
    const {
      attachments,
      balance,
      beneficiary_name,
      line_items,
      notes,
      open_date,
      subaccount_type,
    } = subaccount

    this.setState({
      attachments: attachments,
      balance: balance,
      beneficiary_name: beneficiary_name,
      lineItems: line_items,
      notes: notes,
      subaccount_type: subaccount_type,
      open_date: open_date,
    })
  }

  render() {
    const { location, dispatch } = this.props
    const { number, subnumber } = location.params

    return (
      <Template
        attachments={this.state.attachments}
        balance={this.state.balance}
        beneficiaryName={this.state.beneficiary_name}
        lineItems={this.state.lineItems}
        notes={this.state.notes}
        number={number}
        open_date={this.state.open_date}
        subaccount_type={this.state.subaccount_type}
        subnumber={subnumber}
        dispatch={dispatch}
        openNewLineItemModal={this.openNewLineItemModal}
        onSuccess={this.fetchDetails}
      />
    )
  }
}

export default withRouter(connect()(Subaccount))
