// @flow
import React from 'react'
import { connect } from 'react-redux'
import Error from 'components/Error'
import { TrustInput } from 'components/TrustInput'
import { DateRangeSelection } from 'components/DateRangeSelection'
import type { Account } from 'lib/types'
import { httpGet } from 'lib/http'
import { setLoad, setUnload } from 'lib/notification/actions'
import { rateLimitMessage } from 'lib/rateLimit'
import moment from 'moment'
import { Template } from './Template'
import styles from './styles'

type Props = {
  dispatch: Function,
}

type State = {
  account: ?Account,
  journalId?: string,
  rateLimitProblem: ?string,
  dateStart: Object,
  dateEnd: Object,
}

class GPTMaster extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const defaultEnd = moment()
    const defaultStart = moment().add(-14, 'd')

    this.state = {
      account: null,
      rateLimitProblem: null,
      dateStart: defaultStart.format('YYYY-MM-DD'),
      dateEnd: defaultEnd.format('YYYY-MM-DD'),
    }
  }

  fetchAccount = () => {
    const { dispatch } = this.props
    const { journalId, dateStart, dateEnd } = this.state
    dispatch(setLoad())

    const url =
      '/admin/accounts/gpt-master?' +
      `journal_id=${journalId}&dateStart=${dateStart}&dateEnd=${dateEnd}`

    httpGet(url)
      .then((data: Account) => {
        this.setState({ account: data, rateLimitProblem: null })
      })
      .catch((error) => {
        const { code, unit } = error
        if (code === 598) {
          this.setState({ rateLimitProblem: unit })
        } else {
          throw error
        }
      })
      .finally(() => dispatch(setUnload()))
  }

  validDates = (dateStart, dateEnd) => {
    const momentStart = moment(dateStart)
    const momentEnd = moment(dateEnd)
    const tomorrow = moment().add(1, 'd')

    if (
      momentStart.isValid() &&
      momentEnd.isValid() &&
      momentEnd.isBefore(tomorrow)
    ) {
      return momentStart.isBefore(momentEnd) || momentStart.isSame(momentEnd)
    } else {
      false
    }
  }

  evalFetchAccount = () => {
    const { journalId = '', dateStart, dateEnd } = this.state

    if (journalId.length > 0 && this.validDates(dateStart, dateEnd)) {
      this.fetchAccount()
    }
  }

  handleInputChange = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { name, value } = event.currentTarget
      ? event.currentTarget
      : { name: event.name, value: event.value.value }
    this.setState({ [name]: value }, this.evalFetchAccount)
  }

  renderTemplate = () => {
    const { account, journalId } = this.state

    if (!account) return null

    return <Template account={account} journalId={journalId} />
  }

  render() {
    const { journalId = '', rateLimitProblem, dateStart, dateEnd } = this.state
    if (rateLimitProblem) {
      return (
        <Error
          message={rateLimitMessage(rateLimitProblem)}
          title="Rate Limit Exceeded"
        />
      )
    }

    return (
      <div>
        <div className={styles.trustInputContainer}>
          <h3 className="pageTitle">Master</h3>
          <div className={`${styles.sectionSmall}`}>
            <TrustInput value={journalId} onChange={this.handleInputChange} />
            <div className={styles.dateRangePicker}>
              <DateRangeSelection
                dateStart={dateStart}
                dateEnd={dateEnd}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>
        {this.renderTemplate()}
      </div>
    )
  }
}

export default connect()(GPTMaster)
