// @flow
import React from 'react'
import { connect } from 'react-redux'
import {
  fetchUser,
  saveUser,
  toggleUserRole,
  updateUser,
} from 'lib/user/actions'
import { getUser } from 'lib/user/selectors'
import { Table, Row, Cell } from 'components/Table'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import styles from './styles'
import { withRouter } from 'lib/hooks/withRouter'
import { Link, useNavigate } from 'react-router-dom'

type Props = {
  canSubmit: Function,
  dispatch: Function,
  id: Number,
  onAttrChange: Function,
  onRoleToggle: Function,
  onUserSubmit: Function,
  onSubmit: Function,
  location: Object,
  roles: [Object],
  user: Object,
}

const UserComponent = ({ user, onAttrChange }: Props) => {
  return (
    <div>
      <section className={styles.accountExtrasSection}>
        <form>
          <div className={styles.section}>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="first_name">
                First Name
              </label>
              <input
                className={styles.input}
                type="text"
                name="first_name"
                value={user.first_name}
                onChange={onAttrChange}
              />
            </div>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="last_name">
                Last Name
              </label>
              <input
                className={styles.input}
                type="text"
                name="last_name"
                value={user.last_name}
                onChange={onAttrChange}
              />
            </div>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="email">
                Email
              </label>
              <div className={styles.emailGroup}>
                <input
                  className={styles.input}
                  type="text"
                  name="email"
                  value={user.email}
                  onChange={onAttrChange}
                />
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  )
}
const mapUserStateToProps = (state) => {
  let user = getUser(state)
  return { user }
}
const mapDispatchToUserProps = (dispatch: Props) => {
  return {
    onAttrChange: (event) => {
      dispatch(updateUser(event.target.name, event.target.value))
    },
  }
}
const UserContainer = connect(
  mapUserStateToProps,
  mapDispatchToUserProps,
)(UserComponent)

const RolesComponent = ({ roles, onRoleToggle }: Props) => {
  const roleTypes = roles.map((r) => r.type)

  const roleCheckbox = (role) => (
    <input
      className={styles.checkbox}
      id={role}
      name={role}
      type="checkbox"
      checked={roleTypes.includes(role)}
      onClick={() => onRoleToggle(role)}
    />
  )

  const rolePairs = [
    { desc: 'Admin', role: 'admin' },
    { desc: 'Senior Admin', role: 'senior_admin' },
    { desc: 'Signer', role: 'signer' },
    {
      desc: 'Receives account creation bell notifications',
      role: 'account_creation_notification_recipient',
    },
    {
      desc: 'Receives emails listing negative accounts on allocations',
      role: 'allocation_email_recipient',
    },
    {
      desc: 'Receives annual fee assessment emails',
      role: 'annual_fee_email_recipient',
    },
    {
      desc: 'Receives deposit bell notifications',
      role: 'deposit_notification_recipient',
    },
    {
      desc: 'Non-attorney staff who may review potential clients',
      role: 'potential_client_reviewer',
    },
    { desc: 'Co-Trustee', role: 'co_trustee' },
    { desc: 'Attorney', role: 'attorney' },
    { desc: 'Auditor', role: 'auditor' },
  ]

  return (
    <div>
      <Table
        headers={['Role', 'Authorized']}
        title="Authorized Roles"
        tableSize="infinite"
        tableType="read"
      >
        {rolePairs.map((pair, idx) => (
          <Row key={`role${idx}`}>
            <Cell value={pair.desc} />
            <Cell value={roleCheckbox(pair.role)} />
          </Row>
        ))}
      </Table>
    </div>
  )
}
const mapStateToRolesProps = (state) => {
  const user = getUser(state)
  let roles = []

  if (user && user.roles) {
    roles = user.roles
  }

  return { roles }
}
const mapDispatchToRolesProps = (dispatch: Props) => {
  return {
    onRoleToggle: (role) => {
      dispatch(toggleUserRole(role))
    },
  }
}
const RolesContainer = connect(
  mapStateToRolesProps,
  mapDispatchToRolesProps,
)(RolesComponent)

const SaveButtonComponent = ({ user, onSubmit }: Props) => {
  const navigate = useNavigate()
  return (
    <div>
      <button
        className={styles.saveButton}
        type="submit"
        onClick={() => onSubmit(user, navigate)}
      >
        Save Changes
      </button>
    </div>
  )
}
const mapStateToSaveProps = (state) => {
  const user = getUser(state)

  return {
    user: user,
  }
}
const mapDispatchToSaveProps = (dispatch: Props) => {
  return {
    onSubmit: (params, navigate) => {
      dispatch(saveUser(params, navigate))
    },
  }
}
const SaveButtonContainer = connect(
  mapStateToSaveProps,
  mapDispatchToSaveProps,
)(SaveButtonComponent)

const backLink = <Link to={'/cotrustee/users'}>Back to users</Link>

const Edit = () => (
  <div>
    <Breadcrumbs crumbs={[backLink]} />
    <section className={styles.contentContainer}>
      <div className={styles.row}>
        <section className={styles.formWrapper}>
          <div className={styles.headerSection}>
            <h1 className={styles.headerTitle}>User Edit</h1>
          </div>
          <UserContainer />
          <RolesContainer />
          <div className={styles.navigationSave}>
            <SaveButtonContainer />
          </div>
        </section>
      </div>
    </section>
  </div>
)

class EditContainer extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  props: Props

  componentDidMount() {
    const { dispatch, location } = this.props
    const { id } = location.params
    dispatch(fetchUser(id))
  }

  render() {
    return <Edit />
  }
}

export default withRouter(connect()(EditContainer))
