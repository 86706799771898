// @flow
import { KEY } from './constants'

export const email = (state: Object) => state[KEY].email
export const isCoTrustee = (state: Object) => state[KEY].isCoTrustee
export const isAuditor = (state: Object) => state[KEY].isAuditor
export const isLoggedIn = (state: Object) => state[KEY].isLoggedIn

export const getNewUser =
  (state: Object) => state[KEY].newUser

export const getUser =
  (state: Object) => state[KEY].user

export const getUsers =
  (state: Object) => state[KEY].users
