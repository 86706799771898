import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const searchParams = useSearchParams()
    const [query, setQuery] = searchParams
    const navigate = useNavigate()
    const params = useParams()

    return (
      <Component
        {...props}
        location={{ location, query, setQuery, navigate, params }}
      />
    )
  }

  return ComponentWithRouterProp
}
