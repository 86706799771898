// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__formItemHalf___l_plW {
}

.styles__warning___xhEen {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]};
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowDark"]};
  font-size: 90%;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Closings/components/Edit/AttachmentForm/styles.css"],"names":[],"mappings":"AAIA;AAEA;;AAEA;EACE,8DAA+B;EAC/B,8DAA0B;EAC1B,mDAAmB;EAEnB,cAAc;AAChB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtYellow, gtYellowDark, gtYellowLight from colors;\n\n.formItemHalf {\n  composes: formItemHalf from helpers;\n}\n\n.warning {\n  background-color: gtYellowLight;\n  border: 1px solid gtYellow;\n  color: gtYellowDark;\n  composes: section from helpers;\n  font-size: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtYellow": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]}`,
	"gtYellowDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowDark"]}`,
	"gtYellowLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]}`,
	"formItemHalf": `styles__formItemHalf___l_plW ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItemHalf"]}`,
	"warning": `styles__warning___xhEen ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`
};
export default ___CSS_LOADER_EXPORT___;
