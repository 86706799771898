// @flow
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './styles'

type Props = {
  field?: string,
  canCopy?: boolean,
  copyField?: Function,
  error?: string | null,
  label: string,
  mono?: boolean,
  val?: string | null,
  secondaryVal?: string | null,
  status?: string,
}

const MergeContactField = ({
  field,
  canCopy,
  copyField,
  error,
  mono,
  status,
  val,
  secondaryVal,
  label,
}: Props) => {
  const hasVal = val === undefined ? false : true
  const hasSecondaryVal = secondaryVal === undefined ? false : true
  const valsMatch = val === secondaryVal

  let rowStyle = styles.row

  if (error) {
    rowStyle = styles.rowError
    label = (
      <div>
        {label}&ensp;&middot;&ensp;<span className={styles.error}>{error}</span>
      </div>
    )
  }

  if (valsMatch) {
    if (val !== null && val !== undefined) {
      rowStyle += ` ${styles.match}`
    }
  } else if (hasSecondaryVal) {
    rowStyle += ` ${styles.noMatch}`
  }

  let valueStyle = styles.valueBlank

  if (hasVal && val !== null) {
    valueStyle = styles.value
    if (mono) {
      valueStyle = styles.valueMono
    } else if (status) {
      valueStyle = styles[status]
    }
  } else {
    val = '-'
  }

  valueStyle += ` ${styles.value}`

  const renderCopy = () => {
    return (
      canCopy &&
      hasSecondaryVal &&
      !valsMatch && (
        <div className={styles.copy}>
          <span>Copy From Secondary</span>
          <button
            onClick={() => copyField({ field, secondaryVal })}
            type="button"
            className={styles.copyBtn}
          >
            <FontAwesomeIcon icon={['far', 'clone']} size="lg" />
          </button>
        </div>
      )
    )
  }

  return (
    <div className={rowStyle}>
      <div className={styles.label}>
        <span>{label}</span>
        {renderCopy()}
      </div>
      <div className={valueStyle}>{val}</div>
    </div>
  )
}

export default MergeContactField
