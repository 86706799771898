// @flow
import React from 'react'
import FormButtons from 'components/FormButtons'
import { httpPost, httpPut } from 'lib/http'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import type { Form } from 'lib/types'
import moment from 'moment'
import dateUtil from 'lib/dateUtil'
import styles from './styles'

type Props = {
  accountNumber: number,
  dispatch: Function,
  event: string, // canned event if not custom
  eventObject: Object, // if editing/updating
  form: Form,
}

type State = {
  event: string,
  date: Date,
  date_due: Date,
  date_completed: Date,
  notes: string
}

class ClosingEventForm extends React.Component<Props, State> {
  props: Props

  _setToday = () => {
    const today = moment().format('YYYY-MM-DD')
    return today
  }

  state = {
    event:
      this.props.eventObject ? this.props.eventObject.event : this.props.event,
    date:
      this.props.eventObject ? this.props.eventObject.date : this._setToday(),
    date_due:
      this.props.eventObject ? this.props.eventObject.date_due : '',
    date_completed:
      this.props.eventObject ? this.props.eventObject.date_completed : '',
    notes:
      this.props.eventObject ? this.props.eventObject.notes : ''
  }

  _wrapSubmit = () => (event) => {
    event.preventDefault()

    if (this.props.eventObject) {
      this._onUpdateSubmit()
    } else {
      this._onCreateSubmit()
    }
  }

  _onCreateSubmit = () => {
    const { accountNumber, dispatch } = this.props
    const { event, date, date_due, date_completed, notes } = this.state

    dispatch(setLoad())

    return httpPost(
      `/admin/closings/${accountNumber}/events`,
        { event, date, date_due, date_completed, notes }
    ).then(() => {
      window.location.reload(false)
      dispatch(setBanner('Closing event added'))
    })
    .catch(() => {
      dispatch(setBanner('Closing event error', 'danger'))
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
  }

  _onUpdateSubmit = () => {
    const { accountNumber, dispatch, eventObject } = this.props
    const { event, date, date_due, date_completed, notes } = this.state

    dispatch(setLoad())

    return httpPut(
      `/admin/closings/${accountNumber}/events/${eventObject.id}`,
        { event, date, date_due, date_completed, notes }
    ).then(() => {
      window.location.reload(false)
      dispatch(setBanner('Closing event added'))
    })
    .catch(() => {
      dispatch(setBanner('Closing event error', 'danger'))
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
  }

  _handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  _canSubmit = () => {
    return this.state.event && !this.state.event == ''
  }

  _headerText = (
    this.props.eventObject ? 'Edit Closing Event' : 'Add Closing Event'
  )

  _dateDueLabelStyle = () => {
    if (dateUtil.isDateBeforeToday(this.state.date_due)) {
      if (!this.state.date_completed) {
        return styles.pastDueDateInput
      }
    }

    return styles.input
  }

  render() {
    return (
      <section>
        <h4 className={styles.section}>{this._headerText}</h4>
        <form onSubmit={this._wrapSubmit()}>
          <div className={styles.section}>
            <div className={styles.inputLabelGroup}>
              <label className={styles.label} htmlFor="event">
                Event Description *
              </label>
              <input
                className={styles.input}
                id="event"
                name="event"
                type="text"
                onChange={this._handleChange}
                value={this.state.event}
              />
            </div>
            <div className={styles.dateBlock} >
              <label className={styles.label} htmlFor="date">
                Date *
              </label>
              <div className={styles.formItemQuarter}>
                <input
                  className={styles.input}
                  id="date"
                  name="date"
                  onChange={this._handleChange}
                  type="date"
                  value={this.state.date}
                />
              </div>
              <label className={styles.label} htmlFor="date_due">
                Due Date
              </label>
              <div className={styles.formItemQuarter}>
                <input className={this._dateDueLabelStyle()}
                  id="date_due"
                  name="date_due"
                  onChange={this._handleChange}
                  type="date"
                  value={this.state.date_due || ''}
                />
              </div>
              <label className={styles.label} htmlFor="date_completed">
                Date Completed
              </label>
              <div className={styles.formItemQuarter}>
                <input
                  className={styles.input}
                  id="date_completed"
                  name="date_completed"
                  onChange={this._handleChange}
                  type="date"
                  value={this.state.date_completed || ''}
                  disabled={!this.state.date_due}
                />
              </div>
              <div className={styles.lastBlock}>
              </div>
            </div>
            <div className={styles.inputLabelGroup}>
              <label className={styles.label} htmlFor="notes">
                Notes
              </label>
              <textarea
                className={styles.input}
                id="notes"
                name="notes"
                onChange={this._handleChange}
                value={this.state.notes || ''}
              />
            </div>
          </div>
          <FormButtons
            submitLabel={'Submit'}
            disabled={!this._canSubmit()}
          />
        </form>
      </section>
    )
  }
}

export default ClosingEventForm
