// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Autocomplete__autocompleteContainer___qVltc {
  position: relative;
  width: 100%;
}

.Autocomplete__autoCompleteInputContainer___SagFm {
  display: flex;
}

.Autocomplete__autoCompleteInput___Yi8FD {
  background: white;
  box-sizing: border-box;
  height: 52px;
  border: 1px solid rgba(195, 195, 195, 0.45);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #FFF;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  margin-right: -2px;
  padding-left: 8px;
}

.Autocomplete__autoCompleteInput___Yi8FD input {
    background: transparent;
    border: none;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 8px;
  }

.Autocomplete__autoCompleteInputBtn___iLItw {
  font-size: 15px;
  font-weight: 700;
  width: 218px;
  height: 51px;
  border-radius: 3px;
  background-color: #376EA1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  color: white;
  border: none;
}

.Autocomplete__autocomplete_resultContainer___UNkwd {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  background: white;
  z-index: 100;
}

.Autocomplete__autoCompleteItems_clearIcon___br5bZ {
  margin: 0 10px;
  width: 20px;
  height: 20px;
  color: #ccc;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Contacts/components/Search/Autocomplete/Autocomplete.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,YAAY;EACZ,2CAA2C;EAC3C,2BAA2B;EAC3B,8BAA8B;EAC9B,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AASnB;;AAPE;IACE,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,gBAAgB;EAClB;;AAGF;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,2CAA2C;EAC3C,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,WAAW;EACX,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,WAAW;EACX,eAAe;AACjB","sourcesContent":[".autocompleteContainer {\n  position: relative;\n  width: 100%;\n}\n\n.autoCompleteInputContainer {\n  display: flex;\n}\n\n.autoCompleteInput {\n  background: white;\n  box-sizing: border-box;\n  height: 52px;\n  border: 1px solid rgba(195, 195, 195, 0.45);\n  border-top-left-radius: 3px;\n  border-bottom-left-radius: 3px;\n  background-color: #FFF;\n  display: flex;\n  flex-grow: 1;\n  flex-shrink: 1;\n  align-items: center;\n  margin-right: -2px;\n  padding-left: 8px;\n\n  input {\n    background: transparent;\n    border: none;\n    flex-grow: 1;\n    flex-shrink: 1;\n    margin-left: 8px;\n  }\n}\n\n.autoCompleteInputBtn {\n  font-size: 15px;\n  font-weight: 700;\n  width: 218px;\n  height: 51px;\n  border-radius: 3px;\n  background-color: #376EA1;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);\n  color: white;\n  border: none;\n}\n\n.autocomplete_resultContainer {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n  width: 100%;\n  background: white;\n  z-index: 100;\n}\n\n.autoCompleteItems_clearIcon {\n  margin: 0 10px;\n  width: 20px;\n  height: 20px;\n  color: #ccc;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autocompleteContainer": `Autocomplete__autocompleteContainer___qVltc`,
	"autoCompleteInputContainer": `Autocomplete__autoCompleteInputContainer___SagFm`,
	"autoCompleteInput": `Autocomplete__autoCompleteInput___Yi8FD`,
	"autoCompleteInputBtn": `Autocomplete__autoCompleteInputBtn___iLItw`,
	"autocomplete_resultContainer": `Autocomplete__autocomplete_resultContainer___UNkwd`,
	"autoCompleteItems_clearIcon": `Autocomplete__autoCompleteItems_clearIcon___br5bZ`
};
export default ___CSS_LOADER_EXPORT___;
