import { Notes } from 'components/Notes'
import formatters from 'lib/formatters'
import { httpDelete, httpGet, httpPost, httpPut } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { encodedData } from '../../../../../lib/encrypt.js'
import Dialogs from '../dialogs/component.js'
import ActionsNeeded from '../Footer/ActionsNeeded.js'
import MainCtaFooter from '../Footer/MainCtaFooter.js'
import { PendingRequest } from '../Footer/PendingRequest.js'
import SectionHeader from '../Header/SectionHeader.js'
import DrfHeader from '../HeaderStrip/DrfHeader.js'
import { DeniedSection } from '../modals/ModalSection'
import {
  areAllRequiredActionsCompleted,
  denied,
  getCalculation,
  getColor,
  getHeader,
  getStatusBasedText,
  isMainQueue,
  main,
  pending,
} from './configuration.js'
import { ActionsEnum, DRF_TYPE, recurringStatus } from './constant.js'
import ReviewForm from './forms/ReviewForm.js'
import WriteFormOne from './forms/WriteFormOne.js'
import WriteFormTwo from './forms/WriteFormTwo.js'
import styles from './styles'
import ViewDrf from './view/component.js'
import RequestDocument from './forms/RequestDocument.js'
import { unsetModal } from 'lib/modal/actions'

const DistributionMain = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = params.id
  const [formInit, setFormInit] = useState(null)
  const [formInitPendingDistribution, setFormInitPendingDistribution] = useState(null)
  const [form, setForm] = useState({
    account: '',
    accountNumber: '',
    accountContacts: [],
    amount: '',
    // checkNumber: '',
    dateReceived: '',
    description: '',
    expeditedPaymentNeeded: '',
    expeditedDateNeeded: '',
    events: [],
    journalName: '',
    instructions: '',
    notes: [],
    paymentType: '',
    paymentSubtype: '',
    xeroPayTo: {},
    defaultXeroPayTo: {},
    xeroLink: '',
    // reference: '',
    requestType: '',
    requestorId: '',
    recurringDistribution: {
      dayRepeats: '',
      startMonth: '',
      endDate: '',
      period: 'weekly',
      requested: false,
      scheduledDistributions: [],
      targetDay: '',
    },
    shortCode: '',
    otherDistributions: null,
    expeditedMailing: false,
    mailingFeeAssessed: false,
    templateId: null,
    inSecondSigner: false,
  })

  const [files, setFiles] = useState([])
  const dispatch = useDispatch()
  const [currentStatus, setCurrentStatus] = useState('')
  const [step, setStep] = useState(0)
  const [isApproved, setIsApproved] = useState(null)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showUnableToProcess, setShowUnableToProcess] = useState(false)
  const [approveButtonText, setApproveButtonText] = useState('')
  const [disapproveButtonText, setDisapproveButtonText] = useState('Unable to Process at this Time')
  const [headerTitle, setHeaderTitle] = useState('')
  const [currentDrfRecurring, setCurrentDrfRecurring] = useState(false)
  const [recurringFormDisabled, setRecurringFormDisabled] = useState(false)
  const [xeroLink, setXeroLink] = useState('')
  const [invoiceXeroLink, setInvoiceXeroLink] = useState(null)
  const [expeditedDate, setExpeditedDate] = useState(null)
  const [moveToPending, setMoveToPending] = useState(false)
  const [moveToDenied, setMoveToDenied] = useState(false)
  const [clientSelect, setClientSelect] = useState([])
  const [deniedReasonSelect, setDeniedReasonSelect] = useState([])
  const [trustSelect, setTrustSelect] = useState()
  const [formDisabled, setFormDisabled] = useState(false)
  const [daysInPending, setDaysInPending] = useState(null)
  const [nextStatus, setNextStatus] = useState('')
  const [color, setColor] = useState('cyan')
  const [events, setEvents] = useState([])
  // Actions
  const [requiredActions, setRequiredActions] = useState([])
  const [completedActions, setCompletedActions] = useState([])
  const [lockedForEdit, setLockedForEdit] = useState(null)
  const [stepper, setStepper] = useState({
    title: null,
    stepper: {
      steps: null,
      activeStep: 0,
    },
    id: id,
    idTitle: 'ID',
  })
  const [accounts, setAccounts] = useState([])
  const [checkingBalance, setCheckingBalance] = useState('')
  const [endDate, setEndDate] = useState(null)
  const [edit, setEdit] = useState(true)

  const [calculation, setCalculation] = useState({ message: null, styles: null })

  // standard, template, scheduled
  const [drfType, setDrfType] = useState('standard')
  const [payNowRecurring, setPayNowRecurring] = useState(false)

  const updateForm = (e) => {
    if (e.target.name !== 'amount') {
      e.persist()
    }
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const getXeroLink = (id) => {
    const journalName = form?.account?.journalName
    httpGet(`/admin/xero-link?journalName=${journalName}&id=${id}`)
      .then((res) => {
        setXeroLink(res?.xeroLink)
      })
      .catch(() => {
        dispatch(setBanner('There was an error while getting xero link', 'danger'))
      })
  }

  const handleContactSelect = async (contact) => {
    setForm((prev) => ({ ...prev, xeroPayTo: contact }))
    getXeroLink(contact?.id)
  }

  const clearContactSelect = () => {
    setForm((prev) => ({ ...prev, xeroPayTo: {} }))
  }

  const handleAccountSelect = (account) => {
    setForm((prev) => {
      if (prev.account && prev.account.number !== account.number) {
        return { ...prev, account: account, xeroPayTo: {} }
      } else {
        return { ...prev, account: account }
      }
    })
  }

  const handleConfirmationConfirm = (event) => {
    event?.preventDefault()
    setShowConfirmationDialog(false)
    if (approveButtonText.trim() === 'Ready to Process' && ['pending', 'aged_pending'].includes(form.status)) {
      doUpdate('restore_from_pending')
      return
    } else if (form.status === 'denied' && !isSaveRequired()) {
      doUpdate('denied_closed')
      return
    }
    doUpdate()
  }
  const handleConfirmationCancel = () => {
    setShowConfirmationDialog(false)
    setIsApproved(null)
  }
  const handleMoveToPending = () => {
    setMoveToPending(false)
    doUpdate('pending')
  }
  const handleMoveToDenied = () => {
    setMoveToDenied(false)
    doUpdate('denied')
  }

  const handleUnableProcessConfirm = () => {
    setShowUnableToProcess(false)
    setMoveToPending(true)
  }

  const handleUnableProcessCancel = () => {
    setShowUnableToProcess(false)
    setMoveToDenied(true)
    setIsApproved(null)
  }

  const handleRecurringUpdate = (e) => {
    let { name, value } = e.target
    setForm((prev) => ({
      ...prev,
      recurringDistribution: {
        ...prev.recurringDistribution,
        [name]: value,
      },
    }))
  }

  const fetchData = (id) => {
    if (id) {
      dispatch(setLoad())
      httpGet(`/admin/distributions/${id}`)
        .then(({ distribution }) => {
          setEvents(distribution?.events)
          const formData = {
            ...distribution,
            defaultXeroPayTo: distribution?.xeroPayTo,
            expeditedPaymentNeeded: distribution.expeditedDateNeeded ? 'yes' : 'no',
            expeditedMailing: distribution.expeditedMailing ? 'yes' : 'no',
            mailingFeeAssessed: distribution.mailingFeeAssessed ? 'yes' : 'no',
          }
          setForm(formData)
          setFormInit(formData)
          setExpeditedDate(distribution.expeditedDateNeeded)
          setCurrentStatus(distribution.status)
          setStep(distribution.step)
          setNextStatus(distribution.nextStatus)
          setEndDate(distribution?.recurringDistribution?.endDate)

          // States for form
          const isLockedForEdit = distribution?.lockedForEdit != null && distribution?.lockedForEdit == true
          setCurrentDrfRecurring(
            distribution.recurringDistribution != null &&
              distribution.recurringDistribution?.period != null &&
              !recurringStatus.includes(distribution.status),
          )
          const type = distribution.type
          setRecurringFormDisabled(type != DRF_TYPE.TEMPLATE || isLockedForEdit)
          setDrfType(type)

          setFiles(distribution.files)
          setStatusBasedTexts(distribution.nextStatus, distribution.status)
          setXeroLink(distribution.xeroPayTo.xeroLink)
          setInvoiceXeroLink(distribution.xeroLink)
          setLockedForEdit(isLockedForEdit)
          setDeniedReasonSelect(distribution?.deniedDistribution?.reason)

          const formDisabledState =
            ['paid', 'denied_closed', 'recurring_expired', 'recurring_approved'].includes(distribution.status) ||
            ['admin_wrapped_up'].includes(distribution.nextStatus) ||
            isLockedForEdit
          setFormDisabled(formDisabledState)
          if (['pending', 'aged_pending'].includes(distribution.status)) {
            setClientSelect(distribution?.pendingDistribution?.clientResponsibility)
            setTrustSelect(distribution?.pendingDistribution?.gtResponsibility)

            setFormInitPendingDistribution({
              clientResponsibility: distribution?.pendingDistribution?.clientResponsibility,
              gtResponsibility: distribution?.pendingDistribution?.gtResponsibility,
            })
            if (distribution?.pendingDistribution?.dateEnteredPending) {
              setDaysInPending(formatters.dateTimeDiff(distribution.pendingDistribution.dateEnteredPending, 'days'))
            }
          }
          setRequiredActions(distribution?.requiredActions)
          setCompletedActions(distribution?.completedActions)
          setColor(getColor(distribution?.nextStatus ?? distribution?.status))

          if (['admin_reviewed', 'signer_reviewed'].includes(distribution.nextStatus)) {
            setEdit(false)
          }
          dispatch(setUnload())
        })
        .catch((err) => {
          navigate(-1)
          dispatch(setBanner(err?.message ?? 'Something went worng... Please try again later', 'danger'))
          dispatch(setUnload())
        })
    }
  }
  const doUpdate = (statusArg) => {
    const url = `/admin/distributions/${id}`

    const recurringDistribution =
      drfType == DRF_TYPE.TEMPLATE
        ? {
            period: form.recurringDistribution.period,
            day_repeats: form.recurringDistribution.dayRepeats,
            end_date: form.recurringDistribution.endDate,
            start_month: form.recurringDistribution.startMonth,
            target_day: form.recurringDistribution?.targetDay,
          }
        : {}
    let putStatus
    const isSaveEnabled = isSaveRequired()
    if (statusArg) {
      putStatus = statusArg
    } else if (isSaveEnabled) {
      putStatus = currentStatus
    } else {
      putStatus = nextStatus
    }

    const params = {
      account_number: form.account.number,
      amount: form.amount,
      // check_number: form.checkNumber,
      date_received: form.dateReceived,
      description: form.description,
      expedited_date_needed: form.expeditedDateNeeded,
      instructions: form.instructions,
      xero_pay_to: form.xeroPayTo,
      payment_type: form.paymentType,
      payment_subtype: form.paymentSubtype,
      recurring_distribution: {
        requested: `${form?.recurringDistribution?.requested}` == 'true',
        ...recurringDistribution,
      },
      reference: form.reference,
      request_type: form.requestType,
      status: putStatus,
      step: step,
      pending_distribution: {
        client_responsibility: clientSelect,
        gt_responsibility: trustSelect,
      },
      denied_reason: deniedReasonSelect,
      requestor_id: form.requestorId,
      completed_actions: completedActions,
      expedited_mailing: form.expeditedMailing == 'yes',
      mailing_fee_assessed: form.mailingFeeAssessed == 'yes',
    }
    dispatch(setLoad())
    const isStatusRedirection = (status) => {
      const validStatuses = ['restore_from_pending', ...pending]

      if (['admin_reviewed'].includes(status) && ['check_in_house', 'cashier_check'].includes(form.paymentType)) {
        return true
      }
      return validStatuses.includes(status)
    }

    httpPut(url, params)
      .then(() => {
        dispatch(setBanner('Distribution saved successfully'))
        dispatch(setUnload())
        if (!isSaveEnabled && !isStatusRedirection(putStatus) && !isStatusRedirection(nextStatus)) {
          handleNavigation()
        } else {
          fetchData(id)
        }
      })
      .catch((err) => {
        dispatch(setBanner(`Error saving distribution - ${err}`, 'danger'))
        dispatch(setUnload())
      })
  }

  const buildUploadParams = async (fileName, file) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })

    const data = await toBase64(file)
    const params = {
      content_type: file.type,
      name: fileName,
      data: data.split(',')[1],
    }

    return params
  }

  const onUploadClick = (fileName, file) => {
    buildUploadParams(fileName, file).then((params) => {
      const url = `/admin/distributions/${id}/files`

      dispatch(setLoad())
      httpPost(url, params)
        .then((data) => {
          dispatch(setBanner('File uploaded'))
          setFiles(data.files)
          dispatch(setUnload())
        })
        .catch((err) => {
          dispatch(setBanner(`Error saving - ${err}`, 'danger'))
          dispatch(setUnload())
        })
    })
  }

  const onDeleteClick = (fileId) => {
    const url = `/admin/distributions/files/${fileId}`

    httpDelete(url)
      .then((res) => {
        setFiles(files.filter((file) => file.id !== fileId))
        dispatch(setBanner('File deleted'))
      })
      .catch((err) => {
        dispatch(setBanner(`Error deleting - ${err}`, 'danger'))
      })
  }

  const getActionsNeeded = () => {
    // only for WRIITE
    if (nextStatus !== 'written') return
    if (!form.paymentType || !form.requestType) {
      return null
    }
    dispatch(setLoad())
    const url = `/admin/distributionActions?payment_type=${form.paymentType}&request_type=${form.requestType}`
    httpGet(url)
      .then((res) => {
        setRequiredActions(res?.requiredActions)
        dispatch(setUnload())
      })
      .catch((err) => {
        dispatch(setBanner(err, 'danger'))
        dispatch(setUnload())
      })
  }

  const setStatusBasedTexts = (nextStatus, status) => {
    const obj = getStatusBasedText(nextStatus, status, drfType)
    setHeaderTitle(obj.headerTitle)
    setApproveButtonText(obj.approveButtonText)
    setDisapproveButtonText(obj.disapproveButtonText)

    if (isSaveRequired()) {
      setApproveButtonText(ActionsEnum.SAVE_UPDATES)
    }
  }

  const isSaveRequired = () => {
    // if actions required is same as distribution actions required
    const anyFieldPending = !isEqual(form, formInit)
    const actionsCompleted = areAllRequiredActionsCompleted(completedActions, requiredActions)

    // save required, for pending status below
    const pendingBase = clientSelect?.length > 0 || trustSelect != null

    if (['pending', 'aged_pending'].includes(form.status)) {
      return pendingBase
    }
    // bypass anyFieldPending check if required actions completed
    if ([...main, ...recurringStatus].includes(form.nextStatus) && requiredActions.length != 0 && actionsCompleted) {
      return false
    }
    if (anyFieldPending) {
      return true
    } else if (actionsCompleted) {
      return false
    }
    return true
  }

  const accountContactSearch = (accountNumber) => {
    httpGet(`/admin/distributions/contacts?number=${accountNumber}`)
      .then(({ accountContacts }) => {
        setAccounts(accountContacts)
      })
      .catch(() => {})
  }

  const handleNavigation = () => {
    if (searchParams.get('redirection_back') == 'true') {
      navigate(-1)
    } else {
      navigate(`/distributions/queue?tid=${encodedData('main')}&stid=${encodedData('0')}`)
    }
  }

  const handlePayNowRecurring = () => {
    dispatch(setLoad())
    setPayNowRecurring(false)
    httpPost(`/admin/recurring_distributions/${id}?action=run`)
      .then(() => {
        dispatch(setBanner('Run success'))
        handleNavigation()
      })
      .catch(() => {
        dispatch(setBanner('Ran unsuccessfully', 'danger'))
      })
      .finally(() => {
        dispatch(unsetModal())
        dispatch(setUnload())
      })
  }

  // To be removed
  useEffect(() => {
    if (isApproved !== null) {
      if (!isApproved) {
        setShowUnableToProcess(true)
      } else {
        setShowConfirmationDialog(true)
      }
    }
  }, [isApproved])

  useEffect(() => {
    setStatusBasedTexts(nextStatus, form.status)
  }, [form, formInitPendingDistribution, formInit, trustSelect, clientSelect, requiredActions, completedActions])

  useEffect(() => {
    if (form.expeditedPaymentNeeded !== 'yes') {
      setForm((prev) => ({ ...prev, expeditedDateNeeded: '' }))
      setFormInit((prev) => ({ ...prev, expeditedDateNeeded: '' }))
    }
  }, [form.expeditedPaymentNeeded])

  useEffect(() => {
    fetchData(id)
  }, [id])

  useEffect(() => {
    getActionsNeeded()
  }, [form.paymentType])

  useEffect(() => {
    isSaveRequired()
  }, [form, formInit])

  useEffect(() => {
    const headerStepper = getHeader({
      headerTitle,
      currentStatus,
      nextStatus,
      form,
      id,
      events,
      drfType,
    })
    setStepper(headerStepper)
  }, [headerTitle, currentStatus, nextStatus, form, id, events, drfType])

  useEffect(() => {
    if (form.account?.number) {
      accountContactSearch(form.account?.number)
    }
  }, [form.account])

  useEffect(() => {
    setCalculation(
      getCalculation({
        amount: form.amount,
        checkingBalance,
        openTotal: form?.otherDistributions?.openTotal,
        pendingTotal: form?.otherDistributions?.pendingTotal,
        floorAmount: form.account.floorAmount,
        hasBudget: form.account.hasBudget,
      }),
    )
  }, [form.amount, checkingBalance])

  const formProps = {
    onUploadClick,
    files,
    onDeleteClick,
    form,
    updateForm,
    formDisabled,
    handleAccountSelect,
    currentDrfRecurring,
    recurringFormDisabled,
    nextStatus,
    handleRecurringUpdate,
    lockedForEdit,
    drfType,
    calculation,
    setPayNowRecurring,
    endDate,
  }

  const extraFormProps = {
    handleContactSelect,
    clearContactSelect,
    xeroLink,
    invoiceXeroLink,
    accounts,
  }

  const ctaFormProps = {
    form,
    formDisabled,
    disapproveButtonText,
    approveButtonText,
    files,
    nextStatus,
    handleConfirmationConfirm,
    setShowUnableToProcess,
    isSaveRequired,
    drfType,
    calculation,
    lockedForEdit,
  }

  const dialogProps = {
    showConfirmationDialog,
    handleConfirmationCancel,
    handleConfirmationConfirm,
    showUnableToProcess,
    handleUnableProcessCancel,
    handleUnableProcessConfirm,
    moveToPending,
    setMoveToPending,
    clientSelect,
    setClientSelect,
    trustSelect,
    setTrustSelect,
    handleMoveToPending,
    moveToDenied,
    deniedReasonSelect,
    setDeniedReasonSelect,
    setMoveToDenied,
    handleMoveToDenied,
    payNowRecurring,
    handlePayNowRecurring,
    setPayNowRecurring,
  }

  const extraViewProps = {
    files,
    form,
    drfType,
    accounts,
    edit,
    setEdit,
    calculation,
  }

  return (
    <>
      <form className={styles.form}>
        <DrfHeader stepper={stepper} color={color} expeditedDate={expeditedDate} daysInPending={daysInPending} lockedForEdit={lockedForEdit} />
        <div className={styles.distributionFormContainer}>
          {edit ? (
            <>
              <WriteFormOne {...formProps} />
              <WriteFormTwo {...formProps} {...extraFormProps} />
            </>
          ) : (
            <div className={styles.viewDrfSection}>
              <ViewDrf {...extraViewProps} />
              <RequestDocument {...formProps} {...extraFormProps} />
            </div>
          )}
          <ReviewForm form={form} checkingBalance={checkingBalance} setCheckingBalance={setCheckingBalance} />
        </div>
      </form>

      <div className={styles.footerContainer}>
        <div className={styles.notesList}>
          <Notes distributionId={id} dispatch={dispatch} notes={form.notes} type="distribution" />
          {['pending', 'aged_pending'].includes(currentStatus) && (
            <PendingRequest clientSelect={clientSelect} setClientSelect={setClientSelect} trustSelect={trustSelect} setTrustSelect={setTrustSelect} />
          )}
          {denied.includes(currentStatus) && (
            <section className={styles.cardMarginBot}>
              <SectionHeader title={'Denied Reason'} />
              <div className={styles.actionsNeededContainer}>
                <DeniedSection deniedReasonSelect={deniedReasonSelect} setDeniedReasonSelect={setDeniedReasonSelect} showTitle={false} />
              </div>
            </section>
          )}
          {requiredActions?.length > 0 && (
            <ActionsNeeded
              completedActions={completedActions}
              requiredActions={requiredActions}
              setCompletedActions={setCompletedActions}
              checkColor={isMainQueue(currentStatus) ? 'green' : 'red'}
              sectionTitle={denied.includes(currentStatus) ? 'Closing Actions' : null}
            />
          )}
        </div>
        <MainCtaFooter {...ctaFormProps} />
      </div>

      <Dialogs {...dialogProps} />
    </>
  )
}

export default DistributionMain
