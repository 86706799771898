// @flow
import validators from 'lib/field/validators'

type Props = {
  email: string
}

const validate = ({ email }: Props) => {
  const errors = {
    email: validators.email(email),
  }

  return errors
}

export default validate
