// @flow
import React, { useEffect, useState } from 'react'
import styles from './styles'

type Props = {
  label: string,
  name: string,
  options: Array<Object>,
  onChange: Function,
  placeholder?: string,
}

export const DynamicSelect = ({
  label,
  name,
  options,
  onChange,
  placeholder,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState()

  return (
    <div className={`${styles.dynamicInput} ${styles.formItem}`}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <div>
        <select
          className={styles.select}
          name={name}
          value={selectedOption}
          onChange={(e) => {
            onChange({ name, value: e.target.value })
            setSelectedOption(e.target.value)
          }}
        >
          <option value="" disabled={true}>
            {placeholder || 'Select one'}
          </option>
          {options.map(({ label, value }) => {
            return (
              <option key={value} label={label}>
                {value}
              </option>
            )
          })}
        </select>
      </div>
    </div>
  )
}
