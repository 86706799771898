// @flow
import React from 'react'
import type { Contact } from 'lib/types'
import ContactRef from './ContactRef'
import styles from './styles'
import { GRANTOR_RELATIONSHIPS } from 'lib/constants'
import formatters from 'lib/formatters'

type Props = {
  account_number: String,
  collapse: boolean,
  contact: Contact,
  dispatch: Function,
  edit: boolean,
  full: boolean,
  grantor: Contact,
  onSSNReset: Function,
  relationship: String,
  title: String,
}

const selfServiced = (relationship: String) => (
  <section
    className={styles.cardMarginBot}
  >
    <div className={styles.titleSectionLargeNM}>
      <div className={styles.title}>
        <h5 className={styles.contactType}>Grantor:
          <span className={styles.relationship}> {relationship}</span>
        </h5>
        <div className={styles.noName}>{relationship} Serviced</div>
      </div>
    </div>
  </section>
)

const GrantorRefCollapse = (props: Props) => {
  const {
    account_number,
    collapse,
    contact,
    dispatch,
    edit,
    full,
    grantor,
    onSSNReset,
    relationship,
  } = props
  
  if (!contact && !grantor) {
    return selfServiced(GRANTOR_RELATIONSHIPS[relationship])
  }

  const grantorOfFundedBy =
    formatters.grantorOrFundedBy({account_number: account_number})

  const fullTitle =
    `${grantorOfFundedBy}: ${GRANTOR_RELATIONSHIPS[relationship]}`

  return (
    <ContactRef
      collapse={collapse}
      contact={grantor || contact}
      dispatch={dispatch}
      edit={edit}
      full={full}
      onSSNReset={onSSNReset}
      relationship={relationship}
      title={fullTitle}
    />
  )
}

export default GrantorRefCollapse
