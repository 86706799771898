// @flow
import * as t from './actionTypes'
import { httpGet, httpDelete, httpPost } from 'lib/http'
import { setBanner } from 'lib/notification/actions'
import Rollbar from 'lib/rollbar'

export const fetchAccountHTTP = (number: string) => {
  return (dispatch: Function) => {
    return httpGet(`/admin/accounts/${number}`).then((account) => {
      dispatch({ type: t.SET, account })
    })
  }
}

export const clearAccount = () => {
  return (dispatch: Function) => {
    dispatch({ type: t.SET, account: {} })
  }
}

export const createDocument = (id, params: Object) => {
  const url = `/admin/accounts/${id}/files`

  return (dispatch) => {
    dispatch({
      type: t.SET_UPLOAD_STATUS,
      status: 'uploading'
    })

    httpPost(url, params)
    .then((data) => {
      dispatch(setBanner('Changes saved successfully'))

      dispatch({
        type: t.SET_UPLOAD_STATUS,
        status: 'complete'
      })

      dispatch({
        type: t.SET_DOCUMENT_FOLDERS,
        folders: data.folders
      })
    })
    .catch((err) => {
      Rollbar.error('Error on createDocument', err)
      dispatch(setBanner(`Error saving - ${err}`, 'danger'))

      dispatch({
        type: t.SET_UPLOAD_STATUS,
        status: 'failed'
      })
    })
  }
}

export const deleteDocument = (folder, id) => {
  const url = `/admin/accounts/files/${id}`

  return (dispatch) => {
    httpDelete(url)
    .then(() => {
      dispatch(setBanner('File deleted'))

      dispatch({
        type: t.REMOVE_DELETED_DOCUMENT,
        folder: folder,
        file_id: id
      })
    })
    .catch((err) => {
      Rollbar.error('Error on deleteDocument', err)
      dispatch(setBanner(`Error deleting file - ${err}`, 'danger'))
    })
  }
}
