// @flow
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './styles'
import ContactPicker from 'components/ContactPicker'
import FormButtons from 'components/FormButtons'
import MergeContactFormRef from 'components/References/MergeContactFormRef/MergeContactFormRef'
import { ContactRoleRef } from 'components/References'

type Props = {
  copyField?: Function,
  errors: Object,
  onPrimaryContactSelection: Function,
  onSecondaryContactSelection: Function,
  onSubmit: Function,
  primary_contact?: Object,
  secondary_contact?: Object,
  swapContacts: Function,
}

export const Template = (props: Props) => {
  const {
    copyField,
    errors,
    onPrimaryContactSelection,
    onSecondaryContactSelection,
    onSubmit,
    primary_contact,
    secondary_contact,
    swapContacts,
  } = props

  const renderHeader = () => (
    <h4 className={styles.section}>
      <p style={{ marginTop: 0 }}>
        Select contacts to merge. Please ensure all fields you wish to preserve
        are present on the Primary Contact.
      </p>
      <small>
        * Beneficiaries cannot be merged
      </small>
    </h4>
  )

  const submitButton = () => {
    const disabled = !primary_contact || !secondary_contact

    return (
      <FormButtons disabled={disabled} errors={errors} submitLabel="Merge" />
    )
  }

  const swapButton = () => {
    const disabled = !primary_contact || !secondary_contact

    return (
      <button
        onClick={swapContacts}
        className={styles.swapBtn}
        disabled={disabled}
        type="button"
      >
        Swap Primary / Secondary{' '}
        <FontAwesomeIcon size="lg" rotation={90} icon="exchange-alt" />
      </button>
    )
  }

  const renderPrimaryContact = () => {
    return (
      <section className={styles.section}>
        <h3>
          Primary Contact
          {primary_contact && ` (${primary_contact.id})`}
        </h3>
        <section>
          <ContactPicker
            callback={onPrimaryContactSelection}
            contact={primary_contact}
            fieldName="primary_contact_id"
            label="Primary Contact"
            testHook="select-primary-contact"
          />
          <MergeContactFormRef
            contact={primary_contact}
            secondaryContact={secondary_contact ? secondary_contact : undefined}
            errors={errors.primary_contact}
            title="Primary Contact"
            canCopy={true}
            copyField={copyField}
          />
        </section>
      </section>
    )
  }

  const renderSecondaryContact = () => (
    <section className={styles.section}>
      <h3>
        Secondary Contact
        {secondary_contact && ` (${secondary_contact.id})`}
      </h3>
      <section>
        <ContactPicker
          callback={onSecondaryContactSelection}
          contact={secondary_contact}
          fieldName="secondary_contact_id"
          label="Secondary Contact"
          testHook="select-secondary-contact"
        />
        <MergeContactFormRef
          contact={secondary_contact}
          errors={errors.secondary_contact}
          title="Secondary Contact"
          secondaryContact={primary_contact ? primary_contact : undefined}
          canCopy={false}
        />
      </section>
    </section>
  )

  return (
    <section className={styles.contentContainer}>
      <div>
        <div className={styles.bodyLeft}>{renderHeader()}</div>
        <div className={styles.bodyRight} />
      </div>
      <div>
        <form onSubmit={onSubmit}>
          <div style={{ display: 'flex' }}>
            <div className={styles.bodyLeft}>
              {renderPrimaryContact()}
              {swapButton()}
            </div>
            <div className={styles.bodyRight}>
              {primary_contact && <ContactRoleRef {...primary_contact} />}
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div className={styles.bodyLeft}>
              {renderSecondaryContact()}
              {submitButton()}
            </div>
            <div className={styles.bodyRight}>
              {secondary_contact && <ContactRoleRef {...secondary_contact} />}
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

Template.defaultProps = {
  primary_contact: null,
  secondary_contact: null,
}
