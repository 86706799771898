// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__wrapper___B57ru {
  margin: auto;
  max-width: calc(100% * 3 / 5);
  width: 100%;
}

.styles__header___pviUW {
  margin-bottom: 20px;
}

.styles__contentContainer___AaA89 {
}

.styles__form___d5Mlm {
  width: 100%;
}

.styles__formItem___Rq8eA {
}

.styles__formItemHalf___QeZC7 {
}

.styles__buttons___LDqoV {
  justify-content: flex-end;
}

.styles__button___JHexl {
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Public/Login/styles.css"],"names":[],"mappings":"AAIA;EACE,YAAY;EACZ,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EAEE,mBAAmB;AACrB;;AAEA;AAEA;;AAEA;EACE,WAAW;AACb;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,yBAAyB;AAC3B;;AAEA;AAEA","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtBlueDark, gtBlue from colors;\n\n.wrapper {\n  margin: auto;\n  max-width: calc(100% * 3 / 5);\n  width: 100%;\n}\n\n.header {\n  composes: pageTitle from helpers;\n  margin-bottom: 20px;\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n}\n\n.form {\n  width: 100%;\n}\n\n.formItem {\n  composes: formItem from helpers;\n}\n\n.formItemHalf {\n  composes: formItemHalf from helpers;\n}\n\n.buttons {\n  composes: row from helpers;\n  justify-content: flex-end;\n}\n\n.button {\n  composes: button from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtBlueDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]}`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"wrapper": `styles__wrapper___B57ru`,
	"header": `styles__header___pviUW ${___CSS_LOADER_ICSS_IMPORT_1___.locals["pageTitle"]}`,
	"contentContainer": `styles__contentContainer___AaA89 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"form": `styles__form___d5Mlm`,
	"formItem": `styles__formItem___Rq8eA ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItem"]}`,
	"formItemHalf": `styles__formItemHalf___QeZC7 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItemHalf"]}`,
	"buttons": `styles__buttons___LDqoV ${___CSS_LOADER_ICSS_IMPORT_1___.locals["row"]}`,
	"button": `styles__button___JHexl ${___CSS_LOADER_ICSS_IMPORT_1___.locals["button"]}`
};
export default ___CSS_LOADER_EXPORT___;
