// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { Notes } from 'components/Notes'
import Ticket from 'components/Ticket'
import styles from './styles'

type Props = {
  dispatch: Function,
  ticket: Object,
}

const Reference = ({ dispatch, ticket }: Props) =>
  <section>
    <Notes
      accountNumber={ticket.account_number}
      dispatch={dispatch}
      notes={ticket.notes}
    />
  </section>

export const ClosedStation = (props: Props) => {
  const { ticket } = props
  const { account_number } = ticket
  const header = { subheader: 'Account Creation', title: 'Ticket Closed' }
  const reference = ticket && <Reference {...props} />

  return (
    <Ticket
      header={header}
      history={ticket.history}
      reference={reference}
    >
      <form>
        <h4 className={styles.section}>No further actions required.</h4>
        <h3 id='test-hook-account-added' className={styles.sectionSmall}>
          Account has been added to the system.
        </h3>
        <div className='section'>
          <Link
            className={styles.buttonLink}
            to={`/accounts/${account_number}`}
          >
            View Account
          </Link>
        </div>
      </form>
    </Ticket>
  )
}
