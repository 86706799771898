// @flow
import React from 'react'
import Collapse from 'react-collapse'
import styles from './styles'

type Props = {
  children: any,
  preview: any,
  details: any,
  canToggle: boolean,
  open: boolean,
  title: string,
  sectionStyle: {},
}

class SSIRef extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    const { open } = this.state
    const {
      canToggle = true,
      title,
      preview,
      sectionStyle,
      children,
      details,
    } = this.props
    const allowToggle = details && canToggle

    let toggle = null

    if (allowToggle) {
      toggle = (
        <div
          className={styles.dropdownArea}
          onClick={() => this.setState({ open: !open })}
        >
          <div className={styles.toggleLabel}>
            {open ? 'Hide Details' : 'See Details'}
          </div>
          <span
            className={styles.collapseIcon}
            title={open ? 'Hide' : 'Show'}
          />
        </div>
      )
    }

    return (
      <section data-active={open} className={styles.collapseRef}>
        <div>
          <h5 className={styles.titleSection}>{title}</h5>
          <section style={sectionStyle} className={styles.accountExtrasSection}>
            {preview}
            {children}
            {allowToggle && <Collapse isOpened={open}>{details}</Collapse>}
          </section>
        </div>
        {allowToggle && toggle}
      </section>
    )
  }
}

export default SSIRef
