// @flow
import React from 'react'
import FormButtons from 'components/FormButtons'
import styles from './styles'

type Props = {
  accountNumber: number,
  date: Date,
  handleUpdateClosingDate: Function
}

type State = {
  closeDate: Date,
}

class ClosingDateForm extends React.Component<Props, State> {
  props: Props

  constructor(props) {
    super(props)
    this.state = { closeDate: '' }
  }

  componentDidMount() {
    this.setState({ closeDate: this.props.date })
  }

  _wrapSubmit = () => (event) => {
    event.preventDefault()
    this.props.handleUpdateClosingDate(this.state.closeDate)
  }

  _handleChange = ({ target }) => {
    this.setState({ closeDate: target.value })
  }

  render() {
    return (
      <section>
        <h4 className={styles.section}>Close Account</h4>
        <form onSubmit={this._wrapSubmit()}>
          <div className={styles.section}>
            <label className={styles.label} htmlFor="date">
              Closing Date
            </label>
            <div className={styles.formItemQuarter}>
              <input
                className={styles.input}
                id="date"
                name="date"
                onChange={this._handleChange}
                type="date"
                value={this.state.closeDate || ''}
              />
            </div>
          </div>
          <FormButtons
            submitLabel={'Submit'}
            // disabled={!this._canSubmit()}
          />
        </form>
      </section>
    )
  }
}

export default ClosingDateForm
