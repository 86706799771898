// @flow
import React from 'react'

import { Table as ContactTable } from 'components/Contact'
import styles from './styles'

type Props = {
  contactData: Object,
  createHandler?: Function,
  onChange: Function,
  onSearch: Function,
  selectionHandler: Function,
  selectionFilter?: Function,
  showCreate: Boolean,
  value: string,
}

export const Template = (props: Props) => {
  const {
    contactData,
    onChange,
    createHandler,
    onSearch,
    selectionFilter,
    selectionHandler,
    showCreate,
    value,
  } = props

  let createBtn = null

  if (createHandler && showCreate) {
    createBtn = (
      <button
        id="test-hook-new-contact-btn"
        onClick={createHandler(selectionHandler)}
        className={styles.buttonSecondary}
        type="button"
      >
        Create New Contact
      </button>
    )
  }

  const formHeader = () => {
    let text = 'Select contact from below.'

    if (createHandler) {
      text += ' If they do not exist, create a new one.'
    }

    return <h4>{text}</h4>
  }

  return (
    <section>
      <div className={styles.section}>
        <form onSubmit={onSearch}>
          {formHeader()}
          <div className={styles.searchWrap}>
            <input
              autoFocus
              className={styles.search}
              id="search"
              onChange={onChange}
              placeholder="Search"
              value={value}
            />
            <button id="contact-search" className={styles.button} type="submit">
              Search
            </button>
            {createBtn}
          </div>
        </form>
      </div>
      <ContactTable
        selectionFilter={selectionFilter}
        callback={selectionHandler}
        contacts={contactData}
        modal={true}
      />
    </section>
  )
}
