// @flow
import React, { useState, useRef, useEffect } from 'react'
import { IconSearch } from 'components/Icons/IconSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutocompleteResults } from './AutocompleteResults'

import styles from './Autocomplete.css'

type Props = {
  disabled: Boolean,
  searchQuery: String,
  searchResults: Array,
  searchType: String,
  onChange: Function,
  onSelect: Function,
  onClear: Function,
  autoFocus?: boolean,
  inputType?: 'textarea' | 'input',
}

export const Autocomplete = ({
  disabled,
  searchQuery,
  onChange,
  onSelect,
  onClear,
  searchResults,
  searchType,
  selectedAccount,
  autoFocus,
  inputType,
}: Props) => {
  const containarElementRef = useRef()
  const inputRef = useRef()
  const [isActive, setIsActive] = useState(false)
  const [focusedIndex, setFocusedIndex] = useState(null)
  const hasResults = searchResults.length > 0

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!isActive) {
        return
      }

      if (containarElementRef.current && !containarElementRef.current.contains(e.target)) {
        setIsActive(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [containarElementRef, isActive])

  function onKeyDown(e) {
    setIsActive(true)

    if (e.key === 'Enter') {
      if (focusedIndex != null) {
        onSelect(searchResults[focusedIndex].id)
      }

      setIsActive(false)
      e.target.blur()

      setFocusedIndex(null)

      return
    }

    if (!hasResults) {
      return
    }

    if (e.key === 'ArrowUp') {
      focusPrev()
    } else if (e.key === 'ArrowDown') {
      focusNext()
    }
  }

  function focusNext() {
    if (focusedIndex == null) {
      setFocusedIndex(0)
      return
    }

    let nextValue = focusedIndex + 1

    if (nextValue > searchResults.length - 1) {
      setFocusedIndex(0)
    } else {
      setFocusedIndex(nextValue)
    }
  }

  function focusPrev() {
    if (focusedIndex == null) {
      setFocusedIndex(searchResults.length - 1)
      return
    }

    let nextValue = focusedIndex - 1

    if (nextValue < 0) {
      setFocusedIndex(searchResults.length - 1)
    } else {
      setFocusedIndex(nextValue)
    }
  }

  function onInputChange(e) {
    const value = e.target.value
    onChange(value)
  }

  function onInputClick(item) {
    onSelect(item)
    setIsActive(false)
  }

  function onClearClick() {
    setFocusedIndex(null)
    setIsActive(false)
    onClear()
  }
  return (
    <div className={styles.autocompleteContainer} onKeyDown={onKeyDown} ref={containarElementRef}>
      <div className={styles.autoCompleteInputContainer}>
        <div
          className={disabled ? styles.autoCompleteInputDisabled : styles.autoCompleteInput}
          onClick={() => inputRef?.current && inputRef?.current?.focus()}
        >
          {!selectedAccount?.number ? (
            <InputType
              type={inputType}
              disabled={disabled}
              value={searchQuery}
              ref={inputRef}
              autoFocus={!disabled && autoFocus}
              onFocus={() => setIsActive(true)}
              onChange={onInputChange}
            />
          ) : (
            <InputType
              type={inputType}
              disabled={true}
              value={searchQuery}
              ref={inputRef}
              onFocus={() => setIsActive(true)}
              onChange={onInputChange}
            />
          )}
          {(selectedAccount?.number || searchQuery) && !disabled ? (
            <div
              className={styles.autoCompleteItems_clearIcon}
              role="button"
              onClick={onClearClick}
            >
              <FontAwesomeIcon size="lg" icon={['fas', 'times-circle']} />
            </div>
          ) : (
            <div className={styles.iconSearch}>
              <IconSearch />
            </div>
          )}
        </div>
      </div>
      {hasResults && isActive && (
        <div className={styles.autocomplete_resultContainer}>
          <AutocompleteResults
            searchType={searchType}
            query={searchQuery}
            searchResults={searchResults}
            focusedIndex={focusedIndex}
            onItemClick={(item, selection) => onInputClick(item, selection)}
          />
        </div>
      )}
    </div>
  )
}

export const InputType = ({ type, ...props }) => {
  return type === 'textarea' && !props.disabled ? (
    <textarea {...props} rows={2} className={styles.textarea} />
  ) : (
    <input {...props} />
  )
}
