// @flow
import React from 'react'
import { b64toBlob } from 'lib/file'
import { httpGet } from 'lib/http'
import styles from './styles'

type File = {
  content_type: string,
  file_id: number,
  data: string,
}

type Props = {
  file: File,
  label: string,
}

export class FilePreview extends React.Component {
  props: Props

  constructor(props) {
    super(props)
  }

  displayCachedFile(data, content_type) {
    const blob = b64toBlob(data, content_type)
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }

  fetchFile(id) {
    httpGet(`/admin/files/${id}`).then((data) => {
      const blob = b64toBlob(data.file.data, data.file.content_type)
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    })
  }

  loadFile() {
    const {file_id, data, content_type} = this.props.file

    if (data) {
      this.displayCachedFile(data, content_type)
    } else {
      this.fetchFile(file_id)
    }
  }

  render() {
    return (
      <div>
        <a className={`${styles.docLink} test-hook-show-preview`}
          onClick={() => this.loadFile()}
          target='_blank'>
          {this.props.label || 'Preview'}
        </a>
      </div>
    )
  }
}
