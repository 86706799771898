// @flow
import Protected from './root'

export default (store: Object) => ({
  path: '/',
  element: <Protected />,
  loader: (nextState: Object, cb: Function) => {
    return new Promise((resolve) => {
      require.ensure(
        [],
        (require) => {
          resolve(<Protected />)
        },
        'users-protected',
      )
    })
  },
  children: [
    require('./Contacts').default(store),
    require('./AccountStatements').default(store),
    require('./AccountStatementsView').default(store),
    require('./ActionQueue').default(),
    require('./AnnualFees').default(store),
    require('./Beneficiary').default(store),
    require('./Closings').default(store),
    require('./CoTrustee').default(store),
    require('./DCFReport').default(store),
    require('./DepositReport').default(store),
    require('./Distributions').default(store),
    require('./DCFReportView').default(store),
    require('./FeeReport').default(store),
    require('./SuspendedAccts').default(store),
    require('./TrusteeReceiptReport').default(store),
    require('./Interest').default(store),
    require('./Logout').default(store),
    require('./Tickets').default(store),
    require('./Account').default(store),
    require('./PotentialClient').default(store),
  ],
})
