// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { ApprovalRef } from 'components/References'
import Ticket from 'components/Ticket'
import Reference from './Reference'
import FullAccountDetails from './FullAccountDetails'
import styles from './styles'

type Props = {
  disabled: boolean,
  dispatch: Function,
  errors: Object,
  isSubmitting: boolean,
  onChange: Function,
  onSSNReset: Function,
  onSubmit: Function,
  ticket: Object,
  values: Object,
}

export const CoTrusteeApprovalStation = (props: Props) => {
  const {
    disabled,
    dispatch,
    errors,
    isSubmitting,
    onChange,
    onSSNReset,
    onSubmit,
    ticket,
  } = props
  const { account_number, rejection_notes } = ticket
  const header = {
    subheader: 'Account Creation',
    title: 'Co-Trustee Approval',
  }
  const reference = ticket && <Reference {...props} hideContactRef={true} />

  const handleApproval = (isApproved) => (event) => {
    onChange({target: { name: 'approved?', value: isApproved }})
    onSubmit(event)
  }
  const errorClass = errors.rejection_notes && styles.errorWrapper
  const errorLabel = errors.rejection_notes && ` - ${errors.rejection_notes}`

  return (
    <Ticket
      header={header}
      history={ticket.history}
      reference={reference}
    >
      <ApprovalRef rejection_notes={rejection_notes} />
      <h4 id='test-hook-co-trustee-approval' className={styles.section}>
        Review the Joinder Agreement and attached documents for this GPT
        account. If you find any issues, mark them down as reasons for
        rejection.
      </h4>
      <section className={styles.section}>
        <button
          onClick={() => {
            window.location.href = `/accounts/${account_number}/edit`
          }}
          className={styles.buttonSecondary}
        >
          Edit Account
        </button>
      </section>
      <FullAccountDetails
        dispatch={dispatch}
        onSSNReset={onSSNReset}
        ticket={ticket}
      />
      <form
        id='test-hook-submit-review'
        className={styles.form}
      >
        <section className={styles.cardMarginBot}>
          <section className={styles.sectionNoMargin}>
            <div className={errorClass}>
              <label
                className={styles.label}
                htmlFor='rejection_notes'
              >
                Reasons for Rejection{errorLabel}
              </label>
              <textarea
                className={styles.textarea}
                name='rejection_notes'
                onChange={onChange}
              />
            </div>
          </section>
        </section>
        <section className={styles.buttons}>
          <button
            className={styles.buttonDanger}
            disabled={disabled || isSubmitting}
            id='reject'
            onClick={handleApproval(false)}
            type='button'
          >
            Reject
          </button>
          <button
            className={styles.submit}
            disabled={disabled || isSubmitting}
            id='approve'
            onClick={handleApproval(true)}
            type='button'
          >
            Approve
          </button>
        </section>
      </form>
    </Ticket>
  )
}
