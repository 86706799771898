// @flow
/* global Uint8Array */
/* eslint-disable max-len */

export const b64toBlob = (b64Data: string, contentType: string = '', sliceSize: number = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)
    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, {type: contentType})
}

export const makeBlobURL = (file: Object) => {
  if (file && file.data && file.content_type) {
    const blob = b64toBlob(file.data, file.content_type)
    return URL.createObjectURL(blob)
  }

  return null
}
