// @flow
import React from 'react'
import { GPTLineItemsRef } from 'components/References'
import formatters from 'lib/formatters'
import type { Account } from 'lib/types'
import styles from './styles'

type Props = {
  account: Account,
  journalId: string,
}

export const Template = ({ account, journalId }: Props) => {
  const { balance = 0.0, transactions = [] } = account

  const header = () => {
      return (journalId == 'gta'
        ? 'Guardian Trust Administration '
        : 'Guardian Pooled Trust'
      )
  }

  return (
    <section className={styles.contentContainer}>
      <div className={styles.titleBox}>
        <div className={styles.nameWrapper}>
          <span className={styles.titleName}>
            <h5 className={styles.accountNumber}>{account.number}</h5>
          </span>
          <h1 className={styles.title}>{header()}</h1>
        </div>
        <div className={styles.balanceWrapper}>
          <h5 className={styles.boxLabel}>Total Balance</h5>
          <h1 className={styles.title}>{formatters.money(balance)}</h1>
        </div>
      </div>
      <div className={styles.transactionWrapper}>
        <GPTLineItemsRef lineItems={transactions.reverse()} />
      </div>
    </section>
  )
}
