// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__formItemHalf___kl56G {
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Closings/components/Edit/ClosingFeeForm/styles.css"],"names":[],"mappings":"AAEA;AAEA","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.formItemHalf {\n  composes: formItemHalf from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"formItemHalf": `styles__formItemHalf___kl56G ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItemHalf"]}`
};
export default ___CSS_LOADER_EXPORT___;
