// @flow
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styles from './Overlay.css'

type Props = { children: any, loading: boolean }

const Overlay = (props: Props) => {
  const { children, loading } = props
  return (
    <div className={`${styles.overlay} ${loading ? styles.loading : ''}`}>
      {loading && (
        <div className={styles.iconWrapper}>
          <FontAwesomeIcon className={styles.icon} icon="spinner" spin />
        </div>
      )}
      <div className={styles.loader}>{children}</div>
    </div>
  )
}

export default Overlay
