// @flow
import React, { useCallback, useState, useEffect } from 'react'
import { Autocomplete } from './Autocomplete/Autocomplete'
import styles from './styles.css'
import { SearchResult } from './SearchResult/SearchResult'
import { Filters } from './Filters/Filters'
import { RecentSearches } from './RecentSearches/RecentSearches'
import { connect } from 'react-redux'
import { search, clearSearch } from 'lib/contact/actions'
import { submitSearch } from 'lib/contact/callbacks'
import { createStructuredSelector } from 'reselect'
import { getContacts } from 'lib/contact/selectors'
import { isAuditor } from 'lib/user/selectors'
import { useHistorySearch, useAutocompleteResults } from './utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setLoad, setUnload } from 'lib/notification/actions'
import NewContactForm from 'components/Contact/NewContactForm'
import { setModal } from 'lib/modal/actions'
import queryString from 'query-string'
import { httpRaw } from 'lib/http'
import streamSaver from 'streamsaver'
import { withRouter } from 'lib/hooks/withRouter'
import { Outlet, useNavigate } from 'react-router-dom'

type Props = {
  dispatch: Function,
  result: Array<any>,
  location: Object,
  router: Object,
  isAuditor: boolean,
}

function Search({ dispatch, location, result, isAuditor }: Props) {
  const [searchQuery, setSearchQuery] = useState('')
  const [showFilters, setShowFilters] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({
    product: [],
    grantorRelationship: [],
    role: [],
  })
  const { saveSearch } = useHistorySearch()
  const { results: autocompleteItems } = useAutocompleteResults(searchQuery)

  const isAccountNumber = (searchQuery) => {
    const regex = /^\w\d{4,6}$/
    return searchQuery.match(regex)
  }

  const onSubmitClick = useCallback(() => {
    if (isAccountNumber(searchQuery)) {
      const item = { number: searchQuery.toUpperCase() }
      saveSearch({ type: 'account', value: item })
      location.navigate(`/accounts/${searchQuery.toUpperCase()}`)
    } else {
      submitSearch({ search: searchQuery }, dispatch)
      if (searchQuery) {
        saveSearch({ type: 'query', value: searchQuery })
      }
    }
  }, [searchQuery])

  useEffect(() => {
    if (location.query && location.query.get('q')) {
      setSearchQuery(location.query.get('q'))
      loadSearchResults(location.query.get('q'))
    } else {
      dispatch(clearSearch())
    }
  }, [location.query.get('q')])

  function loadSearchResults(query) {
    dispatch(setLoad())
    dispatch(
      search({
        q: query,
        product: selectedFilters.product[0],
        role: selectedFilters.role[0],
        grantorRelationship: selectedFilters.grantorRelationship[0],
      }),
    )
      .then(() => {
        dispatch(setUnload())
      })
      .catch((err) => console.log('err', err))
  }

  function onSelect(item, selection) {
    if (selection.list === 'accounts') {
      location.navigate(`/accounts/${item.number}`)
      saveSearch({ type: 'account', value: item })
    } else {
      submitSearch({ search: item.name }, dispatch)
      saveSearch({ type: 'contact', value: item })
    }
  }

  function onExportClick(e) {
    e.preventDefault()

    const data = {
      q: searchQuery,
      product: selectedFilters.product[0],
      role: selectedFilters.role[0],
      grantorRelationship: selectedFilters.grantorRelationship[0],
    }

    const qs = queryString.stringify(data)
    const url = `/admin/contacts/export?${qs}`

    httpRaw(url).then((response) => {
      const fileStream = streamSaver.createWriteStream('contacts.csv')
      return response.body.pipeTo(fileStream)
    })
    // exportContacts(data)
  }

  function onApplyFilter() {
    dispatch(setLoad())
    dispatch(
      search({
        q: searchQuery,
        product: selectedFilters.product[0],
        role: selectedFilters.role[0],
        grantorRelationship: selectedFilters.grantorRelationship[0],
      }),
    ).then(() => {
      dispatch(setUnload())
    })
  }

  function onSearchHistoryClick(item) {
    if (item.type === 'account') {
      location.navigate(`/accounts/${item.value.number}`)
    }

    if (item.type === 'contact') {
      submitSearch({ search: item.value.name }, dispatch)
    }

    if (item.type === 'query') {
      submitSearch({ search: item.value }, dispatch)
    }
  }

  function openModal() {
    const data = <NewContactForm />
    dispatch(setModal(data))
  }

  function onClear() {
    setSearchQuery('')
    location.navigate('/contacts')
  }

  function onAutocompleteQueryChange(value) {
    setSearchQuery(value)
  }

  const newContact = () => {
    if (isAuditor) {
      return <div></div>
    } else {
      return (
        <button
          type="button"
          id="test-hook-new-contact-btn"
          className={styles.newContactButton}
          onClick={openModal}
        >
          <FontAwesomeIcon icon="user-plus" size="lg" className={styles.icon} />
          Create New Contact
        </button>
      )
    }
  }

  return (
    <div className={styles.contentContainer}>
      <div className={styles.pageContent}>
        <div className={styles.header}>
          <h3 className={styles.pageTitle}>Contacts</h3>
          {newContact()}
        </div>
        <div className={styles.searchHeader}>
          <Autocomplete
            items={autocompleteItems}
            searchQuery={searchQuery}
            onSubmit={onSubmitClick}
            onChange={onAutocompleteQueryChange}
            onSelect={onSelect}
            onClear={onClear}
          />
          <button onClick={() => setShowFilters((v) => !v)} className={styles.filterBtn}>
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </button>
        </div>
        {showFilters && (
          <Filters
            selectedFilters={selectedFilters}
            onFilterChange={(filters) => setSelectedFilters(filters)}
            onApply={onApplyFilter}
          />
        )}
        {result.count > 0 && (
          <div className={styles.resultsContainer}>
            <div className={styles.resultsSummary}>
              <span>
                Your search returned {result.count} contacts.{' '}
                <a href="#" onClick={onExportClick}>
                  Export CSV
                </a>
              </span>
            </div>
            <SearchResult contacts={result.contacts} />
          </div>
        )}
        {!result.count && <RecentSearches onItemClick={onSearchHistoryClick} />}
      </div>
      <Outlet />
    </div>
  )
}

export const SearchPage = withRouter(
  connect(
    createStructuredSelector({
      result: getContacts,
      isAuditor,
    }),
  )(Search),
)
