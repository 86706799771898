// @flow
import React from 'react'
import { connect } from 'react-redux'
import { createUser, deleteUser, fetchUsers, updateUser } from 'lib/user/actions'
import { getUser, getUsers } from 'lib/user/selectors'
import { Table, Row, Cell } from 'components/Table'
import { Link } from 'react-router-dom'
import styles from './styles'

type Props = {
  canSubmit: Function,
  dispatch: Function,
  user: Object,
  onAttrChange: Function,
  onDeleteClicked: Function,
  onUserSubmit: Function,
  onViewClicked: Function,
  users: [Object]
}

const UsersComponent = ({users = [], onDeleteClicked}: Props) => {
  const viewEditLink = (id) => (
    <Link to={`/cotrustee/users/edit/${id}`}>View/Edit</Link>
  )

  const deleteLink = (id) => (
    <div className={styles.remove}>
      <a
        title="Delete File"
        onClick={() => onDeleteClicked(id)}
      />
    </div>
  )

  const renderUser = (item, idx) => (
    <Row key={`custom${idx}`}>
      <Cell value={item.last_name} />
      <Cell value={item.first_name} />
      <Cell value={item.email} />
      <Cell value={item.roles.length} />
      <Cell value={viewEditLink(item.id)} />
      <Cell value={deleteLink(item.id)} />
    </Row>
  )

  return (
    <div>
      <Table
        title="Authorized Users"
        headers={['Last Name', 'First Name', 'Email', 'Roles', ' ', ' ']}
        tableSize="infinite"
        tableType="read"
      >
        {users.map(renderUser)}
      </Table>
    </div>
  )
}
const mapUsersStateToProps = (state) => {
  const users = getUsers(state)
  return {users: users}
}
const mapDispatchToUsersProps = (dispatch: Props) => {
  return {
    onDeleteClicked: (id) => {
      dispatch(deleteUser(id))
    }
  }
}
const UsersContainer =
  connect(mapUsersStateToProps, mapDispatchToUsersProps)(UsersComponent)

const UserComponent =
  ({user, canSubmit, onAttrChange, onUserSubmit}: Props) => {
  const wrapSubmit = (onSubmit, user) => (event) => {
    event.preventDefault()
    onSubmit(user)
  }

  return (
    <div>
      <h5 className={styles.titleSection}>Add User</h5>
      <section className={styles.accountExtrasSection}>
        <form onSubmit={wrapSubmit(onUserSubmit, user)}>
          <div className={styles.section}>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="first_name">
                First Name
              </label>
              <input
                className={styles.input}
                type="text"
                name="first_name"
                value={user.first_name}
                onChange={onAttrChange}
              />
            </div>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="last_name">
                Last Name
              </label>
              <input
                className={styles.input}
                type="text"
                name="last_name"
                value={user.last_name}
                onChange={onAttrChange}
              />
            </div>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="email">
                Email
              </label>
              <div className={styles.emailGroup}>
                <input
                  className={styles.input}
                  type="text"
                  name="email"
                  value={user.email}
                  onChange={onAttrChange}
                />
              </div>
            </div>
            <button
              type="submit"
              className={styles.button}
              disabled={!canSubmit}
            >
              Submit
            </button>
          </div>
        </form>
      </section>
    </div>
  )
}
const mapUserStateToProps = (state) => {
  let user = getUser(state)

  const isUserValid = () => {
    if (!user) {
      return false
    } else {
      const { first_name, last_name, email } = user
      return first_name !== '' && last_name !== '' && email !== ''
    }
  }

  const canSubmit = isUserValid()

  return {user, canSubmit}
}
const mapDispatchToUserProps = (dispatch: Props) => {
  return {
    onUserSubmit: (user) => {
      dispatch(createUser(user))
    },
    onAttrChange: (event) => {
      dispatch(updateUser(event.target.name, event.target.value))
    }
  }
}
const UserContainer =
  connect(mapUserStateToProps, mapDispatchToUserProps)(UserComponent)

const Main = () => (
  <section className={styles.contentContainer}>
    <div className={styles.row}>
      <section className={styles.formWrapper}>
        <div className={styles.headerSection}>
          <h1 className={styles.headerTitle}>Conductor User Management</h1>
        </div>
        <UsersContainer />
      </section>
      <section className={styles.menuWrapper}>
        <div className={styles.navigationMenuWrapper}>
          <UserContainer />
        </div>
      </section>
    </div>
  </section>
)

class MainContainer extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  props: Props

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchUsers())
  }

  render() {
    return (
      <Main />
    )
  }
}

export default connect()(MainContainer)
