// @flow
import React from 'react'
import { Dialog } from 'components/Dialog'
import { Notes } from 'components/Notes'
import { Warnings } from 'components/Warnings'
import FormButtons from 'components/FormButtons'
import { GPTAccountRef } from 'components/References'
import Ticket from 'components/Ticket'
import { FeeCalculationsRef } from './FeeCalculationsRef'
import { FundCheckLogRef } from './FundCheckLogRef'
import { WarningsAcknowledgementRef } from './WarningsAcknowledgementRef'
import styles from './styles'

type Props = {
  canSubmit: Function,
  dispatch: Function,
  isDeleting: boolean,
  isSubmitting: boolean,
  onBeginDelete: boolean,
  onCancelDelete: boolean,
  onChange: Function,
  onSubmit: Function,
  onDelete: Function,
  ticket: Object,
  values: Object,
}

const Reference = ({ dispatch, ticket }: Props) => (
  <section>
    <GPTAccountRef
      beneficiary={{ name: ticket.beneficiary_name }}
      number={ticket.account_number}
      status={ticket.account_status}
    />
    <Warnings warnings={ticket.warnings} />
    <Notes
      accountNumber={ticket.account_number}
      dispatch={dispatch}
      notes={ticket.notes}
    />
  </section>
)

const FeeStation = (props: Props) => {
  const {
     canSubmit,
     isDeleting,
     isSubmitting,
     onBeginDelete,
     onCancelDelete,
     onDelete,
     onChange,
     onSubmit,
     ticket,
     values } = props
  const {
    acknowledgeDuplicateCheck,
    acknowledgeEarlyDate,
    fee_rate,
    fee_schedule
   } = values
  const header = { subheader: 'Deposit', title: 'Fee Calculation' }
  const reference = ticket && <Reference {...props} />
  return (
    <Ticket header={header} history={ticket.history} reference={reference}>
      <form onSubmit={onSubmit}>
        <p className={styles.infoText}>Confirm fee calculations.</p>
        <FeeCalculationsRef
          amount={ticket.amount}
          balance={ticket.balance}
          feeRate={fee_rate}
          feeSchedule={fee_schedule}
          hasCustomFeeSchedule={ticket['custom_fee_schedule?']}
          enrollmentFee={ticket['enrollment_fee']}
          isRefund={ticket.is_refund}
          isTransfer={ticket.is_transfer}
        />
        <section className={styles.cardMarginBot}>
          <section className={styles.sectionNoMargin}>
            <div className={styles.formItemMono}>
              <label className={styles.label} htmlFor="fee_rate">
                Fee Rate (%)
              </label>
              <input
                autoFocus
                className={styles.input}
                max={100}
                min={0}
                name="fee_rate"
                onChange={onChange}
                step={0.01}
                type="number"
                value={fee_rate}
                disabled={ticket.is_refund || ticket.is_transfer}
              />
            </div>
          </section>
        </section>
        <FundCheckLogRef {...props.ticket} />
        <WarningsAcknowledgementRef
          warnings={ticket.warnings}
          onChange={onChange}
          acknowledgeEarlyDate={acknowledgeEarlyDate}
          acknowledgeDuplicateCheck={acknowledgeDuplicateCheck}
        />
        <FormButtons
          disabled={!canSubmit}
          onDelete={onBeginDelete}
          submitLabel="Save and Continue"
          submitting={isSubmitting}
        />
      </form>
      {isDeleting && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Yes, Delete"
          headerText="Are you sure you want to delete this deposit?"
          onCancel={onCancelDelete}
          onConfirm={onDelete}
        />
      )}
    </Ticket>
  )
}

export { FeeStation }
