// @flow
import moment from 'moment'

const isDateBeforeToday = (date) => {
    const momentToday = moment().format('YYYY-MM-DD')
    const momentDate = moment(date)

    return momentDate.isBefore(momentToday)
}

export default {
  isDateBeforeToday
}