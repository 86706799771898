// @flow
import React from 'react'
import { connect } from 'react-redux'
import { httpGet, httpUrl } from 'lib/http'
import { setLoad, setUnload } from 'lib/notification/actions'
import { Template } from './Template'
import streamSaver from 'streamsaver'

type Props = {
  dispatch: Function,
  params: Object,
}

type State = {
  allClosings: [Object],
  closings: [Object],
  events_due: [Object],
  searchInput: String,
}

class Closings extends React.Component<Props, State> {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      allClosings: [],
      closings: [],
      events_due: [],
      searchInput: '',
    }
  }

  componentDidMount() {
    this.fetchClosings()
  }

  fetchClosings = () => {
    this.props.dispatch(setLoad())
    httpGet('/admin/closings')
      .then((data) => {
        this.setState({
          allClosings: data.closings,
          closings: data.closings,
          events_due: data.events_due,
        })
      })
      .then(() => this.props.dispatch(setUnload()))
      .catch(() => {})
  }
  filterAccountsBySearch = (searchInput) => {
    const filtered = this.state.allClosings.filter((c) =>
      c.number.startsWith(searchInput),
    )

    if (filtered.length > 0 && searchInput.length > 1) {
      this.setState({ closings: filtered })
    } else {
      this.setState({ closings: this.state.allClosings })
    }
  }

  onSearchInputChange = (event) => {
    const newSearchInput = event.target.value.toUpperCase()
    this.setState({ searchInput: newSearchInput })
    this.filterAccountsBySearch(newSearchInput)
  }

  onClearClick = () => {
    this.setState({ searchInput: '' })
    this.setState({ closings: this.state.allClosings })
  }

  onExportClick = () => {
    const sid = localStorage.getItem('sid')
    const url = httpUrl('/admin/closings/export')

    this.props.dispatch(setLoad())
    fetch(url, {
      headers: {
        Authorization: `Bearer ${sid}`,
      },
    })
      .then((response) => {
        const fileStream = streamSaver.createWriteStream('closings-report.csv')
        response.body.pipeTo(fileStream)
      })
      .then(() => this.props.dispatch(setUnload()))
  }

  render() {
    return (
      <Template
        closings={this.state.closings}
        events_due={this.state.events_due}
        searchInput={this.state.searchInput}
        onClearClick={this.onClearClick}
        onExportClick={this.onExportClick}
        onSearchInputChange={this.onSearchInputChange}
      />
    )
  }
}

export default connect()(Closings)
