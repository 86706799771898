// @flow
import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  className: string,
  children: any,
  to: string,
}

const LinkButton = ({ className, children, to }: Props) => {
  const navigate = useNavigate()
  return (
    <button
      type="button"
      children={children}
      className={className}
      onClick={() => {
        navigate(to)
      }}
    />
  )
}

export default LinkButton
