// @flow
import { connect } from 'react-redux'
import React from 'react'
import { httpGet, httpPut } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { Template } from './Template'

type Props = {
  dispatch: Function,
  location: Object,
}

type State = {
  accounts: [Object],
  canSubmit: boolean,
  needsApproval: boolean,
  newStateDescription: String,
  toSuspendAccount: String,
  toUnsuspendAccount: String,
  searchInput: String,
  allSuspensions: [Object],
}

class Main extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      accounts: [],
      allSuspensions: [],
      canSubmit: false,
      needsApproval: false,
      newStateDescription: '',
      toSuspendAccount: '',
      toUnsuspendAccount: '',
      searchInput: '',
    }
  }

  props: Props

  fetchSuspendedAccounts = () => {
    const { dispatch } = this.props

    dispatch(setLoad())
    httpGet('/admin/suspensions')
      .then((data) => {
        this.setState({
          accounts: data.accounts,
          allSuspensions: data.accounts,
        })
      })
      .then(() => dispatch(setUnload()))
  }

  toggleAccount = (account) => {
    const { dispatch } = this.props
    const upperAccount = account.trim().toUpperCase()

    dispatch(setLoad())
    httpPut(`/admin/suspensions/${upperAccount}`)
      .then(({ accounts }) => {
        this.setState({ accounts })
      })
      .catch((error) => {
        dispatch(setBanner(error.message, 'danger'))
      })
      .then(() => dispatch(setUnload()))
  }

  onRemoveClicked = (account) => {
    this.setState({
      toUnsuspendAccount: account,
      needsApproval: true,
      newStateDescription: `unsuspend account ${account}`,
    })
  }

  onSuspendClicked = () => {
    this.setState({
      needsApproval: true,
      newStateDescription: `suspend account ${this.state.toSuspendAccount}`,
    })
  }

  onCancelChange = () => {
    this.setState({ needsApproval: false })
  }

  onApproveChange = () => {
    const account = this.state.toUnsuspendAccount || this.state.toSuspendAccount
    this.toggleAccount(account)
    this.setState({
      needsApproval: false,
      toSuspendAccount: '',
      toUnsuspendAccount: '',
      newStateDescription: '',
    })
  }

  evalCanSubmit = (account) => {
    if (!account) {
      return false
    }
    if (account.length < 5) {
      return false
    }

    const equal = (element) => element == account

    if (this.state.accounts.some(equal)) {
      return false
    }

    return true
  }

  handleAccountChange = (event) => {
    const account = event.currentTarget.value
    this.setState({
      canSubmit: this.evalCanSubmit(account),
      toSuspendAccount: account,
    })
  }

  filterAccountsBySearch = (searchInput) => {
    const filtered = this.state.allSuspensions.filter((c) =>
      c.number.startsWith(searchInput),
    )

    if (filtered.length > 0 && searchInput.length > 1) {
      this.setState({ accounts: filtered })
    } else {
      this.setState({ accounts: this.state.allSuspensions })
    }
  }

  onSearchInputChange = (event) => {
    const newSearchInput = event.target.value.toUpperCase()
    this.setState({ searchInput: newSearchInput })
    this.filterAccountsBySearch(newSearchInput)
  }

  onClearClick = () => {
    this.setState({ searchInput: '' })
    this.setState({ accounts: this.state.allSuspensions })
  }

  componentDidMount() {
    this.props
    this.fetchSuspendedAccounts()
  }

  render() {
    return (
      <Template
        accounts={this.state.accounts}
        canSubmit={this.state.canSubmit}
        needsApproval={this.state.needsApproval}
        newStateDescription={this.state.newStateDescription}
        toSuspendAccount={this.state.toSuspendAccount}
        toUnsuspendAccount={this.state.toUnsuspendAccount}
        onRemoveClicked={this.onRemoveClicked}
        handleAccountChange={this.handleAccountChange}
        onSuspendClicked={this.onSuspendClicked}
        onCancelChange={this.onCancelChange}
        onApproveChange={this.onApproveChange}
        searchInput={this.state.searchInput}
        onClearClick={this.onClearClick}
        onSearchInputChange={this.onSearchInputChange}
      />
    )
  }
}

export default connect()(Main)
