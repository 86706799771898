// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getBeneficiary as getBenef } from 'lib/beneficiary/actions'
import { getBeneficiary } from 'lib/beneficiary/selectors'
import Show from './component'
import Error from 'components/Error'
import { setLoad, setUnload } from 'lib/notification/actions'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  beneficiary: Object,
  dispatch: Function,
  location: Object,
}

class Container extends Component {
  props: Props
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    const { dispatch, location } = this.props
    const { id } = location.params
    const fileKeys = ['photo_id']
    dispatch(setLoad())
    dispatch(getBenef(id, fileKeys))
      .then(() => {
        this.setState({ error: false })
      })
      .catch(() => {
        this.setState({ error: true })
      })
      .then(() => {
        dispatch(setUnload())
      })
  }
  render() {
    if (this.state.error) {
      return (
        <Error
          title="404: Beneficiary Not Found"
          message="The beneficiary you are looking for does not exist."
          icon="beneficiary"
          buttonLink="/contacts"
          buttonTitle="View All Contacts"
        />
      )
    }
    if (this.state.error === false && this.props.beneficiary) {
      return (
        <Show
          beneficiary={this.props.beneficiary}
          dispatch={this.props.dispatch}
        />
      )
    }
    return null
  }
}

export default withRouter(
  connect(
    createStructuredSelector({
      beneficiary: getBeneficiary,
    }),
  )(Container),
)
