// @flow
import SearchPage from './components/Search'
import Edit from './components/Edit'
import Merge from './components/Merge'

export default (store) => ({
  path: 'contacts',
  children: [
    {
      index: true,
      element: <SearchPage />,
    },
    {
      element: <Edit />,
      path: ':id/edit',
    },
    {
      element: <Merge />,
      path: 'merge',
    },
  ],
})
