// @flow
import React from 'react'
import { connect } from 'react-redux'
import formatters from 'lib/formatters'
import { httpDelete, httpPost } from 'lib/http'
import { Dialog } from 'components/Dialog'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { Editor } from '@tinymce/tinymce-react'
import styles from './styles'
import type { Note } from 'lib/types'

type Props = {
  dispatch: Function,
  parentEntity: string,
  parentId: number,
  notes: [Note],
}

class RichNotes extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      currentNotePriority: null,
      deleteNoteId: null,
      notes: props.notes,
      noteBeingEdited: '',
      toggleNotePriority: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.notes != prevProps.notes) {
      this.setState({ notes: this.props.notes })
    }
  }

  handleSubmit = () => {
    const { dispatch } = this.props
    const note = this.state.noteBeingEdited

    const data = {
      note: note,
      parent_entity: this.props.parentEntity,
      parent_id: this.props.parentId
    }

    dispatch(setLoad())
    return httpPost('/admin/notes', data)
      .then(({ note }) => {
        this.setState({
          notes: [note, ...this.state.notes],
          noteBeingEdited: ''
        })
        dispatch(setBanner('Note added successfully'))
      })
      .catch(() => {
        dispatch(setBanner('Error adding note!', 'danger'))
      })
      .then(() => {
        dispatch(setUnload())
      })
  }

  handleRemove = (noteId) => {
    const url = `/admin/notes/${noteId}/`
    this.props.dispatch(setLoad())
    return httpDelete(url)
      .then(() => {
        const { notes } = this.state
        const newNotes = notes.filter((note) => note.id !== noteId)
        this.setState({ notes: newNotes })
        this.props.dispatch(setBanner('Note successfully removed'))
      })
      .catch(() => {
        this.props.dispatch(setBanner('Error removing note', 'danger'))
      })
      .then(() => {
        this.setState({ deleteNoteId: null })
        this.props.dispatch(setUnload())
      })
  }

  priorityClassName = (note) => {
    return note.priority == 'high' ? `${styles.importantNoteWrapper}` : ''
  }

  renderNoteRows = () => {
    const { notes } = this.state
    return notes.map((note, idx) => {
      const timestamp = formatters.timestamp(note.leftAt)

      return (
        <li
          key={idx}
          className={`${styles.noteWrapper} ` + this.priorityClassName(note)}
        >
          <div className={styles.flexRow}>
            <div className={styles.flexSB}>
              <div className={styles.flexRow}>
                <div className={styles.noteContainer}>
                  <div className={styles.user}>{`${note.user.split(
                    '@',
                    1,
                  )}, ${timestamp}`}</div>
                  <div className={styles.readOnlyRichTextEditorContainer}>
                    <Editor
                      apiKey='8ex4v3co62pk1eja7235hzj3ktainwoplhcny5nlu56p8ic3'
                      disabled={true}
                      value={note.note}
                      init={{
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                        plugins: ['autoresize'],
                      }}
                  />
                  </div>
                </div>
              </div>
              <div className={styles.remove}>
                <a
                  title="Remove Note"
                  onClick={() => this.setState({ deleteNoteId: note.id })}
                />
              </div>
            </div>
          </div>
        </li>
      )
    })
  }

  renderPriorityHeaderText = () => {
    const { currentNotePriority } = this.state
    return currentNotePriority == 'high'
      ? 'Remove high priority from this note?'
      : 'Make this note high priority?'
  }

  onNoteEdit = (content) => {
    this.setState({noteBeingEdited: content})
  }

  render() {
    const { deleteNoteId } = this.state
    return (
      <div>
        <ul className={styles.notes}>{this.renderNoteRows()}</ul>
        <div className={styles.rteContainer}>
          <Editor
          apiKey='8ex4v3co62pk1eja7235hzj3ktainwoplhcny5nlu56p8ic3'
          value={this.state.noteBeingEdited}
          onEditorChange={this.onNoteEdit}
          init={{
            min_height: 300,
            menubar: false,
            statusbar: false,
            plugins: [
              'autolink lists link image print preview anchor',
              'searchreplace fullscreen table paste'
              ],
            toolbar:
              'undo redo | bold italic backcolor | \
              alignleft aligncenter alignright | \
              table | bullist numlist outdent indent | \
              link fullscreen print'
            }}
          />
        </div>
        {
          this.state.noteBeingEdited && (
            <button
              className={styles.approve}
              onClick={() => this.handleSubmit()}
            >
              Save Note
            </button>
          )
        }
        {deleteNoteId && (
          <Dialog
            cancelButtonText="Cancel"
            confirmButtonText="Yes, Delete"
            headerText="Are you sure you want to delete this note?"
            onCancel={() => this.setState({ deleteNoteId: null })}
            onConfirm={() => this.handleRemove(deleteNoteId)}
          />
        )}
      </div>
    )
  }
}

export default connect()(RichNotes)
