// @flow
import React from 'react'
import { S3FileInput } from 'components/S3FileInput'
import styles from './styles'

type Props = {
  approvedEarlyDate: boolean,
  errors: Object,
  isDepositDateEarly: boolean,
  onBlur: Function,
  onChange: Function,
  values: Object,
}

const testHookClass = 'test-hook-row-error'

const renderLabelError = (error) => <strong>&ensp;&middot;&ensp;{error}</strong>

const renderCheckFields = ({ errors, onChange, values }: Props) => (
  <div>
    <div className={styles.formItem}>
      <div className={errors.check && styles.errorWrapper}>
        <label
          className={`${styles.label} ${testHookClass}`}
          htmlFor="verification_image">
          Scanned Check
          {errors.check && renderLabelError(errors.check)}
        </label>
        <S3FileInput
          name="verification_image"
          onChange={onChange}
          value={values.verification_image}
          existing={values.verification_image_key}
        />
      </div>
    </div>
  </div>
)

export const DepositFunds = (props: Props) => {
  const {
    approvedEarlyDate,
    errors,
    isDepositDateEarly,
    onBlur,
    onChange,
    values
  } = props
  const shouldCheckFieldsRender = ['check', 'cashiers_check'].includes(
    values.deposit_type,
  )
  const requiresDepositVerification = ['wire', 'direct_deposit'].includes(
    values.deposit_type,
  )

  return (
    <section className={styles.cardMarginBot}>
      <section className={styles.sectionNoMargin}>
        <div className={styles.formItemHalf}>
          <label className={styles.label} htmlFor="deposit_type">
            Deposit Type
          </label>
          <div>
            <select
              className={styles.select}
              name="deposit_type"
              onChange={onChange}
              value={values.deposit_type}
            >
              <option value="check">Check</option>
              <option value="cashiers_check">Cashier&rsquo;s Check</option>
              <option value="direct_deposit">Direct Deposit</option>
              <option value="wire">Wire</option>
            </select>
          </div>
        </div>
        <div className={styles.formItemMono}>
          <div className={errors.deposit_date && styles.errorWrapper}>
            <label
              className={`${styles.label} ${testHookClass}`}
              htmlFor="deposit_date"
            >
              Deposit Date
              {errors.deposit_date && renderLabelError(errors.deposit_date)}
            </label>
            <input
              className={styles.input}
              name="deposit_date"
              onChange={onChange}
              type="date"
              value={values.deposit_date}
            />
          </div>
        </div>
        <div className={styles.formItemMono}>
          <div className={errors.amount && styles.errorWrapper}>
            <label
              className={`${styles.label} ${testHookClass}`}
              htmlFor="amount"
            >
              Deposit Amount
              {errors.amount && renderLabelError(errors.amount)}
            </label>
            <input
              className={styles.input}
              name="amount"
              onBlur={onBlur}
              onChange={onChange}
              placeholder="0.00"
              step={0.01}
              type="number"
              value={values.amount}
            />
          </div>
        </div>
        <div className={styles.inputLabelGroup}>
          <input
            name="is_refund"
            type="checkbox"
            value={values.is_refund}
            disabled={values.is_transfer}
            onChange={onChange}
          />
          <label className={styles.label} htmlFor="is_refund">
            Is this a refund?
          </label>
        </div>
        <div className={styles.inputLabelGroup}>
          <input
            name="is_transfer"
            type="checkbox"
            value={values.is_transfer}
            disabled={values.is_refund}
            onChange={onChange}
          />
          <label className={styles.label} htmlFor="is_transfer">
            Is this a transfer from investment?
          </label>
        </div>
        {requiresDepositVerification && (
          <div className={styles.formItem}>
            <div className={errors.verification_image && styles.errorWrapper}>
              <label className={styles.label} htmlFor="verification_image">
                Deposit Verification
                {errors.verification_image &&
                  renderLabelError(errors.verification_image)}
              </label>
              <S3FileInput
                name="verification_image"
                onChange={onChange}
                value={values.verification_image}
                existing={values.verification_image_key}
              />
            </div>
          </div>
        )}
        {shouldCheckFieldsRender && renderCheckFields(props)}
        <div>
          {
            isDepositDateEarly &&
            <div className={styles.warningLabelGroup}>
              <input
                id="approvedEarlyDate"
                name="approvedEarlyDate"
                type="checkbox"
                checked={approvedEarlyDate}
                onChange={onChange}
              />
              <label
                className={styles.warningLabel}
                htmlFor="approvedEarlyDate">
                I acknowledge that the deposit date is more than 15 days ago
              </label>
            </div>
          }
        </div>
      </section>
    </section>
  )
}
