// @flow
import Container from './container'
import { injectReducer } from 'store/reducers'
import { KEY } from './constants'
import reducer from './reducer'

export default (store) => {
  injectReducer(store, { key: KEY, reducer })
  return {
    path: 'approve-accounts',
    element: <Container />,
  }
}
