// @flow
import React from 'react'
import type { File } from 'lib/types'
import { httpDelete } from 'lib/http'
import { fetchAccountHTTP } from 'lib/account/actions'
import { setModal, unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import verificationDocLabels from 'lib/verificationDocLabels'
import { FilePreview } from 'components/FilePreview'
import styles from './styles'

type Props = {
  attachments: Array,
  dispatch: Function,
  grantor_relationship: String,
  joinder_agreement: File,
  number: String,
  primary_grantor_doc: File,
  secondary_grantor_doc: File,
}

const showLink = (label, file) => <FilePreview file={file} label={label} />

const deleteFile = (dispatch, accountNumber, fileId) => {
  return (
    <section>
      <div className='section'>
        <h4>
          Are you sure you want to delete this file?
        </h4>
      </div>
      <div className={styles.buttons}>
        <button
          className={styles.removeBtn}
          onClick={() => handleDelete(dispatch, accountNumber, fileId)}
        >
          Delete File
        </button>
        <span className={styles.spacer} />
        <button
          className={styles.cancelBtn}
          onClick={() => dispatch(unsetModal())}
        >
          Cancel
        </button>
      </div>
    </section>
  )
}

const handleDelete = (dispatch, accountNumber, fileId) => {
  dispatch(setLoad())
  return httpDelete(`/admin/accounts/${accountNumber}/attachments/${fileId}`)
  .then(() => {
    dispatch(fetchAccountHTTP(accountNumber))
    dispatch(setBanner('File successfully deleted.'))
  })
  .catch(() => {
    dispatch(setBanner('File unsuccessfully deleted.', 'danger'))
  })
  .then(() => {
    dispatch(setUnload())
    dispatch(unsetModal())
  })
}

const showOtherDocs = ({ attachments, number, dispatch }: Props) => {
  if (!Array.isArray(attachments)) return null
  let docs
  if (attachments.length) {
    docs = attachments.map((attachment, idx) => {
      const { file_id } = attachment
      const deleteHandler = () => {
        dispatch(setModal(deleteFile(dispatch, number, file_id)))
      }
      return (
        <div className={styles.otherDocsRow} key={idx}>
          {showLink(attachment.name, attachment)}
          <div className={styles.remove}>
            <a
              title="Delete File"
              onClick={deleteHandler}
            />
          </div>
        </div>
      )
    })
  } else {
    docs = <div className={styles.noDocResults}>No documents</div>
  }
  return (
    <section className={styles.cardMarginBot}>
      <h5 className={styles.titleSection}>Misc. Documents</h5>
      <div className={styles.docsWrapper}>
        {docs}
      </div>
    </section>
  )
}

const GPTAccountDocsRef = (props: Props) => {
  let primary, secondary, joinder, noRequiredDocs
  const { grantor_relationship: relationship } = props
  const labels = verificationDocLabels(relationship)

  if (props.primary_grantor_doc) {
    primary = (
      <div className={styles.docRow}>
        {showLink(labels.primary, props.primary_grantor_doc)}
      </div>
    )
  }

  if (props.secondary_grantor_doc) {
    secondary = (
      <div className={styles.docRow}>
        {showLink(labels.secondary, props.secondary_grantor_doc)}
      </div>
    )
  }

  if (props.joinder_agreement) {
    joinder = (
      <div className={styles.docRow}>
        {showLink('Joinder Agreement', props.joinder_agreement)}
      </div>
    )
  }

  if (!primary && !secondary && !joinder) {
    noRequiredDocs = <div className={styles.noDocResults}>No documents</div>
  }

  return (
    <section>
      <section className={styles.cardMarginBot}>
        <h5 className={styles.titleSection}>Required Documents</h5>
        <div className={styles.sectionNoMargin}>
          {joinder}
          {primary}
          {secondary}
          {noRequiredDocs}
        </div>
      </section>
      {showOtherDocs(props)}
    </section>
  )
}

export default GPTAccountDocsRef
