import React from 'react'
import {
  JOURNAL_IDS,
  LEGACY_PRODUCTS,
  POOLED_FLOW_PRODUCTS,
} from 'lib/constants'
import { setLoad, setUnload } from 'lib/notification/actions'
import { httpGet } from 'lib/http'
import type { Product } from 'lib/types'
import styles from '../DynamicSelect/styles'

type Props = {
  dispatch: Function,
  excludeLegacy: boolean,
  label: string,
  onProductLoad: Function,
  placeholder: string,
}

type State = {
  journalId: string,
}

const renderArrow = () => <i className={styles.arrow} />

export class DynamicTrustSelect extends React.Component<Props, State> {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      journalId: '',
    }
  }

  buildOptions = () => {
    if (this.props.excludeLegacy) {
      return JOURNAL_IDS.filter((jid) => {
        return (
          !LEGACY_PRODUCTS.includes(jid.label) &&
          POOLED_FLOW_PRODUCTS.includes(jid.label)
        )
      })
    } else {
      return JOURNAL_IDS
    }
  }

  fetchProduct = (journalId) => {
    const { dispatch } = this.props
    dispatch(setLoad())
    httpGet(`/admin/products?journal_id=${journalId}`)
      .then((data: Product) => {
        this.props.onProductLoad(data)
      })
      .then(() => dispatch(setUnload()))
      .catch((error) => {
        throw error
      })
  }

  handleJournalIdChange = (journalId) => {
    this.setState({ journalId })
    this.fetchProduct(journalId)
  }
  render() {
    const { label, placeholder } = this.props
    return (
      <div className={`${styles.dynamicInput} ${styles.formItem}`}>
        <label className={styles.label} htmlFor="product">
          {label}
        </label>
        <select
          className={styles.select}
          name="product"
          value={this.state.journalId}
          onChange={(e) => {
            this.handleJournalIdChange(e.target.value)
          }}
        >
          <option value="" disabled={true}>
            {placeholder || 'Select one'}
          </option>
          {this.buildOptions().map(({ label, value }) => {
            return (
              <option key={value} label={label}>
                {value}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
}
