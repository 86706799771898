// @flow
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './AutocompleteResults.css'

type SearchResult = {
  id: String,
  line1: String,
  line2: String,
  line3: String,
}

type Props = {
  query: String,
  focusedIndex: Number,
  searchType: String,
  searchResults: [SearchResult],
  onItemClick: Function,
}

function highlighLabel(label, search) {
  if (label && label.toLowerCase().includes(search.toLowerCase())) {
    return label.replace(
      search,
      `<span class="${styles.matchedText}">${search}</span>`,
    )
  }

  return label
}

export const AutocompleteResults = ({
  query,
  searchResults,
  searchType,
  focusedIndex,
  onItemClick,
}: Props) => {
  return (
    <div className={styles.autoCompleteItems}>
      {searchResults.length > 0 && (
        <React.Fragment>
          <span className={styles.autoCompleteItems_sectionTitle}>
            {searchType}
          </span>
          <ul>
            {searchResults.map((result, idx) => (
              <li
                key={idx}
                role="button"
                className={`${idx === focusedIndex ? styles.active : ''}`}
                onClick={() => onItemClick(result.id)}
              >
                <FontAwesomeIcon
                  size="lg"
                  className={styles.autoCompleteItems_icon}
                  icon={['fas', 'user']}
                />
                <div className={styles.autoCompleteItems_label}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlighLabel(result.line1, query),
                    }}
                    className={styles.top_line}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: highlighLabel(result.line2, query),
                    }}
                    className={styles.small}
                  />
                  {result.line3 && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlighLabel(result.line3, query),
                      }}
                      className={styles.small}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </div>
  )
}
