const theme = {
  button: {
    icon: {
      default: 40,
      small: 20,
    },
  },

  color: {
    active: '#51B14B',
    appBG: '#FAFAFA',
    blue: '#376EA1',
    border: '#E4E4E4',
    error: '#E33131',
    green: '#5FABA1',
    inactive: 'rgba(0,0,0,.1)',
    input: {
      default: 'rgba(102,102,102,1)',
      placeholder: 'rgba(102,102,102,.7)',
    },
    red: '#923F41',
    status: {
      progress: '#E7C76D',
      review: '#376EA1',
      rejected: '#923F41',
      complete: '#41BD4B',
      archive: 'rgba(0,0,0,.1)',
    },
    text: {
      default: '#666666',
      dark: '#31333F',
      light: '#9B9B9B',
    },
    warning: '#DED735',
    white: '#ffffff',
  },

  font: {
    family: 'MaisonNeue, sans-serif',
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      demi: 600,
      bold: 700,
    },
  },

  input: {
    border: {
      default: '#e4e4e4',
      focus: '#C3C3C3',
    },
    height: 45,
  },

  list: {
    item: {
      height: 50,
    },
  },

  rem: 16,

  sidebar: {
    width: 402,
  },
}

export default theme
