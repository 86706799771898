// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* content container */

/* header */
`, "",{"version":3,"sources":["webpack://./universal/styles/ui.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAGtB,WAAW","sourcesContent":["/* content container */\n@value contentMaxWidth: 1440px;\n\n/* header */\n@value headerHeight: 65px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentMaxWidth": `1440px`,
	"headerHeight": `65px`
};
export default ___CSS_LOADER_EXPORT___;
