// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header__sectionSmall___QZ1SO {
}

.Header__row___ni6fh {
}

.Header__ticketFlag___iVai9 {
  display: inline-block;
  height: 25px;
  margin: 0;
  padding: 0;
}

.Header__ticketFlag___iVai9 img {
    height: 25px;
    margin-right: 5px;
  }

.Header__boxLabel___e9Wf1 {
  line-height: 26px;
  margin: 0;
}

.Header__nameWrapper___SmESR {
  width: calc(99.9% * 3/5 - (30px - 30px * 3/5));
}

.Header__nameWrapper___SmESR:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.Header__nameWrapper___SmESR:last-child {
  margin-right: 0;
}

.Header__nameWrapper___SmESR:nth-child(5n) {
  margin-right: 0;
  float: right;
}

.Header__nameWrapper___SmESR:nth-child(5n + 1) {
  clear: both;
}

.Header__nameWrapper___SmESR .Header__boxLabel___e9Wf1 {
    margin-right: 20px;
  }

.Header__beneName___ZjHca {
  box-shadow: none;
  color: text;
  font-size: 25px;
}

.Header__balanceWrapper___XbOip {
  width: calc(99.9% * 2/5 - (30px - 30px * 2/5));
  text-align: right;
}

.Header__balanceWrapper___XbOip:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.Header__balanceWrapper___XbOip:last-child {
  margin-right: 0;
}

.Header__balanceWrapper___XbOip:nth-child(1n) {
  margin-right: 0;
  float: right;
}

.Header__balanceWrapper___XbOip:nth-child(1n + 1) {
  clear: both;
}

.Header__titleBox___Qw_8k {
  position: relative;
}

.Header__titleName___d9Ove {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-top: -5px;
}

.Header__accountNumber___EBYVc {
  background: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGPT"]};
  border-radius: 3px;
  color: white;
  display: inline-block;
  margin-bottom: 5px;
  padding: 2px 4px;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Account/components/Number/Subaccounts/Main/Header/Header.css"],"names":[],"mappings":"AAIA;AAEA;;AAEA;AAEA;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,SAAS;EACT,UAAU;AAMZ;;AAJE;IACE,YAAY;IACZ,iBAAiB;EACnB;;AAGF;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,8CAAgB;AAKlB;;AANA;EACE,WAAgB;EAAhB,kBAAgB;EAAhB,WAAgB;AAKlB;;AANA;EACE,eAAgB;AAKlB;;AANA;EACE,eAAgB;EAAhB,YAAgB;AAKlB;;AANA;EACE,WAAgB;AAKlB;;AAHE;IACE,kBAAkB;EACpB;;AAGF;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,8CAAkB;EAClB,iBAAiB;AACnB;;AAHA;EACE,WAAkB;EAAlB,kBAAkB;EAAlB,WAAkB;AAEpB;;AAHA;EACE,eAAkB;AAEpB;;AAHA;EACE,eAAkB;EAAlB,YAAkB;AAEpB;;AAHA;EACE,WAAkB;AAEpB;;AAEA;EAEE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,wDAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["@value helpers: 'styles/helpers.css';\n@value colors: 'styles/colors.css';\n@value gtGPT from colors;\n\n.sectionSmall {\n  composes: sectionSmall from helpers;\n}\n\n.row {\n  composes: row from helpers;\n}\n\n.ticketFlag {\n  display: inline-block;\n  height: 25px;\n  margin: 0;\n  padding: 0;\n\n  & img {\n    height: 25px;\n    margin-right: 5px;\n  }\n}\n\n.boxLabel {\n  line-height: 26px;\n  margin: 0;\n}\n\n.nameWrapper {\n  lost-column: 3/5;\n\n  .boxLabel {\n    margin-right: 20px;\n  }\n}\n\n.beneName {\n  box-shadow: none;\n  color: text;\n  font-size: 25px;\n}\n\n.balanceWrapper {\n  lost-column: 2/5 1;\n  text-align: right;\n}\n\n.titleBox {\n  composes: sectionSmall row;\n  position: relative;\n}\n\n.titleName {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 5px;\n  margin-top: -5px;\n}\n\n.accountNumber {\n  background: gtGPT;\n  border-radius: 3px;\n  color: white;\n  display: inline-block;\n  margin-bottom: 5px;\n  padding: 2px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `'styles/helpers.css'`,
	"colors": `'styles/colors.css'`,
	"gtGPT": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGPT"]}`,
	"sectionSmall": `Header__sectionSmall___QZ1SO ${___CSS_LOADER_ICSS_IMPORT_1___.locals["sectionSmall"]}`,
	"row": `Header__row___ni6fh ${___CSS_LOADER_ICSS_IMPORT_1___.locals["row"]}`,
	"ticketFlag": `Header__ticketFlag___iVai9`,
	"boxLabel": `Header__boxLabel___e9Wf1`,
	"nameWrapper": `Header__nameWrapper___SmESR`,
	"beneName": `Header__beneName___ZjHca`,
	"balanceWrapper": `Header__balanceWrapper___XbOip`,
	"titleBox": `Header__titleBox___Qw_8k Header__sectionSmall___QZ1SO ${___CSS_LOADER_ICSS_IMPORT_1___.locals["sectionSmall"]} Header__row___ni6fh ${___CSS_LOADER_ICSS_IMPORT_1___.locals["row"]}`,
	"titleName": `Header__titleName___d9Ove`,
	"accountNumber": `Header__accountNumber___EBYVc`
};
export default ___CSS_LOADER_EXPORT___;
