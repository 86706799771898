import React from 'react'
import styles from './Header.css'

const SectionHeader = ({ title, icon, extra, className }) => {
  return (
    <div className={`${styles.sectionHeader} ${className}`}>
      <h5>{title}</h5>
      {icon && <span>{icon}</span>}
      {extra && extra}
    </div>
  )
}

export default SectionHeader
