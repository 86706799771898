// @flow
export const DOB_FORMAT = 'MM/DD/YYYY'
export const EVENT_TIMESTAMP_FORMAT = 'h:mm A [on] MM/DD/YYYY'
export const FILE_TIMESTAMP_FORMAT = 'MM/DD/YY h:mm A'
export const ISO_DATE_FORMAT = 'YYYY-MM-DD'
export const ROLES = {
  able: 'Able',
  attorney: 'Attorney',
  beneficiary: 'Beneficiary',
  co_grantor: 'Co-Grantor',
  consult: 'Consult',
  grantor: 'Grantor',
  referrer: 'Referrer',
  representative: 'Representative',
}
export const GRANTOR_RELATIONSHIPS = {
  coGuardian: 'Co-Guardian',
  court: 'Court',
  famGuardian: 'Family Guardian',
  grandparent: 'Grandparent',
  parent: 'Parent',
  poa: 'Power of Attorney',
  proGuardian: 'Professional Guardian',
  self: 'Self',
  selfRep: 'Self with Representative',
}
export const GSNT_GRANTOR_RELATIONSHIPS = {
  family: 'Family',
  parent: 'Parent',
  grandparent: 'Grandparent',
  proGuardian: 'Professional Guardian',
  rep: 'Representative',
  other: 'Other'
}
export const JOURNAL_IDS = [
  {
    label: 'GPT New',
    value: 'gpt_new',
  },
  {
    label: 'GPT Old',
    value: 'gpt_old',
  },
  {
    label: 'GPT Checking',
    value: 'gpt_checking'
  },
  {
    label: 'GTA',
    value: 'gta'
  },
  {
    label: 'GSNT Old',
    value: 'gsnt_old'
  },
  {
    label: 'GSNT New',
    value: 'gsnt_new'
  }
]
export const PRODUCTS = {
  gpt_old: 'GPT Old',
  gpt_new: 'GPT New',
  gpt_checking: 'GPT Checking',
  gta: 'GTA',
  gsnt_old: 'GSNT Old',
  gsnt_new: 'GSNT New'
}
export const LEGACY_PRODUCTS = [
  'GPT Old'
]
export const EIN_PRODUCTS = [
  'GTA',
  'GSNT New'
]
export const POOLED_FLOW_PRODUCTS = [
  'GPT New',
  'GPT Checking',
  'GSNT New'
]
export const POTENTIAL_CLIENT_FLOW_PRODUCTS = [
  'GTA',
  'GSNT Old',
  'GSNT New'
]
export const TRUST_NAME_PRODUCTS = [
  'GTA'
]
export const SUBACCOUNT_TYPES = {
  asset: 'Asset',
  checking: 'Checking',
  individual_investment: 'Individual Investment',
  msa: 'MSA',
  pooled_investment: 'Pooled Investment',
  pooled_investment_checking: 'Pooled Investment / Checking',
}
export const DEFAULT_FOLDERS = [
	{
		'name': 'Correspondence',
		'files': []
	},
	{
		'name': 'Home',
		'files': []
	},
	{
		'name': 'Investments',
		'files': []
	},
	{
		'name': 'Other',
		'files': []
	},
	{
		'name': 'Previous Investment Statements',
		'files': []
	},
	{
		'name': 'Previous Tax Returns',
		'files': []
	},
	{
		'name': 'Previous Trust Accountings',
		'files': []
	},
	{
		'name': 'Settlement Paperwork',
		'files': []
	},
	{
		'name': 'Tax Documents',
		'files': []
	},
	{
		'name': 'Trust Documents',
		'files': []
	},
	{
		'name': 'Vehicle',
		'files': []
	},
]
