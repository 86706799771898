// @flow
import Show from './components'

export default (store) => ({
  path: 'beneficiaries',
  element: '',
  children: [
    {
      path: 'show/:id',
      element: <Show />,
    },
  ],
})
