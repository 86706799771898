// @flow
import React from 'react'
import type { Contact } from 'lib/types'
import styles from './styles'
import { httpGet } from 'lib/http'

const showLink = (label, key, style) => (
  <a className={style}
    onClick={() => fetchS3File(key)}
    target='_blank'>
    {label}
  </a>
)

const fetchS3File = (key: string) => {
  httpGet(`/admin/files/${key}/preview`).then(({url}) => {
    window.open(url, '_blank')
  })
}

const BeneficiaryDocsRef = (beneficiary: Contact) => {
  let photo_id = 'No attached documents'

  if (beneficiary.photo_id_key) {
    photo_id = showLink('Photo ID', beneficiary.photo_id_key, styles.imageLink)
  }

  return (
    <section className={styles.card}>
      <div className={styles.titleSection}>
        <h5>Attached Documents</h5>
      </div>
      <section className={styles.sectionNoMargin}>
        <div className={styles.docRow}>
          {photo_id}
        </div>
      </section>
    </section>
  )
}

export default BeneficiaryDocsRef
