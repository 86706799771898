// @flow
import React from 'react'
import type { File } from 'lib/types'
import { FileInput } from 'components/FileInput'
import styles from './styles'

type Props = {
  errorMessage: string,
  label: string,
  name: string,
  onChange: Function,
  value: File,
};

export const DocInput = (props: Props) => {
  const { errorMessage, label, name, onChange, value } = props
  if (!label) return null
  const errorLabel = errorMessage && ` - ${errorMessage}`
  const errorClass = errorMessage && styles.errorWrapper
  return (
    <div className={`${styles.formItem} ${errorClass}`}>
      <label className={styles.label} htmlFor={name}>
        {label}{errorLabel}
      </label>
      <FileInput
        name={name}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}
