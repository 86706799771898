// @flow
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, SubmissionError } from 'redux-form'
import formatters from 'lib/formatters'
import cleaners from 'lib/form/cleaners'
import { httpGet, httpPut } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import Component from './component'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  params: Object,
  location: Object,
}

const ComponentForm = reduxForm({
  form: 'editContact',
  propNamespace: 'form',
})(Component)

class Container extends React.Component<Props> {
  constructor(props) {
    super(props)

    this.state = {
      contact: null,
      errors: {},
    }
  }

  componentDidMount() {
    const { dispatch, location } = this.props
    const params = location?.params
    dispatch(setLoad())
    httpGet(`/admin/contacts/${params.id}/edit`)
      .then((contact) => {
        this.setState({ contact: { ...contact, id: params.id } })
      })
      .then(() => dispatch(setUnload()))
  }

  props: Props

  handlePhoneChange = () => {
    const errors = { ...this.state.errors, phoneNumbers: null }
    this.setState({ errors })
  }

  handleSubmit = (data, dispatch) => {
    const { id } = this.state.contact
    return httpPut(`/admin/contacts/${id}`, cleaners.contact(data))
      .then((res) => {
        dispatch(setBanner('Contact successfully updated.'))
        this.props.location.navigate(-1)
      })
      .catch(({ errors }) => {
        const bannerMessage =
          'Contact was not updated. Fill out marked ' +
          'required fields that are based on their current roles.'

        const errorMessages = {}
        errors?.forEach((e) => (errorMessages[e.location] = e.message))
        dispatch(setBanner(bannerMessage, 'danger'))
        dispatch(setUnload())
        this.setState({ errors: errorMessages })
        throw new SubmissionError(errorMessages)
      })
  }

  render() {
    const { contact } = this.state
    if (!contact) return null

    const formattedContact = {
      ...contact,
      cellPhone: formatters.phone(contact.cellPhone),
      dayPhone: formatters.phone(contact.dayPhone),
      eveningPhone: formatters.phone(contact.eveningPhone),
      fax: formatters.phone(contact.fax),
      photoId: {
        content_type: contact.photoIdContentType,
        data: contact.photoIdData,
        key: contact.photoIdKey,
        initial: true,
      },
      ssn: formatters.ssn(contact.ssn),
    }
    return (
      <ComponentForm
        contact={contact}
        initialValues={formattedContact}
        onPhoneChange={this.handlePhoneChange}
        onSubmit={this.handleSubmit}
        errors={this.state.errors}
      />
    )
  }
}

export default withRouter(connect()(Container))
