// @flow
import React from 'react'
import FormButtons from 'components/FormButtons'
import { TrustInput } from 'components/TrustInput'
import { Table, Row, Cell } from 'components/Table'
import styles from './styles'

type Props = {
  canSubmit: boolean,
  onChange: Function,
  onSubmit: Function,
  journalId: string,
  quarter: string,
  year: string,
  statements: Object[],
}

const wrapSubmit = (onSubmit) => (event) => {
  onSubmit()
  event.preventDefault()
}

const yearOptions = () => {
  const options = []
  const maxYear = new Date().getFullYear()

  for (let year = maxYear; year >= 2017; year--) {
    options.push(
      <option key={year} value={year.toString()}>
        {year.toString()}
      </option>,
    )
  }

  return options
}

const renderStatementsList = (statements) => {
  if (statements) {
    saveStatementsToLocal(statements)

    return (
      <div className={styles.table}>
        <Table
          title="Statements"
          headers={['Batch', 'First Account']}
          tableSize="large"
          tableType="read"
        >
          {renderLineItems(statements.first_accounts)}
        </Table>
      </div>
    )
  } else {
    return <div></div>
  }
}

const renderLineItems = (items) => {
  var rows = []

  Object.entries(items).forEach(([key, value]) => {
    rows.push(renderRow(key, value))
  })

  return rows
}

const renderRow = (key, value) => {
  const stmt_link = (
    <a onClick={() => openStatementWindow(key, value)}>{value}</a>
  )

  return (
    <Row key={`batch-${key}`}>
      <Cell value={key} />
      <Cell value={stmt_link} />
    </Row>
  )
}

const saveStatementsToLocal = (statements) => {
  Object.keys(statements).forEach((key) => {
    localStorage.setItem(key, JSON.stringify(statements[key]))
  })
}

const openStatementWindow = (id, account) => {
  window.open(`/dcf-report-view?id=${id}&account=${account}`)
}

const Template = (props: Props) => (
  <section>
    <div className={styles.localHeader}>
      <div className={styles.localHeaderInner}>
        <h5>Generate DCF Report</h5>
      </div>
    </div>
    <div className={styles.contentContainer}>
      <div className={styles.header}>
        <h4>Select the fiscal quarter.</h4>
      </div>
      <div className={styles.warning}>
        GPT Old data was imported from Abacus on 01/01/2018. Any GPT Old
        statements with start dates before that date will indicate a $0 starting
        balance.
      </div>
      <form className={styles.form} onSubmit={wrapSubmit(props.onSubmit)}>
        <div className={styles.section}>
          <div className={styles.halfWidth}>
            <TrustInput onChange={props.onChange} value={props.journalId} />
            <div className={styles.formItem}>
              <label className={styles.label} htmlFor="quarter">
                Quarter
              </label>
              <div>
                <select
                  className={styles.select}
                  name="quarter"
                  value={props.quarter}
                  onChange={props.onChange}
                >
                  <option disabled value="">
                    Select a quarter
                  </option>
                  <option value="1">Q1</option>
                  <option value="2">Q2</option>
                  <option value="3">Q3</option>
                  <option value="4">Q4</option>
                </select>
              </div>
            </div>
            <div className={styles.formItem}>
              <label className={styles.label} htmlFor="year">
                Year
              </label>
              <div>
                <select
                  className={styles.select}
                  name="year"
                  onChange={props.onChange}
                  value={props.year}
                >
                  <option disabled value="">
                    Select a year
                  </option>
                  {yearOptions()}
                </select>
              </div>
            </div>
            {props.statements && renderStatementsList(props.statements)}
          </div>
        </div>
        <FormButtons disabled={!props.canSubmit} submitLabel="Submit" />
      </form>
    </div>
  </section>
)

export default Template
