// @flow
import React from 'react'
import { connect } from 'react-redux'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Card from 'components/Card/Card'
import { DateRangeSelection } from 'components/DateRangeSelection'
import moment from 'moment'
import { httpUrl } from 'lib/http'
import styles from './FeeReport.css'
import queryString from 'query-string'
import streamSaver from 'streamsaver'
import { setLoad, setUnload } from 'lib/notification/actions'

type Props = {
  dispatch: Function,
}

class FeeReport extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      dateStart: '',
      dateEnd: '',
    }
  }

  canSubmit = () => {
    const { dateStart, dateEnd } = this.state
    const momentStart = moment(dateStart)
    const momentEnd = moment(dateEnd)

    if (momentStart.isValid() && momentEnd.isValid()) {
      if (momentStart.isAfter('2002-01-01')) {
        return momentStart.isBefore(momentEnd) || momentStart.isSame(momentEnd)
      }
    }
  }

  render() {
    const { dateStart, dateEnd } = this.state
    return (
      <div className={styles.feeReport}>
        <Breadcrumbs crumbs={['Fee Report']} />
        <div className={styles.body}>
          <Card>
            <div className={styles.form}>
              <div className={styles.dateRangePicker}>
                <DateRangeSelection
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  onChange={this.onChange}
                />
              </div>
              <div className={styles.export}>
                <button onClick={this.exportReport}>Export CSV</button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    )
  }

  onChange = (event) => {
    const { name, value } = event.currentTarget
    this.setState({ [name]: value })
  }

  exportReport = (event) => {
    event.preventDefault()
    if (!this.canSubmit()) {
      return
    }

    const sid = localStorage.getItem('sid')
    const base_url = httpUrl('/admin/reporting/fee-report')
    const { dateStart, dateEnd } = this.state
    const query = {
      date_start: dateStart,
      date_end: dateEnd,
    }

    const qs = queryString.stringify(query, { arrayFormat: 'bracket' })
    const url = `${base_url}?${qs}`

    const { dispatch } = this.props
    dispatch(setLoad())

    fetch(url, {
      headers: {
        Authorization: `Bearer ${sid}`,
      },
    }).then((response) => {
      dispatch(setUnload())
      const fileStream = streamSaver.createWriteStream(
        `fee-report-${dateStart}-to-${dateEnd}.csv`,
      )
      response.body.pipeTo(fileStream)
    })
  }
}

export default connect()(FeeReport)
