// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  AccountExtrasRef,
  ClosingRef,
  GPTAccountDocsRef,
  GPTDatesRef,
  GPTLineItemsRef,
  InvestmentsRef,
  SSIRef,
} from 'components/References'
import FileFolders from 'components/FileFolders'
import { Loader } from 'components/Notifications'
import { Notes } from 'components/Notes'
import { Table, Row as TableRow, Cell } from 'components/Table'
import formatters from 'lib/formatters'
import { setModal } from 'lib/modal/actions'
import type { Account } from 'lib/types'
import styles from './styles'
import { Dialog } from 'components/Dialog'
import AttachmentForm from './AttachmentForm'
import ClosingTrackForm from './ClosingTrackForm'
import Main from './Tabs/Main/Main.js'
import Metrics from './Tabs/Metrics/Metrics.js'
import People from './Tabs/People/People.js'
import Header from './Header/Header.js'

type Props = {
  account: Account,
  dispatch: Function,
  isAuditor: boolean,
  onDeleteClick: Function,
  onUploadClick: Function,
  toggleAccountSuspension: Function,
  toggleAccountSuspensionApproved: Function,
  toggleStatusNeedsApproval: boolean,
  toggleStatusDescription: string,
}

type DataProps = {
  data: Object,
  index: number,
}

const accountExtras = (account) => {
  return (
    <AccountExtrasRef
      ageAtOpen={account['age_at_open']}
      hasCustomFeeSchedule={
        account['fee_schedule'] && account['fee_schedule'] != 'Standard'
      }
      hasNonCashAssets={account['non_cash_assets?']}
      isBurialPrepaid={account['prepaid_burial?']}
      isRestricted={account['restricted?']}
      potentialClientId={account['potential_client_id']}
      receivesMonthlyStatements={account['receives_monthly_statements?']}
      trustName={account['trust_name']}
      productDescription={account['product_description']}
      hasBudget={account['budget?']}
      hasPublicGuardian={account['public_guardian?']}
      hasSpecialTaxSituation={account['special_tax_situation?']}
      spendingPlan={account['spending_plan']}
      floor={account['floor']}
      hasParticipatingOrg={account['participating_org?']}
      hasLien={account['lien?']}
      hasTeams={account['teams?']}
      hasPCard={account['p_card?']}
    />
  )
}

const openAttachmentModal = (account, dispatch) => {
  const modalForm = <AttachmentForm accountNumber={account.number} />
  dispatch(setModal(modalForm))
}

const openClosingFeeModal = (account, dispatch) => {
  const modalForm =
    <ClosingTrackForm accountNumber={account.number} dispatch={dispatch} />
  dispatch(setModal(modalForm))
}

const renderTickets = (props: DataProps) => {
  const { data, index } = props
  let { id, track, station, updated_at, last_event } = data
  track = formatters.legibleTrack(track)
  station = formatters.legibleStation(station)
  const lastUser = (
    <span className="capitalize">{last_event.user.split('@', 1)}</span>
  )
  const description = <span>{last_event.description}</span>
  const updatedTime = (
    <span className={styles.updateTime}>
      ({formatters.naiveDateTime(updated_at).fromNow()})
    </span>
  )
  const updated = (
    <div className={styles.recentActivity}>
      {lastUser} {description} {updatedTime}
    </div>
  )

  return (
    <TableRow key={index} link={`/tickets/${id}`}>
      <Cell value={track} />
      <Cell value={station} />
      <Cell value={updated} />
    </TableRow>
  )
}

export const Template = (props: Props) => {
  const {
    account, dispatch, isAuditor, onDeleteClick, onUploadClick,
    toggleAccountSuspension, toggleAccountSuspensionApproved,
    toggleStatusNeedsApproval, toggleStatusDescription,
  } = props

  const {
    ables,
    attorneys,
    balance,
    beneficiary,
    checking_balance,
    close_date,
    co_grantor,
    consults,
    grantor,
    grantor_relationship,
    id,
    investments,
    notes,
    number,
    open_date,
    open_tickets = [],
    potential_client_id,
    product_id,
    referrer,
    related_account_numbers = [],
    remaindermen,
    remaindermen_notes,
    representative,
    short_code,
    ssi_approval,
    subaccounts,
    status,
    transactions = [],
    trust_name,
    updatedAt,
  } = account

  const hasTransactions = transactions.length > 0
  const ticketHeaders = ['Track', 'Station', 'Recent Activity']

  let openTicketRows = []
  if (open_tickets && open_tickets.length > 0) {
    openTicketRows = open_tickets.map((item, idx) =>
      renderTickets({ data: item, index: idx }),
    )
  }

  let depositButton =
    !['pending','closed'].includes(status)
      && !isAuditor ? (
      <Link
        className={styles.depositButton}
        id="test-hook-deposit-funds"
        to={`/accounts/${number}/deposit`}
      >
        Deposit Funds
      </Link>
    ) : null

  let editButton =
    isAuditor ?
    null :
    (
      <Link
        className={styles.editButton}
        disabled={true}
        to={`/accounts/${number}/edit`}
      >
        Edit Account
      </Link>
    )

  const reportButton = (
    <Link
      className={styles.statementButton}
      to={`/accounts/${number}/statement`}
    >
      Generate Statement
    </Link>
  )

  let closingFeeButton =
    status === 'approved' && !isAuditor ? (
    <Link
      className={styles.closingButton}
      onClick={() => openClosingFeeModal(account, dispatch)}
    >
      Begin Closing Track
    </Link>
  ) : null

  const dbFileDiv = (account, dispatch) => {
    return (
      <div>
        <GPTAccountDocsRef {...account} dispatch={dispatch} />
        <button
          className={styles.addButton}
          onClick={() => openAttachmentModal(account, dispatch)}
        >
          <span className={styles.plus}>+</span>
          Add Document
        </button>
      </div>
    )
  }

  const s3FileDiv = (account) => {
    const mergedFolders = FileFolders.MergeFoldersWithDefaults(account.folders)
    const loading = account.upload_status === 'uploading'

    return (
      <div>
        <FileFolders
          folders={mergedFolders}
          parentEntityId={account.id}
          onDeleteClick={onDeleteClick}
          onUploadClick={onUploadClick}
         >
          <span className="spinner" />
        </FileFolders>
        <Loader non_redux_loading={loading} />
      </div>
    )
  }

  const fileDiv = (account, dispatch) => {
    if (potential_client_id) {
      return s3FileDiv(account, dispatch)
    } else {
      return dbFileDiv(account, dispatch)
    }
  }

  return (
    <section className={styles.contentContainer}>
      {/* Tabs showing dynamic content on the left */}
      <section className={styles.twoThird}>
        <Header
          balance={balance}
          beneficiary={beneficiary}
          checking_balance={checking_balance}
          number={number}
          open_tickets={open_tickets}
          related_account_numbers={related_account_numbers}
          status={status}
        />
        <Tabs defaultIndex={0}>
          <TabList className={[styles.tabList, 'react-tabs']}>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>Main</Tab>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Transactions
            </Tab>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Attachments
            </Tab>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              People
            </Tab>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Investments
            </Tab>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Tickets
            </Tab>
            <Tab
              disabled={!hasTransactions}
              className={[
                styles.buttonLocal,
                hasTransactions ? '' : styles.disabled,
                'react-tabs__tab',
              ]}
            >
              Metrics
            </Tab>
          </TabList>
          <TabPanel>
            <Main
              beneficiary={beneficiary}
              co_grantor={co_grantor}
              grantor={grantor}
              grantor_relationship={grantor_relationship}
              number={number}
              representative={representative}
              subaccounts={subaccounts}
            />
          </TabPanel>
          <TabPanel>
            <GPTLineItemsRef
              accountNumber={number}
              shortCode={short_code}
              trustName={trust_name}
              lineItems={[...transactions].reverse()}
            />
          </TabPanel>
          <TabPanel>
            {fileDiv(account, dispatch)}
          </TabPanel>
          <TabPanel>
            <People
              ables={ables}
              attorneys={attorneys}
              consults={consults}
              referrer={referrer}
              remaindermen={remaindermen}
              remaindermen_notes={remaindermen_notes}
            />
          </TabPanel>
          <TabPanel>
            <InvestmentsRef
              account_id={id}
              history={investments.history}
              next_change_date={investments.next_change_date}
              product_id={product_id}
            />
          </TabPanel>
          <TabPanel>
            <section>
              <Table headers={ticketHeaders} tableSize="small" title="Tickets">
                {openTicketRows}
              </Table>
            </section>
          </TabPanel>
          <TabPanel>
            <Metrics accountNumber={number} transactions={transactions} />
          </TabPanel>
        </Tabs>
        {toggleStatusNeedsApproval && (
          <Dialog
            cancelButtonText="Cancel"
            confirmButtonText="Yes"
            headerText={
              'Are you sure you want to ' +
              toggleStatusDescription +
              '?'
            }
            onCancel={() => toggleAccountSuspensionApproved(false)}
            onConfirm={() => toggleAccountSuspensionApproved(true)}
          />
        )}
      </section>
      {/* Static content on the right */}
      <div className={styles.oneThird}>
        <div className={styles.actions}>
          {editButton}
          {depositButton}
          {reportButton}
          {closingFeeButton}
        </div>
        <div className={styles.cardMarginBot}>
          <GPTDatesRef
            closeDate={close_date}
            openDate={open_date}
            status={status}
            updatedAt={updatedAt}
            toggleAccountSuspension={toggleAccountSuspension}
          />
          {accountExtras(account)}
        </div>
        {['closing', 'closed'].includes(account.status) && (
          <ClosingRef
            collapse={true}
            account={account}
            beneficiary={beneficiary}
          />
          )
        }
        <SSIRef
          collapse={true}
          hasSSI={account['ssi?']}
          hasSSIFood={account['ssi_food?']}
          hasSSIShelter={account['ssi_shelter?']}
          ssiAmount={account.ssi_amount}
          ssiApprovalFile={ssi_approval}
        />
        <Notes accountNumber={number} dispatch={dispatch} notes={notes} />
      </div>
    </section>
  )
}
