import IconBox from './IconBox'

export function IconUnlock() {
  return (
    <IconBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        zoomAndPan="magnify"
        viewBox="0 0 60 60"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
      >
        <defs>
          <clipPath id="390033f111">
            <path
              d="M 2.699219 0.0625 L 59.6875 0.0625 L 59.6875 59.9375 L 2.699219 59.9375 Z M 2.699219 0.0625 "
              clipRule="nonzero"
            />
          </clipPath>
        </defs>
        <g clipPath="url(#390033f111)">
          <path
            fill="#cdcdcd"
            // eslint-disable-next-line max-len
            d="M 56.550781 28.46875 L 31.191406 28.46875 L 31.191406 14.308594 C 31.191406 6.125 23.992188 -0.796875 15.535156 0.144531 C 8.019531 0.777344 2.699219 7.386719 2.699219 14.941406 L 2.699219 31.300781 C 2.699219 31.929688 3.328125 32.5625 3.953125 32.5625 L 7.707031 32.5625 C 8.335938 32.5625 8.960938 31.929688 8.960938 31.300781 L 8.960938 14.308594 C 8.960938 9.585938 13.035156 5.8125 17.726562 6.441406 C 21.796875 6.753906 24.929688 10.53125 24.929688 14.621094 L 24.929688 28.46875 L 21.796875 28.46875 C 19.921875 28.46875 18.667969 29.730469 18.667969 31.617188 L 18.667969 56.792969 C 18.667969 58.679688 20.230469 59.9375 21.796875 59.9375 L 56.550781 59.9375 C 58.425781 59.9375 59.679688 58.679688 59.679688 56.792969 L 59.679688 31.617188 C 59.679688 29.730469 58.117188 28.46875 56.550781 28.46875 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </IconBox>
  )
}
