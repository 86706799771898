// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card__card___XuJGs {
  border: 1px solid rgba(73, 80, 84, 0.15);
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(58, 64, 68, 0.08);
  background: #FFF;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.Card__title___lN8oY {
  padding: 16px 20px;
  background: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtCardHeaderBG"]};
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./universal/components/Card/Card.css"],"names":[],"mappings":"AAGA;EACE,wCAAwC;EACxC,kBAAkB;EAClB,8CAA8C;EAC9C,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,wDAA0B;EAC1B,WAAW;AACb","sourcesContent":["@value colors: 'styles/colors.css';\n@value gtCardHeaderBG from colors;\n\n.card {\n  border: 1px solid rgba(73, 80, 84, 0.15);\n  border-radius: 4px;\n  box-shadow: 0 1px 2px 0 rgba(58, 64, 68, 0.08);\n  background: #FFF;\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  width: 100%;\n}\n\n.title {\n  padding: 16px 20px;\n  background: gtCardHeaderBG;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `'styles/colors.css'`,
	"gtCardHeaderBG": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtCardHeaderBG"]}`,
	"card": `Card__card___XuJGs`,
	"title": `Card__title___lN8oY`
};
export default ___CSS_LOADER_EXPORT___;
