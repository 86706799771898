// @flow
import React from 'react'
import formatters from 'lib/formatters'
import styles from './styles.css'

type Props = {
  lineItems: Array<Object>
}

type DataProps = {
  item: Object,
  index: number,
}

const renderData = ({ item, index }: DataProps) => {
  const { balance, date, description, credit, debit } = item
  const formattedDescription =
    formatters.transactionDescription(description, date)
  let rowStyle = ''

  if (item.rowStyle && item.rowStyle == 'bold') {
    rowStyle = styles.boldRow
  }

  return (
    <tr key={index} className={rowStyle}>
      <td className={styles.dateCell}>{formatters.date(date)}</td>
      <td className={styles.descriptionCell}>{formattedDescription}</td>
      <td className={styles.debitCell}>{debit}</td>
      <td className={styles.creditCell}>{credit}</td>
      <td className={styles.balanceCell}>{balance}</td>
    </tr>
  )
}

export const LineItems = ({ lineItems }: Props) => {
  if (!lineItems) return <div>No Transaction History</div>
  const dataRows = lineItems.map((item, index) => renderData({ item, index }))
  return <tbody>{dataRows}</tbody>
}
