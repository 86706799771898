// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__requestDocumentSection___jupfN {
  background-color: white;
  width: 100%;
  padding: 1.25rem;
}

.styles__cardMarginBot___Ke0Tj {
  position: relative;
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtCardHeaderBG"]};
}

.styles__danger___QK6m5 {
  color: #a13739;
  font-weight: 700;
}

.styles__danger___QK6m5 input {
    color: #a13739 !important;
  }
.styles__orange___jkQFk {
  color: #c1782c;
  font-weight: 700;
}
.styles__orange___jkQFk input {
    color: #c1782c;
  }
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Distributions/components/Main/forms/styles.css"],"names":[],"mappings":"AAIA;EACE,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EAEE,kBAAkB;EAClB,8DAAgC;AAClC;;AAEA;EACE,cAAc;EACd,gBAAgB;AAIlB;;AAHE;IACE,yBAAyB;EAC3B;AAEF;EACE,cAAc;EACd,gBAAgB;AAIlB;AAHE;IACE,cAAc;EAChB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtCardHeaderBG, text, gtGrayMid, gtPurpleDark from colors;\n\n.requestDocumentSection {\n  background-color: white;\n  width: 100%;\n  padding: 1.25rem;\n}\n\n.cardMarginBot {\n  composes: cardMarginBot from helpers;\n  position: relative;\n  background-color: gtCardHeaderBG;\n}\n\n.danger {\n  color: #a13739;\n  font-weight: 700;\n  input {\n    color: #a13739 !important;\n  }\n}\n.orange {\n  color: #c1782c;\n  font-weight: 700;\n  input {\n    color: #c1782c;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtCardHeaderBG": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtCardHeaderBG"]}`,
	"text": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["text"]}`,
	"gtGrayMid": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGrayMid"]}`,
	"gtPurpleDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtPurpleDark"]}`,
	"requestDocumentSection": `styles__requestDocumentSection___jupfN`,
	"cardMarginBot": `styles__cardMarginBot___Ke0Tj ${___CSS_LOADER_ICSS_IMPORT_1___.locals["cardMarginBot"]}`,
	"danger": `styles__danger___QK6m5`,
	"orange": `styles__orange___jkQFk`
};
export default ___CSS_LOADER_EXPORT___;
