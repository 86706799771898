// @flow
import React from 'react'
import { GRANTOR_RELATIONSHIPS, ROLES, PRODUCTS } from 'lib/constants'
import styles from './Filters.css'

function normalizeFilters(filters) {
  return Object.keys(filters).map((k) => {
    return {
      id: k,
      title: filters[k],
    }
  })
}

const filterOptions = [
  {
    id: 'product',
    title: 'Trust',
    options: normalizeFilters(PRODUCTS),
  },
  {
    id: 'role',
    title: 'Role',
    options: normalizeFilters(ROLES),
  },
  {
    id: 'grantorRelationship',
    title: 'Grantor Relationship',
    options: normalizeFilters(GRANTOR_RELATIONSHIPS),
  },
]

type Props = {
  onApply: Function,
  onFilterChange: Function,
  selectedFilters: Array<string>,
}

export const Filters = ({
  onApply,
  onFilterChange,
  selectedFilters,
}: Props) => {
  const onOptionFilterChecked = (e, filterId: string) => {
    const value = e.target.value
    if (selectedFilters[filterId].includes(value)) {
      onFilterChange({
        ...selectedFilters,
        [filterId]: selectedFilters[filterId].filter((it) => it !== value),
      })
    } else {
      onFilterChange({
        ...selectedFilters,
        // commented-out temporarily 2021-04-20
        // [filterId]: [...selectedFilters[filterId], value]
        [filterId]: [value],
      })
    }
  }

  return (
    <div className={styles.filtersContainer}>
      <div className={styles.filtersColumns}>
        {filterOptions.map(({ id: filterId, title, options }) => {
          return (
            <div key={filterId} className={styles.filtersColumn}>
              <div className={styles.filtersColumn_header}>
                <label>{title}</label>
              </div>

              <div className={styles.filtersColumn_options}>
                {options.map(({ id, title }) => {
                  return (
                    <div className={styles.filtersOption} key={id}>
                      <label>
                        <input
                          type="checkbox"
                          value={id}
                          checked={selectedFilters[filterId].includes(id)}
                          onChange={(e) => onOptionFilterChecked(e, filterId)}
                        />
                        <span>{title}</span>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.filterActions}>
        <button className={styles.applyBtn} onClick={() => onApply()}>
          Apply Filter
        </button>
        <button
          className={styles.clearBtn}
          onClick={() => onFilterChange({
            role: [], product: [], grantorRelationship: []})
          }
        >
          Clear All Filter
        </button>
      </div>
    </div>
  )
}
