// @flow
import React from 'react'
import type { Field } from 'lib/types'
import FieldWrapper from './FieldWrapper'
import styles from './styles'

const makeOpt = (key, val) => {
  const opts = {
    key,
    value: key,
  }

  return <option {...opts}>{val}</option>
}

const options = (values) => {
  let data = []
  if (Array.isArray(values)) {
    data = values.map(val => makeOpt(val, val))
    data.unshift(<option value='' key='-1'>Select an option</option>)
  } else {
    data = Object.keys(values).map(key => makeOpt(key, values[key]))
    data.unshift(<option value='' key='-1'>Select an option</option>)
  }

  return data
}

const markup = (field: Field) => {
  const { autoFocus, input, options: opts } = field
  if (field.newValue) {
    input.value = field.newValue
  }

  return (
    <select {...input} autoFocus={autoFocus} className={styles.select}>
      {options(opts)}
    </select>
  )
}

const renderSelectField = (field: Field) => {
  const {
    button,
    children,
    input,
    label,
    meta,
    meta: { asyncValidating },
    placeholder,
    required,
    type,
  } = field
  const props = {
    asyncValidating,
    button,
    innerButton: children,
    label: label || placeholder,
    meta,
    name: input.name,
    required,
    type,
  }

  return (
    <FieldWrapper {...props}>
      {markup(field)}
    </FieldWrapper>
  )
}

export default renderSelectField
