// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import normalizers from 'lib/field/normalizers'
import FormButtons from 'components/FormButtons'
import { renderInputField } from 'components/FormFields'
import { fetchAccountHTTP } from 'lib/account/actions'
import { httpPost } from 'lib/http'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import type { Form } from 'lib/types'
import styles from './styles'

type Props = {
  accountNumber: number,
  date: string,
  custom_amount: number,
  form: Form,
}

const onSubmit = (accountNumber, { date, custom_amount }, dispatch) => {
  dispatch(setLoad())
  return httpPost(
      `/admin/accounts/${accountNumber}/closing-fee`,
      { date, custom_amount })
    .then(() => {
      dispatch(fetchAccountHTTP(accountNumber))
      dispatch(setBanner('Closing Fee successfully applied.'))
    })
    .catch(() => {
      dispatch(setBanner(
        'Closing Fee unsuccessfully applied. Please check account balance',
        'danger')
      )
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
}

const Component = (props: Props) => {
  const { accountNumber, date, custom_amount, form } = props

  const handleSubmit = form.handleSubmit((data, dispatch) => {
    return onSubmit(accountNumber, data, dispatch)
  })

  return (
    <section>
      <h4 className="section">Apply Closing Fee</h4>
      <form onSubmit={handleSubmit}>
        <div className="section">
          <div className={styles.formItemHalf}>
            <Field
              component={renderInputField}
              label="Date"
              name="date"
              type="date"
              required={true}
            />
            <p>
              <i>
              Note: the standard closing fee is $500 for accounts with a
              balance of at least $500,
              otherwise it is the remaining account balance.
              </i>
            </p>
            <Field
              component={renderInputField}
              label="Amount"
              name="custom_amount"
              normalize={normalizers.float}
              required={true}
              placeholder='0.00'
            />
          </div>
        </div>
        <FormButtons disabled={!date || !custom_amount} {...form} />
      </form>
    </section>
  )
}

let ClosingFeeForm = reduxForm({
  form: 'closingFee',
  propNamespace: 'form',
  initialValues: {
    custom_amount: '500.00'
  }
})(Component)

const selector = formValueSelector('closingFee')
ClosingFeeForm = connect((state) => {
  return {
    date: selector(state, 'date'),
    custom_amount: selector(state, 'custom_amount')
  }
})(ClosingFeeForm)

export default ClosingFeeForm
