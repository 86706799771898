// @flow
import * as t from './actionTypes'
import { httpGet, httpPost } from 'lib/http'
import queryString from 'query-string'

export const clearSearch = () => {
  return (dispatch: Function) => {
    return dispatch({ type: t.SET_CONTACTS, contacts: {} })
  }
}

export const create = (params: Object) => {
  return () => httpPost('/admin/contacts/', params)
}

export const setContacts = (data: Object) => {
  return (dispatch) => {
    dispatch({ type: t.SET_CONTACTS, contacts: data })
  }
}

export const search = (params: Object) => {
  const query = queryString.stringify(params)
  return (dispatch: Function) => {
    return httpGet(`/admin/contacts?${query}`)
      .then((data) => {
        dispatch(setContacts(data))
      })
      .catch(() => {
        dispatch(setContacts({}))
      })
  }
}

export const setSSNVerifier = (contactId: number, ssnVerifier: string) => {
  return (dispatch: Function) => {
    return dispatch({ type: t.SET_SSN_VERIFIER, contactId, ssnVerifier })
  }
}
