// @flow
import React from 'react'
import Error from 'components/Error'
import { connect } from 'react-redux'
import { httpGet } from 'lib/http'
import { setLoad, setUnload } from 'lib/notification/actions'
import { Template } from './Template'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  params: Object,
}

class Statement extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      error: false,
      statement: null,
      dateStart: '',
      dateEnd: '',
    }
  }

  handleChange = (event) => {
    const { name, value } = event.currentTarget
    this.setState({ [name]: value })
  }

  canSubmit = () => {
    const { dateStart, dateEnd } = this.state
    return dateStart && dateEnd && dateStart <= dateEnd
  }

  fetchStatement = () => {
    const {
      dispatch,
      location: {
        params: { number, subnumber },
      },
    } = this.props
    const { dateStart, dateEnd } = this.state
    const query = `date_start=${dateStart}&date_end=${dateEnd}`
    dispatch(setLoad())
    const url =
      `/admin/accounts/${number}/` +
      `subaccounts/${subnumber}/statement?${query}`
    httpGet(url)
      .then((statement) => {
        this.setState({ error: false, statement })
      })
      .catch((error) => {
        if (error.code === 404) {
          this.setState({ error: true })
        } else {
          throw error
        }
      })
      .then(() => {
        dispatch(setUnload())
      })
  }

  render() {
    if (this.state.error) {
      return (
        <Error
          title="404: Account Not Found"
          message="The account you are looking for does not exist"
          icon="account"
        />
      )
    }
    if (this.state.error === false) {
      const { statement, dateStart, dateEnd } = this.state
      return (
        <Template
          onSubmit={this.fetchStatement}
          canSubmit={this.canSubmit}
          statement={statement}
          dateStart={dateStart}
          dateEnd={dateEnd}
          onChange={this.handleChange}
        />
      )
    }
    return null
  }
}

export default withRouter(connect()(Statement))
