// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/easings.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable */
/* button styles */
.styles__button___drP8j {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 2px 25px 0;
  position: relative;
  border-radius: 3px;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: white;
  transition: background-color 100ms ${___CSS_LOADER_ICSS_IMPORT_1___.locals["quadInOut"]};
}

/* button size */
.styles__buttonSizeDefault___VLJ29 {
  height: 50px;
}
.styles__buttonSizeSmall___x73mS {
  height: 40px;
  font-size: 14px;
}

/* button color */
.styles__buttonColorBlue___l83Vj {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
}
.styles__buttonColorBlue___l83Vj:hover {
    background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]};
  }
.styles__buttonColorGreen___LTWaW {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]};
}
.styles__buttonColorGreen___LTWaW:hover {
    background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreenDark"]};
  }
.styles__buttonColorGray___OOYq3 {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
}
.styles__buttonColorGray___OOYq3:hover {
    background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGrayMid"]};
  }

/* icon */
.styles__iconWrap___gAt1C {
  display: flex;
  margin-right: 12.5px;
  position: relative;
  top: -2px;
}
`, "",{"version":3,"sources":["webpack://./universal/ui/components/Button/styles.css"],"names":[],"mappings":"AAKA,sBAAsB;AACtB,kBAAkB;AAClB;EACE,aAAa;EACb,qBAAqB;EACrB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,+EAA4C;AAC9C;;AAEA,gBAAgB;AAChB;EAEE,YAAY;AACd;AACA;EAEE,YAAY;EACZ,eAAe;AACjB;;AAEA,iBAAiB;AACjB;EACE,8DAAwB;AAK1B;AAHE;IACE,8DAA4B;EAC9B;AAEF;EACE,8DAAyB;AAK3B;AAHE;IACE,8DAA6B;EAC/B;AAEF;EACE,8DAAwB;EACxB,mDAAa;AAKf;AAHE;IACE,8DAA2B;EAC7B;;AAGF,SAAS;AACT;EACE,aAAa;EACb,oBAAoB;EACpB,kBAAkB;EAClB,SAAS;AACX","sourcesContent":["@value colors: \"styles/colors.css\";\n@value gtBlue, gtBlueDark, gtGray, gtGrayMid, gtGreen, gtGreenDark from colors;\n@value easings: \"styles/easings.css\";\n@value quadInOut from easings;\n\n/* stylelint-disable */\n/* button styles */\n.button {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: center;\n  align-items: center;\n  width: auto;\n  height: auto;\n  padding: 2px 25px 0;\n  position: relative;\n  border-radius: 3px;\n  border: none;\n  outline: none;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 100%;\n  color: white;\n  transition: background-color 100ms quadInOut;\n}\n\n/* button size */\n.buttonSizeDefault {\n  composes: button;\n  height: 50px;\n}\n.buttonSizeSmall {\n  composes: button;\n  height: 40px;\n  font-size: 14px;\n}\n\n/* button color */\n.buttonColorBlue {\n  background-color: gtBlue;\n\n  &:hover {\n    background-color: gtBlueDark;\n  }\n}\n.buttonColorGreen {\n  background-color: gtGreen;\n\n  &:hover {\n    background-color: gtGreenDark;\n  }\n}\n.buttonColorGray {\n  background-color: gtGray;\n  color: gtBlue;\n\n  &:hover {\n    background-color: gtGrayMid;\n  }\n}\n\n/* icon */\n.iconWrap {\n  display: flex;\n  margin-right: 12.5px;\n  position: relative;\n  top: -2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"styles/colors.css"`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"gtBlueDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]}`,
	"gtGray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]}`,
	"gtGrayMid": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGrayMid"]}`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"gtGreenDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreenDark"]}`,
	"easings": `"styles/easings.css"`,
	"quadInOut": `${___CSS_LOADER_ICSS_IMPORT_1___.locals["quadInOut"]}`,
	"button": `styles__button___drP8j`,
	"buttonSizeDefault": `styles__buttonSizeDefault___VLJ29 styles__button___drP8j`,
	"buttonSizeSmall": `styles__buttonSizeSmall___x73mS styles__button___drP8j`,
	"buttonColorBlue": `styles__buttonColorBlue___l83Vj`,
	"buttonColorGreen": `styles__buttonColorGreen___LTWaW`,
	"buttonColorGray": `styles__buttonColorGray___OOYq3`,
	"iconWrap": `styles__iconWrap___gAt1C`
};
export default ___CSS_LOADER_EXPORT___;
