import * as t from './actionTypes'
import { State } from './model'

const initialState: State = {
  notification: {
    message: '',
    type: 'success',
  },
  loading: false,
}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case t.SET_BANNER:
      return {
        ...state,
        notification: {
          message: action.message,
          type: action.messageType || 'success',
        },
      }
    case t.SET_LOADER:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
