// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__localHeader___srKDs {
}

@media print {

.styles__localHeader___srKDs {
    display: none;
}
  }

.styles__localHeaderInner___rSUnX {
}

@media print {

.styles__localHeaderInner___rSUnX {
    display: none;
}
  }

.styles__contentContainer___ndDiY {
  display: flex;
}

.styles__section___hjkuJ {
}

.styles__header___LBYps {
  background: white;
  border-radius: 3px;
  margin: 0 0 20px;
  padding: 20px;
  width: 100%;
}

.styles__halfWidth___p03Qs {
  margin: 0 auto;
  width: 25%;
}

.styles__form___iBMoY {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.styles__warning___nRYVG {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]};
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowDark"]};
  font-size: 90%;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Account/components/Number/Subaccounts/Statement/styles.css"],"names":[],"mappings":"AAIA;AAMA;;AAHE;;AAHF;IAII,aAAa;AAEjB;EADE;;AAGF;AAMA;;AAHE;;AAHF;IAII,aAAa;AAEjB;EADE;;AAGF;EAEE,aAAa;AACf;;AAEA;AAEA;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,aAA2B;EAA3B,wBAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,8DAA+B;EAC/B,8DAA0B;EAC1B,mDAAmB;EAEnB,cAAc;AAChB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: 'styles/colors.css';\n@value gtYellow, gtYellowDark, gtYellowLight from colors;\n\n.localHeader {\n  composes: localHeader from helpers;\n\n  @media print {\n    display: none;\n  }\n}\n\n.localHeaderInner {\n  composes: localHeaderInner from helpers;\n\n  @media print {\n    display: none;\n  }\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n  display: flex;\n}\n\n.section {\n  composes: section from helpers;\n}\n\n.header {\n  background: white;\n  border-radius: 3px;\n  margin: 0 0 20px;\n  padding: 20px;\n  width: 100%;\n}\n\n.halfWidth {\n  margin: 0 auto;\n  width: 25%;\n}\n\n.form {\n  lost-flex-container: column;\n  width: 100%;\n}\n\n.warning {\n  background-color: gtYellowLight;\n  border: 1px solid gtYellow;\n  color: gtYellowDark;\n  composes: section from helpers;\n  font-size: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `'styles/colors.css'`,
	"gtYellow": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]}`,
	"gtYellowDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowDark"]}`,
	"gtYellowLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]}`,
	"localHeader": `styles__localHeader___srKDs ${___CSS_LOADER_ICSS_IMPORT_1___.locals["localHeader"]}`,
	"localHeaderInner": `styles__localHeaderInner___rSUnX ${___CSS_LOADER_ICSS_IMPORT_1___.locals["localHeaderInner"]}`,
	"contentContainer": `styles__contentContainer___ndDiY ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"section": `styles__section___hjkuJ ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`,
	"header": `styles__header___LBYps`,
	"halfWidth": `styles__halfWidth___p03Qs`,
	"form": `styles__form___iBMoY`,
	"warning": `styles__warning___nRYVG ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`
};
export default ___CSS_LOADER_EXPORT___;
