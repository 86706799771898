// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__buttonLocal___ZBvst {
}

.styles__buttonSecondary___DRbak {
}

.styles__formItem___o_7Dt {
}

.styles__label___Lmjjx {
}

.styles__renderButtons___pUUdW {
  margin-bottom: 20px;
}

.styles__renderButtons___pUUdW .styles__buttonSecondary___DRbak,
  .styles__renderButtons___pUUdW .styles__buttonLocal___ZBvst {
    margin-right: 20px;
  }

.styles__renderButtons___pUUdW .styles__buttonSecondary___DRbak:last-child, .styles__renderButtons___pUUdW .styles__buttonLocal___ZBvst:last-child {
      margin-left: 0;
    }

`, "",{"version":3,"sources":["webpack://./universal/components/ContactPicker/styles.css"],"names":[],"mappings":"AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EACE,mBAAmB;AAUrB;;AARE;;IAEE,kBAAkB;EAKpB;;AAHE;MACE,cAAc;IAChB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.buttonLocal {\n  composes: buttonLocal from helpers;\n}\n\n.buttonSecondary {\n  composes: buttonSecondary from helpers;\n}\n\n.formItem {\n  composes: formItem from helpers;\n}\n\n.label {\n  composes: label from helpers;\n}\n\n.renderButtons {\n  margin-bottom: 20px;\n\n  .buttonSecondary,\n  .buttonLocal {\n    margin-right: 20px;\n\n    &:last-child {\n      margin-left: 0;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"buttonLocal": `styles__buttonLocal___ZBvst ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonLocal"]}`,
	"buttonSecondary": `styles__buttonSecondary___DRbak ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonSecondary"]}`,
	"formItem": `styles__formItem___o_7Dt ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItem"]}`,
	"label": `styles__label___Lmjjx ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`,
	"renderButtons": `styles__renderButtons___pUUdW`
};
export default ___CSS_LOADER_EXPORT___;
