// @flow
import React from 'react'
import styles from './Metric.css'
import formatters from 'lib/formatters'

type Props = {
  title: string,
  value?: number,
  className?: string,
}

const Metric = (props: Props) => {
  const { className, title, value } = props

  return (
    <div className={`${styles.metricWrapper} ${className}`}>
      <div className={styles.metricLabel}>{title}</div>
      <div className={styles.metricValue}>
        {typeof value == 'number' && !isNaN(value) ? (
          <div>
            <span className={styles.dollar}>$</span>
            {formatters.moneyCommas(value.toFixed(2))}
          </div>
        ) : (
          'error'
        )}
      </div>
    </div>
  )
}

export default Metric
