import { createRoot } from 'react-dom/client'
import fontawesome from '@fortawesome/fontawesome'
import { faClone } from '@fortawesome/fontawesome-free-regular'
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faUsers,
  faUserPlus,
  faExchangeAlt,
  faCheck,
  faBell,
} from '@fortawesome/fontawesome-free-solid'
import { createBrowserHistory } from 'history'
import { syncHistoryWithStore } from 'react-router-redux'
import createStore from '../universal/store/createStore'
import AppContainer from '../universal/containers/AppContainer'
import createRoutes from '../universal/routes/index'
import { ErrorBoundary } from 'react-error-boundary'

fontawesome.library.add(
  faClone,
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faUsers,
  faUserPlus,
  faExchangeAlt,
  faCheck,
  faBell,
)

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in
// src/routes/index.js, so we need to provide a custom `selectLocationState`
// to inform react-router-redux of its location.

// *********************UPDATED***********************
// Create a history object for React Router and Redux
const browserHistory = createBrowserHistory()

// Preloaded state from the server (if available)
const preloadedState = window.__PRELOADED_STATE__ || {}
// Create the Redux store with preloaded state
const store = createStore(preloadedState, browserHistory)
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: (state) => state.router,
})
// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

let render = (routerKey = null) => {
  const routes = createRoutes(store, history)
  const root = createRoot(MOUNT_NODE)
  root.render(
    <AppContainer
      store={store}
      routes={routes}
      routerKey={routerKey}
      history={history}
    />,
  )
}

// *****************DEPRECEATED NOW*******************
// Enable HMR and catch runtime errors in RedBox
// This code is excluded from production bundle
if (__DEV__ && module.hot) {
  const renderApp = render
  const renderError = (error) => {
    createRoot(MOUNT_NODE).render(
      <ErrorBoundary fallback={<div>Something went wrong!</div>}>
        {renderApp(Math.random())}
      </ErrorBoundary>,
    )
  }
  render = () => {
    try {
      renderApp(Math.random())
    } catch (error) {
      renderError(error)
    }
  }

  module.hot.accept()
}

// ========================================================
// Go!
// ========================================================
let title = 'Conductor for Guardian Trust'
let faviconHref = '/favicon-prod.ico'
// eslint-disable-next-line no-undef
if (__IS_SANDBOX__) {
  title = `[SANDBOX] ${title}`
  faviconHref = '/favicon-sandbox.ico'
  // eslint-disable-next-line no-undef
} else if (__DEV__) {
  title = `[DEV] ${title}`
  faviconHref = '/favicon-dev.ico'
}
document.title = title
document.getElementById('favicon').href = faviconHref
render()
