// @flow
import React from 'react'
import { ContactFormRef } from 'components/References'
import { DynamicSelect } from 'components/DynamicSelect'
import DynamicTrustSelect from 'components/DynamicTrustSelect'
import {
  GRANTOR_RELATIONSHIPS,
  GSNT_GRANTOR_RELATIONSHIPS,
} from 'lib/constants'
import formatters from 'lib/formatters'
import Ticket from 'components/Ticket'
import styles from './styles'

type Props = {
  beneficiary: Object,
  co_grantor: Object,
  ein: string,
  errors: Object,
  grantor: Object,
  investment_id: number,
  grantor_relationship: string,
  canSubmit: boolean,
  onBeneficiarySelection: Function,
  onCoGrantorSelection: Function,
  onEINChange: Function,
  onGrantorSelection: Function,
  onGrantorTypeSelection: Function,
  onInvestmentSelection: Function,
  onProductLoad: Function,
  onReferrerSelection: Function,
  onRepresentativeSelection: Function,
  onTrustNameChange: Function,
  onSubmit: Function,
  product: Object,
  referrer: Object,
  renderContactPicker: Function,
  representative: Object,
  trust_name: string,
}

const showErrors = (errors: Object) => {
  if (Object.getOwnPropertyNames(errors).length === 0) return
  return (
    <div className={`${styles.errors} test-hook-FormButtons-error-message`}>
      <h5>Form Error</h5>
      <h4>Please check for missing required information.</h4>
    </div>
  )
}

const createOptions = (product, array_attribute) => {
  let options = []

  if (product && product[array_attribute]) {
    options = product[array_attribute].map((value, idx) => ({
      value: value.id,
      label: value.name,
      key: idx,
    }))
  }

  return options
}

export const CreateAccountTicket = (props: Props) => {
  const {
    canSubmit,
    errors,
    investment_id,
    grantor_relationship,
    onBeneficiarySelection,
    onCoGrantorSelection,
    onGrantorSelection,
    onGrantorTypeSelection,
    onInvestmentSelection,
    onProductLoad,
    onReferrerSelection,
    onRepresentativeSelection,
    onSubmit,
    product,
  } = props
  const header = { title: 'Account Creation', subheader: 'Select Persons' }
  const beneficiaryPicker = props.renderContactPicker(
    'Beneficiary',
    'beneficiary_id',
    props.beneficiary,
    onBeneficiarySelection,
    'test-hook-select-beneficiary',
  )
  const doRenderGrantor = !['', 'self', 'selfRep'].includes(
    grantor_relationship,
  )
  const grantorPicker = props.renderContactPicker(
    formatters.grantorOrFundedBy(
      props.product && { journal_name: props.product.journal_name },
    ),
    'grantor_id',
    props.grantor,
    onGrantorSelection,
  )
  const doRenderCoGrantor = grantor_relationship === 'coGuardian'
  const coGrantorPicker = props.renderContactPicker(
    'Co-Grantor',
    'co_grantor_id',
    props.co_grantor,
    onCoGrantorSelection,
  )
  const doRenderRepresentative = grantor_relationship === 'selfRep'
  const representativePicker = props.renderContactPicker(
    'Representative',
    'representative_id',
    props.representative,
    onRepresentativeSelection,
  )
  const referrerPicker = props.renderContactPicker(
    'Referrer',
    'referrer_id',
    props.referrer,
    onReferrerSelection,
  )
  const doRenderGrantorRelationshipOptions = () => {
    let options = []

    const relationships =
      props.product && props.product.journal_name == 'gsnt_new'
        ? GSNT_GRANTOR_RELATIONSHIPS
        : GRANTOR_RELATIONSHIPS

    Object.keys(relationships).forEach((key) => {
      options.push(<option value={key}>{relationships[key]}</option>)
    })

    return options
  }

  return (
    <Ticket header={header}>
      <h4>Select the persons associated with the account.</h4>
      <form onSubmit={onSubmit}>
        <section className={styles.section}>
          <section className={styles.grantorSection}>
            <div className={styles.formItemHalf}>
              {
                <DynamicTrustSelect
                  excludeLegacy={true}
                  label="Product"
                  placeholder="Select one"
                  onProductLoad={onProductLoad}
                />
              }
            </div>
          </section>
          <section className={styles.grantorSection}>
            <div className={styles.formItemHalf}>
              <DynamicSelect
                label="Investment"
                placeholder="Select one"
                onChange={({ value }) => onInvestmentSelection({ value })}
                options={createOptions(product, 'investments')}
                value={investment_id}
              />
            </div>
          </section>
          <section className={styles.beneficiarySection}>
            {beneficiaryPicker}
            <ContactFormRef
              contact={props.beneficiary}
              errors={errors.beneficiary}
            />
          </section>
          <section className={styles.grantorSection}>
            <div className={styles.formItemHalf}>
              <div className={`${styles.dynamicInput} ${styles.formItem}`}>
                <label className={styles.label} htmlFor="grantor_relationship">
                  {formatters.grantorOrFundedBy(
                    props.product && {
                      journal_name: props.product.journal_name,
                    },
                  )}{' '}
                  Relationship
                </label>
                <div>
                  <select
                    id="test-hook-grantor-relationship"
                    className={styles.select}
                    name="grantor_relationship"
                    value={grantor_relationship}
                    onChange={onGrantorTypeSelection}
                  >
                    <option value="" disabled={true}>
                      Select an Option
                    </option>
                    {doRenderGrantorRelationshipOptions()}
                  </select>
                </div>
              </div>
            </div>
          </section>
          {doRenderGrantor && grantorPicker}
          <ContactFormRef
            contact={props.grantor}
            errors={errors.grantor}
            title="Grantor"
          />
          {doRenderCoGrantor && coGrantorPicker}
          <ContactFormRef
            contact={props.co_grantor}
            errors={errors.co_grantor}
            title="Co-Grantor"
          />
          {doRenderRepresentative && representativePicker}
          <ContactFormRef
            contact={props.representative}
            errors={errors.representative}
            title="Representative"
          />
          <section className={styles.renderButtons}>
            {referrerPicker}
            <ContactFormRef
              contact={props.referrer}
              errors={errors.referrer}
              title="Referrer (optional)"
            />
          </section>
        </section>
        {showErrors(errors)}
        <section className={styles.buttons}>
          <button
            className={`${styles.button} test-hook-save`}
            disabled={!canSubmit}
          >
            Save and Continue
          </button>
        </section>
      </form>
    </Ticket>
  )
}
