// @flow
import React from 'react'
import styles from './Breadcrumbs.css'

type Props = {
  crumbs: string[],
  className: string,
}

const Breadcrumbs = (props: Props) => {
  const { crumbs, className } = props

  const renderCrumbs = (crumbs) => {
    return crumbs.map((crumb, index) => {
      const slash = index > 0 ? ' > ' : ''

      return (
        <span key={index}>
          {slash}
          <span>{crumb}</span>
        </span>
      )
    })
  }

  return (
    <div className={`${styles.localHeader} ${className}`}>
      <div style={{ marginLeft: 15 }}>{renderCrumbs(crumbs)}</div>
    </div>
  )
}

export default Breadcrumbs
