// @flow
import React from 'react'
import type { Field } from 'lib/types'
import styles from './styles'

const renderButton = (field: Field) => {
  const { input, buttons } = field
  const onClick = (value) => () => input.onChange(value)
  const renderButtons = []
  const buttonProps = {
    type: 'submit',
  }
  if (field.disabled) {
    buttonProps.disabled = 'disabled'
  }

  for (let i in buttons) {
    renderButtons.push(
      <button
        {...buttonProps}
        className={styles.button}
        key={i}
        onClick={onClick(buttons[i])}
      >
        {i}
      </button>
    )
  }

  return (
    <section className={styles.inputContainer}>
      <input {...input} className={styles.input} type='hidden' />
      {renderButtons}
    </section>
  )
}

export default renderButton
