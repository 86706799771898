import React from 'react'
import styles from './styles.css'

import logo from 'styles/img/logo.png'

const HeaderMenuLogo: React.FC = () => {
  return (
    <div className={styles.headerMenuLogo}>
      <img className={styles.headerLogo} src={logo} alt="Conductor" />
      <h4 className={styles.headerTitle}>Conductor</h4>
    </div>
  )
}

export default HeaderMenuLogo
