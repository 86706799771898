// @flow
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Template } from './Template'
import { ContactSelectModal } from 'components/ContactSelectModal'
import { Dialog } from 'components/Dialog'
import formatters from 'lib/formatters'
import { httpPost } from 'lib/http'
import { setModal, unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import cleaners from 'lib/form/cleaners'
import styles from './styles'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  location: Object,
}

type State = {
  errors: Object,
  isDialogOpen: boolean,
  values: Object,
}

class MergeContact extends React.Component<Props, State> {
  state = {
    errors: {},
    isDialogOpen: false,
    values: {},
  }

  props: Props

  handleChange = (key, value) => {
    const values = {
      ...this.state.values,
      [key]: value,
    }

    this.setState({ values })
    this.props.dispatch(unsetModal())
  }

  handleCopyField = ({ field }) => {
    let setPath = ['values', 'primary_contact']
    let getPath = ['values', 'secondary_contact']

    if (_.isArray(field)) {
      setPath = [...setPath, ...field]
      getPath = [...getPath, ...field]
    } else {
      setPath = [...setPath, field]
      getPath = [...getPath, field]
    }

    this.setState((prevState) => {
      const secondaryVal = _.get(prevState, getPath)
      return _.set({ ...prevState }, setPath, secondaryVal)
    })
  }

  handleSwapContacts = () => {
    this.setState(
      ({
        values: { primary_contact, secondary_contact, ...rest_values },
        ...rest
      }) => ({
        ...rest,
        values: {
          ...rest_values,
          primary_contact: secondary_contact,
          secondary_contact: primary_contact,
        },
      }),
    )
  }

  contactSelectHandler = (key) => (contact) => () =>
    this.handleChange(key, contact)

  createSelectHandler = (key) => () => {
    const callback = this.contactSelectHandler(key)
    const selectionFilter = ({
      id,
      beneficiary_of_gpt
    }) => {
      let filtered = false
      let filteredElement

      const renderNoMerge = (text) => (
        <div className={styles.selectionFilter}>
          <span>{`*${text} Cannot Be Merged`}</span>
        </div>
      )

      const renderAlreadySelected = (text) => (
        <div className={`${styles.selectionFilter} ${styles.alreadySelected}`}>
          <span>Selected As {text} Contact</span>
          <FontAwesomeIcon icon="check" size="lg" />
        </div>
      )

      const { primary_contact, secondary_contact } = this.state.values

      if (primary_contact && id === this.state.values.primary_contact.id) {
        filtered = true
        filteredElement = renderAlreadySelected('Primary')
      } else if (
        secondary_contact &&
        id === this.state.values.secondary_contact.id
      ) {
        filtered = true
        filteredElement = renderAlreadySelected('Secondary')
      } else if (beneficiary_of_gpt.length > 0) {
        filtered = true
        filteredElement = renderNoMerge('Beneficiaries')
      }

      return { filtered, filteredElement }
    }
    const event = setModal(
      <ContactSelectModal
        selectionFilter={selectionFilter}
        selectionHandler={callback}
      />,
    )
    this.props.dispatch(event)
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.openDialog()
  }

  closeDialog = () => this.setState({ isDialogOpen: false })

  openDialog = () => this.setState({ isDialogOpen: true })

  renderDialog = () => {
    const { dispatch } = this.props
    const {
      primary_contact: {
        id,
        phone_numbers: {
          day_phone: dayPhone,
          evening_phone: eveningPhone,
          fax,
          cell_phone: cellPhone,
        },
        street_1: street1,
        street_2: street2,
        ...primary_contact
      },
      secondary_contact,
    } = this.state.values
    const postParams = {
      primary_contact: {
        id,
        ...cleaners.contact({
          street1,
          street2,
          dayPhone,
          eveningPhone,
          fax,
          cellPhone,
          ...primary_contact,
        }),
      },
      secondary_contact_id: secondary_contact.id,
    }

    const onCancel = () => this.closeDialog()

    const onConfirm = () => {
      this.closeDialog()
      dispatch(setLoad())
      httpPost('/admin/contacts/merge', postParams)
        .then(() => {
          this.props.location.navigate('/contacts')
          setTimeout(() => {
            dispatch(setUnload())
            dispatch(setBanner('Contacts successfully merged.'))
          }, 500)
        })
        .catch(({ errors }) => {
          dispatch(setUnload())
          this.setState({ errors: formatters.apiErrors(errors) })
        })
    }

    const bodyText = (
      <div>
        This will move the following associations from the{' '}
        <b>Secondary Contact</b> to the <b>Primary Contact</b>:
        <ul>
          <li>Ables</li>
          <li>Attorneys</li>
          <li>Consults</li>
          <li>Referrers</li>
          <li>Grantors</li>
          <li>Co-Grantors</li>
          <li>Representatives</li>
          <li>Statement Recipients</li>
        </ul>
        Then, the <b>Secondary Contact</b> will be <b>deleted</b>.
      </div>
    )

    return (
      <Dialog
        bodyText={bodyText}
        cancelButtonText="Cancel"
        confirmButtonText="Merge"
        headerText="Merge contacts?"
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    )
  }

  render() {
    return (
      <div>
        <Template
          copyField={this.handleCopyField}
          {...this.state.values}
          errors={this.state.errors}
          onPrimaryContactSelection={this.createSelectHandler(
            'primary_contact',
          )}
          onSecondaryContactSelection={this.createSelectHandler(
            'secondary_contact',
          )}
          onSubmit={this.handleSubmit}
          swapContacts={this.handleSwapContacts}
        />
        {this.state.isDialogOpen && this.renderDialog()}
      </div>
    )
  }
}

export default withRouter(connect()(MergeContact))
