// @flow
import React from 'react'
import { AccountStatement } from 'components/AccountStatement'

type Props = {
  statements: Array<Object>,
}

const statementKey = (statement) => {
  return statement.address
    ? `${statement.address.id}:${statement.number}`
    : statement.number
}

const renderStatements = (statements) =>
  statements?.map((statement) => (
    <AccountStatement {...statement} key={statementKey(statement)} />
  ))

export const Report = ({ statements }: Props) => (
  <div>{renderStatements(statements)}</div>
)
