// @flow
import React from 'react'
import { httpGet, httpPut, wsUrl } from 'lib/http'
import { Socket } from 'phoenix'

import Notification from './Notification'

type Props = {
  resolveNotification?: Function,
  canResolve: boolean,
  isLoggedIn: boolean,
}

type State = {
  notifications: [NotificationProps],
}

export type NotificationProps = {
  id: number,
  message: string,
  status: string,
  type: string,
  tagged_gpt_accounts: [
    { tag: string, gpt_account: { id: number, number: string } },
  ],
  tagged_emails: [{ tag: string, email: string }],
  ticket_id: number,
  inserted_at: string,
}

class NotificationContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      menuActive: false,
      notifications: [],
    }
  }

  connectToChannel = () => {
    let socket = new Socket(wsUrl(), {
      params: {},
    })

    socket.connect()

    let channel = socket.channel('notifications')

    channel.on('refresh', () => {
      this.fetchNotifications()
    })

    channel.join()
  }

  fetchNotifications = () => {
    httpGet('/admin/notifications').then(({ notifications }) => {
      this.setState({ notifications })
    })
  }

  resolveNotification = (notification) => {
    httpPut(`/co-trustee/notifications/${notification.id}`, {
      ...notification,
      status: 'resolved',
    }).then(({ notification }) => {
      this.setState(({ notifications }) => {
        notifications = notifications.map((n) => {
          if (n.id === notification.id) {
            return notification
          }
          return n
        })

        return { notifications }
      })
    })
  }

  componentDidMount() {
    this.fetchNotifications()
    this.connectToChannel()
  }

  render() {
    const { canResolve } = this.props
    const { notifications } = this.state

    return (
      <Notification
        canResolve={canResolve}
        notifications={notifications}
        resolveNotification={this.resolveNotification}
      />
    )
  }
}

export default NotificationContainer
