// @flow
import React from 'react'
import icons, { SVGElement } from './IconList'
import { IconTypes } from './IconTypes'

type IconProps = {
  icon: IconTypes,
  fill?: any,
  width: number,
  height: number,
  className?: any,
}

const Icon = (props: IconProps) => {
  const { icon, fill = 'black', width = 10, height = 10, className } = props
  const haveSVG = icons.hasOwnProperty(icon)
  const svg: SVGElement | null = haveSVG ? icons[icon] : null

  if (!svg) {
    return null
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox={svg.viewbox}
      className={className}
    >
      <g fill={fill}>
        {svg.paths ? <path d={svg.paths} /> : null}
        {svg.points ? <polygon points={svg.points} /> : null}
      </g>
    </svg>
  )
}

export default Icon
