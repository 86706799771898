// @flow
import React from 'react'
import formatters from 'lib/formatters'
import type { Ticket } from 'lib/types'
import styles from './styles'
import { Table, Row, Cell } from 'components/Table'

type Props = {
  tickets: Array<Ticket>,
}

type DataProps = {
  data: Object,
  index: number,
}

const renderData = (props: DataProps) => {
  const { data, index, } = props
  let {
    gpt_account,
    id,
    last_event,
    updated_at,
  } = data
  let { beneficiary } = gpt_account
  const name = beneficiary.name
  const number = gpt_account.number
  const reviewer = last_event.user
  const reviewed = formatters.date(updated_at)

  return (
    <Row key={index} link={`/tickets/${id}`}>
      <Cell value={name} bold={true} />
      <Cell value={number} account='gpt' />
      <Cell value={reviewer} />
      <Cell value={reviewed} />
    </Row>
  )
}

class Component extends React.Component {
  props: Props
  constructor(props: Props) {
    super(props)
  }
  render() {
    const { tickets } = this.props
    const headers = [
      'Beneficiary',
      'Account #',
      'Reviewer',
      'Reviewed',
    ]
    let dataRows = []
    if (tickets) {
      dataRows = tickets
        .map((item, idx) => renderData({data: item, index: idx}))
    }

    return (
      <section>
        <div className={styles.localHeader}>
          <div className={styles.localHeaderInner}>
            <h5>Approve New Accounts</h5>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <h4 className="section">
            Accounts listed below are awaiting co-trustee approval.
          </h4>
          <div className={styles.form}>
            <Table headers={headers} tableSize='large' title='Tickets'>
              {dataRows}
            </Table>
          </div>
        </div>
      </section>
    )
  }
}

export default Component
