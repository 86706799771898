// @flow
const file = (value: Object) => {
  return {
    content_type: value.content_type,
    data: value.result.split(',')[1],
    key: value.key
  }
}

const phone = (value: string, previousValue: string) => {
  const matches = value.match(/\d/g)
  if (!matches) return null
  if (matches.length > 10) return previousValue
  let newVal = matches.join('')
  if (matches.length > 7) {
    return newVal.replace(/(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3')
  }

  return newVal
    .replace(/(\d{3})(\d{1,4})/, '$1-$2')
}

const ein = (value: string, previousValue: string) => {
  const matches = value.match(/\d/g)
  if (!matches) return null
  if (matches.length > 9) return previousValue
  const newVal = [
    matches.slice(0, 2).join(''),
    matches.slice(2).join(''),
  ].join('-').replace(/[-]*$/, '')

  return newVal
}

const float = (value: string) => {
  return value
    .replace(/^(.)([^-]*)-/g, '$1$2')
    .replace(/[^\d.-]*/g, '')
    .replace('.', 'x')
    .replace(/\./g, '')
    .replace('x', '.')
}

const currency = (value) => {
  return value ? (value * 1).toFixed(2) : ''
}

const ssn = (value: string, previousValue: string) => {
  const matches = value.match(/\d/g)
  if (!matches) return null
  if (matches.length > 9) return previousValue
  const newVal = [
    matches.slice(0, 3).join(''),
    matches.slice(3, 5).join(''),
    matches.slice(5).join('')
  ].join('-').replace(/[-]*$/, '')

  return newVal
}

export default {
  ein,
  file,
  phone,
  float,
  currency,
  ssn,
}
