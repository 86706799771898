// @flow
import React, { useState } from 'react'
import Select from 'react-select'
import { JOURNAL_IDS } from 'lib/constants'
import styles from './styles'

type Props = {
  multi: boolean,
  onChange: Function,
  placeholder: string,
  value: string | string[],
  isClearable?: boolean,
}

const renderArrow = () => <i className={styles.arrow} />

export const TrustInput = ({
  multi,
  onChange,
  placeholder: propsPlaceholder,
  value,
  isClearable = true,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState('')
  const placeholder =
    propsPlaceholder || (multi ? 'Select trust(s)' : 'Select a trust')
  const name = multi ? 'journalIds' : 'journalId'
  return (
    <div className={`${styles.trustInput} ${styles.formItem}`}>
      <label className={styles.label} htmlFor="journalId">
        Trust
      </label>
      <div>
        <Select
          clearable={isClearable}
          className={styles.trustInput}
          classNamePrefix="react-select"
          optionClassName={styles.trustInputOption}
          name={name}
          value={selectedOption}
          onChange={(value) => {
            setSelectedOption(value)
            onChange({ name, value })
          }}
          options={JOURNAL_IDS}
          multi={multi}
          arrowRenderer={renderArrow}
          placeholder={placeholder}
          styles={{
            menu: (baseStyles, state) => ({
              ...baseStyles,
              margin: '0 auto',
              borderTop: 'transparent',
            }),
            option: (
              baseStyles,
              { data, isDisabled, isFocused, isSelected },
            ) => {
              return {
                ...baseStyles,
                backgroundColor: isSelected && '#E3EBF5CD',
                color: isSelected && '#376EA1',
                '&:active': {
                  backgroundColor: '#E3EBF5',
                },
                '&:hover': {
                  backgroundColor: '#E3EBF5',
                  cursor: 'pointer',
                },
              }
            },
            singleValue: (styles, { data }) => ({
              ...styles,
              color: '#376EA1',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: '#EDECED',
              boxShadow: 'none',
              ':hover': {
                borderColor: '#b3b3b3 #ccc #d9d9d9',
              },
            }),
          }}
        />
      </div>
    </div>
  )
}
