// @flow
import React from 'react'
import { SearchResultItem } from './SearchResultItem'
import { Outlet } from 'react-router-dom'

type Props = {
  contacts: Array<any>,
}

export function SearchResult({ contacts }: Props) {
  return (
    <div>
      {contacts.map((contact, index) => (
        <SearchResultItem key={`${contact.id} ${index}`} contact={contact} />
      ))}

      <Outlet />
    </div>
  )
}
