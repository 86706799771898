import React, { useState, useEffect } from 'react'
import { httpGet } from 'lib/http'
import query_string from 'query-string'
import { Autocomplete } from 'components/Autocomplete/Autocomplete'
import { useDebounce } from '../../lib/hooks'

type Props = {
  disabled: boolean,
  journalName: string,
  onContactSelect: Function,
}

const XeroContactSearch = ({
  disabled,
  journalName,
  onContactSelect,
  valueInsideInput,
  clearContactSelect,
  inputType,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [contacts, setContacts] = useState([])
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)

  useEffect(() => {
    if (debouncedSearchTerm.length >= 2) {
      const queryString = query_string.stringify({
        journalName,
        searchTerm: debouncedSearchTerm,
      })

      httpGet(`/admin/xero-contact-search?${queryString}`)
        .then(({ contacts }) => {
          setContacts(
            contacts.map((contact) => ({
              ...contact,
              line1: contact.name,
              line2: contact.street,
              line3: `${contact.city}, ${contact.state} ${contact.zip}`,
            })),
          )
        })
        .catch((err) => setContacts([]))
    }
  }, [debouncedSearchTerm])

  const handleContactSelect = (selectedId) => {
    if (!valueInsideInput) {
      setSearchTerm('')
    }
    setContacts([])

    const selectedContact = contacts.find((contact) => contact.id === selectedId)
    onContactSelect(selectedContact)
  }

  const clearResults = () => {
    setSearchTerm('')
    setContacts([])
    clearContactSelect()
  }

  useEffect(() => {
    if (valueInsideInput) {
      setSearchTerm(valueInsideInput)
    }
  }, [valueInsideInput])

  return (
    <div>
      <Autocomplete
        disabled={disabled}
        searchType="Contacts"
        searchQuery={searchTerm}
        searchResults={contacts}
        onChange={setSearchTerm}
        onSelect={handleContactSelect}
        onClear={clearResults}
        inputType={inputType}
      />
    </div>
  )
}

export default XeroContactSearch
