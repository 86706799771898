// @flow
import React from 'react'
import { httpGet } from 'lib/http'
import formatters from 'lib/formatters'
import { Table, Row, Cell } from 'components/Table'
import { Loader } from 'components/Notifications'
import styles from './styles'

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accountQueue: [],
      depositQueue: [],
      loading: true
    }
  }

  componentDidMount() {
    this._getActionQueue()
  }

  _getActionQueue() {
    httpGet('/admin/action-queue')
      .then((data) => {
        this.setState({
          accountQueue: data.accounts,
          depositQueue: data.deposits,
          loading: false
        })
      })
  }

  _accountsTable() {
    const { accountQueue } = this.state

    const queueSize = accountQueue.length
    const title =
      'New Account Tickets' + (queueSize > 0 ? ` (${queueSize})` : '')

    const headers = [
      'Beneficiary',
      'Account #',
      'Station',
      'Last Event',
      'Notes'
    ]

    const renderRow = (item, idx) => (
      <Row key={`action-queue-item-${idx}`} link={`/tickets/${item.id}`}>
        <Cell value={item.beneficiaryName} />
        <Cell value={item.accountNumber} account='gpt' />
        <Cell value={formatters.legibleStation(item.station)} />
        <Cell value={this._renderLastEvent(item)} />
        <Cell value=''/>
      </Row>
    )

    const renderedRows = accountQueue.map((item, idx) => renderRow(item, idx))

    return (
      <Table headers={headers} tableSize='large' title={title}>
        {renderedRows}
      </Table>
    )
  }

  _depositsTable() {
    const { depositQueue } = this.state

    const queueSize = depositQueue.length
    const title = 'Deposit Tickets' + (queueSize > 0 ? ` (${queueSize})` : '')

    const headers = [
      'Beneficiary',
      'Account #',
      'Station',
      'Last Event',
      'Notes'
    ]

    const renderRow = (item, idx) => (
      <Row key={`deposit-queue-item-${idx}`} link={`/tickets/${item.id}`}>
        <Cell value={item.beneficiaryName} />
        <Cell value={item.accountNumber} account='gpt' />
        <Cell value={formatters.legibleStation(item.station)} />
        <Cell value={this._renderLastEvent(item)} />
        <Cell className={styles.valueAlert} value={item.notes || ' '} />
      </Row>
    )

    const renderedRows = depositQueue.map((item, idx) => renderRow(item, idx))

    return (
      <Table headers={headers} tableSize='large' title={title}>
        {renderedRows}
      </Table>
    )
  }

  _renderLastEvent(item) {
    const { lastEventUser, lastEventDescription, updatedAt } = item
    const updatedTime = formatters.naiveDateTime(updatedAt).fromNow()
    return (
      <div>
        <strong>{lastEventUser} </strong>
        <span>{lastEventDescription} </span>
        <span className={styles.updatedTime}>({updatedTime})</span>
      </div>
    )
  }

  render() {
    return (
      <section className={styles.contentContainer}>
        <div>
          <h3 className="pageTitle">Queue</h3>
          <p>
            The tickets listed below need your attention.
          </p>
        </div>
        <div className={styles.fullWidth}>
          {this._depositsTable()}
        </div>
        <div className={styles.fullWidth}>
          {this._accountsTable()}
        </div>
        <Loader non_redux_loading={this.state.loading} />
      </section>
    )
  }
}

export default Main
