// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs__localHeader___WPCrH {
  background: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueLight"]};
  border-bottom: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]};
  border-top: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]};
  padding: 10px 0;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]};
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./universal/components/Breadcrumbs/Breadcrumbs.css"],"names":[],"mappings":"AAGA;EACE,wDAAuB;EACvB,qEAA+B;EAC/B,kEAA4B;EAC5B,eAAe;EACf,mDAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["@value colors: 'styles/colors.css';\n@value gtGray, gtBlueDark, gtBlueLight from colors;\n\n.localHeader {\n  background: gtBlueLight;\n  border-bottom: 1px solid gtGray;\n  border-top: 1px solid gtGray;\n  padding: 10px 0;\n  color: gtBlueDark;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `'styles/colors.css'`,
	"gtGray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]}`,
	"gtBlueDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]}`,
	"gtBlueLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueLight"]}`,
	"localHeader": `Breadcrumbs__localHeader___WPCrH`
};
export default ___CSS_LOADER_EXPORT___;
