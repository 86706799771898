// @flow
import React from 'react'
import type { Field } from 'lib/types'
import FieldWrapper from './FieldWrapper'
import renderTextArea from './renderTextArea'

const renderTextAreaField = (field: Field) => {
  const {
    autoFocus,
    label,
    meta: { asyncValidating },
    placeholder,
    required,
  } = field
  const props = {
    asyncValidating,
    autoFocus,
    label: label || placeholder,
    required,
  }
  return (
    <FieldWrapper {...props}>
      {renderTextArea(field)}
    </FieldWrapper>
  )
}

export default renderTextAreaField
