// @flow
import React from 'react'
import styles from './styles'
import formatters from 'lib/formatters'
import { setModal, unsetModal } from 'lib/modal/actions'
import { Table, Row, Cell } from '../Table'
import { Notes } from 'components/Notes'
import { S3FilePreview } from 'components/S3FilePreview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { DateOfDeathRef } from 'components/References'
import dateUtil from 'lib/dateUtil'

type Props = {
  dispatch: Function,
  handleActionDelete: Function,
  handleFileDelete: Function,
  number: String,
  dateOfDeath: Date,
  events: [Object],
  notes: [Object],
  files: [Object],
  nextActions: [Object],
  openAttachmentModal: Function,
  openClosingDateModal: Function,
  openClosingEventModalCreate: Function,
  openClosingEventModalUpdate: Function,
  openClosingFeeModal: Function,
  openDateOfDeathModal: Function,
  onSuccess: Function,
}

export const Template = (props: Props) => {
  const {
    dispatch,
    handleActionDelete,
    handleFileDelete,
    number,
    dateOfDeath,
    events,
    notes,
    nextActions,
    openAttachmentModal,
    openClosingDateModal,
    openClosingEventModalCreate,
    openClosingEventModalUpdate,
    openClosingFeeModal,
    openDateOfDeathModal,
  } = props

  const deleteActionModal = (id) => {
    return (
      <section>
        <div className="section">
          <h4>Are you sure you want to delete this action?</h4>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.removeBtn}
            onClick={() => handleActionDelete(number, id)}
          >
            Delete
          </button>
          <span className={styles.spacer} />
          <button
            className={styles.cancelBtn}
            onClick={() => dispatch(unsetModal())}
          >
            Cancel
          </button>
        </div>
      </section>
    )
  }

  const renderEventRow = (e, idx) => {
    let notes = ' '
    const max = 50

    if (e.notes) {
      if (e.notes.length > max) {
        notes = e.notes.substring(0, max) + '...'
      } else {
        notes = e.notes
      }
    }

    const editLink = (
      <Link
        className={styles.iconLink}
        onClick={() => openClosingEventModalUpdate(e)}
      >
        <FontAwesomeIcon className={'fa-lg'} icon={'edit'} />
      </Link>
    )

    const deleteHandler = () => {
      dispatch(setModal(deleteActionModal(e.id)))
    }

    const dateDue = () => {
      const text = formatters.date(e.date_due)

      if (dateUtil.isDateBeforeToday(e.date_due) && !e.date_completed) {
        return <span style={{ color: 'red' }}>{text}</span>
      } else {
        return text
      }
    }

    const deleteLink = (
      <Link className={styles.iconLink} onClick={deleteHandler}>
        <FontAwesomeIcon className={'fa-lg'} icon={'times-circle'} />
      </Link>
    )

    return (
      <Row key={`events${idx}`}>
        <Cell value={formatters.date(e.date)} />
        <Cell value={e.event} />
        <Cell value={dateDue()} />
        <Cell value={formatters.date(e.date_completed)} />
        <Cell value={notes} italic={true} />
        <Cell value={e.user} />
        <Cell value={editLink} />
        <Cell value={deleteLink} />
      </Row>
    )
  }

  const renderEventsTable = () => {
    let rows = events.map(renderEventRow)

    return (
      <div className={styles.eventsTable}>
        <Table
          title={'Actions Taken'}
          headers={[
            'Date',
            'Event',
            'Due',
            'Completed',
            'Note',
            'User',
            ' ',
            ' ',
          ]}
          tableSize="large"
          tableType="read"
        >
          {rows}
        </Table>
      </div>
    )
  }

  const renderNextActionRow = (event, idx) => {
    const link = (
      <Link
        className={styles.iconLink}
        onClick={() => openClosingEventModalCreate(event)}
      >
        <FontAwesomeIcon className={'fa-lg'} icon={'plus-circle'} />
      </Link>
    )

    return (
      <Row key={`row${idx}`}>
        <Cell className={styles.smallFixedCell} value={link} />
        <Cell value={event} />
      </Row>
    )
  }

  const renderNextActionsTable = (category, events) => {
    let rows = events.map(renderNextActionRow)

    return (
      <div className={styles.nextActionsTable} key={category}>
        <Table
          title={category}
          headers={[]}
          tableSize="medium"
          tableType="read"
        >
          {rows}
        </Table>
      </div>
    )
  }

  const renderNextActions = () => {
    let tables = []

    for (const dict of nextActions) {
      const table = renderNextActionsTable(dict.category, dict.actions)
      tables.push(table)
    }

    return <div>{tables}</div>
  }

  const showLink = (file) => (
    <S3FilePreview file_key={file.key} label={file.name} />
  )

  const showFiles = ({ files, dispatch, onSuccess }: Props) => {
    if (!Array.isArray(files)) return null
    let docs
    if (files.length) {
      docs = files.map((file, idx) => {
        const { id } = file
        const deleteHandler = () => {
          dispatch(setModal(deleteFile(dispatch, onSuccess, id)))
        }
        return (
          <div className={styles.otherDocsRow} key={idx}>
            {showLink(file)}
            <div className={styles.remove}>
              <a title="Delete File" onClick={deleteHandler} />
            </div>
          </div>
        )
      })
    } else {
      docs = <div className={styles.noDocResults}>No files</div>
    }
    return <div className={styles.docsWrapper}>{docs}</div>
  }

  const deleteFile = (dispatch, onSuccess, fileId) => {
    return (
      <section>
        <div className="section">
          <h4>Are you sure you want to delete this file?</h4>
        </div>
        <div className={styles.buttons}>
          <button
            className={styles.removeBtn}
            onClick={() => handleFileDelete(onSuccess, fileId)}
          >
            Delete File
          </button>
          <span className={styles.spacer} />
          <button
            className={styles.cancelBtn}
            onClick={() => dispatch(unsetModal())}
          >
            Cancel
          </button>
        </div>
      </section>
    )
  }

  const setGoToAccountButton = (
    <Link className={styles.accountButton} to={`/accounts/${number}`}>
      Account Page
    </Link>
  )

  const setClosingFeeButton = (
    <Link
      className={styles.closingFeeButton}
      onClick={() => openClosingFeeModal()}
    >
      Set Closing Fee
    </Link>
  )

  const setClosingDateButton = (
    <Link
      className={styles.closingDateButton}
      onClick={() => openClosingDateModal()}
    >
      Close Account
    </Link>
  )

  return (
    <section>
      <div className={styles.localHeader}>
        <div className={styles.localHeaderInner}>
          <Link to={'/closings'} className={styles.beneName}>
            Back to closings
          </Link>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <h2 className={styles.sectionTitle}>Closing Management for {number}</h2>
        <div className={styles.row}>
          <section className={styles.tableWrapper}>
            {events && renderEventsTable()}
            <section className={styles.section}>
              <h2 className={styles.sectionTitle}>Next Actions</h2>
              <a
                className={styles.buttonLocal}
                onClick={() => openClosingEventModalCreate('')}
              >
                Add Custom
              </a>
              {nextActions && renderNextActions()}
            </section>
          </section>
          <section className={styles.oneThird}>
            <div className={styles.actions}>
              {setClosingFeeButton}
              {setClosingDateButton}
              {setGoToAccountButton}
            </div>
            <DateOfDeathRef
              dateOfDeath={dateOfDeath}
              openDateOfDeathModal={openDateOfDeathModal}
            />
            <Notes
              accountNumber={number}
              dispatch={dispatch}
              notes={notes}
              type="closing"
            />
            <section data-active={true} className={styles.collapseRef}>
              <div className={styles.titleSection}>
                <div className={styles.title}>
                  <h5>Files</h5>
                </div>
                <span
                  className={styles.leaveNoteIcon}
                  onClick={() => openAttachmentModal()}
                  title="Attach File"
                />
              </div>
              {showFiles(props)}
            </section>
          </section>
        </div>
      </div>
    </section>
  )
}
