import SimpleFileFolder from 'components/SimpleFileFolder'
import styles from './styles.css'
import SectionHeader from '../../Header/SectionHeader'

export default function RequestDocument(props) {
  const { onUploadClick, files, onDeleteClick } = props
  return (
    <div className={styles.cardMarginBot}>
      <SectionHeader title={'Request Documents'} className={styles.invoiceReviewHeader} />
      <section className={styles.requestDocumentSection}>
        <section className={styles.subSection}>
          <div className={styles.fileUpload}>
            <div className={styles.fileUploadComponent}>
              <SimpleFileFolder onUploadClick={onUploadClick} files={files} onDeleteClick={onDeleteClick} layout="horizontal" />
            </div>
          </div>
        </section>
      </section>
    </div>
  )
}
