// @flow
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import FormButtons from 'components/FormButtons'
import { renderFileUpload, renderInputField } from 'components/FormFields'
import { fetchAccountHTTP } from 'lib/account/actions'
import normalizers from 'lib/field/normalizers'
import validators from 'lib/field/validators'
import { httpPost } from 'lib/http'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import type { Form } from 'lib/types'
import styles from './styles'

type Props = {
  accountNumber: number,
  form: Form,
  onSuccess: Function,
}

const onSubmit = (accountNumber, { file, name }, dispatch) => {
  const data = {
    'content-type': file.content_type,
    data: file.data,
    name: name,
  }
  dispatch(setLoad())
  return httpPost(`/admin/accounts/${accountNumber}/attachments`, data)
    .then(() => {
      dispatch(fetchAccountHTTP(accountNumber))
      dispatch(setBanner('Document successfully attached'))
    })
    .catch(() => {
      dispatch(setBanner('Error attaching document', 'danger'))
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
}

const Component = ({ accountNumber, form }: Props) => {
  const handleSubmit = form.handleSubmit((data, dispatch) => {
    return onSubmit(accountNumber, data, dispatch)
  })
  return (
    <section>
      <h4 className="section">Attach Misc. Document</h4>
      <div className={styles.warning}>
        Required documents (e.g., joinder agreements, birth certificates) should
        not be uploaded here, but instead through ‘Edit Account’.
      </div>
      <form onSubmit={handleSubmit}>
        <div className="section">
          <div className={styles.formItemHalf}>
            <Field
              cleaner={(val) => val && val.trim()}
              component={renderInputField}
              label="Name"
              name="name"
              type="text"
              validate={validators.requiredWithTrim}
              autoFocus
            />
          </div>
          <div className={styles.formItemHalf}>
            <Field
              component={renderFileUpload}
              label="File"
              name="file"
              normalize={normalizers.file}
              validate={validators.requiredField}
            />
          </div>
        </div>
        <FormButtons {...form} />
      </form>
    </section>
  )
}

export default reduxForm({
  form: 'attachment',
  propNamespace: 'form',
})(Component)
