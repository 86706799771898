import React, { useState, useEffect } from 'react'
import { httpGet } from 'lib/http'
import query_string from 'query-string'
import { Autocomplete } from 'components/Autocomplete/Autocomplete'
import { useDebounce } from '../../lib/hooks'

type Props = {
  onAccountSelect: Function,
}

const AccountSearch = ({
  onAccountSelect,
  disabled,
  valueInsideInput,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [accounts, setAccounts] = useState([])
  const [selectedAccount, setSelectedAccount] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3 && !debouncedSearchTerm.includes('-')) {
      const queryString = query_string.stringify({
        q: debouncedSearchTerm,
        searchType: 'accounts_only',
      })

      httpGet(`/admin/contacts/autocomplete?${queryString}`).then(
        ({ accounts }) => {
          setAccounts(
            accounts.map((account) => ({
              ...account,
              id: account.number,
              line1: account.number,
              line2: account.beneficiary,
            })),
          )
        },
      )
    }
  }, [debouncedSearchTerm])

  const handleAccountSelect = (selectedId) => {
    if (!valueInsideInput) {
      setSearchTerm('')
    }
    setAccounts([])

    const selectedAccount = accounts.find(
      (account) => account.id === selectedId,
    )
    setSearchTerm(`${selectedAccount.number} - ${selectedAccount.beneficiary}`)
    setSelectedAccount(selectedAccount)
    onAccountSelect(selectedAccount)
  }

  const clearResults = () => {
    setSearchTerm('')
    setSelectedAccount('')
    setAccounts([])
  }

  useEffect(() => {
    if (valueInsideInput) {
      setSearchTerm(valueInsideInput)
    }
  }, [valueInsideInput])

  return (
    <div>
      <Autocomplete
        searchType="Accounts"
        searchQuery={searchTerm}
        searchResults={accounts}
        onChange={setSearchTerm}
        onSelect={handleAccountSelect}
        onClear={clearResults}
        selectedAccount={selectedAccount}
        disabled={disabled}
      />
    </div>
  )
}

export default AccountSearch
