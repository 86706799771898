// @flow
import React from 'react'
import { DateRangeSelection } from 'components/DateRangeSelection'
import BalanceGraph from './BalanceGraph/BalanceGraph.js'
import MetricsRef from 'components/References/MetricsRef'
import moment from 'moment'
import queryString from 'query-string'
import { httpGet } from 'lib/http'
import styles from './Metrics.css'

type Props = {
  accountNumber: string,
  transactions: Array<Object>,
  growth: {
    capitalGains: number,
    interest: number,
    shortTermGains: number,
    longTermGains: number,
    deposit: number,
  },
  fees: {
    adminFees: number,
    annualFees: number,
    enrollmentFees: number,
  },
}

class Metrics extends React.Component {

  onChange = (event) => {
    const { name, value } = event.currentTarget
    this.setState({ [name]: value })
  }

  canSubmit = () => {
    const { dateStart, dateEnd } = this.state
    const momentStart = moment(dateStart)
    const momentEnd = moment(dateEnd)

    if (momentStart.isValid()
      && momentEnd.isValid()) {
      if (momentStart.isAfter('2002-01-01')) {
        return (momentStart.isBefore(momentEnd)
          || momentStart.isSame(momentEnd)
        )
      }
    }
  }

  constructor(props: Props) {
    super(props)
    const { transactions } = this.props
    const dateOldestTransaction = transactions[0].date
    let dateNewestTransaction = transactions[transactions.length - 1].date

    if (dateOldestTransaction == dateNewestTransaction) {
      const newDate = moment(dateNewestTransaction).add(1, 'day')
      dateNewestTransaction = newDate.format('YYYY-MM-DD')
    }

    this.state = {
      presets: [
        {
          text: 'All Data',
          start: dateOldestTransaction,
          end: dateNewestTransaction,
        },
        {
          text: 'Last Week',
          start: moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek'),
          end: moment()
            .subtract(1, 'weeks')
            .endOf('isoWeek'),
        },
        {
          text: 'Last Month',
          start: moment()
            .subtract(1, 'months')
            .startOf('month'),
          end: moment()
            .subtract(1, 'months')
            .endOf('month'),
        },
      ],
      growth: 'loading',
      fees: 'loading',
      dateEnd: dateNewestTransaction,
      dateStart: dateOldestTransaction,
      transactions: transactions,
    }
  }

  componentDidMount() {
    this.fetchTransactionReport()
  }

  componentDidUpdate(
    prevProps,
    { dateStart: prevStartDate, dateEnd: prevEndDate },
  ) {
    const { dateStart, dateEnd } = this.state
    if (prevStartDate != dateStart || prevEndDate != dateEnd) {
      if (this.canSubmit()) {
        this.fetchTransactionReport()
      }
    }
  }

  render() {
    const {
      growth,
      fees,
      transactions,
      dateEnd,
      dateStart,
    } = this.state

    let growthDetails = []
    let growthPreview = []
    let feesPreview = []

    if (growth) {
      const {
        deposit,
        totalInterest,
        dividendsAndInterest,
        donation,
        interest,
        longTermCapital,
        shortTermCapital,
        unrealizedCapital,
        returnOfCapital,
        mutualFundFeeCredit,
        taxDeferredGrowth,
      } = growth

      growthPreview = [
        { title: 'Deposit', value: deposit },
        { title: 'Change in Investment Value', value: totalInterest },
      ]

      growthDetails = [
        { title: 'Dividends & Interest', value: dividendsAndInterest },
        { title: 'Donation from Guardian Trust', value: donation },
        { title: 'Interest', value: interest },
        { title: 'Long Term Capital', value: longTermCapital },
        { title: 'Short Term Capital', value: shortTermCapital },
        { title: 'Unrealized Capital Gain/Loss', value: unrealizedCapital },
        { title: 'Return of Capital', value: returnOfCapital },
        { title: 'Mutual Fund Fee Credit', value: mutualFundFeeCredit },
        { title: 'Tax Deferred Growth', value: taxDeferredGrowth },
      ]
    }

    if (fees) {
      const { adminFee, advisoryFee, annualFee, enrollmentFee } = fees

      feesPreview = [
        { title: 'Admin Fees', value: Math.abs(adminFee) },
        { title: 'Advisory Fees', value: Math.abs(advisoryFee) },
        { title: 'Annual Fees', value: Math.abs(annualFee) },
        { title: 'Enrollment Fees', value: Math.abs(enrollmentFee) },
      ]
    }

    return (
      <div>
        <div className={styles.dateRangePicker}>
          <DateRangeSelection
            dateStart={dateStart}
            dateEnd={dateEnd}
            onSubmit={this.onSubmit}
            onChange={this.onChange}
          />
        </div>
        <section className={styles.cardMarginBot}>
          <h5 className={styles.sectionTitle}>Balance</h5>
          <section className={styles.sectionNoMargin}>
            <div style={{ height: 350 }}>
              <BalanceGraph
                startDate={dateStart}
                endDate={dateEnd}
                transactions={transactions}
              />
            </div>
          </section>
        </section>
        <section className={styles.rowTop}>
          <section className={styles.oneHalf}>
            <MetricsRef
              loading={growth == 'loading'}
              title="Growth"
              preview={growthPreview}
              details={growthDetails}
            />
          </section>
          <section className={styles.oneHalf}>
            <MetricsRef
              loading={fees == 'loading'}
              title="Fees"
              preview={feesPreview}
            />
          </section>
        </section>
      </div>
    )
  }

  fetchTransactionReport() {
    const { accountNumber } = this.props
    const { dateStart, dateEnd } = this.state
    const query = {
      account_numbers: [accountNumber],
      date_field: 'date',
      date_start: dateStart,
      date_end: dateEnd,
      descriptions: [
        'Administrative Fee',
        'Advisory Fees',
        'Annual Fee',
        'Enrollment Fee',
        'Deposit',
        'Dividends & Interest',
        'Donation from Guardian Trust',
        'Interest',
        'Long Term Capital',
        'Short Term Capital',
        'Unrealized Capital',
        'Unrealized Capital Gain/Loss',
        'Return of Capital',
        'Mutual Fund Fee Credit',
        'Tax Deferred Growth',
      ],
    }
    httpGet(
      `/admin/reporting/transaction-report?${queryString.stringify(query, {
        arrayFormat: 'bracket',
      })}`,
    )
      .then(
        ({
          'Administrative Fee': { amount: adminFee },
          'Advisory Fees': { amount: advisoryFee },
          'Annual Fee': { amount: annualFee },
          'Enrollment Fee': { amount: enrollmentFee },
          Deposit: { amount: deposit },
          'Dividends & Interest': { amount: dividendsAndInterest },
          'Donation from Guardian Trust': { amount: donation },
          Interest: { amount: interest },
          'Long Term Capital': { amount: longTermCapital },
          'Short Term Capital': { amount: shortTermCapital },
          'Unrealized Capital Gain/Loss': { amount: unrealizedCapital },
          'Return of Capital': { amount: returnOfCapital },
          'Mutual Fund Fee Credit': { amount: mutualFundFeeCredit },
          'Tax Deferred Growth': { amount: taxDeferredGrowth },
        }) => {
          this.setState({
            fees: {
              adminFee,
              advisoryFee,
              annualFee,
              enrollmentFee,
            },
            growth: {
              totalInterest:
                dividendsAndInterest +
                donation +
                interest +
                longTermCapital +
                shortTermCapital +
                unrealizedCapital +
                returnOfCapital +
                mutualFundFeeCredit +
                taxDeferredGrowth,
              deposit,
              dividendsAndInterest,
              donation,
              interest,
              longTermCapital,
              shortTermCapital,
              unrealizedCapital,
              returnOfCapital,
              mutualFundFeeCredit,
              taxDeferredGrowth,
            },
          })
        },
      )
      .catch(() =>
        this.setState({
          fees: 'error',
          growth: 'error',
        }),
      )
  }
}

export default Metrics
