// @flow
import React from 'react'
import styles from './styles'

type Props = {
  asyncValidating: boolean,
  children?: Element,
  label: string,
  name?: string,
  type?: string,
  meta: Object,
  innerButton: Object,
}

const FieldWrapper = (props: Props) => {
  let inputType = props.type

  let errorClass
  let label = props.label

  if (props.meta && props.meta.error && props.meta.submitFailed) {
    errorClass = styles.errorWrapper
    label = (
      <div>
        {props.label} — <span className="capitalize">{props.meta.error}</span>
      </div>
    )
  }

  return (
    <div className={inputType}>
      <div className={errorClass}>
        <label htmlFor={props.name} className={styles.fieldLabel}>
          {label}
        </label>
        <div>{props.innerButton}</div>
        <div className={props.asyncValidating ? 'async-validating' : ''}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default FieldWrapper
