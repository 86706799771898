// @flow
import React from 'react'
import { connect } from 'react-redux'
import { TransitionGroup } from 'react-transition-group'
import FocusLock from 'react-focus-lock'
import { createStructuredSelector } from 'reselect'

import styles from './styles'
import { unsetModal } from 'lib/modal/actions'
import { getModal } from 'lib/modal/selectors'

type Props = {
  contents: string,
  dispatch: Function,
  modal: Object,
}

class Modal extends React.Component {
  props: Props

  closeModal() {
    this.props.dispatch(unsetModal())
  }
  render() {
    const modal = this.props.modal
    let contents = null
    if (modal.contents) {
      contents = (
        <div key="1">
          <div className="overlay" onClick={() => this.closeModal()} />
          <section className="modal">
            <div className={styles.inner}>
              {modal.contents}
              <div className={styles.close} onClick={() => this.closeModal()} />
            </div>
          </section>
        </div>
      )
    }

    return (
      modal.contents && (
        <FocusLock>
          <TransitionGroup className="modal" timeout={300}>
            <>{contents}</>
          </TransitionGroup>
        </FocusLock>
      )
    )
  }
}

export default connect(
  createStructuredSelector({
    modal: getModal,
  }),
)(Modal)
