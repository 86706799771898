import React from 'react'
import styles from './styles.css' // Import your CSS file

const RadioOption = ({ name, value, label, checked, onChange }) => {
  return (
    <div className={styles.radioOption}>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={(e) => onChange(!checked ? e : null)}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  )
}

export default RadioOption
