import styles from './styles'

function confirmationText(nextStatus, paymentType, step) {
  if (!nextStatus) {
    return 'Are you sure, you want to continue?'
  }
  switch (paymentType) {
    case 'ach':
    case 'wire':
      switch (nextStatus) {
        case 'written':
          return 'Added screenshot of iTreasury entry and uploaded screenshot in Conductor?'
        case 'admin_reviewed':
          return 'Confirmed iTreasury information and is ready to be paid?'
        case 'signer_reviewed':
          return 'Transaction has been paid in iTreasury and a screenshot uploaded?'
        default:
          return null
      }
    case 'cashier_check':
      switch (nextStatus) {
        case 'written':
          return 'Ready for review?'
        case 'admin_reviewed':
          return 'Check and envelope with label and postage attached. Ready for signer?'
        case 'signer_reviewed':
          switch (step) {
            case 0:
              return 'Verified information on physical check and envelope?'
            case 1:
              return "Signed check and got correct cashier's check at bank?"
            case 2:
              return "Cashier's check has been scanned and uploaded. Check is in the mail."
            default:
              return null
          }
        default:
          return null
      }
    case 'check_external':
      switch (nextStatus) {
        case 'written':
          return 'Ready for review?'
        case 'admin_reviewed':
          return 'Ready to be paid?'
        case 'signer_reviewed':
          return 'Transaction has been paid in Checkeeper?'
        default:
          return null
      }
    case 'check_in_house':
      switch (nextStatus) {
        case 'written':
          return 'Ready for review?'
        case 'admin_reviewed':
          return 'Check and envelope with label and postage attached. Ready for signer?'
        case 'signer_reviewed':
          return 'Physical check has been signed and is in outgoing mail?'
        default:
          return null
      }
    case 'pay_by_phone':
    case 'pay_online':
      switch (nextStatus) {
        case 'written':
          return 'Ready for review?'
        case 'signer_reviewed':
          return 'Ready to be paid?'
        case 'admin_reviewed':
          return 'Transaction has been paid?'
        default:
          return null
      }
  }
}

export default confirmationText

export const ButtonText = ({ primary, secondary }) => {
  return (
    <div className={styles.modalButton}>
      <p>{primary}</p>
      <span>{secondary}</span>
    </div>
  )
}
