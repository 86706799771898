// @flow
import React from 'react'
import styles from './styles.css'

type HeaderUtilityProps = {
  children?: React.ReactNode,
}

const HeaderUtility = (props: HeaderUtilityProps) => {
  const { children } = props

  return (
    <nav role="navigation" className={styles.headerUtility}>
      {children}
    </nav>
  )
}

export default HeaderUtility
