export const summaryBoxNavList = () => [
  { title: 'DRF MAIN PROCESSING QUEUE', countKey: 'main', theme: 'cyan' },
  { title: 'PENDING', countKey: 'pending', theme: 'amber' },
  { title: 'DENIED', countKey: 'denied', theme: 'orange' },
  { title: 'RECURRING PAYMENTS', countKey: 'recurring', theme: 'purple' },
  { title: 'ALL PAID', countKey: 'paid', theme: 'lime' },
]

export const tabTitleList = (type) => {
  const main = [
    { title: 'Write', countKey: 'written', tableTitle: 'Ready to Write' },
    {
      title: 'Admin Review',
      countKey: 'admin_reviewed',
      tableTitle: 'Ready to Review',
    },
    {
      title: 'Signer Review',
      countKey: 'signer_reviewed',
      tableTitle: 'Ready to Approve',
    },
    {
      title: 'Admin Wrap-Up',
      countKey: 'admin_wrapped_up',
      tableTitle: 'Ready to Approve',
    },
    {
      title: 'Approved(NOT YET PAID)',
      countKey: 'approved_not_paid',
      tableTitle: 'Approved(NOT YET PAID)',
    },
    { title: 'Paid Today', countKey: 'paid_today', tableTitle: 'Paid Today' },
  ]

  const pending = [
    { title: 'Pending CLIENT', countKey: 'pending_client' },
    { title: 'Pending INTERNAL', countKey: 'pending_internal' },
    { title: 'Aged Pending', countKey: 'aged_pending' },
  ]

  const paid = [{ title: 'All Paid', countKey: 'paid', tableTitle: 'All Paid' }]

  const denied = [
    { title: 'Denied', countKey: 'denied' },
    { title: 'Denied Closed', countKey: 'denied_closed' },
  ]

  const recurring = [
    { title: 'Write', countKey: 'recurring_written' },
    // { title: 'SR ADMIN REVIEW', countKey: 'recurring_reviewed' },
    { title: 'SR ADMIN REVIEW', countKey: 'recurring_approved' },
    { title: 'APPROVED TEMPLATES', countKey: 'scheduled' },
    { title: 'Expired', countKey: 'expired' },
  ]

  if (type === 'main') return main
  else if (type === 'pending') return pending
  else if (type === 'denied') return denied
  else if (type === 'paid') return paid
  else if (type === 'recurring') return recurring
  return []
}

export const getNewStatus = (currentStatus, index) => {
  let newStatus
  if (currentStatus === 'main') {
    switch (index) {
      case 0:
        newStatus = 'written'
        break
      case 1:
        newStatus = 'admin_reviewed'
        break
      case 2:
        newStatus = 'signer_reviewed'
        break
      case 3:
        newStatus = 'admin_wrapped_up'
        break
      case 4:
        newStatus = 'approved_not_paid'
        break
      case 5:
        newStatus = 'paid_today'
        break
      case 6:
        newStatus = 'paid'
        break
    }
  } else if (currentStatus === 'pending') {
    switch (index) {
      case 0:
        newStatus = 'pending_client'
        break
      case 1:
        newStatus = 'pending_internal'
        break
      case 2:
        newStatus = 'aged_pending'
        break
    }
  } else if (currentStatus === 'paid') {
    switch (index) {
      case 0:
        newStatus = 'paid'
        break
    }
  } else if (currentStatus === 'recurring') {
    switch (index) {
      case 0:
        newStatus = 'recurring_written'
        break
      case 1:
        newStatus = 'recurring_approved'
        break
      case 2:
        newStatus = 'scheduled'
        break
      case 3:
        newStatus = 'expired'
        break
    }
  } else if (currentStatus === 'denied') {
    switch (index) {
      case 0:
        newStatus = 'denied'
        break
      case 1:
        newStatus = 'denied_closed'
        break
    }
  }
  return newStatus
}

export const StatusActionMapper = {
  written: 'Write',
  admin_reviewed: 'Admin Review',
  signer_reviewed: 'Signer Review',
  admin_wrapped_up: 'Admin Wrap-Up',
  paid_today: 'Paid',
  pending_client: 'Pending Client',
  pending_internal: 'Pending Internal',
  aged_pending: 'Aged Pending',
  pending: 'Pending',
  paid: 'Paid',
  denied: 'Denied',
  denied_closed: 'Denied Closed',
  recurring_written: 'Recurring Write',
  recurring_reviewed: 'Recurring Review',
  recurring_approved: 'Recurring Approve',
  scheduled: 'Scheduled',
  expired: 'Expired',
  recurring_expired: 'Recurring Expired',
}

export const getStatusAction = (action) => {
  if (!action) return '---'
  return StatusActionMapper[action] ?? '---'
}

export const filterList = [
  { label: 'ALL TRUSTS', value: 'ALL TRUSTS', filterKey: null },
  { label: 'GPT Old', value: 'GPT Old', filterKey: ['G'] },
  { label: 'GPT New', value: 'GPT New', filterKey: ['F'] },
  { label: 'GPT Checking', value: 'GPT Checking', filterKey: ['C'] },
  { label: 'GTA', value: 'GTA', filterKey: ['N', 'D'] },
  { label: 'GSNT New', value: 'GSNT New', filterKey: ['J'] },
  { label: 'GSNT Old', value: 'GSNT Old', filterKey: ['T'] },
]
