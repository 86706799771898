// @flow
import React from 'react'
import { Template } from './Template'

type Props = {
  onSubmit: Function,
  onChange: Function,
  canSubmit: Function,
  dateStart: string,
  dateEnd: string,
}

export const DateRangeSelection = ({
  canSubmit,
  dateEnd,
  dateStart,
  onChange,
  onSubmit,
}: Props) => (
  <Template
    canSubmit={canSubmit}
    dateEnd={dateEnd}
    dateStart={dateStart}
    onChange={onChange}
    onSubmit={onSubmit}
  />
)
