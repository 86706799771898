// @flow
import React from 'react'
import styles from './Card.css'

type Props = {
  title?: any,
  children?: any,
  className?: string,
}

const Card = (props: Props) => {
  const { title = false, children, className, ...rest } = props
  return (
    <section {...rest} className={`${styles.card} ${className}`}>
      {title && <div className={styles.title}>{title}</div>}
      <div>{children}</div>
    </section>
  )
}

export default Card
