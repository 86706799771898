// @flow
import * as t from './actionTypes'
import { httpDelete, httpGet, httpPost, httpPut } from 'lib/http'
import { setBanner } from 'lib/notification/actions'
import { push } from 'react-router-redux'
import Rollbar from 'lib/rollbar'

export const createPotentialClient = (params: Object) => {
  return (dispatch) =>
    httpPost('/admin/potential_clients', params)
      .then(({ id: id }) => {
        const url = `/potential_clients/edit/${id}/`
        // dispatch(push(url))
        window.location.replace(url)
      })
      .catch((err) => {
        Rollbar.error('Error creating new potential client', err)
        dispatch(
          setBanner(`Error creating new potential client - ${err}`, 'danger'),
        )
      })
}

export const fetchPotentialClients = () => {
  return (dispatch: Function) => {
    return httpGet('/admin/potential_clients')
      .then((data) => {
        dispatch(setPotentialClients(data))
      })
      .catch(() => {
        dispatch(setPotentialClients({}))
      })
  }
}

const setPotentialClients = (data: Object) => {
  return (dispatch) => {
    dispatch({
      type: t.SET_POTENTIAL_CLIENTS,
      potential_clients: data.potential_clients,
    })
  }
}

export const fetchPotentialClient = (id) => {
  return (dispatch: Function) => {
    return httpGet(`/admin/potential_clients/${id}`)
      .then((data) => {
        dispatch(setPotentialClient(data))
      })
      .catch((err) => {
        Rollbar.error('Error loading potential client', err)
        dispatch(setBanner(`Error loading potential client - ${err}`, 'danger'))
      })
  }
}

export const setEIN = (val) => {
  return (dispatch) => {
    dispatch({ type: t.SET_EIN, val })
  }
}

const setPotentialClient = (data: Object) => {
  return (dispatch) => {
    dispatch({
      type: t.SET_POTENTIAL_CLIENT,
      potential_client: data.potential_client,
    })
  }
}

export const savePotentialClient = (params: Object) => {
  const id = params.id
  return (dispatch) => {
    dispatch({
      type: t.SET_UPLOAD_STATUS,
      status: 'uploading',
    })

    httpPut(`/admin/potential_clients/${id}`, params)
      .then((data) => {
        dispatch({
          type: t.SET_UPLOAD_STATUS,
          status: 'complete',
        })

        dispatch(setBanner('Changes saved successfully'))

        dispatch(setPotentialClient(data))
      })
      .catch(({ errors }) => {
        dispatch({
          type: t.SET_UPLOAD_STATUS,
          status: 'failed',
        })

        const msg = errors[0].message

        Rollbar.error('Error on savePotentialClient', msg)
        dispatch(setBanner(`Error saving - ${msg}`, 'danger'))
      })
  }
}

export const toggleReceivesStatements = (role) => {
  return (dispatch) => {
    dispatch({
      type: t.TOGGLE_CONTACT_ROLE_RECIEVES_STATEMENTS,
      role: role,
    })
  }
}

export const updateTrustAttr = (attr, val) => {
  return (dispatch) => {
    dispatch({ type: t.UPDATE_TRUST_ATTR, attr, val })
  }
}

// Distributions

export const createDistribution = () => {
  return (dispatch) => {
    dispatch({ type: t.CREATE_DISTRIBUTION })
  }
}

export const removeDistribution = (id) => {
  return (dispatch) => {
    dispatch({ type: t.REMOVE_DISTRIBUTION, id })
  }
}

export const updateDistribution = (idx, attr, val) => {
  return (dispatch) => {
    dispatch({ type: t.UPDATE_DISTRIBUTION, idx, attr, val })
  }
}

// Income

export const createIncome = () => {
  return (dispatch) => {
    dispatch({ type: t.CREATE_INCOME })
  }
}

export const removeIncome = (id) => {
  return (dispatch) => {
    dispatch({ type: t.REMOVE_INCOME, id })
  }
}

export const updateIncome = (idx, attr, val) => {
  return (dispatch) => {
    dispatch({ type: t.UPDATE_INCOME, idx, attr, val })
  }
}

// Assets

export const createAsset = (subtype) => {
  return (dispatch) => {
    dispatch({ type: t.CREATE_ASSET, subtype })
  }
}

export const removeAsset = (id) => {
  return (dispatch) => {
    dispatch({ type: t.REMOVE_ASSET, id })
  }
}

export const updateAsset = (idx, attr, val, subtype) => {
  return (dispatch) => {
    dispatch({ type: t.UPDATE_ASSET, idx, attr, val, subtype })
  }
}

// Potential client contact roles

export const createContactRole = (role_type, contact) => {
  return (dispatch) => {
    dispatch({ type: t.CREATE_CONTACT_ROLE, role_type, contact })
  }
}

export const removeContactRole = (id) => {
  return (dispatch) => {
    dispatch({ type: t.REMOVE_CONTACT_ROLE, id })
  }
}

export const updateContactRoleRelationship = (
  role_id,
  contact_id,
  relationship_type,
) => {
  return (dispatch) => {
    dispatch({
      type: t.UPDATE_CONTACT_ROLE_RELATIONSHIP,
      role_id,
      contact_id,
      relationship_type,
    })
  }
}

// DocumentsComponent

export const createDocument = (id, params: Object) => {
  const url = `/admin/potential_clients/${id}/files`

  return (dispatch) => {
    dispatch({
      type: t.SET_UPLOAD_STATUS,
      status: 'uploading',
    })

    httpPost(url, params)
      .then((data) => {
        dispatch(setBanner('Changes saved successfully'))

        dispatch({
          type: t.SET_UPLOAD_STATUS,
          status: 'complete',
        })

        dispatch({
          type: t.SET_DOCUMENT_FOLDERS,
          folders: data.folders,
        })
      })
      .catch((err) => {
        Rollbar.error('Error on createDocument', err)
        dispatch(setBanner(`Error saving - ${err}`, 'danger'))

        dispatch({
          type: t.SET_UPLOAD_STATUS,
          status: 'failed',
        })
      })
  }
}

export const deleteDocument = (folder, id) => {
  const url = `/admin/potential_clients/files/${id}`

  return (dispatch) => {
    httpDelete(url)
      .then(() => {
        dispatch(setBanner('File deleted'))

        dispatch({
          type: t.REMOVE_DELETED_DOCUMENT,
          folder: folder,
          file_id: id,
        })
      })
      .catch((err) => {
        Rollbar.error('Error on deleteDocument', err)
        dispatch(setBanner(`Error deleting file - ${err}`, 'danger'))
      })
  }
}

// Approval / Denial

export const approveStep = (id) => {
  return (dispatch) => {
    const url = `/admin/potential_clients/${id}/approve`
    dispatch(approveDeny(url))
  }
}

export const denyStep = (id, denyNote) => {
  return (dispatch) => {
    const url = `/admin/potential_clients/${id}/deny`
    dispatch(approveDeny(url, 'Reason for denial: ' + denyNote))
    dispatch(setDenyNote(''))
  }
}

const approveDeny = (url, denyNote = null) => {
  const params = {
    denyNote: denyNote,
  }

  return (dispatch) => {
    dispatch({
      type: t.SET_UPLOAD_STATUS,
      status: 'uploading',
    })

    httpPost(url, params)
      .then((data) => {
        dispatch({
          type: t.SET_UPLOAD_STATUS,
          status: 'complete',
        })

        dispatch(setBanner('Changes saved successfully'))

        dispatch(setPotentialClient(data))
      })
      .catch(({ errors }) => {
        dispatch({
          type: t.SET_UPLOAD_STATUS,
          status: 'failed',
        })

        const msg = errors[0].message

        Rollbar.error('Error on PotentialClient approveStep', msg)
        dispatch(setBanner(`Error saving - ${msg}`, 'danger'))
      })
  }
}

// Deny note

export const setDenyNote = (denyNote) => {
  return (dispatch) => {
    dispatch({ type: t.SET_denyNote, denyNote })
  }
}
