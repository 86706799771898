// @flow
import React from 'react'
import formatters from 'lib/formatters'
import logo from 'styles/img/logo-2020.png'
import styles from './TrusteeReceipt.css'
import Card from 'components/Card/Card'
import signature from 'styles/img/signature.png'

type Props = {
  accountNumber: string,
  address: Object,
  amount: number,
  beneficiary: string,
  className: string,
  date: string,
  name: string,
  trustName: string,
  vaNumber: string,
}

const receiptBody =
  (amount, displayNumber, beneficiary, date, trustName) => {
  if (!trustName) {
    return (
      <p>
        I, Jennifer Ruiz, the undersigned duly appointed representative of
        the Guardian Pooled Trust, hereby acknowledge receipt, and affirm
        deposit, of {<b>{formatters.money(`${amount}`)}</b>} in the Pooled
        Trust sub-account
        {<b> {displayNumber}</b>} established and administered for the
        sole benefit of {<b>{beneficiary}</b>} on{' '}
        {<b>{formatters.writtenDate(date)}</b>}.
      </p>
    )
  } else {
    return (
      <p>
        I, Jennifer Ruiz, the undersigned duly appointed representative of
        the Guardian Trust Administration, LLC, agent for the Guardian Trust
        Foundation, Inc., Trustee of the {<b>{trustName}</b>}, hereby
        acknowledge receipt, and affirm deposit, of
        {' '}{<b>{formatters.money(`${amount}`)}</b>} in the
        Trust account {<b> {displayNumber}</b>} established and administered
        for the sole benefit of {<b>{beneficiary}</b>} on{' '}
        {<b>{formatters.writtenDate(date)}</b>}.
      </p>
    )
  }
}

const TrusteeReceipt = (props: Props) => {
  const {
    accountNumber,
    address: { street_1, street_2, city, state, zip },
    amount,
    beneficiary,
    className,
    date,
    name,
    trustName,
    vaNumber
  } = props

  const displayNumber = (vaNumber ? vaNumber : accountNumber)

  return (
    <div className={styles.trusteeReceipt}>
      <Card className={`${styles.card} ${className}`}>
        <div className={styles.page}>
          <div className={styles.content}>
            <img src={logo} className={styles.logo} alt="Guardian Trust" />
            <div className={styles.header}>
              <p className={styles.textAlignEnd}>
                www.GuardianTrusts.org<br />
                E-mail: Accounts@GuardianTrusts.org<br />
              </p>
            </div>
            <p className={styles.title}>
              Trustee Receipt<br />
              and Acknowledgement of Trust Funding
            </p>
            {receiptBody(
              amount, displayNumber, beneficiary, date, trustName
            )}
            <p className={styles.signature}>
              <img src={signature} />
              Jennifer Ruiz, Administrator<br />
              Guardian Trust
            </p>
          </div>
          <p className={styles.fromAddress}>
            901 Chestnut Street, Suite C<br />
            Clearwater, Florida 33756<br />
            Tel: (800) 669-2499<br />
            Fax: (727) 754-5948<br />
          </p>
          <p className={styles.toAddress}>
            {name}
            <br />
            {street_1}
            <br />
            {street_2 && (
              <span>
                {street_2}
                <br />
              </span>
            )}
            {city}, {state} {zip}
          </p>
        </div>
      </Card>
    </div>
  )
}

export default TrusteeReceipt
