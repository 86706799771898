// @flow
import React from 'react'
import styles from './styles'

type Props = {
  canSubmit: boolean,
  dateEnd: string,
  onChange: Function,
  onSubmit: Function,
  dateStart: string,
}

export const Template = (props: Props) => (
  <div>
    <div className={styles.formItemMono}>
      <label className={styles.label} htmlFor="dateStart">
        Start Date
      </label>
      <input
        className={styles.input}
        type="date"
        name="dateStart"
        value={props.dateStart}
        onChange={props.onChange}
      />
    </div>
    <div className={styles.endDateSection}>
      <div className={styles.formItemMono}>
        <label className={styles.label} htmlFor="dateEnd">
          End Date
        </label>
        <input
          className={styles.input}
          type="date"
          name="dateEnd"
          value={props.dateEnd}
          onChange={props.onChange}
        />
      </div>
    </div>
  </div>
)
