// @flow
import React from 'react'
import styles from './IconSwitch.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  flag: boolean,
  trueProps: {},
  falseProps: {},
}

const IconSwitch = (props: Props) => {
  const { flag, trueProps, falseProps } = props
  if (flag) {
    return (
      <FontAwesomeIcon
        icon="check"
        size="lg"
        className={styles.checkIcon}
        {...trueProps}
      />
    )
  }
  return (
    <FontAwesomeIcon
      icon="times"
      size="lg"
      className={styles.timesIcon}
      {...falseProps}
    />
  )
}

export default IconSwitch
