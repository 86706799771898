// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__component___ZO_qg {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
}

.styles__trusteeReceiptEmails___LQMK9 {
  padding-top: 20px;
}

@media print {
  .styles__component___ZO_qg {
    padding: 0;
  }

  .styles__trusteeReceiptEmails___LQMK9 {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Account/components/Number/TrusteeReceipt/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,iBAAiB;EACjB,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".component {\n  display: flex;\n  justify-content: space-around;\n  padding-top: 20px;\n  flex-direction: column;\n  align-items: center;\n}\n\n.trusteeReceiptEmails {\n  padding-top: 20px;\n}\n\n@media print {\n  .component {\n    padding: 0;\n  }\n\n  .trusteeReceiptEmails {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"component": `styles__component___ZO_qg`,
	"trusteeReceiptEmails": `styles__trusteeReceiptEmails___LQMK9`
};
export default ___CSS_LOADER_EXPORT___;
