// @flow
import React from 'react'
import { connect } from 'react-redux'
import { httpGet, httpDelete, httpPut } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { unsetModal } from 'lib/modal/actions'
import AttachmentForm from './AttachmentForm'
import ClosingDateForm from './ClosingDateForm'
import ClosingEventForm from './ClosingEventForm'
import ClosingFeeForm from './ClosingFeeForm'
import DateOfDeathForm from './DateOfDeathForm'
import { setModal } from 'lib/modal/actions'
import { Template } from './Template'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  location: Object,
}

type State = {
  isConfirmingDelete: boolean,
  dateOfDeath: Date,
  events: [Object],
  notes: [Object],
  files: [Object],
  nextActions: [Object],
  number: String,
}

class ClosingEdit extends React.Component<Props, State> {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      isConfirmingDelete: false,
      events: [],
      notes: [],
      files: [],
      nextActions: [],
    }
  }

  componentDidMount() {
    const { location } = this.props
    const { id } = location.params
    this.setState({ number: id })
    this.fetchClosing(id)
  }

  fetchClosing = (id) => {
    const number = this.state.number || id

    if (number) {
      httpGet(`/admin/closings/${number}`).then((data) => {
        this.setState({
          dateOfDeath: data.date_of_death,
          events: data.events,
          notes: data.notes,
          files: data.files,
          nextActions: data.next_actions,
        })
      })
    }
  }

  openAttachmentModal = () => {
    const modalForm = (
      <AttachmentForm
        accountNumber={this.state.number}
        dispatch={this.props.dispatch}
        onSuccess={this.fetchClosing}
      />
    )
    this.props.dispatch(setModal(modalForm))
  }

  openClosingDateModal = () => {
    const today = new Date()
    const modalForm = (
      <ClosingDateForm
        accountNumber={this.state.number}
        date={today}
        dispatch={this.props.dispatch}
        handleUpdateClosingDate={this.handleUpdateClosingDate}
      />
    )

    this.props.dispatch(setModal(modalForm))
  }

  openClosingEventModalCreate = (event) => {
    const modalForm = (
      <ClosingEventForm
        accountNumber={this.state.number}
        dispatch={this.props.dispatch}
        event={event}
      />
    )

    this.props.dispatch(setModal(modalForm))
  }

  openClosingEventModalUpdate = (eventObject) => {
    const modalForm = (
      <ClosingEventForm
        accountNumber={this.state.number}
        dispatch={this.props.dispatch}
        eventObject={eventObject}
      />
    )

    this.props.dispatch(setModal(modalForm))
  }

  openClosingFeeModal = () => {
    const modalForm = <ClosingFeeForm accountNumber={this.state.number} />
    this.props.dispatch(setModal(modalForm))
  }

  openDateOfDeathModal = () => {
    const modalForm = (
      <DateOfDeathForm
        accountNumber={this.state.number}
        dateOfDeath={this.dateOfDeath}
        dispatch={this.props.dispatch}
        handleUpdateDateOfDeath={this.handleUpdateDateOfDeath}
      />
    )

    this.props.dispatch(setModal(modalForm))
  }

  handleFileDelete = (onSuccess, fileId) => {
    const { dispatch } = this.props
    dispatch(setLoad())
    const url = `/admin/closings/files/${fileId}`
    return httpDelete(url)
      .then(() => {
        dispatch(onSuccess)
        dispatch(setBanner('File successfully deleted'))
      })
      .catch(() => {
        dispatch(setBanner('File deletion failed', 'danger'))
      })
      .then(() => {
        dispatch(unsetModal())
        dispatch(setUnload())
      })
  }

  handleActionDelete = (number, id) => {
    const { dispatch } = this.props
    dispatch(setLoad())
    const url = `/admin/closings/${number}/events/${id}`
    return httpDelete(url)
      .then(() => {
        dispatch(setBanner('Action successfully deleted'))
        this.fetchClosing(id)
      })
      .catch(() => {
        dispatch(setBanner('Action deletion failed', 'danger'))
      })
      .then(() => {
        dispatch(unsetModal())
        dispatch(setUnload())
      })
  }

  handleUpdateClosingDate = (close_date) => {
    const { dispatch } = this.props
    const { number, id } = this.state

    dispatch(setLoad())
    const url = `/admin/closings/${number}`
    return httpPut(url, { close_date })
      .then(() => {
        dispatch(setBanner('Account closed successfully'))
        this.fetchClosing(id)
      })
      .catch(() => {
        const msg =
          'Error while closing account. Please go to ' +
          'Account Page > Edit Account and check ' +
          'for any missing documents.'
        dispatch(setBanner(msg, 'danger'))
      })
      .then(() => {
        dispatch(unsetModal())
        dispatch(setUnload())
      })
  }

  handleUpdateDateOfDeath = (date_of_death) => {
    const { dispatch } = this.props
    const { number, id } = this.state

    dispatch(setLoad())
    const url = `/admin/closings/${number}`
    return httpPut(url, { date_of_death })
      .then(() => {
        dispatch(setBanner('DOD successfully updated'))
        this.fetchClosing(id)
      })
      .catch(() => {
        dispatch(setBanner('DOD update failed', 'danger'))
      })
      .then(() => {
        dispatch(unsetModal())
        dispatch(setUnload())
      })
  }

  render() {
    return (
      <Template
        dispatch={this.props.dispatch}
        dateOfDeath={this.state.dateOfDeath}
        number={this.state.number}
        events={this.state.events}
        notes={this.state.notes}
        files={this.state.files}
        nextActions={this.state.nextActions}
        onSuccess={this.fetchClosing}
        openAttachmentModal={this.openAttachmentModal}
        openClosingDateModal={this.openClosingDateModal}
        openClosingEventModalCreate={this.openClosingEventModalCreate}
        openClosingEventModalUpdate={this.openClosingEventModalUpdate}
        openClosingFeeModal={this.openClosingFeeModal}
        openDateOfDeathModal={this.openDateOfDeathModal}
        handleActionDelete={this.handleActionDelete}
        handleFileDelete={this.handleFileDelete}
      />
    )
  }
}

export default withRouter(connect()(ClosingEdit))
