// @flow
import Create from './components/Create'
import GPTMaster from './components/GPTMaster'
import NumberRoutes from './components/Number'

export default (store: Object) => ({
  path: 'accounts',
  children: [
    {
      path: 'create',
      element: <Create />,
    },
    {
      element: <GPTMaster />,
      path: 'gpt-master',
    },
    { ...NumberRoutes },
  ],
})
