// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AutocompleteResults__autoCompleteItems___Owy0Z {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
}

.AutocompleteResults__autoCompleteItems___Owy0Z ul {
    list-style: none;
    padding: 0;
  }

.AutocompleteResults__autoCompleteItems___Owy0Z ul:last-child li:last-child {
        border: 0;
      }

.AutocompleteResults__autoCompleteItems___Owy0Z li {
    cursor: pointer;
    padding: 8px;
    padding-top: 10px;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgba(195, 195, 195, 0.45);
    display: flex;
    align-items: center;
  }

.AutocompleteResults__autoCompleteItems___Owy0Z li:hover {
      background-color: rgba(55, 110, 161, 0.1);
    }

.AutocompleteResults__autoCompleteItems___Owy0Z .AutocompleteResults__active___wHQV5 {
    background-color: rgba(55, 110, 161, 0.1);
  }

.AutocompleteResults__autoCompleteItems___Owy0Z .AutocompleteResults__user_icon___LzXXp {
    fill: #5faba1;
  }

.AutocompleteResults__autoCompleteItems___Owy0Z .AutocompleteResults__autoCompleteItems_sectionTitle___PzcMq {
    display: block;
  }

.AutocompleteResults__autoCompleteItems___Owy0Z .AutocompleteResults__autoCompleteItems_label___YTAkF span {
      display: block;
    }

.AutocompleteResults__autoCompleteItems___Owy0Z .AutocompleteResults__autoCompleteItems_icon___xFsuX {
    margin-right: 18px;
  }

.AutocompleteResults__user_icon___LzXXp {
  fill: #5faba1;
  color: #5faba1;
}

.AutocompleteResults__small___SHiBD {
  font-size: 12px;
  font-weight: bold;
}

.AutocompleteResults__matchedText___F1k3l {
  font-weight: 200;
  display: inline !important;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Contacts/components/Search/Autocomplete/AutocompleteResults.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,0BAA0B;EAC1B,sBAAsB;AAkDxB;;AAhDE;IACE,gBAAgB;IAChB,UAAU;EAOZ;;AAJI;QACE,SAAS;MACX;;AAIJ;IACE,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,kDAAkD;IAClD,aAAa;IACb,mBAAmB;EAKrB;;AAHE;MACE,yCAAyC;IAC3C;;AAGF;IACE,yCAAyC;EAC3C;;AAEA;IACE,aAAa;EACf;;AAEA;IACE,cAAc;EAChB;;AAGE;MACE,cAAc;IAChB;;AAGF;IACE,kBAAkB;EACpB;;AAGF;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B","sourcesContent":[".autoCompleteItems {\n  padding-top: 20px;\n  padding-left: 20px;\n  padding-right: 20px;\n  border: 1px solid #e4e4e4;\n  border-radius: 0 0 3px 3px;\n  background-color: #fff;\n\n  ul {\n    list-style: none;\n    padding: 0;\n\n    &:last-child {\n      li:last-child {\n        border: 0;\n      }\n    }\n  }\n\n  li {\n    cursor: pointer;\n    padding: 8px;\n    padding-top: 10px;\n    height: 50px;\n    padding-left: 20px;\n    padding-right: 20px;\n    border-bottom: 1px solid rgba(195, 195, 195, 0.45);\n    display: flex;\n    align-items: center;\n\n    &:hover {\n      background-color: rgba(55, 110, 161, 0.1);\n    }\n  }\n\n  .active {\n    background-color: rgba(55, 110, 161, 0.1);\n  }\n\n  .user_icon {\n    fill: #5faba1;\n  }\n\n  .autoCompleteItems_sectionTitle {\n    display: block;\n  }\n\n  .autoCompleteItems_label {\n    span {\n      display: block;\n    }\n  }\n\n  .autoCompleteItems_icon {\n    margin-right: 18px;\n  }\n}\n\n.user_icon {\n  fill: #5faba1;\n  color: #5faba1;\n}\n\n.small {\n  font-size: 12px;\n  font-weight: bold;\n}\n\n.matchedText {\n  font-weight: 200;\n  display: inline !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoCompleteItems": `AutocompleteResults__autoCompleteItems___Owy0Z`,
	"active": `AutocompleteResults__active___wHQV5`,
	"user_icon": `AutocompleteResults__user_icon___LzXXp`,
	"autoCompleteItems_sectionTitle": `AutocompleteResults__autoCompleteItems_sectionTitle___PzcMq`,
	"autoCompleteItems_label": `AutocompleteResults__autoCompleteItems_label___YTAkF`,
	"autoCompleteItems_icon": `AutocompleteResults__autoCompleteItems_icon___xFsuX`,
	"small": `AutocompleteResults__small___SHiBD`,
	"matchedText": `AutocompleteResults__matchedText___F1k3l`
};
export default ___CSS_LOADER_EXPORT___;
