// @flow
import React from 'react'
import formatters from 'lib/formatters'
import { Table, Row, Cell } from 'components/Table'
import { TrustInput } from 'components/TrustInput'
import styles from './styles'
import { Link } from 'react-router-dom'

type Props = {
  customScheduleAccounts: [Object],
  isSubmitting: boolean,
  journalId: string,
  onChange: Function,
  onSubmit: Function,
  negativeAccounts: [Object],
  scheduled: [Object],
}

const link = (number) => {
  return <Link to={`/accounts/${number}`}>{number}</Link>
}

const renderScheduled = (item, idx) => {
  return (
    <Row key={`scheduled${idx}`}>
      <Cell value={item.beneficiaryName} />
      <Cell value={link(item.number)} className={styles.leftMono} />
      <Cell value={item.feeSchedule} className={styles.leftMono} />
      <Cell value={formatters.money(item.balance || 0)} mono={true} />
      <Cell value={formatters.percentage(item.rate)} mono={true} />
      <Cell value={formatters.money(item.fee || 0)} mono={true} />
      <Cell
        value={formatters.money(item.postFeeCheckingBalance || 0)}
        mono={true}
      />
    </Row>
  )
}

const renderNegativeBalances = (scheduled, negativeAccounts) => {
  if (scheduled.length == 0) {
    return <div></div>
  } else if (scheduled.length && negativeAccounts.length == 0) {
    return (
      <div>
        <p>
          Note: No account will have a negative balance after collecting fees
        </p>
      </div>
    )
  } else {
    const headers = [
      { value: 'Beneficiary Name' },
      { value: 'Account #' },
      { value: 'Checking Balance Today', type: 'mono' },
      { value: 'Checking After Fee', type: 'mono' },
    ]

    return (
      <Table
        headers={headers}
        tableSize="medium"
        title="Negative Balances After Fee Collection"
      >
        {negativeAccounts.map(renderNegatives)}
      </Table>
    )
  }
}

const renderNegatives = (item, idx) => (
  <Row key={`negative${idx}`}>
    <Cell value={item.beneficiaryName} />
    <Cell value={item.number} className={styles.leftMono} />
    <Cell value={formatters.money(item.checkingBalance || 0)} mono={true} />
    <Cell
      value={formatters.money(item.postFeeCheckingBalance || 0)}
      mono={true}
    />
  </Row>
)

export const Template = (props: Props) => {
  const {
    isSubmitting,
    journalId,
    negativeAccounts,
    onChange,
    onSubmit,
    scheduled,
  } = props
  const headers = [
    { value: 'Beneficiary Name' },
    { value: 'Account #' },
    { value: 'Fee Schedule' },
    { value: 'Balance Subject to Fee *', type: 'mono' },
    { value: 'Fee Rate', type: 'mono' },
    { value: 'Fee', type: 'mono' },
    { value: 'Post-Fee Balance', type: 'mono' },
  ]
  return (
    <div>
      <div className={styles.localHeader}>
        <div className={styles.localHeaderInner}>
          <h5>Collect Annual Fees</h5>
        </div>
      </div>
      <section className="content-container">
        <form onSubmit={onSubmit} className={styles.form}>
          <div className="section">
            {<TrustInput value={journalId} onChange={onChange} />}
          </div>
          <div className={styles.disclaimer}>
            <p>
              Please note: MSA fees are collected from the main account. If a
              MSA and its main account are both scheduled for fee collection,
              the post-fee balance should be calculated by summing the two fees
              and subtracting from the current main account balance.
            </p>
          </div>
          <Table headers={headers} tableSize="large" title="Accounts">
            {scheduled.map(renderScheduled)}
          </Table>
          <div className={styles.disclaimer}>
            <p>
              * Balance includes the main account and any subaccounts (except
              for MSA's) on the last day of the prior month
              <br />
            </p>
          </div>
          {renderNegativeBalances(scheduled, negativeAccounts)}
          <section className={styles.buttons}>
            <button
              className={styles.button}
              disabled={isSubmitting || !scheduled.length}
              type="submit"
            >
              Collect Fees
            </button>
          </section>
        </form>
      </section>
    </div>
  )
}
