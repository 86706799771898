// @flow
import React from 'react'
import Ref from 'components/References/Ref'
import styles from './styles'

type Props = {
  toSuspendAccount: String,
  handleAccountChange: Function,
  canSubmit: boolean,
  onSuspendClicked: Function,
}

const SuspendAccountRef = (props: Props) => {
  const wrapSubmit = (onSubmit) => (event) => {
      onSubmit()
      event.preventDefault()
  }

  const numberForm = (
    <form onSubmit={null}>
      <div className={styles.section}>
        <div className={styles.formItemMono}>
          <label className={styles.label} htmlFor="number">
            Account Number
          </label>
          <input
            className={styles.input}
            type="text"
            name="number"
            value={props.toSuspendAccount}
            onChange={props.handleAccountChange}
          />
        </div>
        <button
          type="submit"
          className={styles.button}
          disabled={!props.canSubmit}
          onClick={wrapSubmit(props.onSuspendClicked)}
        >
          Suspend
        </button>
      </div>
    </form>
  )

  return (
    <Ref
      title="Suspend Account"
      preview={numberForm}
    />
  )
}

export default SuspendAccountRef

