// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main__rowTop___MoIsd {
}

.Main__oneHalf___imfUy {
  width: calc(99.9% * 1/2 - (30px - 30px * 1/2));
}

.Main__oneHalf___imfUy:nth-child(1n) {
  float: left;
  margin-right: 30px;
  clear: none;
}

.Main__oneHalf___imfUy:last-child {
  margin-right: 0;
}

.Main__oneHalf___imfUy:nth-child(2n) {
  margin-right: 0;
  float: right;
}

.Main__oneHalf___imfUy:nth-child(2n + 1) {
  clear: both;
}

.Main__full___aJhz_ {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Account/components/Number/Main/Tabs/Main/Main.css"],"names":[],"mappings":"AAEA;AAEA;;AAEA;EACE,8CAAkB;AACpB;;AAFA;EACE,WAAkB;EAAlB,kBAAkB;EAAlB,WAAkB;AACpB;;AAFA;EACE,eAAkB;AACpB;;AAFA;EACE,eAAkB;EAAlB,YAAkB;AACpB;;AAFA;EACE,WAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":["@value helpers: 'styles/helpers.css';\n\n.rowTop {\n  composes: rowTop from helpers;\n}\n\n.oneHalf {\n  lost-column: 1/2 2;\n}\n\n.full {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `'styles/helpers.css'`,
	"rowTop": `Main__rowTop___MoIsd ${___CSS_LOADER_ICSS_IMPORT_0___.locals["rowTop"]}`,
	"oneHalf": `Main__oneHalf___imfUy`,
	"full": `Main__full___aJhz_`
};
export default ___CSS_LOADER_EXPORT___;
