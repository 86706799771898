// @flow
import React from 'react'
import { DocInput } from 'components/DocInput'
import FormButtons from 'components/FormButtons'
import Ticket from 'components/Ticket'
import Reference from './Reference'
import styles from './styles'

type Props = {
  dispatch: Function,
  errors: Object,
  isSubmitting: boolean,
  onChange: Function,
  onSubmit: Function,
  ticket: Object,
  values: Object,
}

export const JoinderAgreementStation = (props: Props) => {
  const { errors, isSubmitting, onChange, onSubmit, ticket, values } = props
  const header = {
    subheader: 'Account Creation',
    title: 'Joinder Agreement',
  }
  const reference = ticket && <Reference {...props} />

  return (
    <Ticket
      header={header}
      history={ticket.history}
      reference={reference}
    >
      <p className={styles.infoText}>
        Upload the Joinder Agreement.
      </p>
      <form className={styles.form} onSubmit={onSubmit}>
        <section className={styles.cardMarginBot}>
          <section className={styles.sectionNoMargin}>
            <DocInput
              errorMessage={errors.joinder_agreement_id}
              label='Joinder Agreement'
              name='joinder_agreement'
              onChange={onChange}
              value={values.joinder_agreement}
            />
          </section>
        </section>
        <FormButtons submitLabel='Save & Continue' submitting={isSubmitting} />
      </form>
    </Ticket>
  )
}
