// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filters__filtersContainer___eSVW9 {
  border: 1px solid rgba(195, 195, 195, 0.45);
  border-radius: 3px;
  background-color: #FFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 30px;
}

.Filters__filtersColumns___pNPvH {
  display: flex;
  overflow: hidden;
}

.Filters__filtersColumn___x1_kT {
  width: 33.33%;
  padding-right: 50px;
}

.Filters__filtersColumn_options___V703e {
  column-count: 2;
  column-width: 50%;
  column-fill: auto;
  height: 100%;
}

.Filters__filtersColumn_header___mq3Q6 {
  margin-bottom: 10px;
}

.Filters__filtersOption___Rx56N label {
    padding: 4px;
    display: block;
  }

.Filters__filtersOption___Rx56N input {
    margin-right: 5px;
  }

.Filters__applyBtn___JsXYY {
  height: 51px;
  width: 120px;
  border-radius: 3px;
  background-color: #376EA1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border: none;
}

.Filters__filterActions___PzKU5 {
  padding-top: 30px;
  flex: none;
}

.Filters__filterActions___PzKU5 button {
    color: white;
    font-weight: bold;
  }

.Filters__clearBtn___RqB97 {
  border: none;
  background: none;
  height: 51px;
  width: 120px;
  color: #376EA1 !important;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Contacts/components/Search/Filters/Filters.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,kBAAkB;EAClB,sBAAsB;EACtB,2CAA2C;EAC3C,aAAa;AACf;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAGE;IACE,YAAY;IACZ,cAAc;EAChB;;AAEA;IACE,iBAAiB;EACnB;;AAGF;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,2CAA2C;EAC3C,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,UAAU;AAMZ;;AAJE;IACE,YAAY;IACZ,iBAAiB;EACnB;;AAGF;EACE,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".filtersContainer {\n  border: 1px solid rgba(195, 195, 195, 0.45);\n  border-radius: 3px;\n  background-color: #FFF;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);\n  padding: 30px;\n}\n\n.filtersColumns {\n  display: flex;\n  overflow: hidden;\n}\n\n.filtersColumn {\n  width: 33.33%;\n  padding-right: 50px;\n}\n\n.filtersColumn_options {\n  column-count: 2;\n  column-width: 50%;\n  column-fill: auto;\n  height: 100%;\n}\n\n.filtersColumn_header {\n  margin-bottom: 10px;\n}\n\n.filtersOption {\n  label {\n    padding: 4px;\n    display: block;\n  }\n\n  input {\n    margin-right: 5px;\n  }\n}\n\n.applyBtn {\n  height: 51px;\n  width: 120px;\n  border-radius: 3px;\n  background-color: #376EA1;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);\n  border: none;\n}\n\n.filterActions {\n  padding-top: 30px;\n  flex: none;\n\n  button {\n    color: white;\n    font-weight: bold;\n  }\n}\n\n.clearBtn {\n  border: none;\n  background: none;\n  height: 51px;\n  width: 120px;\n  color: #376EA1 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersContainer": `Filters__filtersContainer___eSVW9`,
	"filtersColumns": `Filters__filtersColumns___pNPvH`,
	"filtersColumn": `Filters__filtersColumn___x1_kT`,
	"filtersColumn_options": `Filters__filtersColumn_options___V703e`,
	"filtersColumn_header": `Filters__filtersColumn_header___mq3Q6`,
	"filtersOption": `Filters__filtersOption___Rx56N`,
	"applyBtn": `Filters__applyBtn___JsXYY`,
	"filterActions": `Filters__filterActions___PzKU5`,
	"clearBtn": `Filters__clearBtn___RqB97`
};
export default ___CSS_LOADER_EXPORT___;
