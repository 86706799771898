// @flow
import React, { useState, useLayoutEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'

type Props = {
  routerKey: number,
  routes: Object,
  store: Object,
  history: Object,
}

export default function AppContainer(props: Props) {
  const { routes, routerKey, store, history } = props
  const router = createBrowserRouter(routes)

  return (
    <Provider store={store}>
      <div style={{ height: '100%' }}>
        <RouterProvider key={routerKey} router={router} history={history} />
      </div>
    </Provider>
  )
}
