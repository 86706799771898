import IconBox from './IconBox'

export function IconLock() {
  return (
    <IconBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        zoomAndPan="magnify"
        viewBox="0 0 60 60"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
      >
        <defs>
          <clipPath id="868de98499">
            <path
              d="M 17.699219 0 L 59.699219 0 L 59.699219 60 L 17.699219 60 Z M 17.699219 0 "
              clipRule="nonzero"
            />
          </clipPath>
        </defs>
        <g clipPath="url(#868de98499)">
          <path
            fill="#cdcdcd"
            // eslint-disable-next-line max-len
            d="M 47.636719 27 L 29.761719 27 L 29.761719 15 C 29.761719 10.039062 33.773438 6.003906 38.703125 6.003906 C 43.628906 6.003906 47.636719 10.039062 47.636719 15 Z M 44.378906 52.558594 C 44.53125 53.304688 43.957031 54.003906 43.203125 54.003906 L 34.195312 54.003906 C 33.445312 54.003906 32.882812 53.304688 33.023438 52.558594 L 34.757812 43.832031 C 34.792969 43.632812 34.726562 43.4375 34.582031 43.300781 C 33.164062 41.925781 32.402344 39.898438 32.882812 37.679688 C 33.375 35.410156 35.253906 33.554688 37.503906 33.117188 C 41.316406 32.375 44.660156 35.292969 44.660156 39.003906 C 44.660156 40.703125 43.945312 42.214844 42.804688 43.3125 C 42.664062 43.449219 42.597656 43.640625 42.628906 43.84375 Z M 56.578125 27 L 53.597656 27 L 53.597656 15 C 53.597656 6.734375 46.914062 0 38.703125 0 C 30.488281 0 23.800781 6.734375 23.800781 15 L 23.800781 27 L 20.824219 27 C 19.183594 27 17.84375 28.347656 17.84375 30 L 17.84375 57 C 17.84375 58.652344 19.183594 60 20.824219 60 L 56.578125 60 C 58.21875 60 59.554688 58.652344 59.554688 57 L 59.554688 30 C 59.554688 28.347656 58.21875 27 56.578125 27 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </IconBox>
  )
}
