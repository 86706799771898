// @flow
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getCoTrusteeTickets } from './actions'
import { setLoad, setUnload } from 'lib/notification/actions'
import Component from './component'
import { getTickets } from './selectors'

type Props = {
  dispatch: Function,
  tickets: [Object],
}

class Container extends React.Component {
  props: Props
  componentDidMount() {
    this._getIndex()
  }
  _getIndex() {
    const { dispatch } = this.props
    dispatch(setLoad())
    dispatch(getCoTrusteeTickets())
      .then(() => {
        dispatch(setUnload())
      })
  }
  render() {
    const { tickets } = this.props
    return (
      <Component tickets={tickets} />
    )
  }
}

export default connect(createStructuredSelector({
  tickets: getTickets,
}))(Container)
