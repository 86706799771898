// @flow
import { connect } from 'react-redux'
import React from 'react'
import Template from './Template'
import { setLoad, setUnload } from 'lib/notification/actions'
import { httpGet } from 'lib/http'
import { JOURNAL_IDS } from 'lib/constants'

type Props = {
  onSubmit: Function,
  dispatch: Function,
}

type State = {
  journalId: string,
  quarter: string,
  year: string,
  statements: null,
}

class AccountStatements extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { journalId: '', quarter: '', year: '' }
  }

  canSubmit = () => {
    const { journalId, quarter, year } = this.state
    return journalId !== '' && quarter !== '' && year !== ''
  }

  handleInputChange = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { name, value } = event.currentTarget
      ? event.currentTarget
      : { name: event.name, value: event.value ? event.value.value : '' }
    this.setState({ [name]: value })
  }

  fetchReport = () => {
    const { dispatch } = this.props
    const { journalId, quarter, year } = this.state
    dispatch(setLoad())
    const chunk_every = 100
    const query_string =
      `?chunk_every=${chunk_every}&journal_id=${journalId}&` +
      `quarter=${quarter}&` +
      `year=${year}`
    const trust_filter = (journal) => journal.value === journalId
    const trust = JOURNAL_IDS.find(trust_filter) || { label: journalId }
    httpGet(`/admin/dcf-report${query_string}`)
      .then(({ accountStatements }) => {
        this.setState({
          statements: accountStatements,
          trust_name: trust.label,
          quarter,
          year,
        })
      })
      .catch((err) => err)
      .finally(() => {
        dispatch(setUnload())
      })
  }

  render() {
    return (
      <Template
        canSubmit={this.canSubmit()}
        onChange={this.handleInputChange}
        onSubmit={this.fetchReport}
        quarter={this.state.quarter}
        year={this.state.year}
        journalId={this.state.journalId}
        statements={this.state.statements}
      />
    )
  }
}

export default connect()(AccountStatements)
