// @flow
import { KEY } from './constants'

export const LOGIN = `${KEY}/LOGIN`
export const LOGOUT = `${KEY}/LOGOUT`
export const SET_EMAIL = `${KEY}/SET_EMAIL`

export const SET_USERS = `${KEY}/SET_USERS`
export const SET_USER = `${KEY}/SET_USER`

export const ADD_USER = `${KEY}/ADD_USER`
export const REMOVE_USER = `${KEY}/REMOVE_USER`

export const UPDATE_USER = `${KEY}/UPDATE_USER`

export const TOGGLE_ROLE = `${KEY}/TOGGLE_ROLE`
