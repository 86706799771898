// @flow
import formatters from 'lib/formatters'
import React from 'react'
import Graph from './Graph/Graph.js'
import _ from 'lodash'
import moment from 'moment'

type Props = {
  startDate: {},
  endDate: {},
  transactions: [{}],
  XAxisProps: {},
}

const BalanceGraph = (props: Props) => {
  const { transactions, XAxisProps, startDate, endDate } = props

  const calculateGraphData = (txns, startDate, endDate) => {
    let graphData = _.sortBy(
      [{ amount: 0, date: startDate }, ...txns, { amount: 0, date: endDate }],
      ({ date }) => date,
    )

    const startDateMoment = moment(startDate).valueOf()
    const endDateMoment = moment(endDate).valueOf()

    graphData = graphData.reduce(
      (acc, { amount, date }) => {
        window.moment = moment
        date = moment(date).valueOf()
        if (startDateMoment <= date && date <= endDateMoment) {
          acc.data[date] = (acc.data[date] || acc.balance) + amount
        }
        acc.balance += amount
        return acc
      },
      { balance: 0, data: {} },
    )

    graphData.data = Object.keys(graphData.data).map((date) => ({
      date: Number(date),
      balance: graphData.data[date],
    }))

    return graphData
  }

  const { data } = calculateGraphData(
    transactions,
    startDate,
    endDate,
  )

  return (
    <Graph
      TooltipProps={{
        formatter: (balance) => formatters.money(balance),
        labelFormatter: (date) => moment(date).format('MM/DD/YYYY'),
      }}
      XAxisProps={{
        axisLine: false,
        dataKey: 'date',
        padding: { left: 60, right: 60 },
        tickLine: false,
        tickFormatter: (tick) => moment(tick).format('MM/DD/YYYY'),
        type: 'number',
        domain: ['dataMin', 'dataMax'],
        scale: 'time',
        interval: 'preserveStartEnd',
        ...XAxisProps,
      }}
      YAxisProps={{
        domain: ['dataMin', 'dataMax'],
        padding: { bottom: 30, top: 20 },
        axisLine: false,
        dataKey: 'balance',
        orientation: 'right',
        tickFormatter: (balance) => {
          if (balance >= 1000000) {
            return `$${Math.ceil(balance / 100000)}K`
          } else if (balance <= -1000000) {
            return `$${Math.floor(balance / 100000)}K`
          }
          return `$${Math.floor(balance / 100)}`
        },
        tickLine: false,
      }}
      data={data}
      series={[
        {
          dataKey: 'balance',
          stroke: '#4990e2',
          strokeWidth: 1.5,
          name: 'balance',
          type: 'monotone',
        },
      ]}
    />
  )
}

export default BalanceGraph
