// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__inputBox___sGwJ1 {
  display: flex;
  align-items: center;
  background: #fff;
  overflow: hidden;
}

.styles__inputBox___sGwJ1 .styles__prefix___iyu2y {
  font-weight: 500;
  font-size: 14px;
}

.styles__inputBox___sGwJ1 input {
  flex-grow: 1;
  font-size: 14px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.styles__inputBox___sGwJ1:focus-within,
.styles__inputBox___sGwJ1:focus {
  background: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueLight"]};
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
  outline: none;
}
.styles__inputBoxDisabled___FQmnU {
  background: #e1e1e178 !important;
  border: 1px solid rgba(195, 195, 195, 0.45);
}
`, "",{"version":3,"sources":["webpack://./universal/components/DateInput/styles.css"],"names":[],"mappings":"AAIA;EAEE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,+BAA+B;AACjC;;AAEA;;EAEE,wDAAuB;EACvB,8DAAwB;EACxB,aAAa;AACf;AACA;EACE,gCAAgC;EAChC,2CAA2C;AAC7C","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: 'styles/colors.css';\n@value gtBlueLight, gtBlue from colors;\n\n.inputBox {\n  composes: input from helpers;\n  display: flex;\n  align-items: center;\n  background: #fff;\n  overflow: hidden;\n}\n\n.inputBox .prefix {\n  font-weight: 500;\n  font-size: 14px;\n}\n\n.inputBox input {\n  flex-grow: 1;\n  font-size: 14px;\n  background: transparent;\n  border: none;\n  outline: none;\n  padding: 0.5rem 0 0.5rem 0.5rem;\n}\n\n.inputBox:focus-within,\n.inputBox:focus {\n  background: gtBlueLight;\n  border: 1px solid gtBlue;\n  outline: none;\n}\n.inputBoxDisabled {\n  background: #e1e1e178 !important;\n  border: 1px solid rgba(195, 195, 195, 0.45);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `'styles/colors.css'`,
	"gtBlueLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueLight"]}`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"inputBox": `styles__inputBox___sGwJ1 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["input"]}`,
	"prefix": `styles__prefix___iyu2y`,
	"inputBoxDisabled": `styles__inputBoxDisabled___FQmnU`
};
export default ___CSS_LOADER_EXPORT___;
