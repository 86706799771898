// @flow
import { httpGet, httpPost, httpDelete, httpPut } from 'lib/http'
import Rollbar from 'lib/rollbar'
import * as t from './actionTypes'
import { push } from 'react-router-redux'
import { setBanner } from 'lib/notification/actions'
import { useNavigate } from 'react-router'

const hasAuditorRole = () => {
  const userRoles = localStorage.getItem('roles')
  return userRoles && userRoles.includes('auditor')
}

export const logIn = () => {
  return (dispatch: Function) => {
    const isCoTrustee = localStorage.getItem('isCoTrustee') === 'true'
    const isAuditor = hasAuditorRole()
    const email = localStorage.getItem('email') || ''

    dispatch({ type: t.LOGIN, isCoTrustee, isAuditor })
    dispatch({ type: t.SET_EMAIL, email })
    return Promise.resolve()
  }
}

export const clearStorage = () => {
  localStorage.removeItem('email')
  localStorage.removeItem('isCoTrustee')
  localStorage.removeItem('sid')
  localStorage.removeItem('roles')
}

export const logOut = () => {
  console.log('Logging out')
  return () => {
    return new Promise((resolve, reject) => {
      httpDelete('/sessions')
        .then(() => {
          resolve()
        })
        .catch((error) => {
          Rollbar.error('Problem logging out.', error)
          reject(error)
        })
        .finally(() => {
          clearStorage()
          window.location.replace('/login')
        })
    })
  }
}

export const requestToken = (email: String) => {
  return () => {
    return new Promise((resolve, reject) => {
      httpPost('/tokens', { email: email })
        .then(() => {
          resolve()
        })
        .catch((error) => {
          Rollbar.error('Problem calling requestToken(email)', error)
          reject(error)
        })
    })
  }
}

export const requestSession = (token: String) => {
  return () => {
    return new Promise((resolve, reject) => {
      httpPost('/sessions', { token: token })
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          Rollbar.error('Problem calling requestSession(token)', error)
          reject(error)
        })
    })
  }
}

export const register = (params: Object) => {
  return () => {
    return new Promise((resolve, reject) => {
      httpPost('/co-trustee/users', params)
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          Rollbar.error('Problem registering user', error)
          reject(error)
        })
    })
  }
}

export const saveUser = (user: Object, navigate: Object) => {
  const id = user.id
  return (dispatch) => {
    httpPut(`/co-trustee/users/${id}`, user)
      .then(() => {
        dispatch(setBanner('Changes saved successfully'))
        clearUser(dispatch)
        navigate('/cotrustee/users')
      })
      .catch(({ errors }) => {
        const msg = errors[0].message
        Rollbar.error('Error on saveUser', msg)
        dispatch(setBanner(`Error saving - ${msg}`, 'danger'))
      })
  }
}

const setUser = (data: Object) => {
  return (dispatch) => {
    dispatch({
      type: t.SET_USER,
      user: data.user,
    })
  }
}

const setUsers = (data: Object) => {
  return (dispatch) => {
    dispatch({
      type: t.SET_USERS,
      users: data.users,
    })
  }
}

export const fetchUser = (id) => {
  return (dispatch: Function) => {
    return httpGet(`/co-trustee/users/${id}`)
      .then((data) => {
        dispatch(setUser(data))
      })
      .catch(({ message }) => {
        Rollbar.error('Problem fetching user', message)

        dispatch(setBanner(`${message}`, 'danger'))
      })
  }
}

export const fetchUsers = () => {
  return (dispatch: Function) => {
    return httpGet('/co-trustee/users')
      .then((data) => {
        dispatch(setUsers(data))
      })
      .catch(({ code, message }) => {
        Rollbar.error('Problem fetching users', message)

        dispatch(setBanner(`${message}`, 'danger'))

        if (code == 401) {
          // dispatch(push('/'))
          window.location.replace('/contacts')
        }
      })
  }
}

export const updateUser = (attr: String, val) => {
  return (dispatch) => {
    dispatch({
      type: t.UPDATE_USER,
      attr: attr,
      val: val,
    })
  }
}

export const toggleUserRole = (role) => {
  return (dispatch) => {
    dispatch({
      type: t.TOGGLE_ROLE,
      role: role,
    })
  }
}

const clearUser = (dispatch) => {
  dispatch(updateUser('email', ''))
  dispatch(updateUser('last_name', ''))
  dispatch(updateUser('first_name', ''))
}

export const createUser = (user: Object) => {
  const userParams = { ...user, email: user.email }

  return (dispatch) =>
    httpPost('/co-trustee/users', userParams)
      .then(() => {
        dispatch(setBanner('User added successfully'))
        clearUser(dispatch)
        dispatch(fetchUsers())
      })
      .catch((err) => {
        Rollbar.error('Error creating new user', err.message)
        dispatch(setBanner(`Error creating new user - ${err.message}`, 'danger'))
      })
}

export const deleteUser = (id: Number) => {
  return (dispatch) =>
    httpDelete(`/co-trustee/users/${id}`)
      .then(() => {
        dispatch(setBanner('User deleted'))
        dispatch(fetchUsers())
      })
      .catch((err) => {
        Rollbar.error('Error deleting user', err)
        dispatch(setBanner(`Error deleting user - ${err}`, 'danger'))
      })
}
