import _ from 'lodash'

const dateKeys = ['insertedAt', 'dateReceived', 'lastUpdatedDate']
const reasonMap = {
  need_additional_funds: 'Funds',
  need_invoice: 'Document',
  missing_credit_card_receipts: 'Document',
  need_proof_of_payment: 'Document',
  need_signed_distribution_request_form: 'Signature',
  need_additional_information: 'Info',
  not_active: 'Not Active',
  unauthorized_person_submitted: 'Unauthorized',
  other_reason: 'Other',
  clarification_by_senior_admin: 'Sr. Admin',
  clarification_by_signer: 'Co. Trustee',
}
export function sortByKey(array, key, sortOrder = 'asc') {
  let newArr = [...array]
  if (key === 'amount') {
    const sortedArray = _.sortBy(array, (obj) => (sortOrder === 'asc' ? parseInt(obj.amount, 10) : -parseInt(obj.amount, 10)))
    return sortedArray
  } else if (dateKeys.includes(key)) {
    if (['dateEnteredPending', 'daysPending'].includes(key)) {
      key = 'pendingDistribution.dateEnteredPending'
    }
    const sortedArray = _.sortBy(array, (obj) => (sortOrder === 'asc' ? -new Date(obj[key]) : new Date(obj[key])))
    return sortedArray
  } else if (key === 'deniedDistribution.reason') {
    newArr = newArr.map((item) => {
      let reason = item?.deniedDistribution?.reason?.[0]
      if (item?.deniedDistribution?.reason?.length > 1) {
        reason = 'Multiple'
      } else if (reasonMap[reason] != null) {
        reason = reasonMap[reason]
      }
      return {
        ...item,
        deniedDistribution: {
          reason,
        },
      }
    })
    newArr = _.orderBy(newArr, [key], [sortOrder])
    newArr = newArr.map((item, idx) => {
      return {
        ...item,
        deniedDistribution: array[idx]?.deniedDistribution,
      }
    })
  }

  return _.orderBy(newArr, [key], [sortOrder])
}
