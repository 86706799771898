// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__exportLink___eMcyL {
  box-shadow: none;
  cursor: pointer;
  box-shadow: 0 1px 0 rgba(55, 110, 161, 0.2);
  color: #376ea1;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: all 0.2s;
}

.styles__overflow___UzSeq {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["textLight"]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1rem;
}

.styles__noSearch___QqOox {
}

.styles__noResults___ba0Hf {
  background: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]};
  color: white;
  width: 120px;
}

.contactTableWrapper {
    border-radius: 3px;
    margin: auto;
    overflow: auto;
    width: 100%;
  }

.contactTableRow {
    display: flex;
    position: relative;
  }

.modal .contactTableWrapper {
      max-width: 100%;
      position: static;
      width: 100%;
    }

.styles__contactTableSelect___gFyq3 {
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 80px;
  top: 6px;
  z-index: 9;
}
`, "",{"version":3,"sources":["webpack://./universal/components/Contact/styles.css"],"names":[],"mappings":"AAIA;EACE,gBAAgB;EAChB,eAAe;EACf,2CAA2C;EAC3C,cAAc;EACd,6BAAqB;EAArB,qBAAqB;EACrB,oBAAoB;AACtB;;AAEA;EACE,mDAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;AAEA;;AAEA;EACE,wDAAiB;EACjB,YAAY;EAEZ,YAAY;AACd;;AAGE;IACE,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,WAAW;EACb;;AAEA;IACE,aAAa;IACb,kBAAkB;EACpB;;AAGE;MACE,eAAe;MACf,gBAAgB;MAChB,WAAW;IACb;;AAIJ;EAEE,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,UAAU;AACZ","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtBlue, gtBlueLight, gtBlueDark, gtYellowLight, gtYellow, gtGreen, gtRed, textLight from colors;\n\n.exportLink {\n  box-shadow: none;\n  cursor: pointer;\n  box-shadow: 0 1px 0 rgba(55, 110, 161, 0.2);\n  color: #376ea1;\n  text-decoration: none;\n  transition: all 0.2s;\n}\n\n.overflow {\n  color: textLight;\n  font-size: 14px;\n  font-weight: 400;\n  margin-bottom: 1rem;\n}\n\n.noSearch {\n  composes: sectionTitle from helpers;\n}\n\n.noResults {\n  background: gtRed;\n  color: white;\n  composes: sectionTitle from helpers;\n  width: 120px;\n}\n\n:global {\n  .contactTableWrapper {\n    border-radius: 3px;\n    margin: auto;\n    overflow: auto;\n    width: 100%;\n  }\n\n  .contactTableRow {\n    display: flex;\n    position: relative;\n  }\n\n  .modal {\n    .contactTableWrapper {\n      max-width: 100%;\n      position: static;\n      width: 100%;\n    }\n  }\n}\n\n.contactTableSelect {\n  composes: buttonLocal from helpers;\n  height: 40px;\n  line-height: 40px;\n  position: absolute;\n  right: 80px;\n  top: 6px;\n  z-index: 9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"gtBlueLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueLight"]}`,
	"gtBlueDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]}`,
	"gtYellowLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]}`,
	"gtYellow": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]}`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"gtRed": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]}`,
	"textLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["textLight"]}`,
	"exportLink": `styles__exportLink___eMcyL`,
	"overflow": `styles__overflow___UzSeq`,
	"noSearch": `styles__noSearch___QqOox ${___CSS_LOADER_ICSS_IMPORT_1___.locals["sectionTitle"]}`,
	"noResults": `styles__noResults___ba0Hf ${___CSS_LOADER_ICSS_IMPORT_1___.locals["sectionTitle"]}`,
	"contactTableSelect": `styles__contactTableSelect___gFyq3 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["buttonLocal"]}`
};
export default ___CSS_LOADER_EXPORT___;
