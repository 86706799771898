// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__checkbox___ZACh6 {
  margin-right: 10px;
}

.styles__formItem___oauW6 {
  margin-top: 20px;
}

.styles__label___xzInA {
}

.styles__localHeader___uyXoV {
}

.styles__localHeaderInner___pZhAF {
}

.styles__contentContainer___yNnx4 {
  display: flex;
  flex-direction: column;
}

.styles__section___sGRYA {
}

.styles__warning___u9MKQ {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]};
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowDark"]};
  font-size: 90%;
}

.styles__header___ZNnQ6 {
  background: white;
  border-radius: 3px;
  margin: 0 0 20px;
  padding: 20px;
  width: 100%;
}

.styles__halfWidth___IBxon {
  margin: 0 auto;
  width: 25%;
}

.styles__form___ieP4I {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.styles__table___F792l {
  margin-top: 30px;
}

.styles__table___F792l a {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/AccountStatements/styles.css"],"names":[],"mappings":"AAIA;EACE,kBAAkB;AACpB;;AAEA;EAEE,gBAAgB;AAClB;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,aAAa;EACb,sBAAsB;AACxB;;AAEA;AAEA;;AAEA;EACE,8DAA+B;EAC/B,8DAA0B;EAC1B,mDAAmB;EAEnB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,aAA2B;EAA3B,wBAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: 'styles/colors.css';\n@value gtYellow, gtYellowDark, gtYellowLight from colors;\n\n.checkbox {\n  margin-right: 10px;\n}\n\n.formItem {\n  composes: formItem from helpers;\n  margin-top: 20px;\n}\n\n.label {\n  composes: label from helpers;\n}\n\n.localHeader {\n  composes: localHeader from helpers;\n}\n\n.localHeaderInner {\n  composes: localHeaderInner from helpers;\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n  display: flex;\n  flex-direction: column;\n}\n\n.section {\n  composes: section from helpers;\n}\n\n.warning {\n  background-color: gtYellowLight;\n  border: 1px solid gtYellow;\n  color: gtYellowDark;\n  composes: section from helpers;\n  font-size: 90%;\n}\n\n.header {\n  background: white;\n  border-radius: 3px;\n  margin: 0 0 20px;\n  padding: 20px;\n  width: 100%;\n}\n\n.halfWidth {\n  margin: 0 auto;\n  width: 25%;\n}\n\n.form {\n  lost-flex-container: column;\n  width: 100%;\n}\n\n.table {\n  margin-top: 30px;\n}\n\n.table a {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `'styles/colors.css'`,
	"gtYellow": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellow"]}`,
	"gtYellowDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowDark"]}`,
	"gtYellowLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtYellowLight"]}`,
	"checkbox": `styles__checkbox___ZACh6`,
	"formItem": `styles__formItem___oauW6 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItem"]}`,
	"label": `styles__label___xzInA ${___CSS_LOADER_ICSS_IMPORT_1___.locals["label"]}`,
	"localHeader": `styles__localHeader___uyXoV ${___CSS_LOADER_ICSS_IMPORT_1___.locals["localHeader"]}`,
	"localHeaderInner": `styles__localHeaderInner___pZhAF ${___CSS_LOADER_ICSS_IMPORT_1___.locals["localHeaderInner"]}`,
	"contentContainer": `styles__contentContainer___yNnx4 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"section": `styles__section___sGRYA ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`,
	"warning": `styles__warning___u9MKQ ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`,
	"header": `styles__header___ZNnQ6`,
	"halfWidth": `styles__halfWidth___IBxon`,
	"form": `styles__form___ieP4I`,
	"table": `styles__table___F792l`
};
export default ___CSS_LOADER_EXPORT___;
