import * as t from './actionTypes'
import { State } from './model'

const initialState: State = {
  accounts: [],
  contacts: [],
}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case t.SET_ACCOUNTS:
      return { ...state, accounts: action.accounts }
    case t.SET_CONTACTS:
      return { ...state, contacts: parseContacts(action.contacts) }
    case t.SET_SSN_VERIFIER: {
      const contacts = state.contacts.contacts.map((item) => {
        if (item.id === action.contactId) {
          return { ...item, ssn_verifier: action.ssnVerifier }
        } else {
          return item
        }
      })
      return {
        ...state,
        contacts: parseContacts({
          count: state.contacts.count,
          contacts: contacts,
        }),
      }
    }
    default:
      return state
  }
}

const defaultBeneficiary = {
  balance: 0,
  has_open_ticket: 0,
  number: '',
}

export function parseContacts(result) {
  if (!result.contacts) {
    return result
  }

  const contacts = result.contacts.map((contact) => {
    const attorney = contact.attorney_for_gpt.map((it) => ({
      ...defaultBeneficiary,
      ...it,
      role: 'Attorney',
    }))

    const able = contact.able_for_gpt.map((it) => ({
      ...defaultBeneficiary,
      ...it,
      role: 'Able',
    }))

    const grantor = contact.grantor_for_gpt.map((it) => ({
      ...defaultBeneficiary,
      ...it,
      role: 'Grantor',
    }))

    const co_grantor = contact.co_grantor_for_gpt.map((it) => ({
      ...defaultBeneficiary,
      ...it,
      role: 'Co-Grantor',
    }))

    const consult = contact.consult_for_gpt.map((it) => ({
      ...defaultBeneficiary,
      ...it,
      role: 'Consult',
    }))

    const representative = contact.representative_for_gpt.map((it) => ({
      ...defaultBeneficiary,
      ...it,
      role: 'Representative',
    }))

    const potential_client =
      contact.beneficiary_of_potential_client.map((it) => ({
      ...defaultBeneficiary,
      ...it,
      role: 'Potential Client'
    }))

    const beneficiary = contact.beneficiary_of_gpt.map((it) => ({
      ...defaultBeneficiary,
      ...it,
      role: 'Beneficiary',
    }))

    const mergedBeneficiaries = [
      ...beneficiary,
      ...attorney,
      ...able,
      ...grantor,
      ...co_grantor,
      ...consult,
      ...representative,
      ...potential_client
    ]

    return {
      ...contact,
      mergedBeneficiaries,
    }
  })

  return {
    ...result,
    contacts,
  }
}
