// @flow
import React from 'react'
import Error from 'components/Error'
import { connect } from 'react-redux'
import TrusteeReceipt from 'components/TrusteeReceipt/TrusteeReceipt'
import Overlay from 'components/Overlay/Overlay'
import { Table, Row, Cell } from 'components/Table'
import queryString from 'query-string'
import formatters from 'lib/formatters'
import { httpGet } from 'lib/http'
import styles from './styles.css'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  params: Object,
  location: Object,
}

class Statement extends React.Component {
  props: Props

  constructor(props: Props) {
    super(props)
    this.state = {
      accountNumber: null,
      beneficiary: null,
      error: false,
      grantor: null,
      scrubbedBeneficiaryName: null,
      trusteeReceiptEmails: [],
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Error
          title="404: Account Not Found"
          message="The account you are looking for does not exist"
          icon="account"
        />
      )
    }

    const queryParams = this.props.location.query
    const amount = queryParams.get('amount')
    const date = queryParams.get('date')
    const trustName = queryParams.get('trustName')

    const {
      accountNumber,
      beneficiary,
      grantor,
      scrubbedBeneficiaryName,
      trusteeReceiptEmails,
    } = this.state
    const { city, state, street_1, street_2, zip } =
      grantor || beneficiary || {}
    const beneficiary_name = scrubbedBeneficiaryName
    const name = grantor ? grantor.name : beneficiary_name
    const renderEmail = ({ to, inserted_at }) => (
      <Row key={`${to}${inserted_at}`}>
        <Cell value={to} />
        <Cell value={formatters.eventTimestamp(inserted_at)} />
      </Row>
    )

    return (
      <div className={styles.component}>
        <Overlay loading={!beneficiary}>
          <TrusteeReceipt
            accountNumber={accountNumber}
            address={{ street_1, street_2, city, state, zip }}
            amount={amount}
            beneficiary={beneficiary_name}
            date={date}
            name={name}
            trustName={trustName}
          />
        </Overlay>
        <div className={styles.trusteeReceiptEmails}>
          {trusteeReceiptEmails.length > 0 && (
            <Overlay loading={!trusteeReceiptEmails}>
              <Table
                title="History"
                tableType="read"
                headers={['Email', 'Attempted at']}
              >
                {trusteeReceiptEmails.map(renderEmail)}
              </Table>
            </Overlay>
          )}
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.fetchAccount()
    this.fetchTrusteeReceiptEmails()
  }

  fetchAccount() {
    const { params } = this.props.location

    httpGet(`/admin/accounts/${params.number}`)
      .then((data) => {
        this.setState({
          beneficiary: data.beneficiary,
          scrubbedBeneficiaryName: data.scrubbed_beneficiary_name,
          grantor: data.grantor,
          accountNumber: params.number,
        })
      })
      .catch((err) => err)
  }

  fetchTrusteeReceiptEmails() {
    const { source_id } = this.props.location.query
    const params = queryString.stringify({ source_id })
    httpGet(`/admin/trustee-receipt-emails?${params}`)
      .then(({ trustee_receipt_emails: trusteeReceiptEmails }) => {
        this.setState({ trusteeReceiptEmails })
      })
      .catch((err) => err)
  }
}

export default withRouter(connect()(Statement))
