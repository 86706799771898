// @flow
import React from 'react'
import formatters from 'lib/formatters'
import styles from './styles'
import {
  ContactModalRef,
  ContactRef,
  ContactRoleRef,
} from 'components/References'

type Props = {
  selectionFilter: Function,
  callback: Function,
  contacts: Object,
  dispatch: Function,
  modal: boolean,
  onExport: Function,
  onSSNReset: Function,
}

type DataProps = {
  selectionFilter: Function,
  callback: Function,
  data: Object,
  dispatch: Function,
  modal: boolean,
  index: number,
  onClick: Function,
  onSSNReset: Function,
  current?: string,
}

const renderData = (props: DataProps) => {
  const { data, dispatch, index, modal, onSSNReset } = props
  if (modal) {
    return (
      <div key={index} className="contactTableRow">
        {renderSelectionElement(props)}
        <ContactModalRef contact={data} />
      </div>
    )
  }
  return (
    <div key={index} className="contactTableRow">
      <div className="twoThird">
        <ContactRef
          contact={data}
          dispatch={dispatch}
          edit={true}
          full={true}
          onSSNReset={onSSNReset}
        />
      </div>
      <div className="oneThird">
        <ContactRoleRef {...data} />
      </div>
    </div>
  )
}

const renderSelectionElement = ({
  callback,
  data,
  selectionFilter,
}: DataProps) => {
  const { filtered, filteredElement } = selectionFilter
    ? selectionFilter(data)
    : { filtered: false }
  return filtered ? (
    filteredElement
  ) : (
    <div
      onClick={callback(data)}
      className={`${styles.contactTableSelect} test-hook-select-contact`}
    >
      Select This Contact
    </div>
  )
}

export const ContactTable = (props: Props) => {
  const {
    selectionFilter,
    callback,
    contacts: contactData,
    dispatch,
    modal,
    onSSNReset,
    onExport,
  } = props
  const { contacts, count: contactCount } = contactData
  let overFlow = null
  let dataRows

  if (contacts) {
    dataRows = contacts
    if (dataRows.length) {
      dataRows = dataRows.map((item, idx) =>
        renderData({
          selectionFilter,
          callback,
          data: item,
          dispatch,
          index: idx,
          modal,
          onSSNReset,
        }),
      )
    } else {
      dataRows = (
        <h5 className={`${styles.noResults} test-hook-no-results`}>
          No Results
        </h5>
      )
    }

    if (contactCount > 0) {
      overFlow = (
        <h5 className={styles.overflow}>
          Your search returned {formatters.integer(contactCount)} contacts.{' '}
          <a className={styles.exportLink} onClick={onExport}>
            Export CSV
          </a>
        </h5>
      )
    }

    if (contactCount > contacts.length) {
      overFlow = (
        <h5 className={styles.overflow}>
          Your search returned {formatters.integer(contactCount)} contacts. Only
          the first {formatters.integer(contacts.length)} are being displayed.
          <a className={styles.exportLink} onClick={onExport}>
            {' '}
            Export CSV
          </a>
        </h5>
      )
    }
  }

  return (
    <div className="contactTableWrapper">
      {overFlow}
      {dataRows}
    </div>
  )
}

export default ContactTable
