export default {
  AA: 'AA - Armed Forces Americas',
  AE: 'AE - Armed Forces Europe',
  AK: 'AK - Alaska',
  AL: 'AL - Alabama',
  AP: 'AP - Armed Forces Pacific',
  AR: 'AR - Arkansas',
  AS: 'AS - American Samoa',
  AZ: 'AZ - Arizona',
  CA: 'CA - California',
  CO: 'CO - Colorado',
  CT: 'CT - Connecticut',
  DC: 'DC - District Of Columbia',
  DE: 'DE - Delaware',
  FL: 'FL - Florida',
  FM: 'FM - Federated States Of Micronesia',
  GA: 'GA - Georgia',
  GU: 'GU - Guam',
  HI: 'HI - Hawaii',
  IA: 'IA - Iowa',
  ID: 'ID - Idaho',
  IL: 'IL - Illinois',
  IN: 'IN - Indiana',
  KS: 'KS - Kansas',
  KY: 'KY - Kentucky',
  LA: 'LA - Louisiana',
  MA: 'MA - Massachusetts',
  MD: 'MD - Maryland',
  ME: 'ME - Maine',
  MH: 'MH - Marshall Islands',
  MI: 'MI - Michigan',
  MN: 'MN - Minnesota',
  MO: 'MO - Missouri',
  MP: 'MP - Northern Mariana Islands',
  MS: 'MS - Mississippi',
  MT: 'MT - Montana',
  NC: 'NC - North Carolina',
  ND: 'ND - North Dakota',
  NE: 'NE - Nebraska',
  NH: 'NH - New Hampshire',
  NJ: 'NJ - New Jersey',
  NM: 'NM - New Mexico',
  NV: 'NV - Nevada',
  NY: 'NY - New York',
  OH: 'OH - Ohio',
  OK: 'OK - Oklahoma',
  OR: 'OR - Oregon',
  PA: 'PA - Pennsylvania',
  PR: 'PR - Puerto Rico',
  PW: 'PW - Palau',
  RI: 'RI - Rhode Island',
  SC: 'SC - South Carolina',
  SD: 'SD - South Dakota',
  TN: 'TN - Tennessee',
  TX: 'TX - Texas',
  UM: 'UM - United States Minor Outlying Islands',
  UT: 'UT - Utah',
  VA: 'VA - Virginia',
  VI: 'VI - U.S. Virgin Islands',
  VT: 'VT - Vermont',
  WA: 'WA - Washington',
  WI: 'WI - Wisconsin',
  WV: 'WV - West Virginia',
  WY: 'WY - Wyoming',
}
