// @flow
import React from 'react'
import type { Contact } from 'lib/types'
import styles from './styles'
import { Table, Row, Cell } from 'components/Table'
import Ref from 'components/References/Ref'
import formatters from 'lib/formatters'

type Props = {
  contacts: Array<Contact>,
  full: boolean,
  title: string,
  notes: string,
}

const renderPhone = ( phone, label ) => {
  if (phone) {
    return (
      <div className={styles.contactListPhone}>
        <span className='phoneLabel'>{label}: </span>
        <span className='mono'>{formatters.phone(phone)}</span>
      </div>
    )
  }
}

class RemaindermenRef extends React.Component {
  props: Props
  constructor(props) {
    super(props)
  }
  render() {
    const {
      contacts,
      title = 'Contacts',
      notes = 'No notes',
    } = this.props
    let noResults = `No ${title}`
    let dataRows = null

    if (contacts && contacts.length) {
      dataRows = []
      contacts.map((contact, idx) => {
        const { name, phone_numbers, email } = contact
        let cellPhone, dayPhone, eveningPhone, fax
        if (phone_numbers) {
          cellPhone = phone_numbers.cell_phone
          dayPhone = phone_numbers.day_phone
          eveningPhone = phone_numbers.evening_phone
          fax = phone_numbers.fax
        } else {
          cellPhone = contact.cellPhone
          dayPhone = contact.dayPhone
          eveningPhone = contact.eveningPhone
          fax = contact.fax
        }
        cellPhone = cellPhone && renderPhone(cellPhone, 'Cell')
        dayPhone = dayPhone && renderPhone(dayPhone, 'Day')
        eveningPhone = eveningPhone && renderPhone(eveningPhone, 'Evening')
        fax = fax && renderPhone(fax, 'Fax')
        let phoneNumbers = (
          <div>
            {cellPhone}
            {dayPhone}
            {eveningPhone}
            {fax}
          </div>
        )

        if (!cellPhone && !dayPhone && !eveningPhone && !fax) {
          phoneNumbers = null
        }

        dataRows.push(
          <Row key={idx}>
            <Cell value={name} />
            <Cell value={phoneNumbers} />
            <Cell value={formatters.address(contact)} />
            <Cell value={email} />
          </Row>
        )
      })
      noResults = null
    }

    return (
      <div>
        <Ref
          title="Remaindermen Notes"
        >
          <p className={styles.notesSection}>{notes}</p>
        </Ref>
        <Table
          headers={['Name', 'Phone', 'Address', 'Email']}
          title={title}
          tableType='read'
          noResults={noResults}
        >
          {dataRows}
        </Table>
      </div>
    )
  }
}

export default RemaindermenRef
