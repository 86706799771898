// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DepositReport__localHeader___H6AHv {
}

.DepositReport__localHeaderInner___m7BY7 {
}

.DepositReport__contentContainer___R6eP1 {
  display: flex;
  flex-direction: column;
}

.DepositReport__form___CyohD {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.DepositReport__section___oMgLN {
}

.DepositReport__halfWidth___LCCYG {
  margin: 0 auto;
  width: 25%;
}

.DepositReport__depositsTable___KqlZ1 {
  width: 100%;
}

.DepositReport__highlightCell___Q7ZOY {
  font-size: 15px;
  font-weight: bold;
}

.DepositReport__export___SyqUS {
  margin-top: 20px;
}

.DepositReport__export___SyqUS button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    box-shadow: 0 1px 0 rgba(55, 110, 161, 0.2);
    color: #376ea1;
    -webkit-text-decoration: none;
    text-decoration: none;
    transition: all 0.2s;
  }
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/DepositReport/DepositReport.css"],"names":[],"mappings":"AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAA2B;EAA3B,wBAA2B;EAC3B,WAAW;AACb;;AAEA;AAEA;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAWlB;;AAVE;IACE,6BAA6B;IAC7B,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,2CAA2C;IAC3C,cAAc;IACd,6BAAqB;IAArB,qBAAqB;IACrB,oBAAoB;EACtB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.localHeader {\n  composes: localHeader from helpers;\n}\n\n.localHeaderInner {\n  composes: localHeaderInner from helpers;\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n  display: flex;\n  flex-direction: column;\n}\n\n.form {\n  lost-flex-container: column;\n  width: 100%;\n}\n\n.section {\n  composes: section from helpers;\n}\n\n.halfWidth {\n  margin: 0 auto;\n  width: 25%;\n}\n\n.depositsTable {\n  width: 100%;\n}\n\n.highlightCell {\n  font-size: 15px;\n  font-weight: bold;\n}\n\n.export {\n  margin-top: 20px;\n  button {\n    background-color: transparent;\n    border: 0;\n    box-shadow: none;\n    cursor: pointer;\n    box-shadow: 0 1px 0 rgba(55, 110, 161, 0.2);\n    color: #376ea1;\n    text-decoration: none;\n    transition: all 0.2s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"localHeader": `DepositReport__localHeader___H6AHv ${___CSS_LOADER_ICSS_IMPORT_0___.locals["localHeader"]}`,
	"localHeaderInner": `DepositReport__localHeaderInner___m7BY7 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["localHeaderInner"]}`,
	"contentContainer": `DepositReport__contentContainer___R6eP1 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["content-container"]}`,
	"form": `DepositReport__form___CyohD`,
	"section": `DepositReport__section___oMgLN ${___CSS_LOADER_ICSS_IMPORT_0___.locals["section"]}`,
	"halfWidth": `DepositReport__halfWidth___LCCYG`,
	"depositsTable": `DepositReport__depositsTable___KqlZ1`,
	"highlightCell": `DepositReport__highlightCell___Q7ZOY`,
	"export": `DepositReport__export___SyqUS`
};
export default ___CSS_LOADER_EXPORT___;
