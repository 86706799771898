// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__errorWrapper___cyIZM {
  border-left: 3px solid #A13739;
  padding-left: 10px;
}

.styles__errorWrapper___cyIZM label {
    color: #A13739;
  }

.styles__infoText___dLnXH {
}

.styles__form___yGWN_ {
}

.styles__formItem___aGYkA {
}

.styles__formItemHalf___RBmFI {
}

.styles__formItemMono___fUmC1 {
}

.styles__input___yZAjT {
}

.styles__label___nNGpq {
}

.styles__select___GNudV {
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Account/components/Number/Deposit/styles.css"],"names":[],"mappings":"AAIA;EACE,8BAA8B;EAC9B,kBAAkB;AAKpB;;AAHE;IACE,cAAc;EAChB;;AAGF;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtBlueDark, gtGrayLight from colors;\n\n.errorWrapper {\n  border-left: 3px solid #A13739;\n  padding-left: 10px;\n\n  label {\n    color: #A13739;\n  }\n}\n\n.infoText {\n  composes: infoText from helpers;\n}\n\n.form {\n  composes: form from helpers;\n}\n\n.formItem {\n  composes: formItem from helpers;\n}\n\n.formItemHalf {\n  composes: formItemHalf from helpers;\n}\n\n.formItemMono {\n  composes: formItemMono from helpers;\n}\n\n.input {\n  composes: input from helpers;\n}\n\n.label {\n  composes: label from helpers;\n}\n\n.select {\n  composes: select from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtBlueDark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlueDark"]}`,
	"gtGrayLight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGrayLight"]}`,
	"errorWrapper": `styles__errorWrapper___cyIZM`,
	"infoText": `styles__infoText___dLnXH ${___CSS_LOADER_ICSS_IMPORT_1___.locals["infoText"]}`,
	"form": `styles__form___yGWN_ ${___CSS_LOADER_ICSS_IMPORT_1___.locals["form"]}`,
	"formItem": `styles__formItem___aGYkA ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItem"]}`,
	"formItemHalf": `styles__formItemHalf___RBmFI ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItemHalf"]}`,
	"formItemMono": `styles__formItemMono___fUmC1 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["formItemMono"]}`,
	"input": `styles__input___yZAjT ${___CSS_LOADER_ICSS_IMPORT_1___.locals["input"]}`,
	"label": `styles__label___nNGpq ${___CSS_LOADER_ICSS_IMPORT_1___.locals["label"]}`,
	"select": `styles__select___GNudV ${___CSS_LOADER_ICSS_IMPORT_1___.locals["select"]}`
};
export default ___CSS_LOADER_EXPORT___;
