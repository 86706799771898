// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__form___NoTkY {
  display: flex;
  flex-flow: column nowrap;
}

.styles__formItem___w_mED {
}

.styles__formItemHalf___aJXnH {
}

.styles__formItemQuarter___aH62r {
}

.styles__contentContainer___s_94q {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 20px;
  padding-top: 20px;
}

.styles__contentContainer___s_94q:before {
  content: '';
  display: table;
}

.styles__contentContainer___s_94q:after {
  content: '';
  display: table;
  clear: both;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Contacts/components/Edit/styles.css"],"names":[],"mappings":"AAEA;EACE,aAA2B;EAA3B,wBAA2B;AAC7B;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,aAAwB;EAAxB,mBAAwB;EAExB,oBAAoB;EACpB,iBAAiB;AACnB;;AANA;EAGE,WAAsB;EAAtB,cAAsB;AAGxB;;AANA;EAGE,WAAsB;EAAtB,cAAsB;EAAtB,WAAsB;AAGxB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.form {\n  lost-flex-container: column;\n}\n\n.formItem {\n  composes: formItem from helpers;\n}\n\n.formItemHalf {\n  composes: formItemHalf from helpers;\n}\n\n.formItemQuarter {\n  composes: formItemQuarter from helpers;\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n  lost-flex-container: row;\n  lost-utility: clearfix;\n  padding-bottom: 20px;\n  padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"form": `styles__form___NoTkY`,
	"formItem": `styles__formItem___w_mED ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItem"]}`,
	"formItemHalf": `styles__formItemHalf___aJXnH ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItemHalf"]}`,
	"formItemQuarter": `styles__formItemQuarter___aH62r ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItemQuarter"]}`,
	"contentContainer": `styles__contentContainer___s_94q ${___CSS_LOADER_ICSS_IMPORT_0___.locals["content-container"]}`
};
export default ___CSS_LOADER_EXPORT___;
