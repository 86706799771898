// @flow
import React from 'react'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import Card from 'components/Card/Card'
import TrusteeReceipt from 'components/TrusteeReceipt/TrusteeReceipt'
import { TrustInput } from 'components/TrustInput'
import moment from 'moment'
import { httpGet } from 'lib/http'
import styles from './TrusteeReceiptReport.css'
import queryString from 'query-string'

class TrusteeReceiptReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      trust: 'gpt_old',
      trusteeReceipts: [],
      dateStart: moment().format('YYYY-MM-DD'),
      excludeEmailed: true,
    }
  }

  canSubmit = () => {
    const { dateStart } = this.state
    const momentStart = moment(dateStart)
    return momentStart.isValid() && momentStart.isAfter('2002-01-01')
  }

  render() {
    const {
      excludeEmailed,
      dateStart,
      trust,
      trusteeReceipts
    } = this.state

    return (
      <div className={styles.page}>
        <Breadcrumbs
          className={styles.noPrint}
          crumbs={['Trustee Receipt Report']}
        />
        <div className={styles.body}>
          <Card className={styles.noPrint}>
            <div className={styles.form}>
              <TrustInput
                isClearable={false}
                onChange={({ value: { value: selectedTrust } }) =>
                  this.setState({ trust: selectedTrust })
                }
                value={trust}
              />
              <div className={styles.formItemMono}>
                <label htmlFor="dateStart" className={styles.label}>
                  Deposit Entry Date
                </label>
                <input
                  className={styles.input}
                  name="dateStart"
                  onChange={this.onChange}
                  type="date"
                  value={dateStart}
                />
              </div>
              <div className={styles.excludeEmailed}>
                <input
                  checked={excludeEmailed}
                  id="excludeEmailed"
                  type="checkbox"
                  onChange={() =>
                    this.setState({ excludeEmailed: !excludeEmailed })
                  }
                />
                <label className={styles.label} htmlFor="excludeEmailed">
                  Exclude emails
                </label>
              </div>
            </div>
          </Card>
          {dateStart && this.renderTrusteeReceipts(trusteeReceipts)}
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps,
    {
      dateStart: prevStartDate,
      trust: prevTrust,
      excludeEmailed: prevEmailed }
  ) {
    if (
      this.state.dateStart != prevStartDate ||
      this.state.trust != prevTrust ||
      this.state.excludeEmailed != prevEmailed
    ) {
      if (this.canSubmit()) {
        this.fetchReport()
      }
    }
  }

  componentDidMount() {
    this.fetchReport()
  }

  renderTrusteeReceipts = (trusteeReceipts) => {
    if (trusteeReceipts.length > 0) {
      return trusteeReceipts.map((props, i) => {
        return (
          <div key={i} className={styles.printPage}>
            <TrusteeReceipt {...props} className={styles.trusteeReceipt} />
          </div>
        )
      })
    } else {
      return (
          <div className={styles.printPage}>
            <p>No receipts found for this date range</p>
          </div>
      )
    }
  }

  onChange = (event) => {
    const { name, value } = event.currentTarget
    this.setState({ [name]: value })
  }

  fetchReport = () => {
    const { dateStart, excludeEmailed, trust } = this.state
    const query = {
      product: trust,
      start_date: dateStart,
      end_date: dateStart,
      exclude_emailed: excludeEmailed,
    }

    httpGet(
      `/admin/reporting/trustee-receipt-report?${queryString.stringify(query)}`,
    )
      .then(({ trustee_receipts: trusteeReceipts }) => {
        this.setState({
          trusteeReceipts: trusteeReceipts.map(
            ({account_number,
              beneficiary_name,
              trust_name,
              va_number, ...rest }) => ({
              accountNumber: account_number,
              beneficiary: beneficiary_name,
              trustName: trust_name,
              vaNumber: va_number,
              ...rest,
            }),
          ),
        })
      })
      .catch(() => this.setState({ trusteeReceipts: [] }))
  }
}

export default TrusteeReceiptReport
