// @flow
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import styles from './styles'
import { unsetBanner } from 'lib/notification/actions'
import { getNotification } from 'lib/notification/selectors'

type Props = {
  dispatch: Function,
  message: string,
  notification: Object,
  type: string,
};

class Banner extends React.Component<Props> {
  props: Props

  _closeMessage() {
    this.props.dispatch(unsetBanner())
  }

  render() {
    const notification = this.props.notification
    if (!notification.message) {
      return null
    }
    let bannerType
    switch(notification.type) {
      case 'warning':
        bannerType = styles.warning
        break
      case 'danger':
        bannerType = styles.danger
        break
      case 'success':
      default:
        bannerType = styles.success
        break
    }
    return (
      <div className={bannerType}>
        <div className={styles.bannerInner}>
          <div className={styles.message}>{notification.message}</div>
          <div
            className={styles.close}
            onClick={() => this._closeMessage()}
          ></div>
        </div>
      </div>
    )
  }
}

export default connect(createStructuredSelector({
  notification: getNotification,
}))(Banner)
