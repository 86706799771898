// @flow
import React from 'react'
import formatters from 'lib/formatters'
import type { Account, Contact } from 'lib/types'
import {
  Cell,
  Row,
  Table,
} from 'components/Table'
import styles from './styles'

type Props = {
  beneficiary: Contact,
}

const renderAccount = (account: Account) => {
  return (
    <Row
      key={account.number}
      link={`/accounts/${account.number}`}
    >
      <Cell value={account.number} />
      <Cell value='GPT' />
      <Cell value={account.status} status={account.status} />
      <Cell value={formatters.money(account.balance || 0)} />
    </Row>
  )
}

const BeneficiaryAccountsRef = ({ beneficiary }: Props) => {
  let accounts = (
    <div className={styles.noResults}>No accounts</div>
  )
  const headers = ['Number', 'Type', 'Status', 'Balance']
  if (beneficiary.beneficiary_of_gpt.length) {
    accounts = beneficiary.beneficiary_of_gpt.map((account) => (
      renderAccount(account)
    ))
  }

  return (
    <Table title='Accounts' headers={headers} tableSize="medium">
      {accounts}
    </Table>
  )
}

export default BeneficiaryAccountsRef
