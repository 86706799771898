import CoreLayout from '../layouts/CoreLayout'
import protectedRoutes from './Protected'
import publicRoutes from './Public'
import errorRoutes from './Error'
import { logIn } from 'lib/user/actions'
import { httpGet } from 'lib/http'
import { isLoggedIn } from 'lib/user/selectors'
import NotFound from './Error/components/NotFound/component'
import { clearStorage, logOut } from '../lib/user/actions'

const createRoutes = (store, history) => {
  return [
    {
      path: '/',
      element: <CoreLayout />,
      errorElement: <NotFound />,
      loader: async (nextState) => {
        const state = store.getState()
        if (isLoggedIn(state)) {
          return nextState
        }
        try {
          await httpGet('/sessions')
          store?.dispatch(logIn())
          return nextState
        } catch (error) {
          if (error.code === 401) {
            clearStorage()
          }
          if (window.location.pathname !== '/login') {
            window.location.replace('/login')
            store?.dispatch(logOut())
            console.log('Redirecting to login...')
          }
          return null
        }
      },
      children: [publicRoutes(store), protectedRoutes(store), errorRoutes()],
    },
  ]
}

export default createRoutes
