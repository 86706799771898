// @flow
import React from 'react'
import { httpGet } from 'lib/http'
import styles from './styles'

type Props = {
  file_key: string,
  label: string
}

export class S3FilePreview extends React.Component {
  props: Props

  constructor(props) {
    super(props)
  }

  fetchFile() {
    const file_key = this.props.file_key
    httpGet(`/admin/files/${file_key}/preview`).then(({url}) => {
      window.open(url, '_blank')
    })
  }

  render() {
    return (
      <div>
        <a className={`${styles.docLink} test-hook-show-preview`}
          onClick={() => this.fetchFile()}
          target='_blank'>
          {this.props.label || 'Preview'}
        </a>
      </div>
    )
  }
}
