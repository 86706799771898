// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import formatters from 'lib/formatters'
import type { Contact, Product } from 'lib/types'
import { httpPost } from 'lib/http'
import { setLoad, setUnload } from 'lib/notification/actions'
import { setModal, unsetModal } from 'lib/modal/actions'
import { enhanceNewContactForm } from 'components/Contact/NewContactForm'
import SSNResetForm from 'components/SSNResetForm'
import { ContactSelectModal } from 'components/ContactSelectModal'
import { CreateAccountTicket } from './Template'
import styles from './styles'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  location: Object,
}

type Values = {
  ein?: string,
  investment_id: number,
  grantor_relationship: string,
  beneficiary?: Contact,
  grantor?: Contact,
  co_grantor?: Contact,
  referrer?: Contact,
  representative?: Contact,
  trust_name?: string,
}

type State = {
  errors: Object,
  product: Product,
  submitting: boolean,
  values: Values,
}

class Component extends React.Component<Props, State> {
  static defaultProps = {
    dispatch: () => {},
  }

  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      submitting: false,
      values: {
        ein: '',
        grantor_relationship: '',
        investment_id: 0,
        trust_name: '',
      },
    }
  }

  props: Props

  canSubmit = () => {
    const { beneficiary, investment_id } = this.state.values
    return beneficiary && investment_id > 0
  }

  clearErrors = () => {
    return (key) => {
      let { errors } = this.state
      errors[key] = {}
      this.setState({ errors })
    }
  }

  handleChange = (key, value) => {
    const values = {
      ...this.state.values,
      [key]: value,
    }

    if (key === 'grantor_relationship' && value !== 'co_grantor') {
      delete values.co_grantor
    }

    this.setState({ values })
    this.props.dispatch(unsetModal())
  }

  contactSelectHandler = (key) => {
    return (contact) => {
      return () => {
        this.handleChange(key, contact)
      }
    }
  }

  createHandler = (selectionHandler) => {
    return () => {
      const createHandler = (data) => selectionHandler(data)()
      const NewContactForm = enhanceNewContactForm(createHandler)
      const data = <NewContactForm />
      this.props.dispatch(setModal(data))
    }
  }

  createSelectHandler = (key) => {
    return () => {
      const callback = this.contactSelectHandler(key)
      const event = setModal(
        <ContactSelectModal
          dispatch={this.props.dispatch}
          selectionHandler={callback}
        />,
      )
      this.props.dispatch(event)
    }
  }

  handleEINChange = (event) => {
    this.handleChange('ein', event.target.value.replace('-', ''))
  }

  handleGrantorTypeSelection = (event) => {
    this.handleChange('grantor_relationship', event.target.value)
  }

  handleInvestmentSelection = (event) => {
    this.handleChange('investment_id', event.value)
  }

  handleProductLoad = (data: Product) => {
    this.setState({ product: data.product })
    this.setDefaultInvestment(data.product)
  }

  handleTrustNameChange = (event) => {
    this.handleChange('trust_name', event.target.value)
  }

  setDefaultInvestment = (product) => {
    let id = 0

    if (product?.investments?.length == 1) {
      id = product.investments[0].id
    }

    const newValues = { ...this.state.values, ['investment_id']: id }
    this.setState({ values: newValues })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { dispatch } = this.props
    const { values } = this.state
    const postParams = {
      ein: values.ein,
      investment_id: Number(values.investment_id),
      grantor_relationship: values.grantor_relationship,
      beneficiary_id: values.beneficiary && values.beneficiary.id,
      co_grantor_id: values.co_grantor && values.co_grantor.id,
      grantor_id: values.grantor && values.grantor.id,
      referrer_id: values.referrer && values.referrer.id,
      representative_id: values.representative && values.representative.id,
      trust_name: values.trust_name,
    }
    dispatch(setLoad())
    httpPost('/admin/accounts', postParams)
      .then((data) => {
        this.props.location.navigate(`/tickets/${data.id}`)
      })
      .catch((err) => {
        if (err?.errors)
          this.setState({ errors: formatters.apiErrors(err?.errors) })
      })
      .finally(() => dispatch(setUnload()))
  }

  renderContactPicker = (label, fieldName, contact, callback, testHook) => {
    const { dispatch } = this.props
    const stateKeys = {
      beneficiary_id: 'beneficiary',
      grantor_id: 'grantor',
      co_grantor_id: 'coGrantor',
      referrer_id: 'referrer',
    }
    const onSSNReset = (id, ssnVerifier) => {
      return () => {
        const stateKey = stateKeys[fieldName]
        const stateChange = {}
        stateChange[stateKey] = { ...contact, ssn_verifier: ssnVerifier }
        this.setState(stateChange)
      }
    }
    const openSSNResetModal = () => {
      const modalForm = (
        <SSNResetForm contact={contact} onSuccess={onSSNReset} />
      )
      dispatch(setModal(modalForm))
    }

    let editButton
    let resetSSNLink
    let selectText = `Select ${label}`
    if (contact) {
      selectText = `Change ${label}`
      editButton = (
        <button
          onClick={() => {
            window.location.href = `/contacts/${contact.id}/edit`
          }}
          className={styles.buttonSecondary}
        >
          Edit Contact
        </button>
      )
      resetSSNLink = (
        <button
          type="button"
          onClick={openSSNResetModal}
          className={styles.buttonSecondary}
        >
          Reset SSN
        </button>
      )
    }
    return (
      <section className={styles.renderButtons}>
        <div className={styles.formItem}>
          <div>
            <label className={styles.label}>{label}</label>
          </div>
          <input
            className={styles.buttonLocal}
            id={testHook}
            name={fieldName}
            onClick={callback}
            type="button"
            value={selectText}
          />
          {editButton}
          {resetSSNLink}
        </div>
      </section>
    )
  }

  render() {
    return (
      <CreateAccountTicket
        {...this.state.values}
        canSubmit={this.canSubmit()}
        errors={this.state.errors}
        renderContactPicker={this.renderContactPicker}
        onBeneficiarySelection={this.createSelectHandler('beneficiary')}
        onCoGrantorSelection={this.createSelectHandler('co_grantor')}
        onEINChange={this.handleEINChange}
        onGrantorSelection={this.createSelectHandler('grantor')}
        onInvestmentSelection={this.handleInvestmentSelection}
        onProductLoad={this.handleProductLoad}
        onReferrerSelection={this.createSelectHandler('referrer')}
        onRepresentativeSelection={this.createSelectHandler('representative')}
        onTrustNameChange={this.handleTrustNameChange}
        onGrantorTypeSelection={this.handleGrantorTypeSelection}
        onSubmit={this.handleSubmit}
        product={this.state.product}
      />
    )
  }
}

export default withRouter(connect()(Component))
