// @flow
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import FormButtons from 'components/FormButtons'
import { renderTextAreaField } from 'components/FormFields'
import type { Form } from 'lib/types'
import styles from './styles'

type Props = {
  form: Form,
  handleSubmit: Function,
  onCancel: Function,
}

class _LeaveNoteForm extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      note: '',
    }
  }

  canSubmit = () => {
    return this.state.note !== ''
  }

  handleChange = (e) => {
    this.setState({ note: e.currentTarget.value })
  }

  render() {
    const { form, onCancel, handleSubmit } = this.props
    return (
      <section>
        <h4 className="section">Leave Note</h4>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="section">
            <div className={styles.formItem}>
              <Field
                cleaner={(val) => val && val.trim()}
                component={renderTextAreaField}
                name="note"
                autoFocus
                onChange={this.handleChange}
              />
            </div>
          </div>
          <FormButtons
            disabled={!this.canSubmit()}
            onCancel={onCancel}
            {...form}
          />
        </form>
      </section>
    )
  }
}

export const LeaveNoteForm = reduxForm({
  form: 'addNote',
  propNamespace: 'form',
})(_LeaveNoteForm)
