import CheckboxSelect from '../../../../../components/CheckBoxSelect'
import RadioOption from '../../../../../components/RadioOption'
import { pendingRequestOptions, pendingRequestRadioOptions, deniedRequestRadioOptions } from '../Main/constant'
import styles from '../Main/styles.css'

export const PendingSection = ({ clientSelect, setClientSelect, trustSelect, setTrustSelect }) => {
  const handleCheckboxChange = (updatedOptions) => {
    setClientSelect(updatedOptions)
  }
  const handleTrustSelect = (e) => {
    setTrustSelect(e.target.value)
  }
  const clearRadio = () => {
    setTrustSelect(null)
  }

  return (
    <div className={styles.pendingSectionLayout}>
      <div className={styles.pendingSectionHeader}>
        <p>PENDING</p>
        <span>Check all that apply</span>
      </div>

      <div className={styles.checkbox}>
        <p>Client Responsibilty</p>
        <CheckboxSelect options={pendingRequestOptions} selectedOptions={clientSelect} onChange={handleCheckboxChange} />

        <p>
          Guardian Trust Responsibilty{' '}
          {trustSelect && (
            <button className={styles.radioClear} onClick={clearRadio}>
              Clear
            </button>
          )}
        </p>

        {pendingRequestRadioOptions.map((option) => {
          return (
            <RadioOption
              key={option.id}
              name={option.label}
              value={option.id}
              label={option.label}
              checked={option.id === trustSelect}
              onChange={handleTrustSelect}
            />
          )
        })}
      </div>
    </div>
  )
}

export const DeniedSection = ({ deniedReasonSelect, setDeniedReasonSelect, showTitle }) => {
  const handleCheckboxChange = (updatedOptions) => {
    setDeniedReasonSelect(updatedOptions)
  }
  return (
    <div className={styles.pendingSectionLayout}>
      {showTitle && (
        <div className={styles.pendingSectionHeader}>
          <p>DENIED</p>
          <span>Check all that apply</span>
        </div>
      )}

      <div className={styles.checkbox}>
        <CheckboxSelect options={deniedRequestRadioOptions} selectedOptions={deniedReasonSelect} onChange={handleCheckboxChange} />
      </div>
    </div>
  )
}
