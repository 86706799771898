import { Dialog } from 'components/Dialog'
import { httpDelete, httpPost } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { useDispatch } from 'react-redux'
import { ButtonText } from '../Main/confirmationText'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const dialogTitle = {
  recurring_approved: 'Yes, expire template now.',
  recurring_expired: 'Take me to the new template',
}

const dialogText = {
  recurring_approved: 'Template will expire immediately.',
  recurring_expired:
    'Template has been duplicated and found in the Write step of Recurring Payments tab',
}

const ExpireDialog = ({ setDialog, recurringId, status }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleNavigate = (id) => {
    if (id) {
      navigate(`/distributions/${id}`)
      return
    }
    if (searchParams.get('redirection_back') == 'true') {
      navigate(-1)
    } else {
      navigate(`/distributions/queue?tid=${encodedData('main')}&stid=${encodedData('0')}`)
    }
  }
  const expireTemplate = () => {
    const url = `/admin/recurring_distributions/${recurringId}`
    setDialog(false)
    dispatch(setLoad())
    httpDelete(url)
      .then((res) => {
        dispatch(setBanner('Template expired.'))
        handleNavigate()
      })
      .catch((err) => {
        dispatch(setBanner(`Error expiring template - ${err}`, 'danger'))
      })
      .finally(() => {
        dispatch(setUnload())
      })
  }
  const copyTemplate = () => {
    const url = `/admin/recurring_distributions/duplicate/${recurringId}`
    setDialog(false)
    dispatch(setLoad())
    httpPost(url)
      .then(({ id }) => {
        dispatch(setBanner('Template expired.'))
        handleNavigate(id)
      })
      .catch((err) => {
        dispatch(setBanner(`Error expiring template - ${err}`, 'danger'))
      })
      .finally(() => {
        dispatch(setUnload())
      })
  }

  const handleButton = () => {
    switch (status) {
      case 'recurring_approved':
        expireTemplate()
        break
      case 'recurring_expired':
        copyTemplate()
        break
    }
  }
  return (
    <Dialog
      cancelButtonText={<ButtonText primary="Cancel" />}
      confirmButtonText={<ButtonText primary={status && dialogTitle[status]} />}
      headerText={<div>{status && dialogText[status]}</div>}
      onCancel={() => setDialog(false)}
      onConfirm={handleButton}
    />
  )
}

export default ExpireDialog
