import React from 'react'
import Error from 'components/Error'

const NotFound = () => (
  <Error
    title="404: Page Not Found"
    message="The page you are looking for does not exist."
  />
)
export default NotFound
