// @flow
const amount = (amount: string): boolean => {
  return Boolean(amount) && (Number(amount) < 0 || Number(amount) > 0)
}

const categoryId = (categoryId: number): boolean => {
  return Boolean(categoryId > 0)
}

const dateRange = (
  start_date: string, end_date: string
): boolean => {
  return Boolean(start_date) && Boolean(end_date) && start_date <= end_date
}

const investmentId = (categoryId: number): boolean => {
  return Boolean(categoryId > 0)
}

const transactionDate = (transaction_date: string) => {
  return Boolean(transaction_date)
}

export default {
  amount,
  categoryId,
  dateRange,
  investmentId,
  transactionDate,
}
