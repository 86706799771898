// @flow
import React from 'react'
import { Field } from 'redux-form'
import type { Form } from 'lib/types'
import {renderInputField} from 'components/FormFields'
import styles from './styles'

type Props = {
  form: Form,
  title: string,
  help: string,
}

const Component = (props: Props) => {
  const { form, title, help, } = props
  return (
    <section className={styles.contentContainer}>
      <div className={styles.wrapper}>
        <h2 className={styles.header}>{title}</h2>
        <form onSubmit={form.handleSubmit} className={styles.form}>
          <section className='section'>
            <h4>{help}</h4>
            <div className={styles.formItemHalf}>
              <Field
                component={renderInputField}
                label='Email'
                name='email'
                placeholder='Email'
                type='text'
                required={true}
                autoFocus
              />
            </div>
          </section>
          <section className={styles.buttons}>
            <button type='submit' className={styles.button}>Log In</button>
          </section>
        </form>
      </div>
    </section>
  )
}

export default Component
