// @flow
import React from 'react'
import styles from './styles'

type Props = {
  bold?: boolean,
  error?: string | null,
  label: string,
  mono?: boolean,
  val?: string | null,
  status?: string,
  singleLine?: string | null,
}

const Row = ({ error, label, val, mono, bold, status, singleLine, id }: Props) => {
  const value = val ? val : '—'
  let valueStyle = styles.valueBlank
  if (val) {
    valueStyle = styles.value
    if (mono) {
      valueStyle = styles.valueMono
    } else if (bold) {
      valueStyle = styles.valueBold
    } else if (status) {
      valueStyle = styles[status]
    }
  }
  let rowStyle = styles.row
  if (error) {
    rowStyle = styles.rowError
    label = (
      <div className="test-hook-row-error">
        {label}&ensp;&middot;&ensp;<span className={styles.error}>{error}</span>
      </div>
    )
  }
  if (singleLine) {
    rowStyle = styles.singleLine
  }

  return (
    <div className={rowStyle} id={id}>
      <div className={styles.label}>{label}</div>
      <div className={`${valueStyle} test-hook-row-label-${label}`}>{value}</div>
    </div>
  )
}

export default Row
