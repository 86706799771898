// @flow
import React from 'react'
import formatters from 'lib/formatters'
import styles from './styles'

type Props = {
  acknowledgeEarlyDate: boolean,
  acknowledgeDuplicateCheck: boolean,
  disabled: boolean,
  onChange: Function,
  warnings: Array<string>
}

export const WarningsAcknowledgementRef = (props: Props) => {
  const {
    disabled,
    warnings,
    onChange,
    acknowledgeEarlyDate,
    acknowledgeDuplicateCheck
  } = props

  const earlyDateDiv = (warning, idx) => (
    <div key={idx}>
      <div className={styles.warningLabelGroup}>
        <input
          name="acknowledgeEarlyDate"
          type="checkbox"
          checked={acknowledgeEarlyDate}
          onChange={onChange}
        />
        <label className={styles.warningLabel}>
          {formatters.legibleWarningAcknowledgement(warning)}
        </label>
      </div>
    </div>
  )

  const duplicateCheckDiv = (warning, idx) => (
    <div key={idx}>
      <div className={styles.warningLabelGroup}>
        <input
          name="acknowledgeDuplicateCheck"
          type="checkbox"
          checked={acknowledgeDuplicateCheck}
          onChange={onChange}
        />
        <label className={styles.warningLabel}>
          {formatters.legibleWarningAcknowledgement(warning)}
        </label>
      </div>
    </div>
  )

  const renderWarnings = () => {
    return warnings.map((warning, idx) => {
      // Note: `disabled` will be true if user is on the blacklist,
      // therefore they've already acknowledged the warnings
      if (disabled) {
        return <div key={idx}></div>
      } else if (warning == 'early_date') {
        return earlyDateDiv(warning, idx)
      } else {
        return duplicateCheckDiv(warning, idx)
      }
    })
  }

  return (
    <div>
      {renderWarnings()}
    </div>
  )
}
