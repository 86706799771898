// @flow
import React from 'react'
import { Notes } from 'components/Notes'
import { GPTAccountRef } from 'components/References'
import { Warnings } from 'components/Warnings'
import Ticket from 'components/Ticket'
import cleaners from 'lib/field/cleaners'
import { FeeCalculationsRef } from './FeeCalculationsRef'
import { ApprovalRef } from 'components/References'
import { DepositFunds } from 'components/DepositFunds'
import { WarningsAcknowledgementRef } from './WarningsAcknowledgementRef'
import styles from './styles'

type Props = {
  canSubmit: boolean,
  dispatch: Function,
  errors: Object,
  isSubmitting: boolean,
  onBlur: Function,
  onChange: Function,
  onSubmit: Function,
  onDelete?: Function,
  ticket: Object,
  values: Object,
}

const Reference = ({ dispatch, ticket }: Props) => (
  <section>
    <GPTAccountRef
      beneficiary={{ name: ticket.beneficiary_name }}
      number={ticket.account_number}
      status={ticket.account_status}
    />
    <Warnings warnings={ticket.warnings} />
    <Notes
      accountNumber={ticket.account_number}
      dispatch={dispatch}
      notes={ticket.notes}
    />
  </section>
)

const normalizeAmount = (amount) => {
  return typeof amount === 'number' ? amount : cleaners.money(amount)
}

const renderLabelError = (error) => <strong>&ensp;&middot;&ensp;{error}</strong>

export const ResolutionStation = (props: Props) => {
  const {
    canSubmit,
    errors,
    isSubmitting,
    onBlur,
    onChange,
    onDelete,
    onSubmit,
    ticket,
    values,
  } = props
  const {
    acknowledgeDuplicateCheck, acknowledgeEarlyDate, fee_rate, fee_schedule
  } = values
  const header = { subheader: 'Deposit', title: 'Resolution' }
  const reference = ticket && <Reference {...props} />

  return (
    <Ticket header={header} history={ticket.history} reference={reference}>
      <ApprovalRef rejection_notes={ticket.rejection_notes} />
      <h4 className={styles.section}>Resolve deposit information.</h4>
      <form onSubmit={onSubmit}>
        <DepositFunds
          errors={props.errors}
          onBlur={onBlur}
          onChange={onChange}
          values={values}
        />
        <FeeCalculationsRef
          amount={normalizeAmount(values.amount)}
          balance={ticket.balance}
          feeRate={fee_rate}
          feeSchedule={fee_schedule}
          hasCustomFeeSchedule={ticket['custom_fee_schedule?']}
          enrollmentFee={ticket['enrollment_fee']}
        />
        <section className={styles.cardMarginBot}>
          <div className={styles.sectionNoMargin}>
            <div className={errors.fee_rate && styles.errorWrapper}>
              <label className={styles.label} htmlFor="fee_rate">
                Fee Rate (%)
                {errors.fee_rate && renderLabelError(errors.fee_rate)}
              </label>
              <input
                autoFocus
                className={styles.input}
                max={100}
                min={0}
                name="fee_rate"
                onChange={onChange}
                step={0.01}
                type="number"
                value={fee_rate}
              />
            </div>
          </div>
        </section>
        <WarningsAcknowledgementRef
          warnings={ticket.warnings}
          onChange={props.onChange}
          acknowledgeEarlyDate={acknowledgeEarlyDate}
          acknowledgeDuplicateCheck={acknowledgeDuplicateCheck}
        />
        <section className={styles.buttons}>
          <div className={styles.buttonsLeft}>
            {onDelete && (
              <button
                className={styles.buttonDanger}
                disabled={isSubmitting}
                onClick={onDelete}
              >
                Delete
              </button>
            )}
          </div>
          <div className={styles.buttonsRight}>
            <button
              className={styles.button}
              disabled={!canSubmit}>
              Save and Continue
            </button>
          </div>
        </section>
      </form>
    </Ticket>
  )
}
