// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../styles/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__link___s7dNj {
  margin-bottom: 20px;
  margin-right: 20px;
}

.styles__statusTable___to3Bf {
  margin-top: 30px;
  margin-bottom: 30px;
}

.styles__contentContainer___Vm6Y9 {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 0;
  padding-top: 20px;
}

.styles__contentContainer___Vm6Y9:before {
  content: '';
  display: table;
}

.styles__contentContainer___Vm6Y9:after {
  content: '';
  display: table;
  clear: both;
}

.styles__tableWrapper___VUSGU {
  width: 100%;
}

.styles__titleWrapper___in76J {
  display: flex;
  flex-direction: row;
}

.styles__section___0TLb5 {
  margin-bottom: 20px;
}

.styles__sectionNoMargin___pLJFx {
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/CoTrustee/components/Xero/components/Main/styles.css"],"names":[],"mappings":"AAIA;EAEE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EAEE,aAAwB;EAAxB,mBAAwB;EAExB,iBAAiB;EACjB,iBAAiB;AACnB;;AANA;EAGE,WAAsB;EAAtB,cAAsB;AAGxB;;AANA;EAGE,WAAsB;EAAtB,cAAsB;EAAtB,WAAsB;AAGxB;;AAEA;EACE,WAAW;AACb;;AAEA;EAEE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EAEE,mBAAmB;AACrB;;AAEA;AAEA","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtGray, gtGreen, gtBackground from colors;\n\n.link {\n  composes: buttonSecondary from helpers;\n  margin-bottom: 20px;\n  margin-right: 20px;\n}\n\n.statusTable {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n.contentContainer {\n  composes: content-container from helpers;\n  lost-flex-container: row;\n  lost-utility: clearfix;\n  padding-bottom: 0;\n  padding-top: 20px;\n}\n\n.tableWrapper {\n  width: 100%;\n}\n\n.titleWrapper {\n  composes: titleWrapper from helpers;\n  display: flex;\n  flex-direction: row;\n}\n\n.section {\n  composes: section from helpers;\n  margin-bottom: 20px;\n}\n\n.sectionNoMargin {\n  composes: sectionNoMargin from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtGray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGray"]}`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"gtBackground": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBackground"]}`,
	"link": `styles__link___s7dNj ${___CSS_LOADER_ICSS_IMPORT_1___.locals["buttonSecondary"]}`,
	"statusTable": `styles__statusTable___to3Bf`,
	"contentContainer": `styles__contentContainer___Vm6Y9 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["content-container"]}`,
	"tableWrapper": `styles__tableWrapper___VUSGU`,
	"titleWrapper": `styles__titleWrapper___in76J ${___CSS_LOADER_ICSS_IMPORT_1___.locals["titleWrapper"]}`,
	"section": `styles__section___0TLb5 ${___CSS_LOADER_ICSS_IMPORT_1___.locals["section"]}`,
	"sectionNoMargin": `styles__sectionNoMargin___pLJFx ${___CSS_LOADER_ICSS_IMPORT_1___.locals["sectionNoMargin"]}`
};
export default ___CSS_LOADER_EXPORT___;
