// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__radioOption___xXqqX {
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.styles__radioOption___xXqqX input {
    margin-right: 10px;
  }
.styles__radioOption___xXqqX label {
    font-size: 13px;
  }
.styles__radioOption___xXqqX input[type='radio'] {
    width: 16px;
    height: 16px;
    accent-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]};
    border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]};
  }
`, "",{"version":3,"sources":["webpack://./universal/components/RadioOption/styles.css"],"names":[],"mappings":"AAIA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AAcrB;AAbE;IACE,kBAAkB;EACpB;AACA;IACE,eAAe;EACjB;AAEA;IACE,WAAW;IACX,YAAY;IACZ,0DAAmB;IACnB,8DAAuB;EACzB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n@value colors: \"styles/colors.css\";\n@value gtRed, gtGreen from colors;\n\n.radioOption {\n  margin-top: 12px;\n  display: flex;\n  align-items: center;\n  input {\n    margin-right: 10px;\n  }\n  label {\n    font-size: 13px;\n  }\n\n  input[type='radio'] {\n    width: 16px;\n    height: 16px;\n    accent-color: gtRed;\n    border: 1px solid gtRed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"colors": `"styles/colors.css"`,
	"gtRed": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]}`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"radioOption": `styles__radioOption___xXqqX`
};
export default ___CSS_LOADER_EXPORT___;
