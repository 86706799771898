import SendIcon from '@mui/icons-material/Send'
import { LockUnlockIcon } from '../permissions'
import { map } from 'lodash'

export const headers = ({ currentStatus, sort, nextStatus, handleFilter, className }) => {
  const DAYS_IN_QUEUE = {
    value: (
      <div style={{ lineHeight: '12px' }}>
        Days In <br /> Queue
      </div>
    ),
    sort: sort,
    filter: 'insertedAt',
    handleFilter,
    className,
    id: 'insertedAt',
  }
  const EXPEDITE_DATE_NEEDED = {
    value: <SendIcon sx={{ color: '#8E3132' }} />,
    className,
    id: 'expeditedDateNeeded',
    sort: sort,
    filter: 'expeditedDateNeeded',
    handleFilter,
  }
  const BENEFICIARY = {
    value: 'Beneficiary',
    sort: sort,
    filter: 'account.number',
    handleFilter,
    className,
    id: 'beneficiary',
  }
  const PAY_TO = {
    value: 'Pay To',
    sort: sort,
    filter: 'xeroPayTo.name',
    handleFilter,
    className,
    id: 'xeroPayTo',
  }
  const AMOUNT = {
    value: 'Amount',
    sort: sort,
    filter: 'amount',
    handleFilter,
    className,
    id: 'amount',
  }
  const REQUEST_TYPE = {
    value: 'Request Type',
    sort: sort,
    filter: 'requestType',
    handleFilter,
    className,
    id: 'requestType',
  }
  const LAST_UPDATED_AT = {
    value: 'Last Updated',
    sort: sort,
    filter: 'lastUpdatedDate',
    handleFilter,
    className,
    id: 'lastUpdatedDate',
  }
  const LAST_UPDATED_USER = {
    value: 'By',
    sort: sort,
    filter: 'lastUpdatedUser',
    handleFilter,
    className,
    id: 'lastUpdatedUser',
  }
  const DRF_ID = { value: 'ID', sort: sort, filter: 'id', handleFilter, className, id: 'id' }
  const PAY_BY = {
    value: 'Pay By',
    sort: sort,
    filter: 'paymentType',
    handleFilter,
    className,
    id: 'paymentType',
  }
  const OWNER = {
    value: 'Owner',
    sort: sort,
    filter: 'owner.name',
    handleFilter,
    className,
    id: 'owner',
  }
  const DRF_TYPE = {
    value: 'Type',
    filter: 'type',
    sort: sort,
    handleFilter,
    className,
    id: 'type',
  }
  const FREQUENCY = {
    value: 'Frequency',
    filter: 'recurringDistribution.period',
    sort: sort,
    handleFilter,
    className,
    id: 'period',
  }
  const DATE_PAID = {
    value: 'Paid On',
    sort: sort,
    filter: 'datePaid',
    handleFilter,
    className,
    id: 'datePaid',
  }
  const DAYS_PENDING = {
    value: (
      <div style={{ lineHeight: '12px' }}>
        Days <br /> Pending
      </div>
    ),
    sort: sort,
    filter: 'pendingDistribution.dateEnteredPending',
    handleFilter,
    className,
    id: 'dateEnteredPending',
  }
  const reasonFilter = currentStatus === 'pending' ? 'pendingDistribution.reason' : 'deniedDistribution.reason'
  const REASON = {
    value: 'Reason',
    sort: sort,
    filter: reasonFilter,
    handleFilter,
    className,
    id: 'reason',
  }
  const NEXT_PAYMENT_DATE = {
    value: (
      <div style={{ lineHeight: '12px' }}>
        Next Payment <br /> Date
      </div>
    ),
    sort: sort,
    filter: 'nextPaymentDate',
    handleFilter,
    className,
    id: 'nextPaymentDate',
  }
  const RUN_NOW = {
    value: 'Run Now',
    sort: sort,
    filter: 'paymentType',
    handleFilter,
    className,
    id: 'paymentType',
  }
  const END_DATE = {
    value: 'End Date',
    sort: sort,
    filter: 'recurringDistribution.endDate',
    handleFilter,
    className,
    id: 'endDate',
  }
  const LOCK_UNLOCK = {
    value: <LockUnlockIcon lockedForEdit={true} />,
    sort: sort,
    filter: 'lockedForEdit',
    handleFilter,
    className,
  }
  const createHeader = () => {
    if (currentStatus == 'main') {
      let main = [
        DAYS_IN_QUEUE,
        DRF_TYPE,
        EXPEDITE_DATE_NEEDED,
        BENEFICIARY,
        PAY_TO,
        AMOUNT,
        REQUEST_TYPE,
        LAST_UPDATED_AT,
        LAST_UPDATED_USER,
        DRF_ID,
      ]
      switch (nextStatus) {
        // remove pay to and amount column in main
        case 'written':
          main = main.filter((i) => !['Pay To', 'Amount'].includes(i.value))
          break
        case 'admin_wrapped_up':
          main = map(main, function (a) {
            return a.id === 'requestType' ? OWNER : a
          }).concat(PAY_BY)
          break
        default:
          main.splice(6, 0, OWNER)
          main = main.concat(PAY_BY)
          break
      }

      return main
    } else if (currentStatus === 'paid') {
      let paid = [
        DAYS_IN_QUEUE,
        DRF_TYPE,
        EXPEDITE_DATE_NEEDED,
        BENEFICIARY,
        PAY_TO,
        AMOUNT,
        REQUEST_TYPE,
        LAST_UPDATED_AT,
        LAST_UPDATED_USER,
        DRF_ID,
      ]
      paid = paid.map((cell) => (cell.id === 'lastUpdatedDate' ? DATE_PAID : cell))
      paid.splice(6, 0, OWNER)
      paid = paid.concat(PAY_BY)
      return paid
    } else if (['pending', 'denied'].includes(currentStatus)) {
      let header = [DAYS_PENDING, DRF_TYPE, BENEFICIARY, REASON, OWNER, LAST_UPDATED_AT, PAY_TO, AMOUNT, DAYS_IN_QUEUE, DRF_ID]
      if (currentStatus === 'denied') {
        return header
          .map((h) => {
            if (h.id === 'lastUpdatedDate') {
              return { ...h, value: 'Denied On' }
            }
            return h
          })
          .slice(1)
      } else if (currentStatus === 'pending') {
        const DATE_PENDING = {
          value: (
            <div style={{ lineHeight: '12px' }}>
              Date Entered
              <br /> Pending
            </div>
          ),
          sort: sort,
          filter: 'pendingDistribution.dateEnteredPending',
          handleFilter,
          className,
          id: 'dateEnteredPending',
        }
        header.splice(5, 0, DATE_PENDING)
      }
      return header
    } else if (currentStatus === 'recurring') {
      let recurring = [
        DAYS_IN_QUEUE,
        EXPEDITE_DATE_NEEDED,
        BENEFICIARY,
        PAY_TO,
        AMOUNT,
        REQUEST_TYPE,
        LAST_UPDATED_AT,
        LAST_UPDATED_USER,
        FREQUENCY,
        PAY_BY,
        END_DATE,
        DRF_ID,
      ]
      recurring = recurring.filter((cell) => cell.id != 'expeditedDateNeeded')

      let removeColumnId = []
      switch (nextStatus) {
        case 'recurring_written':
          removeColumnId = ['period']
          recurring = recurring.filter((cell) => !removeColumnId.includes(cell.id))
          return recurring
        case 'recurring_reviewed':
        case 'recurring_approved':
          removeColumnId = ['insertedAt', 'endDate']
          recurring = recurring.filter((cell) => !removeColumnId.includes(cell.id))
          return recurring
        case 'scheduled':
          removeColumnId = ['insertedAt', 'lastUpdatedDate', 'lastUpdatedUser']
          recurring = recurring.filter((cell) => !removeColumnId.includes(cell.id))
          // if (['development', 'sandbox'].includes(process.env.NODE_ENV)) {
          //   recurring = [RUN_NOW, ...recurring]
          // }
          recurring.splice(7, 0, NEXT_PAYMENT_DATE)
          return recurring
        default:
          return recurring
      }
    }
  }

  const arr = createHeader()
  arr.push(LOCK_UNLOCK)
  return arr
}
