// @flow
import React from 'react'
import { NavLink } from 'react-router-dom'

import styles from './styles.css'

type HeaderUtilityItemProps = {
  label: string,
  to?: string,
  onClick?: () => void,
  id?: string,
}

const HeaderUtilityItem = (props: HeaderUtilityItemProps) => {
  const { label, to, onClick, id } = props

  return (
    <NavLink
      to={to}
      id={id}
      className={({ isActive }) =>
        isActive
          ? `${styles.headerUtilityItemActive} ${styles.headerUtilityItem}`
          : styles.headerUtilityItem
      }
      onClick={onClick}
    >
      {label}
    </NavLink>
  )
}

export default HeaderUtilityItem
