import { useEffect, useState } from 'react'
import { colorTheme } from '../colors/color'
import { navigateWithoutRedirect } from './configuration'
import styles from './styles'
import { filterList } from './constant'

const TableFilter = ({ filterKey, setFilterKey, selectedOptions, setSelectedOptions, color }) => {
  const handleCheckboxChange = (option) => {
    setFilterKey(option.filterKey)
    setSelectedOptions([option.value])
    if (option.filterKey != null) {
      navigateWithoutRedirect('fkid', JSON.stringify(option))
    }
  }

  useEffect(() => {
    if (!filterKey) {
      setSelectedOptions(filterList[0].value)
      navigateWithoutRedirect('fkid', JSON.stringify(filterList[0]))
    } else {
      const filterApplied = filterList.find((f) => JSON.stringify(f.filterKey) === JSON.stringify(filterKey))
      if (filterApplied != null) {
        setSelectedOptions([filterApplied.value])
      }
    }
  }, [filterKey])

  return (
    <div className={`${styles.tableFilter} ${colorTheme[color].bgDark}`}>
      <div className={styles.tableSelectFilter}>
        <p>FILTER RESULTS BY TRUST</p>
      </div>
      <div className={styles.checkboxGroup}>
        {filterList.map((option) => (
          <div
            key={option.value}
            className={
              selectedOptions.includes(option.value) ? `${styles.checkboxActive} ${colorTheme[color].textDark}` : styles.checkboxActiveInactive
            }
          >
            <input type="checkbox" id={option.value} checked={selectedOptions.includes(option.value)} onChange={() => handleCheckboxChange(option)} />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TableFilter
