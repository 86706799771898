// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AutocompleteResults__autoCompleteItems___SaKij {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
}

.AutocompleteResults__autoCompleteItems___SaKij ul {
    list-style: none;
    padding: 0;
  }

.AutocompleteResults__autoCompleteItems___SaKij ul:last-child li:last-child {
        border: 0;
      }

.AutocompleteResults__autoCompleteItems___SaKij li {
    cursor: pointer;
    padding: 16px;
    padding-top: 10px;
    height: auto;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgba(195, 195, 195, 0.45);
    display: flex;
    align-items: center;
    gap: 4px;
  }

.AutocompleteResults__autoCompleteItems___SaKij li:hover {
      background-color: rgba(55, 110, 161, 0.1) !important;
    }

.AutocompleteResults__autoCompleteItems___SaKij .AutocompleteResults__active___X8NBu {
    background-color: rgba(55, 110, 161, 0.1);
  }

.AutocompleteResults__autoCompleteItems___SaKij .AutocompleteResults__user_icon___jixnv {
    fill: #5faba1;
  }

_sectionTitle.AutocompleteResults__autoCompleteItems___SaKij {
    display: block;
  }

_icon.AutocompleteResults__autoCompleteItems___SaKij {
    margin-right: 20px;
  }

.AutocompleteResults__autoCompleteItems_label___BP1dT {
  display: flex;
  flex-direction: column;
}

.AutocompleteResults__user_icon___jixnv {
  fill: #5faba1;
  color: #5faba1;
}

.AutocompleteResults__top_line___k3ORw {
  font-weight: bold;
  margin-bottom: 2px;
}

.AutocompleteResults__small___OgYtw {
  font-size: 12px;
}

.AutocompleteResults__matchedText___sFvA_ {
  font-weight: 200;
  display: inline !important;
}
`, "",{"version":3,"sources":["webpack://./universal/components/Autocomplete/AutocompleteResults.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,0BAA0B;EAC1B,sBAAsB;AA6CxB;;AA3CE;IACE,gBAAgB;IAChB,UAAU;EAOZ;;AAJI;QACE,SAAS;MACX;;AAIJ;IACE,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,kDAAkD;IAClD,aAAa;IACb,mBAAmB;IACnB,QAAQ;EAKV;;AAHE;MACE,oDAAoD;IACtD;;AAGF;IACE,yCAAyC;EAC3C;;AAEA;IACE,aAAa;EACf;;AAEA;IACE,cAAc;EAChB;;AAEA;IACE,kBAAkB;EACpB;;AAGF;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B","sourcesContent":[".autoCompleteItems {\n  padding-top: 20px;\n  padding-left: 20px;\n  padding-right: 20px;\n  border: 1px solid #e4e4e4;\n  border-radius: 0 0 3px 3px;\n  background-color: #fff;\n\n  ul {\n    list-style: none;\n    padding: 0;\n\n    &:last-child {\n      li:last-child {\n        border: 0;\n      }\n    }\n  }\n\n  li {\n    cursor: pointer;\n    padding: 16px;\n    padding-top: 10px;\n    height: auto;\n    padding-left: 20px;\n    padding-right: 20px;\n    border-bottom: 1px solid rgba(195, 195, 195, 0.45);\n    display: flex;\n    align-items: center;\n    gap: 4px;\n\n    &:hover {\n      background-color: rgba(55, 110, 161, 0.1) !important;\n    }\n  }\n\n  .active {\n    background-color: rgba(55, 110, 161, 0.1);\n  }\n\n  .user_icon {\n    fill: #5faba1;\n  }\n\n  &_sectionTitle {\n    display: block;\n  }\n\n  &_icon {\n    margin-right: 20px;\n  }\n}\n\n.autoCompleteItems_label {\n  display: flex;\n  flex-direction: column;\n}\n\n.user_icon {\n  fill: #5faba1;\n  color: #5faba1;\n}\n\n.top_line {\n  font-weight: bold;\n  margin-bottom: 2px;\n}\n\n.small {\n  font-size: 12px;\n}\n\n.matchedText {\n  font-weight: 200;\n  display: inline !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoCompleteItems": `AutocompleteResults__autoCompleteItems___SaKij`,
	"active": `AutocompleteResults__active___X8NBu`,
	"user_icon": `AutocompleteResults__user_icon___jixnv`,
	"autoCompleteItems_label": `AutocompleteResults__autoCompleteItems_label___BP1dT`,
	"top_line": `AutocompleteResults__top_line___k3ORw`,
	"small": `AutocompleteResults__small___OgYtw`,
	"matchedText": `AutocompleteResults__matchedText___sFvA_`
};
export default ___CSS_LOADER_EXPORT___;
