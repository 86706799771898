// @flow
import React from 'react'
import { connect } from 'react-redux'
import cleaners from 'lib/field/cleaners'
import { httpPost } from 'lib/http'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { rateLimitMessage } from 'lib/rateLimit'
import type { Product } from 'lib/types'
import { Template } from './Template'
import type { Values } from './Template'
import validate from './validate'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  location: Object,
}

type State = {
  isSubmitting: boolean,
  values: Values,
}

export class Interest extends React.Component<Props, State> {
  props: Props

  constructor(props: Props) {
    super(props)
    this.state = {
      isSubmitting: false,
      values: {
        amount: '',
        categoryId: 0,
        end_date: '',
        investmentId: 0,
        product: '',
        transaction_date: '',
        start_date: '',
      },
    }
  }

  buildParams = () => {
    const {
      amount,
      categoryId,
      end_date,
      investmentId,
      start_date,
      transaction_date,
    } = this.state.values
    return {
      amount: cleaners.money(amount),
      category_id: categoryId,
      end_date,
      investment_id: investmentId,
      start_date,
      transaction_date,
    }
  }

  canSubmit = (): boolean => {
    const { values } = this.state
    return (
      validate.amount(values.amount) &&
      validate.categoryId(values.categoryId) &&
      validate.dateRange(values.start_date, values.end_date) &&
      validate.investmentId(values.investmentId) &&
      validate.transactionDate(values.transaction_date)
    )
  }

  setDefaultOptions = (product) => {
    let options = [
      { array: product.categories, value_attr: 'categoryId' },
      { array: product.investments, value_attr: 'investmentId' },
    ]
    options.forEach((option) => {
      let value = 0
      if (option?.array?.length >= 1) {
        value = option.array[0].id
      }

      this.setState(({ values }) => ({
        values: { ...values, [option.value_attr]: value },
      }))
    })
  }

  handleProductLoad = (data: Product) => {
    this.setState(({ values }) => ({
      values: { ...values, product: data.product },
    }))
    this.setDefaultOptions(data.product)
  }

  handleChange = (event) => {
    const { name, value } = event.target
      ? event.target
      : {
          name: event.name,
          value: event?.value?.value
            ? event.value.value
            : event?.value
            ? event.value
            : '',
        }
    this.setState(({ values }) => ({ values: { ...values, [name]: value } }))
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({ isSubmitting: true }, this.postInterest)
  }

  postInterest = () => {
    const { dispatch } = this.props

    dispatch(setLoad())
    return httpPost('/admin/allocations', this.buildParams())
      .then(() => {
        this.props.location.navigate('/contacts')
        dispatch(setBanner('Allocation successfully created.'))
      })
      .catch(({ code, unit }) => {
        if (code === 598) {
          dispatch(setBanner(rateLimitMessage(unit), 'danger'))
        } else {
          dispatch(setBanner('No allocation created.', 'danger'))
        }
      })
      .then(() => {
        this.setState({ isSubmitting: false })
        dispatch(setUnload())
      })
  }

  updateAmount = (updater) => {
    const { values } = this.state
    const newValues = { ...values, amount: updater(values.amount) }
    this.setState({ values: newValues })
  }

  render() {
    return (
      <Template
        canSubmit={!this.state.isSubmitting && this.canSubmit()}
        dispatch={this.dispatch}
        onChange={this.handleChange}
        onProductLoad={this.handleProductLoad}
        onSubmit={this.handleSubmit}
        updateAmount={this.updateAmount}
        values={this.state.values}
      />
    )
  }
}

export default withRouter(connect()(Interest))
