// @flow
import React from 'react'
import type { Account } from 'lib/types'
import Row from './Row'
import styles from './styles'
import formatters from 'lib/formatters'

const GPTAccountRef = (props: Account) => {
  let { beneficiary } = props
  if (!beneficiary) {
    beneficiary = { name: '' }
  }

  return (
    <section className={styles.cardMarginBot}>
      <h5 className={styles.titleSection}>Account</h5>
      <section className={styles.sectionNoMargin}>
        <div className={styles.rowWrapper}>
          <Row label="Account Number" val={props.number} />
          <Row label="Beneficiary" val={beneficiary.name} />
          <Row
            label="Account Status"
            val={formatters.toTitleCase(props.status)}
            status={props.status}
          />
        </div>
      </section>
    </section>
  )
}

export default GPTAccountRef
