// @flow
import type { Account, Contact, LineItem } from 'lib/types'
import validators from 'lib/field/validators'

const account = (account: Account = {}) => {
  const {
    beneficiary_id,
    grantor_id,
    co_grantor_id,
    grantor_relationship,
    joinder_agreement,
    primary_grantor_doc,
    representative_id,
    secondary_grantor_doc,
  } = account
  let errors = {
    beneficiary_id: beneficiary_id ? null : 'Required',
    grantor_id: null,
    grantor_relationship: grantor_relationship ? null : 'Required',
    joinder_agreement: validators.file(joinder_agreement),
    primary_grantor_doc: validators.file(primary_grantor_doc),
    secondary_grantor_doc: validators.file(secondary_grantor_doc),
  }
  if (!['self', 'selfRep', 'court'].includes(grantor_relationship)) {
    if (!grantor_id) {
      errors.grantor_id = 'Required'
    } else if (grantor_id === beneficiary_id) {
      errors.grantor_id = 'Cannot be the beneficiary'
    }
  }
  if (grantor_relationship === 'coGuardian') {
    if (!co_grantor_id) {
      errors.co_grantor_id = 'Required'
    } else if (co_grantor_id === beneficiary_id) {
      errors.co_grantor_id = 'Cannot be the beneficiary'
    }
  }
  if (grantor_relationship === 'selfRep') {
    if (!representative_id) {
      errors.representative_id = 'Required'
    } else if (representative_id === beneficiary_id) {
      errors.representative_id = 'Cannot be the beneficiary'
    }
  }

  return errors
}

const contact = ({ name }: Contact) => {
  const errors = {
    name: name ? null : 'Required',
  }

  return errors
}

const lineItem = (lineItem: LineItem = {}) => {
  const {amount, memo, effective_date} = lineItem

  const errors = {
    memo: memo ? null : 'Required',
    effective_date: effective_date ? null : 'Required',
  }

  if (isNaN(amount))  {
    errors.amount = 'Valid number required'
  }

  return errors
}

export default {
  account,
  contact,
  lineItem,
}
