// @flow
import React from 'react'
import { ContactBasicFormRef } from 'components/References'
import FormButtons from 'components/FormButtons'
import Ticket from 'components/Ticket'
import Reference from './Reference'
import styles from './styles'

type Props = {
  errors: Object,
  isSubmitting: boolean,
  onAddAble: Function,
  onAddConsult: Function,
  onRemoveAble: Function,
  onRemoveConsult: Function,
  onSubmit: Function,
  ticket: Object,
  values: Object,
}

const buttonText = (label, contact_ids) => (
  contact_ids.length ? `Select Additional ${label}` : `Select ${label}`
)

const renderAblesAndConsults = (contacts, errors, onRemoveContact) =>
  contacts.map((contact, idx) => {
    const removeBtn = (
      <div
        onClick={onRemoveContact(contact.id)}
        className={styles.removeButton}
      />
    )

    return (
      <ContactBasicFormRef
        contact={contact}
        key={idx}
        remove={removeBtn}
        errors={errors.contacts}
      />
    )
  })

export const AblesAndConsultsStation = (props: Props) => {
  const {
    errors,
    isSubmitting,
    onAddAble,
    onAddConsult,
    onRemoveAble,
    onRemoveConsult,
    onSubmit,
    ticket,
    values: { ables, consults }
  } = props
  const header = {
    subheader: 'Account Creation',
    title: 'Ables And Consults',
  }
  const reference = ticket && <Reference {...props} />

  return (
    <Ticket
      header={header}
      history={ticket.history}
      reference={reference}
    >
      <p className={styles.infoText}>
        Select ables and consults to add to the account.
      </p>
      <form className={styles.form} onSubmit={onSubmit}>
        <section className={styles.cardMarginBot}>
          <section className={styles.sectionNoMargin}>
            <div className={styles.formItem}>
              <h3>Ables</h3>
              <button
                id='test-hook-select-ables'
                onClick={onAddAble}
                className={styles.buttonLocalSecondary}
                type='button'
              >
                {buttonText('Able', ables)}
              </button>
              <div>{renderAblesAndConsults(ables, errors, onRemoveAble)}</div>
            </div>
          </section>
        </section>
        <section className={styles.cardMarginBot}>
          <section className={styles.sectionNoMargin}>
            <div className={styles.formItem}>
              <h3>Consults</h3>
              <button
                id='test-hook-select-consults'
                onClick={onAddConsult}
                className={styles.buttonLocalSecondary}
                type='button'
              >
                {buttonText('Consult', consults)}
              </button>
              <div>
                {renderAblesAndConsults(consults, errors, onRemoveConsult)}
              </div>
            </div>
          </section>
        </section>
        <FormButtons submitLabel='Save & Continue' submitting={isSubmitting} />
      </form>
    </Ticket>
  )
}
