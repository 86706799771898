// @flow
import React from 'react'
import styles from './styles'
import { NavLink, Link } from 'react-router-dom'

type Props = {
  title: string,
  message: string,
  icon?: string,
  buttonLink?: string,
  buttonTitle?: string,
}

const Destination = (props: Props) => {
  if (props.buttonLink && props.buttonTitle) {
    return (
      <Link className={styles.actionButton} to={props.buttonLink}>
        {props.buttonTitle}
      </Link>
    )
  }
  return null
}

const Error = (props: Props) => {
  let iconClass = styles.icon
  switch (props.icon) {
    case 'account':
      iconClass = styles.iconAccount
      break
    case 'ticket':
      iconClass = styles.iconTicket
      break
    case 'beneficiary':
      iconClass = styles.iconBeneficiary
      break
  }

  return (
    <section className={styles.contentContainer}>
      <section className={styles.section}>
        <div className={iconClass}></div>
        <h3 className={styles.errorTitle}>{props.title}</h3>
        <p className={styles.errorMessage}>{props.message}</p>
        <div className={styles.column}>
          <NavLink to="/contacts">
            <div className={styles.actionButton}>Back to Dashboard</div>
          </NavLink>
          <Destination {...props} />
        </div>
      </section>
    </section>
  )
}

export default Error
