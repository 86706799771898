// @flow
import React from 'react'
import Row from 'components/References/Row'
import formatters from 'lib/formatters'
import styles from './styles'

type Props = {
  account_number: String,
  amount: number,
  beneficiary_name: String,
  deposit_date: String,
  deposit_type: String,
}

export const FundCheckLogRef = (props: Props) => {
  const {
    account_number,
    amount,
    beneficiary_name,
    deposit_date,
    deposit_type,
  } = props
  let checkNumber = null
  return (
    <div className={styles.cardMarginBot}>
      <h5 className={styles.sectionTitle}>Check Log</h5>
      <div className={styles.sectionNoMargin}>
        <Row label='Beneficiary Name' val={beneficiary_name} full={true} />
        <Row label='Account Number' val={account_number} full={true} />
        {checkNumber}
        <Row
          label='Deposit Amount'
          val={formatters.money(amount || 0)}
          full={true}
        />
        <Row
          label='Date'
          val={formatters.date(deposit_date)}
          full={true}
        />
      </div>
    </div>
  )
}
