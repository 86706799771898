// @flow
import React from 'react'
import { connect } from 'react-redux'
import { Template } from './Template'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  location: Object,
}

class AccountStatementsView extends React.Component {
  props: Props

  constructor(props) {
    super(props)
    this.state = {
      statements: [],
    }
  }

  fetchDataFromLocalStorage() {
    const json = localStorage.getItem(0)
    const statements = JSON.parse(json)
    this.setState({ statements: statements })
  }

  componentDidMount() {
    const id = this.props.location.query.get('id')
    document.title = `Batch ${id}`

    this.fetchDataFromLocalStorage()
  }

  render() {
    return <Template statements={this.state.statements} />
  }
}

export default withRouter(connect()(AccountStatementsView))
