import React from 'react'
// import xeroLogo from 'styles/img/xero-new-tab.png'
import styles from './styles'
import { IconXero } from '../Icons/IconXero'

type Props = {
  xeroUrl: string,
  description: string,
}

const XeroLink = ({ xeroUrl, description }: Props) => (
  <div className={styles.container}>
    <a
      href={xeroUrl}
      className={styles.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {/* <img src={xeroLogo} className={styles.img} alt="Open in Xero" /> */}
      <IconXero />
      {description && <span className={styles.description}>{description}</span>}
    </a>
  </div>
)

export default XeroLink
