// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__buttons___qroNe {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.styles__cardMarginBot___Mke_o {
}

.styles__sectionNoMargin___LQz8a {
}

.styles__infoText___yWvWH {
}

.styles__button___D2XEW {
}

.styles__buttonSecondary___EVLjO {
}

.styles__buttonDanger___MVJLQ {
  margin-right: 20px;
}

.styles__buttonLink___bNT6T {
  margin-right: 20px;
}

.styles__buttonLocalSecondary___C0oaD {
}

.styles__errorWrapper___bpjd0 {
}

.styles__form___Sna21 {
}

.styles__formItem____gJJn {
}

.styles__label___Qg_F6 {
}

.styles__removeButton___LJ7y9 {
}

.styles__section___HBQtX {
}

.styles__sectionSmall___VsmY9 {
}

.styles__submit___r0oWd {
}

.styles__textarea___g6aua {
}

.styles__textareaLarge___bbW1e {
  min-height: 200px;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Tickets/GPTAccountCreation/styles.css"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,kBAAkB;AACpB;;AAEA;EAEE,kBAAkB;AACpB;;AAEA;AAGA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA;;AAEA;EAEE,iBAAiB;AACnB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.buttons {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.cardMarginBot {\n  composes: cardMarginBot from helpers;\n}\n\n.sectionNoMargin {\n  composes: sectionNoMargin from helpers;\n}\n\n.infoText {\n  composes: infoText from helpers;\n}\n\n.button {\n  composes: buttonSecondary from helpers;\n}\n\n.buttonSecondary {\n  composes: buttonSecondary from helpers;\n}\n\n.buttonDanger {\n  composes: buttonDanger from helpers;\n  margin-right: 20px;\n}\n\n.buttonLink {\n  composes: button from helpers;\n  margin-right: 20px;\n}\n\n.buttonLocalSecondary {\n  composes: buttonLocal from helpers;\n  composes: buttonSecondary from helpers;\n}\n\n.errorWrapper {\n  composes: errorWrapper from helpers;\n}\n\n.form {\n  composes: form from helpers;\n}\n\n.formItem {\n  composes: formItem from helpers;\n}\n\n.label {\n  composes: label from helpers;\n}\n\n.removeButton {\n  composes: removeButton from helpers;\n}\n\n.section {\n  composes: section from helpers;\n}\n\n.sectionSmall {\n  composes: sectionSmall from helpers;\n}\n\n.submit {\n  composes: button from helpers;\n}\n\n.textarea {\n  composes: textarea from helpers;\n}\n\n.textareaLarge {\n  composes: textarea from helpers;\n  min-height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"buttons": `styles__buttons___qroNe`,
	"cardMarginBot": `styles__cardMarginBot___Mke_o ${___CSS_LOADER_ICSS_IMPORT_0___.locals["cardMarginBot"]}`,
	"sectionNoMargin": `styles__sectionNoMargin___LQz8a ${___CSS_LOADER_ICSS_IMPORT_0___.locals["sectionNoMargin"]}`,
	"infoText": `styles__infoText___yWvWH ${___CSS_LOADER_ICSS_IMPORT_0___.locals["infoText"]}`,
	"button": `styles__button___D2XEW ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonSecondary"]}`,
	"buttonSecondary": `styles__buttonSecondary___EVLjO ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonSecondary"]}`,
	"buttonDanger": `styles__buttonDanger___MVJLQ ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonDanger"]}`,
	"buttonLink": `styles__buttonLink___bNT6T ${___CSS_LOADER_ICSS_IMPORT_0___.locals["button"]}`,
	"buttonLocalSecondary": `styles__buttonLocalSecondary___C0oaD ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonLocal"]} ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonSecondary"]}`,
	"errorWrapper": `styles__errorWrapper___bpjd0 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["errorWrapper"]}`,
	"form": `styles__form___Sna21 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["form"]}`,
	"formItem": `styles__formItem____gJJn ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItem"]}`,
	"label": `styles__label___Qg_F6 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`,
	"removeButton": `styles__removeButton___LJ7y9 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["removeButton"]}`,
	"section": `styles__section___HBQtX ${___CSS_LOADER_ICSS_IMPORT_0___.locals["section"]}`,
	"sectionSmall": `styles__sectionSmall___VsmY9 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["sectionSmall"]}`,
	"submit": `styles__submit___r0oWd ${___CSS_LOADER_ICSS_IMPORT_0___.locals["button"]}`,
	"textarea": `styles__textarea___g6aua ${___CSS_LOADER_ICSS_IMPORT_0___.locals["textarea"]}`,
	"textareaLarge": `styles__textareaLarge___bbW1e ${___CSS_LOADER_ICSS_IMPORT_0___.locals["textarea"]}`
};
export default ___CSS_LOADER_EXPORT___;
