// @flow
import React from 'react'
import { reduxForm } from 'redux-form'
import { Field } from 'redux-form'
import type { Form } from 'lib/types'
import FormButtons from 'components/FormButtons'
import { renderInputField } from 'components/FormFields'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { createLineItem } from 'lib/subaccount/actions'
import cleaners from 'lib/form/cleaners'
import validators from 'lib/form/validators'
import normalizers from 'lib/field/normalizers'
import styles from './styles'

type Props = {
  dispatch: Function,
  form: Form,
  number: number,
  subnumber: number,
  afterCreate: Function,
}

const onSubmit = (data, dispatch) => {
  dispatch(setLoad())
  return dispatch(
    createLineItem(data.number, data.subnumber, cleaners.lineItem(data)))
    .then((result) => {
      dispatch(setBanner('Transaction successfully recorded.'))
      data.afterCreate(result)
    })
    .catch(() => {
      dispatch(setBanner('Transaction not saved!', 'danger'))
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
}

const Component = ({ number, subnumber, afterCreate, form }: Props) => {
  const handleSubmit = form.handleSubmit((data, dispatch) => {
    return onSubmit(
      {...data, number: number, subnumber: subnumber, afterCreate: afterCreate},
      dispatch
    )
  })

  const handleCancel = (() => {
    return form.dispatch(unsetModal())
  })

  return (
      <section>
        <h4 className="section">Add Transaction</h4>
        <form
          onSubmit={handleSubmit}
        >
            <div className={styles.section}>
              <div className={styles.formItemQuarter}>
                <Field
                  component={renderInputField}
                  label='Date'
                  name='date'
                  type='date'
                  autoFocus
                />
              </div>
              <div className={styles.formItemQuarter}>
                <Field
                  component={renderInputField}
                  name='amount'
                  normalize={normalizers.float}
                  placeholder='0.00'
                  label='Amount'
                />
              </div>
              <div className={styles.formItemHalf}>
                <Field
                  component={renderInputField}
                  name='memo'
                  label='Memo'
                  type='text'
                />
              </div>
            </div>
            <FormButtons {...form} onCancel={handleCancel} />
          </form>
        </section>
  )
}

const LineItemForm = reduxForm({
  form: 'simple',
  propNamespace: 'form',
  validate: validators.lineItem,
})(Component)

export default LineItemForm
