// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__formItemMono___JEFSq {
}

.styles__input___Zwoq5 {
}

.styles__label___KfuV0 {
}
`, "",{"version":3,"sources":["webpack://./universal/components/DateRangeSelection/styles.css"],"names":[],"mappings":"AAEA;AAEA;;AAEA;AAEA;;AAEA;AAEA","sourcesContent":["@value helpers: 'styles/helpers.css';\n\n.formItemMono {\n  composes: formItemMono from helpers;\n}\n\n.input {\n  composes: input from helpers;\n}\n\n.label {\n  composes: label from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `'styles/helpers.css'`,
	"formItemMono": `styles__formItemMono___JEFSq ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItemMono"]}`,
	"input": `styles__input___Zwoq5 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"label": `styles__label___KfuV0 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`
};
export default ___CSS_LOADER_EXPORT___;
