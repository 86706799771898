// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconSwitch__checkIcon___hN3M0 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]};
}

.IconSwitch__timesIcon___ty4S1 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]};
}

`, "",{"version":3,"sources":["webpack://./universal/components/IconSwitch/IconSwitch.css"],"names":[],"mappings":"AAGA;EACE,mDAAc;AAChB;;AAEA;EACE,mDAAY;AACd","sourcesContent":["@value colors: \"styles/colors.css\";\n@value gtGreen, gtRed from colors;\n\n.checkIcon {\n  color: gtGreen;\n}\n\n.timesIcon {\n  color: gtRed;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"styles/colors.css"`,
	"gtGreen": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtGreen"]}`,
	"gtRed": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtRed"]}`,
	"checkIcon": `IconSwitch__checkIcon___hN3M0`,
	"timesIcon": `IconSwitch__timesIcon___ty4S1`
};
export default ___CSS_LOADER_EXPORT___;
