// @flow
import React from 'react'
import { Report } from './Report'

type Props = {
  statements: Object[],
}

export const Template = ({statements, }: Props) => {
  return (
    <Report statements={statements} />
  )
}
