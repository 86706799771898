// @flow
import Main from './components/Main'
import Component from './components/Edit'
export default (store) => ({
  path: 'closings',
  children: [
    {
      element: <Main />,
      index: true,
    },
    {
      path: 'edit/:id',
      element: <Component />,
    },
  ],
})
