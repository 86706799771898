// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__formItemQuarter___AIXk1 {
  width: 25%;
  margin-bottom: 20px;
}

.styles__formItemQuarter___AIXk1 input {
  margin-top: 10px;
  top: 2px;
}

.styles__input___r819m {
}

.styles__inputLabelGroup___puHa2 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.styles__inputLabelGroup___puHa2 input {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 0;
  top: 2px;
  position: relative;
}

.styles__inputLabelGroup___puHa2 textarea {
  height: 180px;
  line-height: 22px;
}

.styles__inputLabelGroup___puHa2 label {
  font-size: 14px;
}

.styles__label___KkBXX {
  text-align: left;
  margin-bottom: 10px;
}

.styles__section___bgIQE {
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Closings/components/Edit/ClosingDateForm/styles.css"],"names":[],"mappings":"AAEA;EAEE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,QAAQ;AACV;;AAEA;AAEA;;AAEA;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EAEE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;AAEA","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.formItemQuarter {\n  composes: formItemQuarter from helpers;\n  width: 25%;\n  margin-bottom: 20px;\n}\n\n.formItemQuarter input {\n  margin-top: 10px;\n  top: 2px;\n}\n\n.input {\n  composes: input from helpers;\n}\n\n.inputLabelGroup {\n  composes: clearfix from helpers;\n  margin-top: 10px;\n  margin-bottom: 20px;\n}\n\n.inputLabelGroup input {\n  margin-top: 10px;\n  margin-right: 10px;\n  margin-left: 0;\n  top: 2px;\n  position: relative;\n}\n\n.inputLabelGroup textarea {\n  height: 180px;\n  line-height: 22px;\n}\n\n.inputLabelGroup label {\n  font-size: 14px;\n}\n\n.label {\n  composes: label from helpers;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.section {\n  composes: section from helpers;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"formItemQuarter": `styles__formItemQuarter___AIXk1 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["formItemQuarter"]}`,
	"input": `styles__input___r819m ${___CSS_LOADER_ICSS_IMPORT_0___.locals["input"]}`,
	"inputLabelGroup": `styles__inputLabelGroup___puHa2 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["clearfix"]}`,
	"label": `styles__label___KkBXX ${___CSS_LOADER_ICSS_IMPORT_0___.locals["label"]}`,
	"section": `styles__section___bgIQE ${___CSS_LOADER_ICSS_IMPORT_0___.locals["section"]}`
};
export default ___CSS_LOADER_EXPORT___;
