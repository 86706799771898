// @flow
import React from 'react'
import styles from './styles'
import formatters from 'lib/formatters'
import CustomStepper, { CustomStepperProps } from 'components/CustomStepper'

type BodyProps = {
  children?: Element,
  history: Array<Object>,
  reference: Element,
  bodyAltPaddingTop?: string,
}

type HeaderProps = {
  meta: Object,
  subheader: string,
  title: string,
  stepper: CustomStepperProps,
  id?: string | number,
}

type Props = {
  reference: Element,
  children: Element,
  header: HeaderProps,
  history: Array<Object>,
  visible: boolean,
  bodyAlt?: boolean,
  bodyAltPaddingTop?: string,
}

const Body = ({ children, reference, history }: BodyProps) => {
  return (
    <section className={styles.body}>
      <div className={styles.bodyLeft}>{children}</div>
      <div className={styles.bodyRight}>
        <div className={styles.refWrap}>
          {reference}
          {renderHistory(history)}
        </div>
      </div>
    </section>
  )
}

const BodyAlt = ({ children, reference, history, bodyAltPaddingTop }: BodyProps) => {
  return (
    <section className={`${styles.bodyAlt} ${bodyAltPaddingTop}`}>
      <div className={styles.bodyLeftAlt}>{children}</div>
      {(reference || renderHistory(history)) && (
        <div className={styles.bodyRightAlt}>
          <div className={styles.refWrap}>
            {reference}
            {renderHistory(history)}
          </div>
        </div>
      )}
    </section>
  )
}

const renderHistory = (history) => {
  if (!history) return null

  const events = history.map((event, idx) => {
    const timestamp = formatters.eventTimestamp(event.inserted_at)
    let eventStyle = styles.eventWrapper
    if (event.description === 'rejected') {
      eventStyle = styles.eventRejected
    } else if (['approved', 'closed'].includes(event.description)) {
      eventStyle = styles.eventPositive
    }
    return (
      <li key={`history${idx}`} className={eventStyle}>
        <div className={styles.meta}>
          <div className={styles.user}>{event.user.split('@', 1)}</div>
          <div className={styles.timeStamp}>{timestamp}</div>
        </div>
        <div className={styles.event}>{event.description}</div>
      </li>
    )
  })

  return (
    <div className={styles.card}>
      <h5 className={styles.sectionTitle}>Ticket History</h5>
      <ul className={styles.events}>{events}</ul>
    </div>
  )
}

const renderHeaderMeta = (meta) => {
  if (!meta) return null

  return <h5>Ticket Status: {meta.status}</h5>
}

const Header = ({ title, subheader, meta, stepper, id }: HeaderProps) => {
  subheader = subheader ? subheader + ': ' : null
  return (
    <section className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.headerLeft}>
          <h5 id="test-hook-ticket-header" className={styles.headerLeftTitle}>
            {subheader} <span className={styles.headerInnerTitle}>{title}</span>
          </h5>
          {id && <p>REQUEST ID {id}</p>}
        </div>
        {renderHeaderMeta(meta)}
      </div>
      <div className={styles.headerStepper}>
        {stepper?.steps && <CustomStepper steps={stepper.steps} activeStep={stepper.activeStep} />}
      </div>
    </section>
  )
}

const Ticket = (props: Props) => {
  if (props.disable) {
    return <>{props.children}</>
  }
  return (
    <section>
      <Header {...props.header} />
      {props?.bodyAlt ? (
        <BodyAlt
          reference={props.reference}
          history={props.history}
          bodyAltPaddingTop={props.bodyAltPaddingTop}
        >
          {props.children}
        </BodyAlt>
      ) : (
        <Body reference={props.reference} history={props.history}>
          {props.children}
        </Body>
      )}
    </section>
  )
}

export default Ticket
