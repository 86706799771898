// @flow
import React from 'react'
import formatters from 'lib/formatters'
import type { Contact } from 'lib/types'
import Row from './Row'
import styles from './styles'
import { Link } from 'react-router-dom'

type Props = {
  contact: Contact,
  dob: String,
  onRelationshipChange: Function,
  relationship: String,
  relationshipId: Number,
  relationshipOptions: [String],
  errors?: Object,
  remove?: Element,
};

const relationshipDiv = (
  relationshipId,
  contactId,
  relationship,
  relationshipOptions = [],
  onRelationshipChange = null
) => {
    if (relationship) {
      const selectOptions = relationshipOptions.map((arr, idx) =>
          <option value={arr[0]} key={idx}>
            {arr[1]}
          </option>
      )

      const select = (<select
          className={styles.select}
          name="relationship_type"
          value={relationship}
          onChange={(event) =>
            onRelationshipChange(relationshipId, contactId, event.target.value)
          }
        >
          {selectOptions}
        </select>
      )

      const error = (
        relationship.startsWith('unspecified') ? 'required' : null
      )

      return (
        <Row
          label='Relationship'
          error={error}
          val={select}
        />
      )
    } else {
        return (<div></div>)
    }
}

const calculateAge = (dob_string) => {
  const dob = new Date(dob_string)
  const diff_ms = Date.now() - dob.getTime()
  const age_dt = new Date(diff_ms)

  return Math.abs(age_dt.getUTCFullYear() - 1970)
}

const minorDiv = ({ dob = null }: Props) => {
  if (dob && calculateAge(dob) < 18) {
    return (
      <Row
        label='Minor'
        val='YES'
        status='valueAlert'
      />
    )
  } else {
    return (<div></div>)
  }
}

const companyDiv = (contact) => {
  if (contact.company_name) {
    return (
      <Row
        label='Company Name'
        val={contact.company_name}
      />
    )
  } else {
    return (<div></div>)
  }
}

class ContactRef extends React.Component {
  props: Props
  constructor(props) {
    super(props)
  }
  _streetAddress({ street_1, street_2 }) {
    return street_2 ? `${street_1} ${street_2}` : street_1
  }
  _main() {
    const {
      contact,
      onRelationshipChange = null,
      relationship = null,
      relationshipId = 0,
      relationshipOptions = [],
      errors = {},
      remove = null,
    } = this.props

    let contactErrors = {}
    if (errors && errors.hasOwnProperty(contact.id)) {
      contactErrors = errors[contact.id]
    }

    const editButton = (
      <Link
        to={`/contacts/${contact.id}/edit`}
        className={styles.basicEdit}
      ></Link>
    )

    return (
      <div className={styles.formRefContent}>
        <div className={styles.formRefSection}>
          <Row
            label='Name'
            val={contact.name}
            error={contactErrors.name}
            bold={true}
          />
          {relationshipDiv(
            relationshipId,
            contact.id,
            relationship,
            relationshipOptions,
            onRelationshipChange
          )}
          {minorDiv(contact)}
          <Row
            label='Email'
            val={contact.email}
            error={contactErrors.email}
          />
          {companyDiv(contact)}
        </div>
        <div className={styles.formRefSection}>
          <Row
            label='Cell'
            val={formatters.phone(contact.phone_numbers.cell_phone)}
            mono={true}
            error={contactErrors.phone_numbers}
          />
          <Row
            label='Day'
            val={formatters.phone(contact.phone_numbers.day_phone)}
            mono={true}
            error={contactErrors.phone_numbers}
          />
          <Row
            label='Evening'
            val={formatters.phone(contact.phone_numbers.evening_phone)}
            mono={true}
            error={contactErrors.phone_numbers}
          />
          <Row
            label='Fax'
            val={formatters.phone(contact.phone_numbers.fax)}
            mono={true}
            error={contactErrors.phone_numbers}
          />
        </div>
        <div className={styles.formRefSection}>
          <Row
            label="Street 1"
            val={this._streetAddress(contact)}
            error={contactErrors.street_1}
          />
          <Row
            label="City"
            val={contact.city}
            error={contactErrors.city}
          />
          <Row
            label="State"
            val={contact.state}
            error={contactErrors.state}
          />
          <Row
            label="Zip"
            val={contact.zip}
            error={contactErrors.zip}
          />
        </div>
        <div className={styles.formRefButtons}>
          {remove}
          {editButton}
        </div>
      </div>
    )
  }

  render() {
    const {
      contact,
    } = this.props
    if (!contact) return null

    return (
      <section className={styles.contactBasicFormRef}>
        {this._main()}
      </section>
    )
  }
}

export default ContactRef
