import React from 'react'
import styles from './styles.css'

const CheckboxSelect = ({ options, selectedOptions, onChange, checkColor }) => {
  const handleCheckboxChange = (optionId) => {
    const updatedSelectedOptions = selectedOptions?.includes(optionId)
      ? selectedOptions?.filter((id) => id !== optionId)
      : [...(selectedOptions ?? []), optionId]

    onChange(updatedSelectedOptions)
  }

  const getCheckedColor = () => {
    let style = styles.red
    switch (checkColor) {
      case 'red':
        return (style = styles.red)
      case 'green':
        return (style = styles.green)
      default:
        return style
    }
  }

  return (
    <>
      {options?.map((option) => (
        <div key={option.id} className={`${styles.inputLabelGroup} ${getCheckedColor()}`}>
          <input
            id={option.id}
            name={option.id}
            type="checkbox"
            checked={selectedOptions?.includes(option.id)}
            onChange={() => handleCheckboxChange(option.id)}
          />
          <label htmlFor={option.id}>{option.label}</label>
        </div>
      ))}
    </>
  )
}

export default CheckboxSelect
