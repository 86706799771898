// @flow
import React from 'react'
import formatters from 'lib/formatters'
import { Link } from 'react-router-dom'
import { Table, Row, Cell } from 'components/Table'
import Ref from 'components/References/Ref'
import { SUBACCOUNT_TYPES } from 'lib/constants'

type Subaccount = {
  balance: number,
  number: string,
  subaccount_type: string
}

type Props = {
  accountNumber: string,
  subaccounts: [Subaccount]
}

const BalanceRef = (props: Props) => {
  let dataRows = []

  const {
    accountNumber,
    subaccounts
  } = props

  const renderSubaccountLink = (subaccount_type, number) => {
    var link

      if (
        ['asset', 'individual_investment', 'msa'].includes(subaccount_type)
      ) {
        link = <Link
          to={`/accounts/${accountNumber}/subaccounts/${number}`}>{number}
        </Link>
      } else {
        link = <Link
          to={`/accounts/${number}`}>{number}
        </Link>
      }

      return link
  }

  subaccounts.map((subaccount, idx) => {
    const {
      balance,
      number,
      subaccount_type,
    } = subaccount

    const subaccount_title = SUBACCOUNT_TYPES[subaccount_type]

    dataRows.push(
      <Row key={`subaccount${idx}`}>
        <Cell
          value={renderSubaccountLink(subaccount_type, number)}
          accountNumber={accountNumber}
          number={number}
        />
        <Cell value={subaccount_title} />
        <Cell value={formatters.money(balance)} mono={true}/>
      </Row>,
    )
  })

  let noResults = dataRows.length ? null : 'No subaccounts'

  const headers = [
    { value: 'Number' },
    { value: 'Type' },
    { value: 'Balance', type: 'mono' },
  ]

  return (
    <Ref
      title="Account Balances"
      preview={
        <Table
        headers={headers}
        tableSize="large"
        tableType="read"
        noResults={noResults}
        >
        {dataRows}
    </Table>
      }
      details={false}
    />
  )
}

export default BalanceRef
