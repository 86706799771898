// @flow
import React from 'react'

import formatters from 'lib/formatters'
import type { Contact } from 'lib/types'
import MergeContactField from './MergeContactField/MergeContactField'
import styles from './styles'

type Props = {
  canCopy?: boolean,
  copyField?: Function,
  contact: Contact,
  errors?: Object,
  secondaryContact?: Object,
}

const defaultSecondaryContact = { phone_numbers: {} }

class MergeContactFormRef extends React.Component<Props> {
  static defaultProps = {
    errors: {},
    secondaryContact: defaultSecondaryContact,
    canCopy: false,
    copyField: () => {},
  }

  props: Props

  _addInfo() {
    const { canCopy, copyField, contact, secondaryContact, errors } = this.props
    const hasSecondaryContact = secondaryContact !== defaultSecondaryContact
    const formatAge = ({ dob }) =>
      dob === undefined ? undefined : formatters.age(formatters.date(dob))
    const formatPhotoID = ({ photo_id_key, has_photo_id }, isPrimaryContact) =>
      isPrimaryContact || hasSecondaryContact
        ? photo_id_key || has_photo_id ? 'Yes' : 'No'
        : undefined
    const formatSSN = ({ ssn_verifier }, isPrimaryContact) =>
      isPrimaryContact || hasSecondaryContact
        ? ssn_verifier ? 'Yes' : 'No'
        : undefined
    const formatDOB = ({ dob }) =>
      dob === undefined ? undefined : formatters.date(dob)
    const formatPhone = ({ phone_numbers: phone_numbers }, phone_type) =>
      phone_numbers[phone_type] === undefined
        ? undefined
        : formatters.phone(phone_numbers[phone_type])

    return (
      <div className={styles.formRefContent}>
        <MergeContactField
          label="Name"
          field="name"
          val={contact.name}
          error={errors.name}
          secondaryVal={secondaryContact.name}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="Age"
          val={formatAge(contact)}
          mono={true}
          secondaryVal={formatAge(secondaryContact)}
          canCopy={false}
          copyField={copyField}
        />
        <MergeContactField
          label="DOB"
          field="dob"
          val={formatDOB(contact)}
          mono={true}
          error={errors.dob}
          secondaryVal={formatDOB(secondaryContact)}
          canCopy={canCopy}
          copyField={({ field }) =>
            copyField({ field, secondaryVal: secondaryContact.dob })
          }
        />
        <MergeContactField
          label="Photo ID?"
          val={formatPhotoID(contact, true)}
          error={errors.photo_id_id}
          secondaryVal={formatPhotoID(secondaryContact, false)}
          canCopy={false}
          copyField={copyField}
        />
        <MergeContactField
          label="SSN?"
          val={formatSSN(contact, true)}
          error={errors.ssn}
          secondaryVal={formatSSN(secondaryContact, false)}
          canCopy={false}
          copyField={copyField}
        />
        <MergeContactField
          label="Cell"
          field={['phone_numbers', 'cell_phone']}
          mono={true}
          error={errors.phone_numbers}
          val={formatPhone(contact, 'cell_phone')}
          secondaryVal={formatPhone(secondaryContact, 'cell_phone')}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="Day"
          field={['phone_numbers', 'day_phone']}
          val={formatPhone(contact, 'day_phone')}
          secondaryVal={formatPhone(secondaryContact, 'day_phone')}
          mono={true}
          error={errors.phone_numbers}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="Evening"
          field={['phone_numbers', 'evening_phone']}
          val={formatPhone(contact, 'evening_phone')}
          secondaryVal={formatPhone(secondaryContact, 'evening_phone')}
          mono={true}
          error={errors.phone_numbers}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="Fax"
          field={['phone_numbers', 'fax']}
          val={formatPhone(contact, 'fax')}
          secondaryVal={formatPhone(secondaryContact, 'fax')}
          mono={true}
          error={errors.phone_numbers}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="Email"
          field="email"
          val={contact.email}
          error={errors.email}
          secondaryVal={secondaryContact.email}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="Street 1"
          field="street_1"
          val={contact.street_1}
          error={errors.street_1}
          secondaryVal={secondaryContact.street_1}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="Street 2"
          field="street_2"
          val={contact.street_2}
          secondaryVal={secondaryContact.street_2}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="City"
          field="city"
          val={contact.city}
          error={errors.city}
          secondaryVal={secondaryContact.city}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="State"
          field="state"
          val={contact.state}
          error={errors.state}
          secondaryVal={secondaryContact.state}
          canCopy={canCopy}
          copyField={copyField}
        />
        <MergeContactField
          label="Zip"
          field="zip"
          val={contact.zip}
          error={errors.zip}
          secondaryVal={secondaryContact.zip}
          canCopy={canCopy}
          copyField={copyField}
        />
      </div>
    )
  }

  renderGlobalErrors = () => {
    const { _error } = this.props.errors

    if (!_error) {
      return null
    }

    const errors = Array.isArray(_error) ? _error : [_error]
    const headerText = 'Please resolve the following errors:'

    return (
      <div className={styles.globalErrors}>
        <h4>{headerText}</h4>
        <ul>{errors.map((error) => <li field={error}>{error}</li>)}</ul>
      </div>
    )
  }

  render() {
    if (!this.props.contact) return null

    return (
      <section className="marginBottom">
        {this.renderGlobalErrors()}
        {this._addInfo()}
      </section>
    )
  }
}

export default MergeContactFormRef
