// @flow
import React from 'react'
import formatters from 'lib/formatters'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import queryString from 'query-string'
import { httpGet } from 'lib/http'
import { Table, Row, Cell } from 'components/Table'
import { Link } from 'react-router-dom'
import styles from './styles.css'

type Props = {
  lineItems: Array<Object>,
  accountNumber: string,
  shortCode: string,
  trustName: string,
}

type DataProps = {
  item: Object,
  index: number,
}

const renderData = (
  { accountNumber, item, index, shortCode, trustName }: DataProps) => {
  const { amount, date, description, reference, source_id, source_type } = item
  const credit = amount > 0 ? formatters.money(amount) : ' '
  const debit = amount < 0 ? formatters.money(amount * -1) : ' '
  const formattedDescription =
    formatters.transactionDescription(description, date)

  const isDeposit = [
    'Check Received',
    'Cashier’s Check Received',
    'Direct Deposit Received',
    'Wire Received',
  ].includes(description)

  const hasVerificationImage = reference && reference.length > 0

  const showReceipt = ({ amount, beneficiary, date, source_id }) => {
    amount = amount / 100
    const params = queryString.stringify({
      date,
      amount,
      beneficiary,
      source_id,
      trustName,
    })
    window.open(`/accounts/${accountNumber}/trustee-receipt?${params}`)
  }

  const showVerification = ({ reference }) => {
    const url = `/admin/deposits/${reference}/verification`

    httpGet(url).then(({url: s3_url}) => {
      window.open(s3_url)
    })
  }

  const renderTrusteeReceiptButton = (item) => {
    return (
      <div title="Trustee Receipt" className={styles.receiptButton}>
        <FontAwesomeIcon
          onClick={() => showReceipt(item)}
          icon="receipt"
          size="lg"
        />
      </div>
    )
  }

  const renderDepositVerificationButton = (item) => {
    return (
      <div title="Deposit Verification" className={styles.infoButton}>
        <FontAwesomeIcon
          onClick={() => showVerification(item)}
          icon="info"
          size="1x"
        />
      </div>
    )
  }

  const renderXeroBankTransactionLink = () => {
    const url = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${shortCode}`
      + `&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=${source_id}`
    return (
      <Link
        to={url}
        target="_blank"
      >
        {formattedDescription}
      </Link>
    )
  }

  const renderXeroAccountsReceiveableLink = () => {
    const url = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${shortCode}`
      + `&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${source_id}`
    return (
      <Link
        to={url}
        target="_blank"
      >
        {formattedDescription}
      </Link>
    )
  }

  const renderXeroAccountsPayableLink = () => {
    const url = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${shortCode}`
      + `&redirecturl=/AccountsPayable/View.aspx?InvoiceID=${source_id}`
    return (
      <Link
        to={url}
        target="_blank"
      >
        {formattedDescription}
      </Link>
    )
  }

  const renderDescriptionCell = () => {
    if (source_type == 'ACCPAY') {
      return renderXeroAccountsPayableLink()
    } else if (['CASHREC','CASHPAID'].includes(source_type)) {
      return renderXeroBankTransactionLink()
    } else if (source_type == 'ACCREC') {
      return renderXeroAccountsReceiveableLink()
    } else {
      return formattedDescription
    }
  }

  return {
    isDeposit,
    Row: (
      <Row key={index}>
        <Cell value={formatters.date(date)} />
        <Cell className={styles.tdDescriptionCell}
          value={renderDescriptionCell()} />
        <Cell value={debit} mono={true} />
        <Cell value={credit} mono={true} />
        <Cell
          value={isDeposit ? renderTrusteeReceiptButton(item) : true}
          mono={true}
        />
        <Cell
          value={
            isDeposit && hasVerificationImage
            ? renderDepositVerificationButton(item)
            : true
          }
          mono={true}
        />
      </Row>
    ),
  }
}

const GPTLineItemsRef = ({
  accountNumber, lineItems, shortCode, trustName }: Props) => {
  const headers = [
    { value: 'Date' },
    { value: 'Description' },
    { value: 'Debit', type: 'mono' },
    { value: 'Credit', type: 'mono' },
  ]
  let noResults = 'No Transaction History'
  const { rows, hasDeposit } = lineItems.reduce(
    ({ hasDeposit, rows }, item, index) => {
      const { isDeposit, Row } =
        renderData({ accountNumber, item, index, shortCode, trustName })
      rows.push(Row)
      return { hasDeposit: hasDeposit || isDeposit, rows }
    },
    { hasDeposit: false, rows: [] },
  )
  if (hasDeposit) {
    headers.push({ value: true, type: 'mono' })
    headers.push({ value: true, type: 'mono' })
  }

  return (
    <Table
      headers={headers}
      isOpen={true}
      title="Account Transaction History"
      tableSize="infinite"
      tableType="read"
      noResults={noResults}
      hasDeposit={hasDeposit}
    >
      {rows}
    </Table>
  )
}

export default GPTLineItemsRef
