// @flow
import React from 'react'
import styles from './styles'
import formatters from 'lib/formatters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, Row, Cell } from '../Table'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import dateUtil from 'lib/dateUtil'
import { Link } from 'react-router-dom'

type Props = {
  closings: [Object],
  events_due: [Object],
  searchInput: String,
  onClearClick: Function,
  onExportClick: Function,
  onSearchInputChange: Function,
}

export const Template = (props: Props) => {
  const { closings, events_due } = props
  const renderClosingRow = (closing, idx) => {
    const { number, name, date_of_death, date, event, balances, open_date } =
      closing

    const filtered_dod =
      date_of_death == '1900-01-01' ? null : formatters.date(date_of_death)

    const link = <Link to={`/closings/edit/${number}`}>{number}</Link>

    const { total, subaccounts } = balances

    return (
      <Row key={`row${idx}`}>
        <Cell value={link} />
        <Cell value={name} />
        <Cell value={filtered_dod} />
        <Cell value={event} />
        <Cell value={formatters.date(date)} />
        <Cell value={formatters.money(subaccounts)} />
        <Cell value={formatters.money(total)} />
        <Cell value={formatters.date(open_date)} />
      </Row>
    )
  }

  const renderClosingTable = () => {
    let rows = closings.map(renderClosingRow)
    let tableHeader = 'Closing Accounts (' + rows.length + ')'

    return (
      <div className={styles.eventsTable}>
        <Table
          title={tableHeader}
          headers={[
            'Number',
            'Beneficiary',
            'Date of Death',
            'Last Action',
            'Last Action Date',
            'Subaccounts Balance',
            'Total Balance',
            'Open Date',
          ]}
          tableSize="infinite"
          tableType="read"
        >
          {rows}
        </Table>
      </div>
    )
  }

  const dateDue = (ed) => {
    const text = formatters.date(ed.date_due)

    if (dateUtil.isDateBeforeToday(ed.date_due) && !ed.date_completed) {
      return <span style={{ color: 'red' }}>{text}</span>
    } else {
      return text
    }
  }

  const link = (number) => {
    return <Link to={`/closings/edit/${number}`}>{number}</Link>
  }

  const renderEventsDueRow = (ed, idx) => (
    <Row key={idx}>
      <Cell value={dateDue(ed)} />
      <Cell value={link(ed.number)} />
      <Cell value={ed.event} />
    </Row>
  )

  const renderEventsDueRows = () =>
    events_due.map((ed, idx) => renderEventsDueRow(ed, idx))

  return (
    <section className={styles.contentContainer}>
      <h2 className={styles.sectionTitle}>Closing Management</h2>
      <div className={styles.fullScreen}>
        <Tabs defaultIndex={0} className="tabsContainer">
          <TabList className={[styles.tabList, 'react-tabs']}>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Closings List
            </Tab>
            <Tab className={[styles.buttonLocal, 'react-tabs__tab']}>
              Events Due
            </Tab>
          </TabList>
          <TabPanel>
            <div className={styles.row}>
              <section className={styles.inputWrapper}>
                <input
                  className={styles.searchInput}
                  type="string"
                  name="searchInput"
                  placeholder="Find account number"
                  value={props.searchInput}
                  onChange={props.onSearchInputChange}
                />
                {props.searchInput && (
                  <div
                    className={styles.clearIcon}
                    role="button"
                    onClick={props.onClearClick}
                  >
                    <FontAwesomeIcon size="lg" icon={['fas', 'times-circle']} />
                  </div>
                )}
                <a onClick={props.onExportClick} className={styles.exportLink}>
                  Export to CSV
                </a>
              </section>
            </div>
            <div className={styles.row}>{closings && renderClosingTable()}</div>
          </TabPanel>
          <TabPanel>
            <Table
              title="Events Due"
              headers={['Due', 'Number', 'Action']}
              tableSize="infinite"
            >
              {events_due && renderEventsDueRows()}
            </Table>
          </TabPanel>
        </Tabs>
      </div>
    </section>
  )
}
