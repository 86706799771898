// @flow
import React from 'react'
import styles from './styles'
import { Dialog } from 'components/Dialog'
import { Table, Row, Cell } from 'components/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SuspendAccountRef } from 'components/References'
import { Link } from 'react-router-dom'

type Props = {
  accounts: [Object],
  canSubmit: boolean,
  needsApproval: boolean,
  newStateDescription: String,
  toSuspendAccount: String,
  toUnsuspendAccount: String,
  onRemoveClicked: Function,
  handleAccountChange: Function,
  onSuspendClicked: Function,
  onCancelChange: Function,
  onApproveChange: Function,
  searchInput: String,
  onClearClick: Function,
  onSearchInputChange: Function,
}

export const Template = (props: Props) => {
  const { accounts } = props

  const toggleLink = (account) => (
    <div className={styles.remove}>
      <a
        title="Unsuspend Account"
        onClick={() => props.onRemoveClicked(account)}
      />
    </div>
  )

  const accountRows = (accounts) => {
    let rows = []

    accounts.map(({ number, name }) => {
      const accountLink = <Link to={`/accounts/${number}`}>{number}</Link>

      rows.push(
        <Row key={`idx${number}`}>
          <Cell value={accountLink} />
          <Cell value={name} />
          <Cell value={toggleLink(number)} />
        </Row>,
      )
    })

    return rows
  }

  const renderTable = () => {
    return (
      <div className={styles.accountsTable}>
        <Table
          title={`Suspended accounts (${accounts.length})`}
          headers={['Number', 'Name', 'Unsuspend']}
          tableSize="large"
          tableType="read"
        >
          {accountRows(accounts)}
        </Table>
      </div>
    )
  }

  return (
    <section className={styles.contentContainer}>
      <h2 className={styles.sectionTitle}>Suspended Accounts Management</h2>
      <div className={styles.row}>
        <section className={styles.inputWrapper}>
          <input
            className={styles.input}
            type="string"
            name="searchInput"
            placeholder="Find account number"
            value={props.searchInput}
            onChange={props.onSearchInputChange}
          />
          {props.searchInput && (
            <div
              className={styles.clearIcon}
              role="button"
              onClick={props.onClearClick}
            >
              <FontAwesomeIcon size="lg" icon={['fas', 'times-circle']} />
            </div>
          )}
        </section>
      </div>
      <div className={styles.row}>
        <section className={styles.twoThird}>
          {accounts && renderTable()}
        </section>
        <section className={styles.oneThird}>
          <SuspendAccountRef
            toSuspendAccount={props.toSuspendAccount || ''}
            handleAccountChange={props.handleAccountChange || null}
            canSubmit={props.canSubmit || false}
            onSuspendClicked={props.onSuspendClicked || null}
          />
        </section>
      </div>
      {props.needsApproval && (
        <Dialog
          cancelButtonText="Cancel"
          confirmButtonText="Yes"
          headerText={
            'Are you sure you want to ' + props.newStateDescription + '?'
          }
          onCancel={props.onCancelChange}
          onConfirm={props.onApproveChange}
        />
      )}
    </section>
  )
}
