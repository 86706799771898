// @flow
import React from 'react'
import { reduxForm } from 'redux-form'
import { create } from 'lib/contact/actions'
import cleaners from 'lib/form/cleaners'
import validators from 'lib/form/validators'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import type { Form } from 'lib/types'
import { Contact as ContactForm } from 'components/Forms'

type Props = {
  form: Form,
}

const Component = ({ form }: Props) => {
  return (
    <form onSubmit={form.handleSubmit}>
      <h3>Create New Contact</h3>
      <ContactForm form={form} />
    </form>
  )
}

export const enhanceNewContactForm = (callback?: Function) => {
  return reduxForm({
    form: 'simple',
    onSubmit: (data, dispatch) => {
      dispatch(setLoad())
      return dispatch(create(cleaners.contact(data)))
        .then((data) => {
          callback && callback(data)
          dispatch(setBanner('Contact successfully created.'))
        })
        .catch(() => {
          dispatch(setBanner('Contact unsuccessfully created.', 'danger'))
        })
        .then(() => {
          dispatch(unsetModal())
          dispatch(setUnload())
        })
    },
    propNamespace: 'form',
    validate: validators.contact,
  })(Component)
}

const NewContactForm = enhanceNewContactForm()

export default NewContactForm
