// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/ui.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__mainContainer___G7HrY {
  min-height: 100%;
  padding-top: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["headerHeight"]};
}
@media print {
  .styles__mainContainer___G7HrY {
    padding-top: 0;
}
  }
`, "",{"version":3,"sources":["webpack://./universal/layouts/CoreLayout/styles.css"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,yDAAyB;AAK3B;AAHE;EAJF;IAKI,cAAc;AAElB;EADE","sourcesContent":["@value headerHeight from \"styles/ui.css\";\n\n.mainContainer {\n  min-height: 100%;\n  padding-top: headerHeight;\n\n  @media print {\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerHeight": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["headerHeight"]}`,
	"mainContainer": `styles__mainContainer___G7HrY`
};
export default ___CSS_LOADER_EXPORT___;
