// @flow
import React from 'react'
import styles from './styles.css'

type HeaderMenuSectionProps = {
  label?: string,
  children?: React.ReactNode,
}

const HeaderMenuSection = (props: HeaderMenuSectionProps) => {
  const { label, children } = props

  return (
    <div className={styles.headerMenuSection}>
      {label && <h4 className={styles.headerMenuLabel}>{label}</h4>}

      {children}
    </div>
  )
}

export default HeaderMenuSection
