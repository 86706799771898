// @flow
import React from 'react'
import { ContactBasicFormRef } from 'components/References'
import FormButtons from 'components/FormButtons'
import Ticket from 'components/Ticket'
import Reference from './Reference'
import styles from './styles'

type Props = {
  dispatch: Function,
  errors: Object,
  isSubmitting: boolean,
  onAddContact: Function,
  onRemoveContact: Function,
  onSubmit: Function,
  ticket: Object,
  values: Object,
}

const buttonText = (attorney_ids) => (
  attorney_ids.length ? 'Select Additional Attorney' : 'Select Attorney'
)

const renderAttorneys = (attorneys, errors, onRemoveContact) =>
  attorneys.map((attorney, idx) => {
    const removeBtn = (
      <div
        onClick={onRemoveContact(attorney.id)}
        className={styles.removeButton}
      />
    )

    return (
      <ContactBasicFormRef
        contact={attorney}
        key={idx}
        remove={removeBtn}
        errors={errors.attorneys}
      />
    )
  })

export const AttorneysStation = (props: Props) => {
  const {
    errors,
    isSubmitting,
    onAddContact,
    onRemoveContact,
    onSubmit,
    ticket,
    values: { attorneys }
  } = props
  const header = {
    subheader: 'Account Creation',
    title: 'Attorneys',
  }
  const reference = ticket && <Reference {...props} />

  return (
    <Ticket
      header={header}
      history={ticket.history}
      reference={reference}
    >
      <p className={styles.infoText}>
        Select attorneys to add to the account.
      </p>
      <form className={styles.form} onSubmit={onSubmit}>
        <section className={styles.cardMarginBot}>
          <section className={styles.sectionNoMargin}>
            <h3>Attorneys</h3>
            <button
              id='test-hook-select-attorney'
              onClick={onAddContact}
              className={styles.buttonLocalSecondary}
              type='button'
            >
              {buttonText(attorneys)}
            </button>
            <div>{renderAttorneys(attorneys, errors, onRemoveContact)}</div>
          </section>
        </section>
        <FormButtons submitLabel='Save & Continue' submitting={isSubmitting} />
      </form>
    </Ticket>
  )
}
