// @flow
import React from 'react'
import Collapse from 'react-collapse'
import { Link } from 'react-router-dom'
import { httpGet } from 'lib/http'
import formatters from 'lib/formatters'
import type { Contact } from 'lib/types'
import { setModal } from 'lib/modal/actions'
import SSNResetForm from 'components/SSNResetForm'
import Row from './Row'
import ticketFlag from 'styles/img/ticket.png'
import styles from './styles'


type Props = {
  collapse: boolean,
  contact: Contact,
  dispatch: Function,
  edit: boolean,
  full: boolean,
  onSSNReset: Function,
  title: string,
  topSection: boolean,
}

type State = {
  isOpened: boolean
}

const fetchS3File = (key: string) => {
  httpGet(`/admin/files/${key}/preview`).then(({url}) => {
    window.open(url, '_blank')
  })
}

const showLink = (label, key, style) => (
  <a className={style}
    onClick={() => fetchS3File(key)}
    target='_blank'>
    {label}
  </a>
)

class ContactRef extends React.Component<Props, State> {
  props: Props
  state = { isOpened: false }

  _addInfo = () => {
    const {
      contact,
      full = false,
    } = this.props

    let photoID, photoIDLabel

    if (contact.photo_id_key) {
      photoIDLabel = 'Photo ID'
      photoID = showLink('Photo ID', contact.photo_id_key, styles.imageLink)
    } else {
      photoIDLabel = 'Photo ID?'
      photoID = contact.has_photo_id ? 'Yes' : 'No'
    }

    const blockStyle = full ? styles.full : styles.normal

    let ssnLabel = 'Social Security Number', ssnValue
    if (contact.ssn) {
      ssnValue= formatters.ssn(contact.ssn)
    } else {
      ssnLabel = ssnLabel + '?'
      ssnValue= contact.ssn_verifier ? 'Yes' : 'No'
    }

    return (
      <div className={blockStyle}>
        <div className={styles.userInfo}>
          <Row
            label='Age'
            val={formatters.age(formatters.date(contact.dob))}
            mono={true}
          />
          <Row
            label='DOB'
            val={formatters.date(contact.dob)}
            mono={true}
          />
          <Row
            label={photoIDLabel}
            val={photoID}
          />
          <Row
            label={ssnLabel}
            val={ssnValue}
          />
        </div>
        <div className={styles.contactInfo}>
          <Row
            label='Cell'
            val={
              formatters.phone(
                contact.cellPhone ||
                contact.phone_numbers &&
                contact.phone_numbers.cell_phone
              )
            }
            mono={true}
          />
          <Row
            label='Day'
            val={
              formatters.phone(
                contact.dayPhone ||
                contact.phone_numbers &&
                contact.phone_numbers.day_phone
              )
            }
            mono={true}
          />
          <Row
            label='Evening'
            val={
              formatters.phone(
                contact.eveningPhone ||
                contact.phone_numbers &&
                contact.phone_numbers.evening_phone
              )
            }
            mono={true}
          />
          <Row
            label='Fax'
            val={
              formatters.phone(
                contact.fax ||
                contact.phone_numbers &&
                contact.phone_numbers.fax
              )
            }
            mono={true}
          />
        </div>
        <div className={styles.addressInfo}>
          <Row
            label='Email'
            val={contact.email}
          />
          <Row
            label="Address"
            val={formatters.address(contact)}
          />
          <Row
            label='Latest Note'
            val={formatters.truncatedString(contact.latest_note, 100)}
          />
        </div>
      </div>
    )
  }

  _openModal = () => {
    const { contact, dispatch, onSSNReset } = this.props
    const modalForm = (
      <SSNResetForm contact={contact} onSuccess={onSSNReset} />
    )
    dispatch(setModal(modalForm))
  }

  render() {
    const {
      collapse = false,
      contact,
      edit = false,
      title = 'Contact',
      topSection = true,
    } = this.props
    if (!contact) return null
    const { has_open_ticket: hasOpenTicket } = contact
    let { isOpened } = this.state
    let collapseLink
    let editLink
    let resetSSNLink
    if (edit) {
      editLink = (
        <Link
          to={`/contacts/${contact.id}/edit`}
          className={styles.refEdit}
        > </Link>
      )
    }
    if (edit && !collapse) {
      resetSSNLink = (
        <div onClick={this._openModal} className={styles.resetSSN}>
          Reset SSN
        </div>
      )
    }
    if (collapse) {
      collapseLink = (
        <div
          className={styles.dropdownArea}
          onClick={() => this.setState({ isOpened: !isOpened }) }
        >
          <div className={styles.toggleLabel}>
            {isOpened ? 'Hide Details' : 'See All Details'}
          </div>
          <span
            className={styles.collapseIcon}
            title={isOpened ? 'Hide' : 'Show'}
          />
        </div>
      )
    } else {
      isOpened = true
    }
    const openTickets = hasOpenTicket ? (
      <div className={styles.ticketFlag}>
        <img src={ticketFlag} />
      </div>
    ) : null
    let renderTitle = topSection ? (
      <div
        className={`${styles.titleSectionLarge} ${styles.titleSectionTicket}`}
      >
        {openTickets}
        <div className={styles.title}>
          <h5 className={styles.contactType}>{title}</h5>
          <div className={`${styles.name} test-hook-contact-name`}>
            {contact.name}
          </div>
        </div>
        {resetSSNLink}
        {editLink}
      </div>
    ) : null

    return (
      <section className={styles.collapseRef} data-active={isOpened}>
        {renderTitle}
        <Collapse isOpened={isOpened}>
          {this._addInfo()}
        </Collapse>
        {collapseLink}
      </section>
    )
  }
}

export default ContactRef
