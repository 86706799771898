// @flow
import React from 'react'
import { connect } from 'react-redux'
import SSNResetForm from 'components/SSNResetForm'
import EINResetForm from 'components/EINResetForm'
import cleaners from 'lib/field/cleaners'
import { httpGet, httpPut } from 'lib/http'
import { setModal, unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { ContactSelectModal } from 'components/ContactSelectModal'
import { Template } from './Template'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  params: Object,
  location: Object,
}

class Edit extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      changes: {},
      errors: {},
      isSubmitting: false,
      values: {
        ables: [],
        statement_recipients: [],
        attorneys: [],
        beneficiary: '',
        close_date: '',
        consults: [],
        'custom_fee_schedule?': false,
        floor: null,
        grantor: '',
        grantor_relationship: '',
        'non_cash_assets?': false,
        open_date: '',
        'prepaid_burial?': false,
        previous_roles: [],
        product_name: '',
        'receives_monthly_statements?': false,
        referrer: '',
        representative: '',
        'restricted?': false,
        spending_plan: null,
        'ssi?': false,
        'ssi_food?': false,
        'ssi_shelter?': false,
        statement_recipient_list: [],
        trust_name: null,
      },
    }
  }

  componentDidMount() {
    this.fetchValues()
  }

  props: Props

  fetchValues = () => {
    const { dispatch, location } = this.props
    dispatch(setLoad())
    httpGet(`/admin/accounts/${location.params.number}/edit`).then((data) => {
      const values = {
        ...data,
        ssi_amount: this.formatCurrency(data.ssi_amount / 100.0),
        statement_recipient_list: this.getStatementRecipientList(data),
      }
      this.setState({ values })
      dispatch(setUnload())
    })
  }

  formatCurrency = (value) => {
    return value ? (value * 1).toFixed(2) : ''
  }

  getStatementRecipientList = (data) => {
    const distinct = (item, idx, list) => {
      return idx == 0 || item.id != list[idx - 1].id
    }

    var contacts = [
      data.beneficiary,
      data.grantor,
      data.co_grantor,
      data.attorneys,
      data.ables,
      data.consults,
      data.referrer,
      data.representative,
      data.statement_recipients,
    ]
      .flat()
      .filter((x) => x)

    var list = contacts
      .map((c) => {
        var recipient = {}
        recipient['id'] = c.id
        recipient['name'] = c.name
        recipient['checked'] = data.statement_recipients.some((r) => r.id == c.id)
        return recipient
      })
      .sort((x, y) => x.id - y.id)
      .filter(distinct)

    return list
  }

  getParams = () => {
    const { changes, values } = this.state
    const params = {}
    Object.entries(values).forEach(([key, value]) => {
      if (changes[key]) params[key] = value
    })
    if (params.ssi_amount) params.ssi_amount = cleaners.money(params.ssi_amount)
    return params
  }

  getPrimaryGrantorDocLabel = () => {
    const relationship = this.state.values.grantor_relationship

    switch (relationship) {
      case 'coGuardian':
      case 'proGuardian':
      case 'famGuardian':
      case 'court':
        return 'Court Order'
      case 'parent':
      case 'grandparent':
        return 'Birth Certificate'
      case 'poa':
        return 'Power of Attorney'
    }
  }

  handleBlur = ({ target }) => {
    if (target.name === 'ssi_amount') {
      const { values } = this.state
      const newValues = {
        ...values,
        ssi_amount: this.formatCurrency(values.ssi_amount),
      }
      this.setState({ values: newValues })
    }
  }

  handleChange = ({ target }) => {
    const changes = { ...this.state.changes, [target.name]: true }
    const errors = { ...this.state.errors, [target.name]: null }
    const values = {
      ...this.state.values,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value,
    }
    this.setState({ changes, errors, values })
  }

  handleSubmit = (event: Object) => {
    event.preventDefault()

    this.props.dispatch(setLoad())
    this.setState({ isSubmitting: true }, this.putValues)
  }

  openAbleSelectionModal = () => {
    const { dispatch } = this.props
    const handler = (contact: Object) => () => {
      dispatch(unsetModal())

      const { changes, values } = this.state
      const ids = values.able_ids

      if (ids.includes(contact.id)) return

      const newChanges = { ...changes, able_ids: true }
      const newValues = {
        ...values,
        able_ids: ids.concat(contact.id),
        ables: values.ables.concat(contact),
      }
      this.setState({ changes: newChanges, values: newValues })
    }
    dispatch(setModal(<ContactSelectModal dispatch={dispatch} selectionHandler={handler} />))
  }

  openAttorneySelectionModal = () => {
    const { dispatch } = this.props
    const handler = (contact: Object) => () => {
      dispatch(unsetModal())

      const { changes, values } = this.state
      const ids = values.attorney_ids

      if (ids.includes(contact.id)) return

      const newChanges = { ...changes, attorney_ids: true }
      const newValues = {
        ...values,
        attorney_ids: ids.concat(contact.id),
        attorneys: values.attorneys.concat(contact),
      }
      this.setState({ changes: newChanges, values: newValues })
    }
    dispatch(setModal(<ContactSelectModal dispatch={dispatch} selectionHandler={handler} />))
  }

  openBeneficiarySSNResetModal = () => {
    const { values } = this.state
    const { beneficiary } = values
    const successHandler = (_contactId, ssnVerifier: string) => () => {
      const newValues = {
        ...values,
        beneficiary: { ...beneficiary, ssn_verifier: ssnVerifier },
      }
      this.setState({ values: newValues })
    }
    this.props.dispatch(setModal(<SSNResetForm contact={beneficiary} onSuccess={successHandler} />))
  }

  openCoGrantorSelectionModal = () => {
    const { dispatch } = this.props
    const handler = (contact: Object) => () => {
      dispatch(unsetModal())

      const { changes, values } = this.state
      const newChanges = { ...changes, co_grantor_id: true }

      if (values.grantor_id === contact.id) {
        const newValues = { ...values, co_grantor: null, co_grantor_id: null }
        this.setState({ changes: newChanges, values: newValues })
        return
      }

      const newValues = {
        ...values,
        co_grantor: contact,
        co_grantor_id: contact.id,
      }
      this.setState({ changes: newChanges, values: newValues })
    }
    dispatch(setModal(<ContactSelectModal dispatch={dispatch} selectionHandler={handler} />))
  }

  openCoGrantorSSNResetModal = () => {
    const { values } = this.state
    const coGrantor = values.co_grantor
    const successHandler = (_contactId, ssnVerifier: string) => () => {
      const newValues = {
        ...values,
        co_grantor: { ...coGrantor, ssn_verifier: ssnVerifier },
      }
      this.setState({ values: newValues })
    }
    this.props.dispatch(setModal(<SSNResetForm contact={coGrantor} onSuccess={successHandler} />))
  }

  openConsultSelectionModal = () => {
    const { dispatch } = this.props
    const handler = (contact: Object) => () => {
      dispatch(unsetModal())

      const { changes, values } = this.state
      const ids = values.consult_ids

      if (ids.includes(contact.id)) return

      const newChanges = { ...changes, consult_ids: true }
      const newValues = {
        ...values,
        consult_ids: ids.concat(contact.id),
        consults: values.consults.concat(contact),
      }
      this.setState({ changes: newChanges, values: newValues })
    }
    dispatch(setModal(<ContactSelectModal dispatch={dispatch} selectionHandler={handler} />))
  }

  openEINResetModal = () => {
    const { values } = this.state
    const successHandler = (_contactId, ein_verifier: string) => () => {
      const newValues = {
        ...values,
        ein_verifier: ein_verifier,
      }
      this.setState({ values: newValues })
    }
    this.props.dispatch(
      setModal(
        <EINResetForm
          accountNumber={this.props.location.params.number}
          ein_verifier={values.ein_verifier}
          onSuccess={successHandler}
        />,
      ),
    )
  }

  openGrantorSelectionModal = () => {
    const { dispatch } = this.props
    const handler = (contact: Object) => () => {
      dispatch(unsetModal())

      const changes = { ...this.state.changes, grantor_id: true }
      const values = {
        ...this.state.values,
        grantor: contact,
        grantor_id: contact.id,
      }
      if (this.state.values.co_grantor_id === contact.id) {
        changes.co_grantor_id = true
        values.co_grantor = null
        values.co_grantor_id = null
      }
      this.setState({ changes, values })
    }
    dispatch(setModal(<ContactSelectModal dispatch={dispatch} selectionHandler={handler} />))
  }

  openGrantorSSNResetModal = () => {
    const { values } = this.state
    const { grantor } = values
    const successHandler = (_contactId, ssnVerifier: string) => () => {
      const newValues = {
        ...values,
        grantor: { ...grantor, ssn_verifier: ssnVerifier },
      }
      this.setState({ values: newValues })
    }
    this.props.dispatch(setModal(<SSNResetForm contact={grantor} onSuccess={successHandler} />))
  }

  openReferrerSelectionModal = () => {
    const { dispatch } = this.props
    const handler = (contact: Object) => () => {
      dispatch(unsetModal())

      const changes = { ...this.state.changes, referrer_id: true }
      const values = {
        ...this.state.values,
        referrer: contact,
        referrer_id: contact.id,
      }
      this.setState({ changes, values })
    }
    dispatch(setModal(<ContactSelectModal dispatch={dispatch} selectionHandler={handler} />))
  }

  openRepresentativeSelectionModal = () => {
    const { dispatch } = this.props
    const handler = (contact: Object) => () => {
      dispatch(unsetModal())

      const changes = { ...this.state.changes, representative_id: true }
      const values = {
        ...this.state.values,
        representative: contact,
        representative_id: contact.id,
      }
      this.setState({ changes, values })
    }
    dispatch(setModal(<ContactSelectModal dispatch={dispatch} selectionHandler={handler} />))
  }

  parseJSONError = (acc, e) => {
    switch (e.locationType) {
      case 'field':
        acc[e.location] = e.message
        return acc
      case 'fieldGroup':
        acc[e.location] = e.errors.reduce(this.parseJSONError, {})
        return acc
    }
  }

  putValues = () => {
    const {
      dispatch,
      location: {
        params: { number: accountNumber },
      },
    } = this.props

    httpPut(`/admin/accounts/${accountNumber}`, this.getParams())
      .then(() => {
        this.props.location.navigate(`/accounts/${accountNumber}`)
        dispatch(setBanner('Changes were saved.'))
      })
      .catch(({ errors }) => {
        this.setState({
          errors: errors.reduce(this.parseJSONError, {}),
          isSubmitting: false,
        })
        const msg = 'Changes were not saved. Please check form for errors.'
        dispatch(setBanner(msg, 'danger'))
        dispatch(setUnload())
      })
  }

  removeAble = (id: number) => {
    const { changes, values } = this.state
    const newChanges = { ...changes, able_ids: true }
    const newValues = {
      ...values,
      able_ids: values.able_ids.filter((i) => i !== id),
      ables: values.ables.filter((a) => a.id !== id),
    }
    this.setState({ changes: newChanges, values: newValues })
  }

  removeAttorney = (id: number) => {
    const { changes, values } = this.state
    const newChanges = { ...changes, attorney_ids: true }
    const newValues = {
      ...values,
      attorney_ids: values.attorney_ids.filter((i) => i !== id),
      attorneys: values.attorneys.filter((a) => a.id !== id),
    }
    this.setState({ changes: newChanges, values: newValues })
  }

  removeConsult = (id: number) => {
    const { changes, values } = this.state
    const newChanges = { ...changes, consult_ids: true }
    const newValues = {
      ...values,
      consult_ids: values.consult_ids.filter((i) => i !== id),
      consults: values.consults.filter((a) => a.id !== id),
    }
    this.setState({ changes: newChanges, values: newValues })
  }

  removeReferrer = () => {
    const changes = { ...this.state.changes, referrer_id: true }
    const values = { ...this.state.values, referrer: null, referrer_id: null }
    this.setState({ changes, values })
  }

  shouldCoGrantorRender = () => {
    return this.state.values.grantor_relationship === 'coGuardian'
  }

  shouldGrantorRender = () => {
    const relationship = this.state.values.grantor_relationship
    return !['self', 'selfRep', 'court', ''].includes(relationship)
  }

  shouldGrantorDocsRender = () => {
    const relationship = this.state.values.grantor_relationship
    return !['self', 'selfRep', ''].includes(relationship)
  }

  shouldOtherGrantorDocsRender = () => {
    const relationship = this.state.values.grantor_relationship
    return ['coGuardian', 'famGuardian', 'proGuardian'].includes(relationship)
  }

  shouldRepresentativeRender = () => {
    return this.state.values.grantor_relationship === 'selfRep'
  }

  toggleStatementRecipient = ({ target }) => {
    const { changes, values } = this.state
    var contact = values.statement_recipient_list.find((e) => e.id == target.id)
    contact.checked = !contact.checked

    const newRecipientIds = values.statement_recipient_list
      .filter((r) => r.checked)
      .map((r) => r.id)

    const newChanges = { ...changes, statement_recipient_ids: true }
    const newValues = { ...values, statement_recipient_ids: newRecipientIds }
    this.setState({ changes: newChanges, values: newValues })
  }

  render() {
    return (
      <Template
        accountNumber={this.props.location.params.number}
        errors={this.state.errors}
        isSubmitting={this.state.isSubmitting}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        openAbleSelectionModal={this.openAbleSelectionModal}
        openAttorneySelectionModal={this.openAttorneySelectionModal}
        openBeneficiarySSNResetModal={this.openBeneficiarySSNResetModal}
        openCoGrantorSelectionModal={this.openCoGrantorSelectionModal}
        openCoGrantorSSNResetModal={this.openCoGrantorSSNResetModal}
        openConsultSelectionModal={this.openConsultSelectionModal}
        openEINResetModal={this.openEINResetModal}
        openGrantorSelectionModal={this.openGrantorSelectionModal}
        openGrantorSSNResetModal={this.openGrantorSSNResetModal}
        openReferrerSelectionModal={this.openReferrerSelectionModal}
        openRepresentativeSelectionModal={this.openRepresentativeSelectionModal}
        primaryGrantorDocLabel={this.getPrimaryGrantorDocLabel()}
        removeAble={this.removeAble}
        removeAttorney={this.removeAttorney}
        removeConsult={this.removeConsult}
        removeReferrer={this.removeReferrer}
        shouldCoGrantorRender={this.shouldCoGrantorRender()}
        shouldGrantorDocsRender={this.shouldGrantorDocsRender()}
        shouldGrantorRender={this.shouldGrantorRender()}
        shouldOtherGrantorDocsRender={this.shouldOtherGrantorDocsRender()}
        shouldRepresentativeRender={this.shouldRepresentativeRender()}
        toggleStatementRecipient={this.toggleStatementRecipient}
        values={this.state.values}
      />
    )
  }
}

export default withRouter(connect()(Edit))
