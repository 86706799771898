import { useCallback, useEffect, useState } from 'react'
import streamSaver from 'streamsaver'
import queryString from 'query-string'
import { httpRaw, httpGet } from 'lib/http'

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export function useExport(location: Object) {
  const exportContacts = useCallback(() => {
    const {
      q,
      grantorRelationship: grantor_relationship,
      role,
      product,
    } = location.query
    const qs = queryString.stringify({ q, grantor_relationship, role, product })
    const url = `/admin/contacts/export?${qs}`

    httpRaw(url).then((response) => {
      const fileStream = streamSaver.createWriteStream('contacts.csv')
      return response.body.pipeTo(fileStream)
    })
  }, [location])

  return { exportContacts }
}

const localStorageHistoryKey = '__contact_searh_history'

export function useHistorySearch() {
  const getLocalStorageValue = () => {
    const storedValue = localStorage.getItem(localStorageHistoryKey)
    try {
      const parsedValue = JSON.parse(storedValue)
      return Array.isArray(parsedValue) ? parsedValue : []
    } catch (e) {
      return []
    }
  }

  const saveSearch = (item) => {
    const history = getLocalStorageValue()
    const newValue = [item, ...history.slice(0, 5)]
    localStorage.setItem(localStorageHistoryKey, JSON.stringify(newValue))
  }

  const getHistory = () => {
    return getLocalStorageValue()
  }

  return { saveSearch, getHistory }
}

const MIN_LENGHT = 3

export function useAutocompleteResults(searchQuery: string) {
  const [results, setResults] = useState({
    accounts: [],
    contacts: [],
  })
  const debounceSearchTerm = useDebounce(searchQuery, 300)

  useEffect(() => {
    let isCancel = false
    const queryValue = searchQuery.trim()
    const isSearchQueryValid = queryValue && queryValue.length >= MIN_LENGHT

    if (!isSearchQueryValid) {
      setResults({ contacts: [], accounts: [] })
      return
    }

    httpGet(`/admin/contacts/autocomplete?q=${queryValue}`).then((response) => {
      if (!isCancel) {
        const { contacts, accounts } = response
        setResults({ contacts, accounts })
      }
    })

    return () => (isCancel = true)
  }, [debounceSearchTerm])

  return { results }
}
