// @flow
import React from 'react'
import type { Field } from 'lib/types'
import FieldWrapper from './FieldWrapper'
import renderInput from './renderInput'

const renderInputField = (field: Field) => {
  const {
    autoFocus,
    button,
    children,
    input,
    label,
    meta,
    meta: { asyncValidating },
    placeholder,
    required,
    type,
  } = field
  const props = {
    asyncValidating,
    autoFocus,
    button,
    innerButton: children,
    label: label || placeholder,
    meta,
    name: input.name,
    required,
    type,
  }

  return <FieldWrapper {...props}>{renderInput(field)}</FieldWrapper>
}

export default renderInputField
