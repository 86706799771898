// @flow
import React from 'react'
import { connect } from 'react-redux'
import type { Contact, File } from 'lib/types'
import cleaners from 'lib/field/cleaners'
import formCleaners from 'lib/form/cleaners'
import formatters from 'lib/formatters'
import { httpGet, httpDelete, httpPut } from 'lib/http'
import { setModal, unsetModal } from 'lib/modal/actions'
import { setLoad, setUnload, setBanner } from 'lib/notification/actions'
import { ContactSelectModal } from 'components/ContactSelectModal'
import Error from 'components/Error'
import { ApprovalStation } from './Deposit/ApprovalStation'
import { ClosedStation } from './Deposit/ClosedStation'
import { FeeStation } from './Deposit/FeeStation'
import { ResolutionStation } from './Deposit/ResolutionStation'
import { AblesAndConsultsStation } from './GPTAccountCreation/AblesAndConsultsStation'
import { AttorneysStation } from './GPTAccountCreation/AttorneysStation'
import { CoTrusteeApprovalStation } from './GPTAccountCreation/CoTrusteeApprovalStation'
import { ClosedStation as AccountClosedStation } from './GPTAccountCreation/ClosedStation'
import { JoinderAgreementStation } from './GPTAccountCreation/JoinderAgreementStation'
import { RemaindermenStation } from './GPTAccountCreation/RemaindermenStation'
import { ReviewStation } from './GPTAccountCreation/ReviewStation'
import { VerificationStation } from './GPTAccountCreation/VerificationStation'
import { withRouter } from 'lib/hooks/withRouter'

type Props = {
  dispatch: Function,
  location: Object,
}

type State = {
  disabled: boolean,
  errors: Object,
  isDeleting: boolean,
  isSubmitting: boolean,
  show404: boolean,
  ticket: ?Object,
  values: {
    ables: Array<Contact>,
    acknowledgeDuplicateCheck: boolean,
    acknowledgeEarlyDate: boolean,
    amount: number,
    attorneys: Array<Contact>,
    consults: Array<Contact>,
    remaindermen: Array<Contact>,
    remaindermen_notes: string,
    deposit_type: string,
    deposit_date: string,
    fee_rate: string,
    fee_schedule: string,
    rejection_notes: string,
    joinder_agreement?: File,
    primary_grantor_doc?: File,
    secondary_grantor_doc?: File,
    warnings: Array<String>,
  },
}

class Ticket extends React.Component<Props, State> {
  props: Props
  state = {
    disabled: true,
    errors: {},
    isDeleting: false,
    isSubmitting: false,
    show404: false,
    ticket: null,
    values: {
      acknowledgeDuplicateCheck: false,
      acknowledgeEarlyDate: false,
      ables: [],
      amount: 0,
      attorneys: [],
      consults: [],
      remaindermen: [],
      remaindermen_notes: '',
      deposit_type: '',
      deposit_date: '',
      fee_rate: '',
      fee_schedule: '',
      rejection_notes: '',
      warnings: [],
    },
  }

  componentDidMount() {
    this.fetchTicket()
  }

  _errorPage = () => {
    return (
      <Error
        title="404: Ticket Not Found"
        message="The ticket you are looking for does not exist."
        icon="ticket"
        buttonLink="/action-queue"
        buttonTitle="View Action Queue"
      />
    )
  }

  fetchTicket = () => {
    const {
      dispatch,
      location: { params },
    } = this.props
    dispatch(setLoad())
    httpGet(this.apiPath())
      .then((data) => {
        const { ticket, values } = data
        const { station, user_blacklist } = ticket
        let formattedValues = {
          ...this.state.values,
          ...values,
          fee_rate: Number((values.fee_rate * 100).toFixed(15)),
          rejection_notes: '',
        }
        if (station === 'resolution') {
          formattedValues = this.formattedResolutionValues(formattedValues)
        }
        const storedEmail = localStorage.getItem('email')
        const disabled = user_blacklist && user_blacklist.includes(storedEmail)
        const newState = {
          disabled: disabled,
          errors: {},
          ticket,
          values: formattedValues,
        }
        this.setState(newState)
      })
      .catch(() => {
        this.setState({ show404: true })
      })
      .then(() => dispatch(setUnload()))
  }

  formattedResolutionValues = (values) => {
    return { ...values, amount: formatters.inputMoney(values.amount) }
  }

  handleBlur = ({ target }) => {
    const amount = formatters.inputMoney(target.value)
    this.setState({ values: { ...this.state.values, amount } })
  }

  handleChange = ({ target: { checked, name, value } }) => {
    if (name === 'fee_rate' && (value < 0 || value > 100)) return

    const booleans = ['acknowledgeEarlyDate', 'acknowledgeDuplicateCheck']

    if (booleans.includes(name)) {
      this.setState({ values: { ...this.state.values, [name]: checked } })
      return
    }

    this.setState({ values: { ...this.state.values, [name]: value } })
  }

  handleSelectContact = (key) => {
    return (contact) => {
      return () => {
        if (this.state.values[key].some(({ id }) => id === contact.id)) {
          this.props.dispatch(unsetModal())
          return
        }
        const contacts = [...this.state.values[key], contact]
        this.setState(
          {
            ...this.state,
            values: { ...this.state.values, [key]: contacts },
          },
          () => {
            this.props.dispatch(unsetModal())
          },
        )
      }
    }
  }

  canSubmit = () => {
    const { acknowledgeEarlyDate, acknowledgeDuplicateCheck } =
      this.state.values

    const { warnings } = this.state.ticket
    const length = warnings ? warnings.length : 0
    let allAcknowledged = false

    if (length == 2) {
      allAcknowledged = acknowledgeEarlyDate && acknowledgeDuplicateCheck
    } else if (length == 1 && warnings[0] == 'early_date') {
      allAcknowledged = acknowledgeEarlyDate
    } else if (length == 1) {
      allAcknowledged = acknowledgeDuplicateCheck
    } else {
      allAcknowledged = true
    }

    return allAcknowledged && !this.state.disabled
  }

  handleSubmit = (event) => {
    event.preventDefault()

    if (!this.state.isDeleting) {
      this.props.dispatch(setLoad())
      this.setState({ isSubmitting: true }, this.putTicket)
    }
  }

  handleBeginDelete = () => {
    this.setState({ isDeleting: true })
  }

  handleCancelDelete = () => {
    this.setState({ isDeleting: false })
  }

  handleDelete = () => {
    const onDelete = (event) => {
      event.preventDefault()
      this.props.dispatch(setLoad())
      this.setState({ isSubmitting: true }, this.deleteTicket)
    }

    return onDelete
  }

  handleApproval = (event, approval) => {
    event.persist()
    const newState = { values: { ...this.state.values, 'approved?': approval } }
    this.setState(newState, () => this.handleSubmit(event))
  }

  handleShowContactSearch = (key) => () => {
    const callback = this.handleSelectContact(key)
    const modal = (
      <ContactSelectModal
        dispatch={this.props.dispatch}
        selectionHandler={callback}
      />
    )
    const event = setModal(modal)
    this.props.dispatch(event)
  }

  handleRemoveContact = (key) => (id) => () => {
    const filter = ({ id: filter_id }) => filter_id !== id
    const contacts = this.state.values[key].filter(filter)
    const values = { ...this.state.values, [key]: contacts }
    this.setState({ ...this.state, values: values })
  }

  handleNotesEdit = ({ target }) => {
    const values = {
      ...this.state.values,
      ['remaindermen_notes']: target.value,
    }
    this.setState({ ...this.state, values: values })
  }

  putTicket = () => {
    const {
      ticket: { station, track },
      values,
    } = this.state
    const { dispatch } = this.props
    const cleanedValues = {
      ...values,
      amount: cleaners.money(values.amount),
      able_ids: formCleaners.contactList(values.ables),
      attorney_ids: formCleaners.contactList(values.attorneys),
      consult_ids: formCleaners.contactList(values.consults),
      remaindermen_ids: formCleaners.contactList(values.remaindermen),
      fee_rate: cleaners.fee(values.fee_rate),
    }
    httpPut(this.apiPath(), { station, track, values: cleanedValues })
      .then(() => this.fetchTicket())
      .catch(({ errors }) => {
        if (errors.length == 1 && errors[0].message === 'outdated_ticket') {
          const msg =
            'Ticket has been updated by another user. ' +
            'Please go back to the queue and retry.'
          dispatch(setBanner(msg, 'danger'))
        } else {
          this.setState({ errors: formatters.apiErrors(errors) })
        }
      })
      .finally(() => {
        this.props.dispatch(setUnload())
        this.setState({ isSubmitting: false })
      })
  }

  deleteTicket = () => {
    const {
      dispatch,
      location: { params },
    } = this.props
    const { id } = params
    const endpoint = `/admin/tickets/${id}`
    httpDelete(endpoint)
      .then(() => {
        this.props.location.navigate('/action-queue')
        dispatch(setUnload())
        dispatch(setBanner(`Ticket (${id}) deleted successfully.`))
      })
      .catch(({ message }) => {
        dispatch(setUnload())
        dispatch(setBanner(`Failed to delete ticket. (${message})`))
        this.setState({ isSubmitting: false })
      })
  }

  apiPath = () => {
    return `/admin/tickets/${this.props.location.params.id}`
  }

  render() {
    const {
      ticket,
      values: { deposit_date, amount },
    } = this.state
    const co_trustee_disabled =
      this.state.disabled || localStorage.getItem('isCoTrustee') !== 'true'
    if (this.state.show404) return this._errorPage()
    if (!ticket) return null
    const stations = {
      deposit: {
        fee: (
          <FeeStation
            canSubmit={this.canSubmit()}
            dispatch={this.props.dispatch}
            isDeleting={this.state.isDeleting}
            isSubmitting={this.state.isSubmitting}
            onBeginDelete={this.handleBeginDelete}
            onCancelDelete={this.handleCancelDelete}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete()}
            ticket={ticket}
            values={this.state.values}
          />
        ),
        approval: (
          <ApprovalStation
            canSubmit={this.canSubmit()}
            disabled={this.state.disabled}
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isDeleting={this.state.isDeleting}
            isSubmitting={this.state.isSubmitting}
            onApproval={this.handleApproval}
            onBeginDelete={this.handleBeginDelete}
            onCancelDelete={this.handleCancelDelete}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete()}
            ticket={ticket}
            values={this.state.values}
          />
        ),
        resolution: (
          <ResolutionStation
            canSubmit={this.canSubmit()}
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete()}
            ticket={ticket}
            values={this.state.values}
          />
        ),
        closed: (
          <ClosedStation
            dispatch={this.props.dispatch}
            depositDate={deposit_date}
            amount={amount}
            ticket={ticket}
            values={this.state.values}
          />
        ),
      },
      gpt_account_creation: {
        verification: (
          <VerificationStation
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            ticket={ticket}
            values={this.state.values}
          />
        ),
        joinder_agreement: (
          <JoinderAgreementStation
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            ticket={ticket}
            values={this.state.values}
          />
        ),
        attorney: (
          <AttorneysStation
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            ticket={ticket}
            values={this.state.values}
            onAddContact={this.handleShowContactSearch('attorneys')}
            onRemoveContact={this.handleRemoveContact('attorneys')}
          />
        ),
        ables_and_consults: (
          <AblesAndConsultsStation
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onAddAble={this.handleShowContactSearch('ables')}
            onAddConsult={this.handleShowContactSearch('consults')}
            onChange={this.handleChange}
            onRemoveAble={this.handleRemoveContact('ables')}
            onRemoveConsult={this.handleRemoveContact('consults')}
            onSubmit={this.handleSubmit}
            ticket={ticket}
            values={this.state.values}
          />
        ),
        remaindermen: (
          <RemaindermenStation
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            ticket={ticket}
            values={this.state.values}
            onAddContact={this.handleShowContactSearch('remaindermen')}
            onRemoveContact={this.handleRemoveContact('remaindermen')}
            onNotesEdit={this.handleNotesEdit}
          />
        ),
        review: (
          <ReviewStation
            disabled={this.state.disabled}
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            ticket={ticket}
            values={this.state.values}
            onSSNReset={() => this.fetchTicket}
          />
        ),
        co_trustee_approval: (
          <CoTrusteeApprovalStation
            disabled={co_trustee_disabled}
            dispatch={this.props.dispatch}
            errors={this.state.errors}
            isSubmitting={this.state.isSubmitting}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            ticket={ticket}
            values={this.state.values}
            onSSNReset={() => this.fetchTicket}
          />
        ),
        closed: (
          <AccountClosedStation
            dispatch={this.props.dispatch}
            ticket={ticket}
          />
        ),
      },
    }
    return stations[ticket.track][ticket.station]
  }
}

export default withRouter(connect()(Ticket))
