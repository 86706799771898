// @flow
import React from 'react'

import { enhanceNewContactForm } from 'components/Contact/NewContactForm'
import { setModal } from 'lib/modal/actions'
import { httpGet } from 'lib/http'
import { Template } from './Template'

type Props = {
  dispatch: Function,
  selectionFilter?: Function,
  selectionHandler: Function,
  showCreate: Boolean,
}

type State = {
  contactData: Object,
  showCreate: Boolean,
  value: string,
}

export class ContactSelectModal extends React.Component<Props, State> {
  static defaultProps = { showCreate: false }

  state = {
    contactData: {},
    showCreate: false,
    value: '',
  }

  props: Props

  handleChange = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ value: currentTarget.value })
  }

  handleCreate = (selectionHandler) => {
    const { dispatch } = this.props
    return () => {
      const createHandler = (data) => selectionHandler(data)()
      const NewContactForm = enhanceNewContactForm(createHandler)
      const data = <NewContactForm />
      dispatch(setModal(data))
    }
  }

  handleSearch = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    const showCreate = true && this.props.dispatch

    httpGet(`/admin/contacts?q=${encodeURI(this.state.value)}`)
      .then((contactData) => this.setState({ showCreate, contactData }))
      .catch(() => this.setState({ showCreate, contactData: {} }))
  }

  render() {
    return (
      <Template
        selectionFilter={this.props.selectionFilter}
        contactData={this.state.contactData}
        createHandler={this.handleCreate}
        onChange={this.handleChange}
        onSearch={this.handleSearch}
        selectionHandler={this.props.selectionHandler}
        showCreate={this.state.showCreate}
        value={this.state.value}
      />
    )
  }
}
