// @flow
import React from 'react'
import { Field, reduxForm } from 'redux-form'
import normalizers from 'lib/field/normalizers'
import validators from 'lib/field/validators'
import { httpPut } from 'lib/http'
import { unsetModal } from 'lib/modal/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import type { Form } from 'lib/types'
import FormButtons from 'components/FormButtons'
import { renderInputField } from 'components/FormFields'
import styles from './styles'

type Props = {
  contact: Object,
  form: Form,
  onSuccess: Function,
}

const resetSSN = (data) => {
  const ssn = data.ssn ? data.ssn.replace(/-/g, '') : ''
  const putData = { ssn: ssn }
  return httpPut(`/admin/contacts/${data.contactId}/ssn`, putData)
}

const onSubmit = (data, dispatch, onSuccess) => {
  dispatch(setLoad())
  return resetSSN(data)
    .then(({ ssn_verifier: ssnVerifier }) => {
      onSuccess(data.contactId, ssnVerifier)(dispatch)
      dispatch(setBanner('SSN successfully reset.'))
    })
    .catch(() => {
      dispatch(setBanner('SSN unsuccessfully reset.', 'danger'))
    })
    .then(() => {
      dispatch(unsetModal())
      dispatch(setUnload())
    })
}

const Component = ({ contact, form, onSuccess }: Props) => {
  const { id, name, ssn_verifier }  = contact
  const handleSubmit = form.handleSubmit((data, dispatch) => {
    return onSubmit({...data, contactId: id}, dispatch, onSuccess)
  })
  let oldSSN
  if (ssn_verifier) {
    oldSSN = (
      <div className={styles.formItem}>
        <label className={styles.label}>Old Social Security Number</label>
        <div className={styles.mono}>XXX-XX-{ssn_verifier}</div>
      </div>
    )
  }

  return (
    <section>
      <h4 className='section'>Reset Social Security Number</h4>
      <form onSubmit={handleSubmit}>
        <div className='section'>
          <div className={styles.formItem}>
            <label className={styles.label}>Name</label>
            <div>{name}</div>
          </div>
          {oldSSN}
          <div className={styles.formItemMono}>
            <Field
              component={renderInputField}
              label='New Social Security Number'
              name='ssn'
              normalize={normalizers.ssn}
              placeholder='000-00-0000'
              type='text'
              validate={validators.ssn}
              autoFocus
            />
          </div>
        </div>
        <FormButtons {...form} />
      </form>
    </section>
  )
}

const SSNResetForm = reduxForm({
  form: 'ssnReset',
  propNamespace: 'form',
})(Component)

export default SSNResetForm
