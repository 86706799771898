// @flow
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'lib/constants'

const email = (val: string) => {
  return val.trim().toLowerCase()
}

const file = (val: Object) => {
  if (!val) return null
  const { content_type, data } = val
  if (!content_type || !data) return null
  return {
    content_type,
    data,
  }
}

const momentObj = (val: string) => {
  return moment(val, ISO_DATE_FORMAT, true)
}

const money = (val: string) => {
  if (!val) return null
  const cents = parseFloat(val.replace(/[^\-0-9\.]/g, '')) * 100
  return Math.round(cents)
}

const fee = (val: number) => {
  if (!val && val !== 0) return null
  return val / 100
}

const numbers = (val: string) => {
  if (!val) return null
  const matches = val.match(/\d/g)
  if (!matches) return null
  return matches.join('')
}

export default {
  email,
  fee,
  file,
  momentObj,
  money,
  numbers,
}
