import { clearSearch, search, setContacts } from 'lib/contact/actions'
import { fetchAccountHTTP } from 'lib/account/actions'
import { setBanner, setLoad, setUnload } from 'lib/notification/actions'
import { push } from 'react-router-redux'

export const submitSearch = (data: Object, dispatch: Function) => {
  const query = {
    q: data.search,
  }
  if (data.grantorRelationship) {
    query.grantorRelationship = data.grantorRelationship.value
  }
  if (data.role) {
    query.role = data.role.value
  }
  if (data.product) {
    query.product = data.product.value
  }
  if (query.q === undefined) {
    query.q = ''
  }
  dispatch(search(query))
}

export const lookupAccount = (number: string, dispatch: Function) => {
  if (!number || !number.trim()) {
    dispatch(clearSearch())
    return null
  }
  number = number.toUpperCase()
  dispatch(setLoad())
  dispatch(fetchAccountHTTP(number))
    .then(() => {
      // dispatch(push(`/accounts/${number}`))
      window.location.replace(`/accounts/${number}`)
    })
    .catch(({ code }) => {
      dispatch(setContacts({ count: 0, contacts: {} }))

      if (code !== 404) {
        dispatch(setBanner(`Error looking up account ${number}`))
      }

      dispatch(setUnload())
    })
}
