// @flow
import React from 'react'
import { AccountStatement } from 'components/AccountStatement'
import { DateRangeSelection } from 'components/DateRangeSelection'
import FormButtons from 'components/FormButtons'
import styles from './styles'

type Props = {
  canSubmit: Function,
  onSubmit: Function,
  onChange: Function,
  dateStart: string,
  dateEnd: string,
  statement: Object,
}

const wrapSubmit = (onSubmit) => (event) => {
  onSubmit()
  event.preventDefault()
}

export const Template = ({
  canSubmit,
  onSubmit,
  statement,
  dateStart,
  dateEnd,
  onChange,
}: Props) => {
  return statement ? (
    <AccountStatement {...statement} />
  ) : (
    <section>
      <div className={styles.localHeader}>
        <div className={styles.localHeaderInner}>
          <h5>Generate Account Statement</h5>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <h4>Specify the date range.</h4>
        </div>
        <form className={styles.form} onSubmit={wrapSubmit(onSubmit)}>
          <div className={styles.section}>
            <div className={styles.halfWidth}>
              <DateRangeSelection
                dateStart={dateStart}
                dateEnd={dateEnd}
                onSubmit={onSubmit}
                onChange={onChange}
              />
            </div>
          </div>
          <FormButtons disabled={!canSubmit} submitLabel="Submit" />
        </form>
      </div>
    </section>
  )
}
