// @flow
import { KEY } from './constants'

export const getDenyNote =
  (state: Object) => state[KEY].denyNote

export const getPotentialClients =
  (state: Object) => state[KEY].potential_clients

export const getPotentialClient =
  (state: Object) => state[KEY].potential_client

export const getOriginalPotentialClient =
  (state: Object) => state[KEY].org_potential_client
