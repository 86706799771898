// @flow
import React from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from 'recharts'

type LineProps = { name: string }

type Props = {
  data: [{}],
  series: [LineProps],
  TooltipProps: {},
  XAxisProps: {},
  YAxisProps: {},
}

const Graph = (props: Props) => {
  const { data, series, TooltipProps, XAxisProps, YAxisProps } = props
  return (
    <ResponsiveContainer>
      <LineChart data={data}>
        <XAxis {...XAxisProps} />
        <YAxis {...YAxisProps} />
        <Tooltip {...TooltipProps} />
        {series.map((LineProps, index) => {
          return (
            <Line
              {...LineProps}
              isAnimationActive={false}
              key={name}
              yAxisId={index}
            />
          )
        })}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default Graph
