import { combineReducers } from 'redux'
import { routerReducer as router } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import account from 'lib/account'
import beneficiary from 'lib/beneficiary'
import notification from 'lib/notification'
import contact from 'lib/contact'
import modal from 'lib/modal'
import user from 'lib/user'
import potential_client from 'lib/potential_client'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    // Add sync reducers here
    [account.constants.KEY]: account.reducer,
    [beneficiary.constants.KEY]: beneficiary.reducer,
    [notification.constants.KEY]: notification.reducer,
    [contact.constants.KEY]: contact.reducer,
    [modal.constants.KEY]: modal.reducer,
    [user.constants.KEY]: user.reducer,
    [potential_client.constants.KEY]: potential_client.reducer,
    form: formReducer,
    router,
    ...asyncReducers,
  })
}

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
