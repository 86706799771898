// @flow
import React from 'react'
import styles from './SearchResult.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import formatters from 'lib/formatters'

type Props = {
  contact: any,
}

export function SearchResultItem({ contact }: Props) {
  const { mergedBeneficiaries } = contact

  function renderLink(number, role) {
    if (role == 'Potential Client') {
      return (
        <Link
          className={styles.contactAccountNumber}
          to={`/potential_clients/edit/${number}`}
        >
          PC{number}
        </Link>
      )
    } else {
      return (
        <Link
          className={styles.contactAccountNumber}
          to={`/accounts/${number}`}
        >
          {number}
        </Link>
      )
    }
  }

  return (
    <div className={styles.contact}>
      <div className={styles.contactHeader}>
        <div className={styles.contactHeaderRow}>
          <Link
            to={`/contacts/${contact.id}/edit`}
            className={styles.refEdit}
          />
          <label className={styles.contactHeaderRow__name}>
            {contact.name}
            {contact.has_open_ticket && (
              <FontAwesomeIcon
                className={styles.ticketIcon}
                icon={'ticket-alt'}
                transform={{ rotate: -45 }}
              />
            )}
          </label>
          <span>{`${mergedBeneficiaries.length} Accounts`}</span>
        </div>

        <div className={styles.contactHeaderRow}>
          <label>Email</label>
          <a href={`mailto:${contact.email}`}>{contact.email || 'N/A'}</a>
        </div>

        <div className={styles.contactHeaderRow}>
          <label>Cell Number</label>
          <span>
            {contact.phone_numbers.cell_phone
              ? formatters.phone(contact.phone_numbers.cell_phone)
              : 'N/A'}
          </span>
        </div>

        <div className={styles.contactHeaderRow}>
          <label>Day Number</label>
          <span>
            {contact.phone_numbers.day_phone
              ? formatters.phone(contact.phone_numbers.day_phone)
              : 'N/A'}
          </span>
        </div>
      </div>
      {mergedBeneficiaries && mergedBeneficiaries.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Account</th>
              <th>Role</th>
              <th>Beneficiary</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {mergedBeneficiaries.map(({ number, beneficiary, role }, index) => (
              <tr key={`${number} ${index}`}>
                <td>{renderLink(number, role)}</td>
                <td>{role}</td>
                <td>
                  <Link to={`/beneficiaries/show/${beneficiary.id}`}>
                    {beneficiary.name}
                  </Link>
                </td>
                <td>
                  {beneficiary.has_open_ticket && (
                    <FontAwesomeIcon
                      className={styles.ticketIcon}
                      icon={'ticket-alt'}
                      transform={{ rotate: -45 }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
