import * as t from './actionTypes'
import { httpGet } from 'lib/http'

export const getCoTrusteeTickets = () => {
  return (dispatch: Function) => {
    return httpGet('/co-trustee/tickets')
      .then((data) => {
        dispatch({ type: t.INDEX, tickets: data })
      })
  }
}
