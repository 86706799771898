// @flow
import React from 'react'
import logo from 'styles/img/logo-2020-cropped.png'
import styles from './styles'

type Props = {
  address: Object
}

export const RecipientAddress = ({address}: Props) => {
  if (!address) return null

  const {
    name,
    street_1,
    street_2,
    city,
    state,
    zip
  } = address

  return (
    <div className={styles.address}>
      <img src={logo} className={styles.logo} alt="Guardian Trust" />
      <p className={styles.fromAddress}>
        901 Chestnut Street, Suite C<br />
        Clearwater, Florida 33756<br />
    </p>
    <p className={styles.toAddress}>
      {name}
      <br />
      {street_1}
      <br />
      {street_2 && (
        <span>
          {street_2}
          <br />
        </span>
      )}
      {city}, {state} {zip}
    </p>
    </div>
  )
}
