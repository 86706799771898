// @flow
import React, { useState, useEffect } from 'react'
import { useHistorySearch } from '../utils'
import styles from './RecentSearches.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

type Props = {
  onItemClick: Function,
}

export function RecentSearches({ onItemClick }: Props) {
  const { getHistory } = useHistorySearch()
  const [searchHistory, setSearchHistory] = useState([])

  useEffect(() => {
    setSearchHistory(() => getHistory())
  }, [setSearchHistory])

  return (
    <div className={styles.container}>
      <label className={styles.title}>Recent searches</label>

      <div>
        {searchHistory.map((it, idx) => (
          <div
            key={idx}
            className={styles.item}
            role="button"
            onClick={() => onItemClick(it, idx)}
          >
            <FontAwesomeIcon
              size="lg"
              className={styles.autoCompleteItems_icon}
              icon={['fas', 'clock']}
            />
            <div className={styles.itemLabel}>
              <span>
                {it.type === 'query' && it.value}
                {it.type === 'account' && it.value.number}
                {it.type === 'contact' && it.value.name}
              </span>
              {it.type === 'account' && (
                <span className={styles.small}>{it.value.name}</span>
              )}
            </div>
            <FontAwesomeIcon
              size="lg"
              className={styles.linkIcon}
              icon={[
                'fas',
                it.type === 'account' ? 'external-link-alt' : 'search',
              ]}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
