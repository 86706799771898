// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../styles/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Overlay__overlay___JPgMB {
  position: relative;
  overflow: hidden;
}

.Overlay__overlay___JPgMB .Overlay__iconWrapper___eaxQR {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.Overlay__overlay___JPgMB .Overlay__iconWrapper___eaxQR .Overlay__icon___Dg4KU {
      color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]};
      width: 10%;
      max-width: 70px;
      max-height: 70px;
      height: auto;
    }

.Overlay__overlay___JPgMB.Overlay__loading___U1fi8 .Overlay__loader___XxZFt {
  filter: blur(3px);
}
`, "",{"version":3,"sources":["webpack://./universal/components/Overlay/Overlay.css"],"names":[],"mappings":"AAGA;EACE,kBAAkB;EAClB,gBAAgB;AAuBlB;;AArBE;IACE,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,qCAAqC;IACrC,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EASzB;;AAPE;MACE,mDAAa;MACb,UAAU;MACV,eAAe;MACf,gBAAgB;MAChB,YAAY;IACd;;AAIJ;EACE,iBAAiB;AACnB","sourcesContent":["@value colors: \"styles/colors.css\";\n@value gtBlue from colors;\n\n.overlay {\n  position: relative;\n  overflow: hidden;\n\n  .iconWrapper {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    background: rgba(255, 255, 255, 0.75);\n    z-index: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    .icon {\n      color: gtBlue;\n      width: 10%;\n      max-width: 70px;\n      max-height: 70px;\n      height: auto;\n    }\n  }\n}\n\n.overlay.loading .loader {\n  filter: blur(3px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"styles/colors.css"`,
	"gtBlue": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["gtBlue"]}`,
	"overlay": `Overlay__overlay___JPgMB`,
	"iconWrapper": `Overlay__iconWrapper___eaxQR`,
	"icon": `Overlay__icon___Dg4KU`,
	"loading": `Overlay__loading___U1fi8`,
	"loader": `Overlay__loader___XxZFt`
};
export default ___CSS_LOADER_EXPORT___;
