import * as t from './actionTypes'
import { State } from './model'

const initialState: State = {
  modal: {
    contents: '',
  },
}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case t.SET_MODAL:
      return {
        ...state,
        modal: {
          contents: action.contents,
        },
      }
    default:
      return state
  }
}
