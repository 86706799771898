import * as t from './actionTypes'
import { State } from './model'

const initialState: State = {}

export default (state = initialState, action: any): State => {
  switch (action.type) {
    case t.SET:
      return action.account
    case t.REMOVE_DELETED_DOCUMENT:
      return {...state, folders: removeFile(state.folders, action)}
    case t.SET_DOCUMENT_FOLDERS:
      return {...state, folders: action.folders}
    case t.SET_UPLOAD_STATUS:
      return {...state, upload_status: action.status}
    default:
      return state
  }
}

const removeFile = (folders, action) => {
  let newFolders = []

  for (let i=0; i<folders.length; i++) {
    let curFolder = folders[i]

    if (curFolder.name != action.folder) {
      newFolders.push(curFolder)
    } else {
      const filteredFiles = curFolder.files.filter(f => f.id != action.file_id)
      curFolder.files = filteredFiles
      newFolders.push(curFolder)
    }
  }

  return newFolders
}
