// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../../styles/helpers.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__contentContainer___MqL09 {
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 0;
  padding-top: 20px;
}

.styles__contentContainer___MqL09:before {
  content: '';
  display: table;
}

.styles__contentContainer___MqL09:after {
  content: '';
  display: table;
  clear: both;
}

.styles__pageContent___svs9r {
  width: 100%;
}

.styles__searchHeader___OlW6A {
  display: flex;
}

.styles__filterBtn___tXDxB {
  font-size: 15px;
  font-weight: 700;
  margin-left: 30px;
  height: 50px;
  width: 159px;
  border-radius: 3px;
  background-color: #59A198;
  border: none;
  color: white;
}

.styles__resultsContainer___nXHYK {
  padding-top: 32px;
}

.styles__resultsSummary___guy1a {
  padding-bottom: 32px;
}

.styles__header___P8o4W {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(195, 195, 195, 0.45);
  margin-bottom: 18px;
  margin-top: 60px;
}

.styles__pageTitle___IKxCa {
  font-size: 32px;
  font-weight: 500;
}

.styles__buttonSecondary___iPprF {
  margin-right: 20px;
}

.styles__buttonSecondary___iPprF:first-child {
    margin-left: 0;
  }

.styles__newContactButton___exWWv {
  margin-right: 0;
}

.styles__icon___Pm51w {
  color: #6a93b9;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./universal/routes/Protected/Contacts/components/Search/styles.css"],"names":[],"mappings":"AAEA;EAEE,aAAwB;EAAxB,mBAAwB;EAExB,iBAAiB;EACjB,iBAAiB;AACnB;;AANA;EAGE,WAAsB;EAAtB,cAAsB;AAGxB;;AANA;EAGE,WAAsB;EAAtB,cAAsB;EAAtB,WAAsB;AAGxB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,8BAA8B;EAC9B,kDAAkD;EAClD,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EAEE,kBAAkB;AAKpB;;AAHE;IACE,cAAc;EAChB;;AAGF;EAEE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["@value helpers: \"styles/helpers.css\";\n\n.contentContainer {\n  composes: content-container from helpers;\n  lost-flex-container: row;\n  lost-utility: clearfix;\n  padding-bottom: 0;\n  padding-top: 20px;\n}\n\n.pageContent {\n  width: 100%;\n}\n\n.searchHeader {\n  display: flex;\n}\n\n.filterBtn {\n  font-size: 15px;\n  font-weight: 700;\n  margin-left: 30px;\n  height: 50px;\n  width: 159px;\n  border-radius: 3px;\n  background-color: #59A198;\n  border: none;\n  color: white;\n}\n\n.resultsContainer {\n  padding-top: 32px;\n}\n\n.resultsSummary {\n  padding-bottom: 32px;\n}\n\n.header {\n  padding-bottom: 24px;\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid rgba(195, 195, 195, 0.45);\n  margin-bottom: 18px;\n  margin-top: 60px;\n}\n\n.pageTitle {\n  font-size: 32px;\n  font-weight: 500;\n}\n\n.buttonSecondary {\n  composes: buttonSecondary from helpers;\n  margin-right: 20px;\n\n  &:first-child {\n    margin-left: 0;\n  }\n}\n\n.newContactButton {\n  composes: buttonSecondary;\n  margin-right: 0;\n}\n\n.icon {\n  color: #6a93b9;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpers": `"styles/helpers.css"`,
	"contentContainer": `styles__contentContainer___MqL09 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["content-container"]}`,
	"pageContent": `styles__pageContent___svs9r`,
	"searchHeader": `styles__searchHeader___OlW6A`,
	"filterBtn": `styles__filterBtn___tXDxB`,
	"resultsContainer": `styles__resultsContainer___nXHYK`,
	"resultsSummary": `styles__resultsSummary___guy1a`,
	"header": `styles__header___P8o4W`,
	"pageTitle": `styles__pageTitle___IKxCa`,
	"buttonSecondary": `styles__buttonSecondary___iPprF ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonSecondary"]}`,
	"newContactButton": `styles__newContactButton___exWWv styles__buttonSecondary___iPprF ${___CSS_LOADER_ICSS_IMPORT_0___.locals["buttonSecondary"]}`,
	"icon": `styles__icon___Pm51w`
};
export default ___CSS_LOADER_EXPORT___;
