// @flow
import React from 'react'
import formatters from 'lib/formatters'
import Row from './Row'
import { Link } from 'react-router-dom'
import Ref from 'components/References/Ref'

type Props = {
  account: Object,
  beneficiary: Object,
  number: String,
}

const ClosingRef = (props: Props) => {
  const {
    account,
    beneficiary
  } = props

  const le = account.last_closing_event ?
              account.last_closing_event.event :
              null

  const ln = account.last_closing_note ?
              account.last_closing_note.note :
              null

  const showEditLink = ({ number }) => <Link
    to={`/closings/edit/${number}`}>{'View/Edit'}
  </Link>

  const dodDisplay = beneficiary && beneficiary.dod != '1900-01-01' ?
                    formatters.date(beneficiary.dod) :
                    'Not Set'

  return (
    <Ref
      title="Closing Summary"
      preview={
        <div>
          <Row
            singleLine
            label="Status"
            val={formatters.toTitleCase(account.status)}
          />
          <Row
            singleLine
            label="Date of Death"
            val={dodDisplay}
            mono={true}
          />
          <Row
            singleLine
            label="Latest Event"
            val={le}
          />
          <Row
            singleLine
            label="Latest Note"
            val={ln}
          />
          <Row
            singleLine
            label=""
            val={showEditLink(account)}
            mono={true}
          />
        </div>
      }
    />
  )
}

export default ClosingRef
