// @flow
import { httpGet, httpPost } from 'lib/http'
import Rollbar from 'lib/rollbar'

export const fetchStatus = () => {
  return () => {
    return new Promise((resolve, reject) => {
      httpGet('/co-trustee/xero')
        .then((data) => {
          resolve(data)
        })
        .catch((error) => {
          Rollbar.error('Problem calling fetchStatus()', error)
          reject(error)
        })
    })
  }
}

export const submitCode = (code: String) => {
  return () => {
    return new Promise((resolve, reject) => {
      httpPost('/co-trustee/xero/auth-code', { code })
        .then(() => {
          resolve()
        })
        .catch((error) => {
          console.log('error', error)
          Rollbar.error('Problem calling submitCode(code)', error)
          reject(error)
        })
    })
  }
}
